import React from 'react';

import { EditableInfoContainer, EditableInfoInput, EditableInfoIcon } from './components';

const CustomEditableInfo = ({ icon, error, value, onChange }) => {
  return (
    <EditableInfoContainer>
      <EditableInfoIcon icon={icon} />
      <EditableInfoInput value={value} onChange={(e) => onChange(e.target.value)} disabled />
    </EditableInfoContainer>
  );
};

export default CustomEditableInfo;
