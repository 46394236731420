import React from 'react';

import ICONS from '../../constants/icons';
import {
  CardContainer,
  CardHeader,
  CardHeaderIconCell,
  HeaderProfileCell,
  ProfilePic,
  MessengerIcon,
  ParticipantCardBody,
  CardName,
  CardLevel,
  ParticipantCardFooter,
  ParticipantHealthCell,
  ParticipantHealthCellValue,
  ParticipantPhysicsWrapper,
  ParticipantPhysic,
  CardFooter,
  FooterLine,
} from './components';

const ParticipantCard = ({ participant }) => {
  return (
    <CardContainer
      style={{
        minWidth: '24rem',
        width: 'max(26rem, Calc(100% / 6 - 2.4rem))',
        height: 'fit-content',
      }}
    >
      <CardHeader style={{ background: '#fafafa' }}>
        <CardHeaderIconCell />
        <HeaderProfileCell>
          <ProfilePic profilePic={participant?.avatar?.url || ''}>
            {!participant?.avatar?.url &&
              participant?.fullName
                ?.split(' ')
                .map((x) => x.substring(0, 1))
                .join('')}
          </ProfilePic>
        </HeaderProfileCell>
        <CardHeaderIconCell>
          <MessengerIcon active={'message'} />
        </CardHeaderIconCell>
      </CardHeader>
      <ParticipantCardBody>
        <CardName
          style={{
            marginBottom: '4px',
            color: '#373333dd',
            height: '4rem',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {participant?.fullName}
        </CardName>
        <CardLevel style={{ marginBottom: '1.2rem' }}>
          {participant?.programs?.[0]?.fitnessLevel}
        </CardLevel>
        <ParticipantPhysicsWrapper>
          <ParticipantPhysic
            physIcon={ICONS.Weight}
          >{`${participant?.weight}kg`}</ParticipantPhysic>
          <ParticipantPhysic physIcon={ICONS.Height}>{participant?.height}</ParticipantPhysic>
          <ParticipantPhysic physIcon={ICONS.Age}>{`${participant?.age}y`}</ParticipantPhysic>
        </ParticipantPhysicsWrapper>
      </ParticipantCardBody>
      <ParticipantCardFooter>
        <ParticipantHealthCell>
          Health Status
          <ParticipantHealthCellValue>
            {(participant?.programs?.[0]?.healthCondition &&
              participant?.programs?.[0]?.healthConditionDetails) ||
              'Healthy'}
          </ParticipantHealthCellValue>
        </ParticipantHealthCell>
        <ParticipantHealthCell>
          Injuries
          <ParticipantHealthCellValue>
            {`${
              participant?.programs?.[0]?.injuryDetails ||
              participant?.programs?.[0]?.injury ||
              'None'
            }`}
          </ParticipantHealthCellValue>
        </ParticipantHealthCell>
      </ParticipantCardFooter>
      <CardFooter style={{ height: '9px', background: '#fafafa' }}>
        <FooterLine />
        <FooterLine />
      </CardFooter>
    </CardContainer>
  );
};

export default ParticipantCard;
