import styled from 'styled-components';

export const TemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 'fit-content';
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 2rem;
`;

export const TemplateImage = styled.div`
  width: ${({ size }) => size || '29rem'};
  height: ${({ size }) => size || '29rem'};
  border-radius: 50%;
  border: 1rem solid #ffffff;
  box-shadow: 0 0 0 3px #00ff91;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

export const TemplateGridSquare = styled.div`
  width: 33%;
  border-right: 1px solid #ffffff80;
  border-top: 1px solid #ffffff80;
  flex-grow: 1;
`;

export const TemplatePrimaryText = styled.div`
  font-family: Space-r;
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 4.8rem auto 1.6rem;
  text-align: center;
`;

export const TemplateSecondaryText = styled.div`
  font-family: Roboto-l;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #05000099;
  max-width: 48.8rem;
  text-align: center;
`;
