import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ColumnsWrapper = styled.div`
  width: 100%;
  max-height: Calc(100% - 11.4rem);
  flex-grow: 1;
  background: #ffffff;
  display: flex;
`;

export const ModalColumn = styled.div`
  width: ${({ main }) => (main ? '50%' : '25%')};
  flex-grow: 1;
  border-right: ${({ left }) => (left ? '1px solid #05000033' : 'none')};
  border-left: ${({ right }) => (right ? '1px solid #05000033' : 'none')};
  background: ${({ left }) => (left ? '#ffffff' : '#F1F0F0')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalHeader = styled.div`
  width: 100%;
  min-height: 6rem;
  display: flex;
  align-items: center;
  line-height: 2.4rem;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Space-b')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  font-size: 2.2rem;
  padding: 0 2.8rem;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  background: #ffffff;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 3.2rem;
  border-top: 1px solid #05000033;
`;

export const ModalHeaderCenterLine = styled.div`
  width: 1px;
  height: 100%;
  background: #05000033;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const LeftSideBlock = styled.div`
  width: 100%;
  padding: 2rem 3.2rem 3.8rem;
  border-bottom: 1px solid #05000033;
  &:last-child {
    border-bottom: none;
  }
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const LeftSideBlockTitle = styled.div`
  font-size: 1.6rem;
  font-family: Roboto-r;
  line-height: 2.1rem;
  margin: 1.6rem 0;
  display: flex;
  align-items: center;
`;

export const LeftSideBlockOptional = styled.span`
  font-size: 1.4rem;
  font-family: Roboto-r;
  line-height: 2.1rem;
  color: #0500004d;
  margin-left: 0.8rem;
  letter-spacing: 0.08rem;
`;

export const MessageCheck = styled.div`
  color: ${({ active }) => (active ? '#050000cc' : '#05000066')};
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  margin: 22px 0 12px;
  cursor: pointer;
  & > .checkbox {
    background-image: url(${({ active }) => (active ? ICONS.TermsCheckMark : 'none')});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-color: ${({ theme, active }) => (active ? theme.color.primary : '#05000033')};
  }
  overflow: hidden;
`;

export const MessageCheckBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #05000033;
  margin-right: 16px;
`;

export const ContentContainer = styled.div`
  padding: 2rem 3.2rem;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const ContentWrapper = styled.div`
  max-height: Calc(100% - 6rem);
  height: 100%;
  width: 100%;
  padding: 1.2rem 0.4rem;
`;

export const WorkoutBlockDropBox = styled.div`
  width: 100%;
  padding-bottom: 24%;
  background: #ffffff;
  min-height: 104px;
  border-radius: 12px;
  position: relative;
`;

export const WorkoutBlockDropBoxDashed = styled.div`
  position: absolute;
  width: Calc(100% - 1.2rem);
  height: Calc(100% - 1.2rem);
  top: 0.6rem;
  left: 0.6rem;
  border: 1px dashed #96949499;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  font-family: Space-r;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 2rem;
  border-color: ${({ error, theme }) => (error ? theme.color.error : '#96949499')};
  color: ${({ error, theme }) => (error ? theme.color.error : '#0500004d')};
  &:hover {
    border-color: ${({ error, theme }) => (error ? theme.color.error : '#15df68')};
  }
  cursor: pointer;
`;

export const WorkoutBlockDropBoxPlus = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${ICONS.PlusSimple});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0.4rem;
  position: relative;
`;

export const TooltipWorkoutBlock = styled.div`
  opacity: 80%;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0 1.4rem;
  font-family: Roboto-r;
  overflow: hidden;
  color: #050000;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 100%;
    background: #00ff911f;
  }
  line-height: 3.6rem;
`;

export const TooltipBlocksWrapper = styled.div`
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
  height: 100%;
  overflow: auto;
  max-height: 21rem;
`;

export const RightColumnContainer = styled.div`
  width: 100%;
  padding: 0.8rem;
  flex-grow: 1;
  max-height: Calc(100% - 6rem);
`;

export const WorkoutBlocksBoxScrollable = styled.div`
  width: 100%;
  max-height: 100%;
  height: 100%;
  background: white;
  padding: 1.2rem 0.2rem;
  border-radius: 8px;
`;

export const WorkoutBlocksBox = styled.div`
  padding: 1.4rem;
  overflow: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
  width: 100%;
  height: 100%;
  /* height: Calc(100% - 6rem);
  min-height: Calc(100% - 6rem); */
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Gotham-r;
  line-height: 2.4rem;
  color: #4a4a4acc;
`;

export const DraggableSign = styled.div`
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  background: #f1f0f0;
  margin-bottom: 2rem;
  background-image: url(${ICONS.DragIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const WorkoutBlocksContainer = styled.div`
  width: 100%;
  margin-top: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const WorkoutBlockItem = styled.div`
  width: Calc(50% - 1.6rem);
  height: 6.4rem;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: move;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding: 1.4rem 1.6rem 1rem;
  text-align: center;
  border-color: ${({ active, theme }) => (active ? theme.color.primary : 'transparent')};
  background: ${({ active }) => (active ? '#ffffff' : '#f1f0f0')};
  color: ${({ active }) => (active ? '#050000' : '#373333')};
`;

export const PseudoWorkoutBlock = styled.div`
  width: Calc(50% - 1.6rem);
`;

export const WorkoutBlockForm = styled.div`
  width: 100%;
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 3px rgba(74, 74, 74, 0.12);
  margin-bottom: 16px;
`;

export const WorkoutBlockHeader = styled.div`
  width: 100%;
  border-radius: 12px 12px 0 0;
  padding: 2.2rem 2.4rem;
  border-bottom: 1px solid #05000033;
  font-family: Space-b;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #050000cc;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const WorkoutBlockHeaderIconsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const WorkoutBlockHeaderIcon = styled.div`
  width: 2rem;
  height: 2rem;
  background-image: ${({ edit, dublicate }) =>
    edit
      ? `url(${ICONS.EditSimpleGrey})`
      : dublicate
      ? `url(${ICONS.Copy})`
      : `url(${ICONS.BinSimpleGrey})`};
  background-size: contain;
  background-position: center;
  margin: 0 0.4rem;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const WorkoutBlockFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1.6rem;
`;

export const AttrCheckBox = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid #05000033;
  margin-right: 1.2rem;
  background-color: ${({ theme, active }) => (active ? theme.color.primary : 'transparent')};
  background-image: url(${({ active }) => (active ? ICONS.TermsCheckMark : 'none')});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-color: ${({ theme, active }) => (active ? theme.color.primary : '#05000033')};
  cursor: pointer;
`;

export const WorkoutBlockApplyButton = styled.div`
  width: 124px;
  height: 40px;
`;

export const WorkoutBlockContent = styled.div`
  padding: 4rem 3.2rem 3.2rem;
  width: 100%;
`;

export const AttributeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.6rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AttributeLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #050000cc;
  line-height: 2.1rem;
  padding: ${({ small }) => (small ? '1rem 2rem 1rem 0' : '1rem 0')};
  flex-grow: ${({ flexGrow }) => (flexGrow ? '1' : 'none')};
  width: ${({ flexGrow, small }) => (flexGrow ? '100%' : small ? '12rem' : '30%')};
  min-width: ${({ small }) => (small ? '12rem' : '18.6rem')};
`;
export const AttributeUnit = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #050000cc;
  line-height: 4rem;
  padding-left: 1rem;
`;

export const AsNeeded = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #050000cc;
  line-height: 2.1rem;
`;

export const AsNeededCheck = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 30%;
  border: 1px solid ${({ checked, theme }) => (checked ? theme.color.primary : '#0500003e')};
  margin-right: 0.8rem;
  margin-left: 2rem;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ checked }) => checked && ICONS.TermsCheckMark});
`;

export const AttributeBullet = styled.div`
  width: ${({ clock }) => (clock ? '12px' : '8px')};
  height: ${({ clock }) => (clock ? '12px' : '8px')};
  margin: ${({ clock }) => (clock ? '0' : '2px')};
  border-radius: 50%;
  background: ${({ theme, clock }) => (clock ? 'transparent' : theme.color.primary)};
  background-image: url(${ICONS.Clock});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.2rem;
`;

export const ExerciseWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1.6rem;
  align-items: center;
`;

export const ExerciseLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Roboto-l;
  color: #373333cc;
  min-width: 18.6rem;
  width: 30%;
`;

export const ExerciseIndex = styled.span`
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Space-r;
  padding-right: 1.6rem;
  color: ${({ transparent }) => (transparent ? '#ffffff00' : '#050000')};
`;

export const ExerciseOpenButton = styled.span`
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(${ICONS.OptionView});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1rem;
  cursor: pointer;
`;

export const ExerciseContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
`;

export const DeleteExerciseIcon = styled.div`
  width: 1.8rem;
  height: ${({ nonAbsolute }) => (nonAbsolute ? '2rem' : '100%')};
  background-image: url(${ICONS.BinSimpleGrey});
  background-size: 92% auto;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: ${({ nonAbsolute }) => (nonAbsolute ? 'auto' : 'absolute')};
  left: Calc(100% + 0.6rem);
  &:hover {
    background-size: 100% auto;
  }
`;

export const ExerciseWeight = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WorkoutBlockTable = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #f7f7f7;
  display: flex;
`;

export const WorkoutBlockTableColumnLeft = styled.div`
  width: 42%;
  border-radius: 8px 0 0 8px;
  border-right: 1px solid #0500000a;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const WorkoutBlockTableAttributeRow = styled.div`
  width: 100%;
  height: 3.6rem;
  padding: 0 1.7rem;
  display: flex;
  align-items: center;
  border-top: 1px solid #0500000a;
  &:first-child {
    border-top: none;
  }
`;
export const WorkoutBlockTableAttributeNum = styled.span`
  font-size: 1.6rem;
  font-family: Space-b;
  color: #05000099;
  line-height: 2.4rem;
  padding-right: 0.8rem;
`;
export const WorkoutBlockTableAttributeLabel = styled.span`
  padding-right: 0.8rem;
  font-size: 1.4rem;
  font-family: Roboto-m;
  color: #05000099;
  line-height: 2.4rem;
`;
export const WorkoutBlockTableColumnRight = styled.div`
  flex-grow: 1;
  border-radius: 0 8px 8px 0;
  padding: 0.4rem 1.2rem 0.4rem 1.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WorkoutBlockWorkoutRow = styled.div`
  width: 100%;
  margin: 0.4rem 0;
  background: white;
  border-radius: 8px;
  height: 4.2rem;
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
`;
export const CustomBlockDescriptionLine = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-family: Roboto-m;
  color: #05000099;
  line-height: 2.4rem;
  overflow: hidden;
  min-height: 2.4rem;
`;
export const CustomBlockDescription = styled.div`
  width: 100%;
  margin: 0.4rem 0;
  font-size: 1.4rem;
  font-family: Roboto-m;
  color: #05000099;
  line-height: 2.4rem;
  padding: 1rem 1.6rem;
  overflow: hidden;
`;
export const WorkoutBlockWorkoutIndex = styled.span`
  padding-right: 1.2rem;
  font-family: Space-r;
  font-size: 1.2rem;
`;
export const WorkoutBlockWorkoutName = styled.span`
  padding-right: 1.4rem;
  font-family: Roboto-r;
  font-size: 1.2rem;
  color: #05000099;
`;
export const WorkoutBlockWorkoutValue = styled.span`
  padding-right: 1.4rem;
  font-family: Roboto-l;
  font-size: 1.2rem;
  color: #373333cc;
`;
export const WorkoutBlockComment = styled.div`
  padding-left: 3.6rem;
  min-height: 2.5rem;
  font-family: Roboto-l;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background-image: url(${ICONS.Comment});
  background-size: 2.5rem auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-top: 2rem;
`;
export const WorkoutBlockAdditionalAttrWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const WorkoutBlockAdditionalAttr = styled.div`
  padding-left: 2.8rem;
  font-family: Roboto-r;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background-image: url(${ICONS.CheckMark});
  background-size: 1.6rem auto;
  background-position: 0 center;
  background-repeat: no-repeat;
`;

export const WorkoutBlockOrderArrow = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 8px;
  border: 1px solid #f1f0f0;
  margin-right: 4px;
  background-image: url(${ICONS.ArrowUpLight});
  transform: ${({ down }) => (down ? 'rotate(180deg)' : 'none')};
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: center;
  &:last-child {
    margin-right: 2rem;
  }
  ${({ nonClickable }) =>
    nonClickable
      ? `
      opacity: 0.6;
      `
      : `
  &:hover {
    background: #f1f0f0;
    background-image: url(${ICONS.ArrowUpDark});
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center;
  }
  cursor: pointer;
  `}
`;
