import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
  CropContainer,
  ModalHeading,
  ButtonsWrapper,
  EditSaveButtonWrapper,
  FlexWrapper,
  AutocropButtonWrapper,
} from './components';
import CustomButton from '../button/CustomButton';

const customStyles = {
  overlay: {
    backgroundColor: '#000000bb',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: '80vh',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '8px',
    border: 'none',
    padding: '0 ',
  },
};

const CropImageModal = ({ image, closeModal, onSubmit }) => {
  const [crop, setCrop] = useState({ aspect: 1 });
  const [initialCrop, setInitialCrop] = useState({});
  const [imageDimentions, setImageDimensions] = useState(null);

  const onImageLoaded = (image) => {
    const { width, height } = image;
    setImageDimensions({ w: width, h: height });
    const initialSize = Math.min(width, height) - 40;
    const x = (width - initialSize) / 2;
    const y = (height - initialSize) / 2;
    setCrop((curr) => ({ ...curr, width: initialSize, height: initialSize, x, y }));
    setInitialCrop((curr) => ({
      ...curr,
      width: initialSize + 40,
      height: initialSize + 40,
      x: x - 20,
      y: y - 20,
    }));
    return false;
  };

  const cropImage = async () => {
    const img = new Image();
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');
      const scaleX = img.width / imageDimentions.w;
      const scaleY = img.height / imageDimentions.h;
      ctx.drawImage(
        img,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );
      const [file, blob] = await new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], 'filenametttt');
            resolve([file, canvas.toDataURL()]);
          },
          'image/jpeg',
          1,
        );
      });

      onSubmit(file, blob);
      closeModal();
    };
    img.src = image;
  };

  return (
    <Modal
      onRequestClose={() => {}}
      isOpen={!!image}
      style={customStyles}
      contentLabel="Crop Image"
      ariaHideApp={false}
    >
      <CropContainer>
        <ReactCrop
          src={image}
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          minHeight={100}
          minWidth={100}
          onImageLoaded={onImageLoaded}
        />
      </CropContainer>
      <ModalHeading>Crop Your Image Before Saving</ModalHeading>
      <ButtonsWrapper>
        <AutocropButtonWrapper>
          <CustomButton
            height="40px"
            fontSize="1.4rem"
            onClick={() => setCrop((curr) => ({ ...curr, ...initialCrop }))}
          >
            Autocrop
          </CustomButton>
        </AutocropButtonWrapper>
        <FlexWrapper>
          <EditSaveButtonWrapper>
            <CustomButton height="40px" fontSize="1.4rem" onClick={closeModal}>
              Cancel
            </CustomButton>
          </EditSaveButtonWrapper>
          <EditSaveButtonWrapper>
            <CustomButton height="40px" fontSize="1.4rem" green onClick={cropImage}>
              Save
            </CustomButton>
          </EditSaveButtonWrapper>
        </FlexWrapper>
      </ButtonsWrapper>
    </Modal>
  );
};

export default CropImageModal;
