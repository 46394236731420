import {
  createCoachExerciseMutation,
  editExerciseMutation,
  getExercisesQuery,
} from '@witness/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useState, useEffect } from 'react';

const useExercise = () => {
  const [searchText, setSearchText] = useState(null);

  const { data: exercises, loading: exercisesLoading, refetch: exercisesRefetch } = useQuery(
    getExercisesQuery,
    {
      variables: {
        record: {
          searchText,
        },
      },
      skip: !searchText,
    },
  );

  const [createCoachExercise] = useMutation(createCoachExerciseMutation);
  const [editExercise] = useMutation(editExerciseMutation);

  const createNewExercise = async ({ id, title, type, video, preview }) => {
    if (id) {
      const edited = await editExercise({
        variables: {
          record: {
            id,
            title,
            type,
            video: (preview && video) || null,
          },
        },
      });
      return edited?.data?.editExercise;
    } else {
      const added = await createCoachExercise({
        variables: {
          record: {
            title,
            type,
            video,
          },
        },
      });
      return added?.data?.createCoachExercise;
    }
  };

  useEffect(() => {
    exercisesRefetch();
  }, [searchText, exercisesRefetch]);

  return { exercises, searchText, setSearchText, exercisesLoading, createNewExercise };
};

export default useExercise;
