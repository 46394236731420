import React, { useCallback, useContext, useMemo } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import CustomInput from '../../components/input/CustomInput';
import ChatContext from '../../services/ChatContext';
import AttachmentButton from './AttachmentButton';
import {
  Avatar,
  AvatarName,
  ConversationsHeader,
  MessageListContainer,
  ChatFooter,
  SendButton,
  MessageInputContainer,
  Loading,
  SendingIndicator,
  FetchingIndicator,
} from './components';
import Message from './Message';

const MessageList = ({ conversation, messages = [], hideHeader }) => {
  const {
    sendTextMessage,
    sendMediaMessage,
    fetchMoreMessages,
    hasMoreMessages,
    loadingMessages,
    sendingMessage,
    fetchingMoreMessages,
  } = useContext(ChatContext);

  const [messageText, setMessageText] = useState('');

  const handleMessageSend = useCallback(() => {
    if (!sendingMessage) {
      sendTextMessage(messageText).then(() => {
        setMessageText('');
      });
    }
  }, [sendTextMessage, messageText, sendingMessage]);

  const messageListBottomRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (messageListBottomRef?.current) {
        messageListBottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, [500]);
  }, [conversation, sendingMessage]);

  const handleScrollToTop = useCallback(
    (event) => {
      if (conversation && !conversation.isNew && event.target.scrollTop === 0 && hasMoreMessages) {
        fetchMoreMessages();
      }
    },
    [fetchMoreMessages, hasMoreMessages, conversation],
  );

  const initials = useMemo(() => {
    const [first, last] = conversation?.user?.name?.split(' ') || ['', ''];
    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`;
  }, [conversation]);

  return (
    <>
      {!hideHeader && (
        <ConversationsHeader>
          <Avatar src={conversation.user.avatar} size="4.2rem">
            {initials}
          </Avatar>
          <AvatarName>{conversation.user.name}</AvatarName>
        </ConversationsHeader>
      )}
      <MessageListContainer onScroll={handleScrollToTop}>
        {loadingMessages && <Loading>Loading...</Loading>}

        {fetchingMoreMessages && <FetchingIndicator>Loading more messages...</FetchingIndicator>}

        {messages.map((message, index) => (
          <Message
            key={message.id}
            message={message}
            showAvatar={
              index === 0 ||
              (message?.user?.id && message?.user?.id !== messages[index - 1]?.user?.id)
            }
            isLast={messages.length === index + 1}
          />
        ))}

        {sendingMessage && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginTop: 'auto',
              paddingTop: '2.2rem',
              paddingRight: '1rem',
            }}
          >
            <SendingIndicator>Sending...</SendingIndicator>
          </div>
        )}

        <div className="it-scrolls-to-here">
          <div ref={messageListBottomRef} />
        </div>
      </MessageListContainer>
      <ChatFooter>
        <AttachmentButton
          accept="image/*,video/*"
          onChange={(e) => sendMediaMessage(e.target.files[0])}
        />
        <MessageInputContainer>
          <CustomInput
            fill
            height="4.8rem"
            margin="0"
            onChange={(v) => setMessageText(v)}
            value={messageText}
            borderRadius="8px"
            backgroundColor="#F7F6F6"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                handleMessageSend();
              }
            }}
          />
        </MessageInputContainer>
        <SendButton onClick={handleMessageSend} />
      </ChatFooter>
    </>
  );
};

export default MessageList;
