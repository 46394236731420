import React from 'react';
import ReactSelect, { components } from 'react-select';
import { OptionName, OptionCircle, OptionRow } from './components';

const Option = (props) => {
  return (
    <components.Option {...props}>
      <OptionRow>
        {props.data.value && <OptionCircle className="option_icon" status={props.data.value} />}
        <OptionName>{props.data.label}</OptionName>
      </OptionRow>
    </components.Option>
  );
};

const Value = (props) => {
  const data = props.getValue()[0];
  return (
    <components.ValueContainer {...props}>
      <OptionRow>
        {data.value && <OptionCircle className="option_icon" status={data.value} />}
        {props.hasValue && <OptionName>{data.label}</OptionName>}
      </OptionRow>
    </components.ValueContainer>
  );
};

const styles = ({ error }) => ({
  container: (provided) => ({
    ...provided,
    minWidth: '21rem',
    marginLeft: '1.55rem',
    height: '4.4rem',
    boxShadow: 'none',
  }),
  control: (provided) => ({
    ...provided,
    height: '4.4rem',
    borderRadius: '8px',
    boxShadow: 'none',
    borderColor: error ? '#F50A4F' : '#05000033',
    '&:hover': {
      borderColor: error ? '#F50A4F' : '#05000033',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
    paddingRight: 0,
    background: state.isSelected ? 'rgba(0,255,145,0.4)' : 'white',
    '&:hover': {
      background: state.isSelected ? 'rgba(0,255,145,0.4)' : 'rgba(0,255,145,0.1)',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    paddingRight: 0,
  }),
});

const SelectStatus = ({ value, onChange, options, error, ...rest }) => {
  return (
    <ReactSelect
      {...rest}
      options={options}
      styles={styles({ error })}
      components={{
        IndicatorSeparator: null,
        Option,
        // ValueContainer: Value,
        SingleValue: Value,
      }}
      value={value}
      onChange={onChange}
      isSearchable={false}
    />
  );
};

export default SelectStatus;
