import {
  createCoachProgramTemplateMutation,
  getProgramTemplateQuery,
  getProgramTemplatesQuery,
  deleteProgramTemplateMutaton,
  getCoachProgramsQuery,
  uploadTemplateMutaton,
} from '@witness/graphql';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useDebounce } from '../services/utils';

const useTemplate = ({ initialFilter } = {}) => {
  const location = useLocation();
  const history = useHistory();
  const [calendarLoading, setCalendarLoading] = useState(false);

  const [templateFilter, setTemplateFilter] = useState(initialFilter || {});
  const delayedName = useDebounce(templateFilter?.templateName, 500);

  const updateFilter = (key, value) => {
    if (key === 'templateLength' && value === templateFilter?.templateLength) {
      setTemplateFilter((curr) => ({ ...curr, templateLength: undefined }));
    } else {
      setTemplateFilter((curr) => ({ ...curr, [key]: value }));
    }
  };

  const {
    data: programTemplate,
    loading: loadingProgramTemplate,
    refetch: refetchProgramTemplate,
  } = useQuery(getProgramTemplateQuery, {
    variables: {
      record: {
        uid: location.pathname.split('?')[0].split('/')[2],
      },
    },
    skip:
      location.pathname.split('?')[0].split('/')[1] !== 'template' ||
      !location.pathname.split('?')[0].split('/')[2],
  });

  const { data: programTemplates, loading: programTemplatesLoading } = useQuery(
    getProgramTemplatesQuery,
    {
      variables: {
        filter: {
          programName: delayedName,
          programLength: templateFilter?.templateLength,
          trainingType: templateFilter?.trainingType?.value,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const { data: coachPrograms, loading: coachProgramsLoading } = useQuery(getCoachProgramsQuery, {
    variables: {
      filter: {
        programName: delayedName,
        programLength: templateFilter?.templateLength,
        trainingType: templateFilter?.trainingType?.value,
      },
    },
    skip: templateFilter?.searchBy?.value !== 'programs',
    partialRefetch: templateFilter?.searchBy?.value === 'programs',
    fetchPolicy: 'network-only',
  });

  const [createProgramTemplate, { loading: templateChangeLoading }] = useMutation(
    createCoachProgramTemplateMutation,
  );

  const createTemplate = async (values, { setErrors }) => {
    const { templateName, templateLength, trainingTypes, uid } = values;
    try {
      const addedTemp = await createProgramTemplate({
        variables: {
          record: {
            uid,
            trainingTypes: trainingTypes?.map((a) => a.value),
            programName: templateName,
            programLength: templateLength,
          },
        },
      });
      history.push(`/template/${addedTemp?.data?.createOrUpdateProgramTemplate?.uid}`);
    } catch (err) {
      if (err.graphQLErrors.find((x) => x.message === 'template_name_already_exists')) {
        setErrors({ templateName: 'Name is already used' });
      }
    }
  };

  const [deleteProgramTemplate] = useMutation(deleteProgramTemplateMutaton);
  const deleteTemplate = async (uid) => {
    await deleteProgramTemplate({
      variables: {
        record: {
          uid,
        },
      },
    });
    history.push(`/templates`);
  };

  const [uploadProgramTemplate] = useMutation(uploadTemplateMutaton);
  const uploadTemplate = async (programUid, val, alert) => {
    alert.info(`The Template Is Being Uploaded`);
    try {
      await uploadProgramTemplate({
        variables: {
          record: {
            coachProgramUid: programUid,
            templateUid: val?.type === 'template' ? val?.id : undefined,
            coachProgramTemplateUid: val?.type === 'program' ? val?.id : undefined,
          },
        },
      });
      alert.success(`Program Updated Successfully!`);
    } catch (err) {
      alert.error(`Something went wrong`);
    }
  };

  const apolloClient = useApolloClient();

  const updateCalendarCache = useCallback(
    (newCalendar) => {
      apolloClient.writeQuery({
        query: getProgramTemplateQuery,
        variables: {
          record: {
            uid: programTemplate?.uid,
          },
        },
        data: {
          ...programTemplate,
          getProgramTemplate: {
            ...programTemplate.getProgramTemplate,
            calendar: newCalendar,
          },
        },
      });
    },
    [apolloClient, programTemplate],
  );

  return {
    programTemplates: programTemplates?.getProgramTemplates,
    programTemplatesLoading,
    programTemplate: programTemplate?.getProgramTemplate,
    refetchProgramTemplate,
    loadingProgramTemplate,
    createTemplate,
    deleteTemplate,
    updateCalendarCache,
    templateChangeLoading: templateChangeLoading || loadingProgramTemplate || calendarLoading,
    setCalendarLoading,
    coachPrograms: coachPrograms?.getCoachPrograms,
    coachProgramsLoading,
    uploadTemplate,
    updateFilter,
    templateFilter,
  };
};

export default useTemplate;
