import { schema } from 'normalizr';

export const conversationSchema = new schema.Entity(
  'conversations',
  {},
  { idAttribute: 'conversationId' },
);

export const serializeConversations = (conversations = []) => {
  return conversations.reduce((acc, next) => {
    acc[next.groupId] = next;
    return acc;
  }, {});
};
