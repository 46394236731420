import React from 'react';
import { Wrapper, Icon, Message, CloseBtn } from './components';

const AlertSnack = ({ options, message, close }) => (
  <Wrapper type={options.type}>
    <Icon type={options.type} />
    <Message>{message}</Message>
    <CloseBtn onClick={close}>X</CloseBtn>
  </Wrapper>
);
export default AlertSnack;
