import React from 'react';
import { Link } from 'react-router-dom';

const Calendar = () => {
  return (
    <>
      <h1>Calendar page</h1>
      <Link to="signup/coach">go to signup page</Link>
    </>
  );
};

export default Calendar;
