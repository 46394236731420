import { CometChat } from '@cometchat-pro/chat';

class CometChatManager {
  instance = null;
  initialized = null;
  authorized = null;
  appId = null;
  region = null;
  myUserId = null;

  constructor() {
    // Unique ids
    this.messagesListenerId = Math.random() * 1000;

    // Define requests
    this.usersRequest = null;
    this.conversationsRequest = null;
    this.messagesRequest = null;

    // Listeners
    this.onMessage = null;
    this.onConversationUpdate = null;
    this.onConversationAdd = null;

    // Storage
    this.subscribedGroupId = null;
    this.conversations = [];
  }

  static getInstance = () => {
    if (!CometChatManager.instance) {
      CometChatManager.instance = new CometChatManager();
    }

    return CometChatManager.instance;
  };

  static initialize = async (appId, region) => {
    try {
      CometChatManager.appId = appId;
      CometChatManager.region = region;

      // Create settings
      const appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(region)
        .build();

      // Initialize chatkit
      const initialized = await CometChat.init(appId, appSetting);

      // Save initialized
      CometChatManager.initialized = initialized;

      return initialized;
    } catch (e) {
      console.log('CometChat.init', e);
      throw e;
    }
  };

  static login = async (authToken) => {
    try {
      // Initialized if not initialized
      if (!CometChatManager.initialized) {
        await CometChatManager.initialize(CometChatManager.appId, CometChatManager.region);
      }

      // Then login to cometchat using that authToken
      const user = await CometChat.login(authToken);

      // Save my user id
      CometChatManager.authorized = true;
      CometChatManager.myUserId = user.uid;

      return user;
    } catch (e) {
      console.log('CometChat.login', e);
      throw e;
    }
  };

  connect = async (settings) => {
    const { onConversationUpdate, onConversationAdd, conversationLimit } = settings;

    // Save onConversationUpdate listener
    this.onConversationUpdate = onConversationUpdate;
    this.onConversationAdd = onConversationAdd;

    // Build new conversations request (to clear old pagination)
    this.buildConversationsRequest(conversationLimit);

    // Clear previous conversations if exist
    if (this.conversations.length) this.conversations = [];

    // Fetch conversations
    const initialConversations = await this.fetchConversations(conversationLimit);

    // Listen to messages
    this.subscribeToMessages();

    return initialConversations;
  };

  subscribeToGroup = async (settings) => {
    const { groupId, onMessage, messageLimit, isGroup } = settings;

    // Update subscribed group message listener
    this.onMessage = onMessage;

    // Update subscribed group id
    this.subscribedGroupId = groupId;

    // Build new messages request
    this.buildMessagesRequest(groupId, messageLimit, isGroup);

    // Fetch messages for subscribed groupId
    const initialMessages = await this.fetchMessages();

    // Check if group exists in saved conversations
    const existing = await this.findConversationByGroupId(
      groupId,
      isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER,
    );
    if (!existing) {
      const lastMessage = initialMessages[initialMessages.length - 1];
      const cometChatUser = await this.fetchUser(groupId);
      const newConversation = Conversation.createFromCometChatUser(cometChatUser, lastMessage);
      newConversation.unreadMessagesCount = await this.fetchGroupUnreadMessageCount(
        groupId,
        newConversation.isGroup,
      );
      this.conversations.push(newConversation);
      if (this.onConversationAdd) this.onConversationAdd(newConversation);
    }

    return initialMessages;
  };

  buildMessagesRequest = (groupId, limit = 30, isGroup) => {
    const builder = new CometChat.MessagesRequestBuilder();

    const withId = isGroup ? builder.setGUID(groupId) : builder.setUID(groupId);

    this.messagesRequest = withId.setLimit(limit).build();
  };

  buildConversationsRequest = (limit = 30) => {
    this.conversationsRequest = new CometChat.ConversationsRequestBuilder().setLimit(limit).build();
  };

  buildUsersRequest = (searchString, limit = 50) => {
    this.usersRequest = new CometChat.UsersRequestBuilder()
      .setLimit(limit)
      .setSearchKeyword(searchString)
      // .joinedOnly(true)
      // .friendsOnly(true)
      .build();
  };

  static logout = async () => {
    CometChatManager.initialized = null;
    CometChatManager.authorized = null;
    CometChatManager.myUserId = null;
    CometChatManager.instance = null;

    return await CometChat.logout();
  };

  fetchUser = (groupId) => {
    try {
      // Fetch groups according to request
      return CometChat.getUser(String(groupId));
    } catch (e) {
      console.log('CometChat.fetchUser', e);
      throw e;
    }
  };

  fetchConversations = async (limit) => {
    try {
      // Build conversations request if not exists
      if (!this.conversationsRequest) this.buildConversationsRequest(limit);

      // Fetch conversations
      const cometChatConversations = await this.conversationsRequest.fetchNext();

      // Convert cometchat conversations to our format
      const conversations = cometChatConversations.map((conversation) => {
        return Conversation.createFromCometChatConversation(conversation);
      });

      // Find new conversations
      const newConversations = conversations.filter(
        (conversation) =>
          !this.conversations.find(
            (localConversation) => localConversation.groupId === conversation.groupId,
          ),
      );
      // Save conversations locally (add only new conversations)
      this.conversations = [...this.conversations, ...newConversations];

      return newConversations;
    } catch (e) {
      console.log('fetchConversations', e);
      throw e;
    }
  };

  fetchMessages = async () => {
    try {
      // Fetch messages
      const cometChatMessages = await this.messagesRequest.fetchPrevious();

      // Convert messages
      return cometChatMessages.map(Message.createFromCometChatMessage);
    } catch (e) {
      console.log('fetchMessages', e);
      return [];
    }
  };

  fetchAllUnreadMessageCount = async () => {
    const response = await CometChat.getUnreadMessageCountForAllUsers();
    let unreadMessagesCount = 0;
    Object.keys(response).forEach((groupId) => {
      unreadMessagesCount += response[groupId];
    });
    return unreadMessagesCount;
  };

  fetchGroupUnreadMessageCount = async (groupId, isGroup) => {
    try {
      // Fetch unread messages count for group members
      const results = isGroup
        ? await CometChat.getUnreadMessageCountForGroup(groupId)
        : await CometChat.getUnreadMessageCountForUser(groupId);

      // Get unread messages count
      return results[groupId] ? results[groupId] : 0;
    } catch (e) {
      console.log('fetchGroupUnreadMessageCount', e);
      throw e;
    }
  };

  subscribeToMessages = (callback = null) => {
    // Listen to new live messages
    CometChat.addMessageListener(
      this.messagesListenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          callback && callback(textMessage);
          this.handleMessageReceived(textMessage);
        },
        onMediaMessageReceived: (mediaMessage) => {
          callback && callback(mediaMessage);
          this.handleMessageReceived(mediaMessage);
        },
      }),
    );
  };

  unsubscribeFromGroup = () => {
    this.subscribedGroupId = null;
  };

  unsubscribeFromMessages = () => {
    // Remove listener
    CometChat.removeMessageListener(this.messagesListenerId);

    // Reset local variables
    // Requests
    this.usersRequest = null;
    this.conversationsRequest = null;
    this.messagesRequest = null;

    // Listeners
    this.onMessage = null;
    this.onConversationUpdate = null;
    this.onConversationAdd = null;

    // Storage
    this.subscribedGroupId = null;
    this.conversations = [];
  };

  handleMessageReceived = async (cometChatMessage) => {
    const message = Message.createFromCometChatMessage(cometChatMessage);
    const { groupId, user } = message;
    const receiverId =
      cometChatMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP ? groupId : user.id;

    // Update conversation unread messages counter
    const conversation = await this.findConversationByGroupId(
      receiverId,
      cometChatMessage.receiverType,
    );

    if (receiverId === this.subscribedGroupId) {
      // Trigger subscribed group onMessage listener
      if (this.onMessage) this.onMessage(message);
    }

    // First check if such conversation is fetched
    if (conversation) {
      // Update existing conversation (mutate, yes)
      conversation.lastMessage = message;
      conversation.unreadMessageCount = conversation.unreadMessageCount + 1;
      // Trigger onConversationUpdate
      if (this.onConversationUpdate) this.onConversationUpdate(conversation);
    } else {
      // Convert conversation
      const conversation = Conversation.createFromCometChatMessage(cometChatMessage);

      // Increase unreadMessageCount
      conversation.unreadMessageCount = await this.fetchGroupUnreadMessageCount(
        conversation.groupId,
        conversation.isGroup,
      );
      // Push this new conversation to local list
      this.conversations.push(conversation);
      // Trigger onConversationAdd
      if (this.onConversationAdd) this.onConversationAdd(conversation);
    }
  };

  markAsRead = (messageId, groupId, receiverType) => {
    CometChat.markAsRead(messageId, String(groupId), receiverType);
    this.fetchGroupUnreadMessageCount(groupId, receiverType === CometChat.RECEIVER_TYPE.GROUP).then(
      async (unreadMessageCount) => {
        // Find this conversation
        const conversation = await this.findConversationByGroupId(groupId, receiverType);
        // Update unread message count (with mutation)
        conversation.unreadMessageCount = unreadMessageCount;
        // Trigger conversation update
        if (this.onConversationUpdate) this.onConversationUpdate(conversation);
      },
    );
  };

  sendTextMessage = async (text, isGroup, overrideReceiver) => {
    // Build message object
    const textMessage = new CometChat.TextMessage(
      overrideReceiver || String(this.subscribedGroupId),
      text,
      isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER,
    );

    // Send message
    const cometChatMessage = await CometChat.sendMessage(textMessage);

    // Conver message
    const message = Message.createFromCometChatMessage(cometChatMessage);

    // Find subscribed conversation
    const conversation = await this.findConversationByGroupId(
      this.subscribedGroupId,
      isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER,
    );
    // Update last message
    conversation.lastMessage = message;
    // Trigger onConversationUpdate
    if (this.onConversationUpdate) this.onConversationUpdate(conversation);

    // Trigger onMessage
    if (this.onMessage) this.onMessage(message);

    return message;
  };

  sendMediaMessage = async ({ file, messageType, receiverType }) => {
    // Build message object
    const textMessage = new CometChat.MediaMessage(
      String(this.subscribedGroupId),
      file,
      messageType,
      receiverType,
    );

    // Send message
    const cometChatMessage = await CometChat.sendMediaMessage(textMessage);

    // Convert message
    const message = Message.createFromCometChatMessage(cometChatMessage);

    // Find subscribed conversation
    const conversation =
      (await this.findConversationByGroupId(this.subscribedGroupId, receiverType)) ||
      Conversation.createFromCometChatMessage(cometChatMessage);

    // Update last message
    conversation.lastMessage = message;
    // Trigger onConversationUpdate
    if (this.onConversationUpdate) this.onConversationUpdate(conversation);

    // Trigger onMessage
    if (this.onMessage) this.onMessage(message);

    return message;
  };

  findConversationByGroupId = async (groupId, type) => {
    const existing = this.conversations.find((conversation) => conversation.groupId === groupId);

    if (existing) {
      return existing;
    }

    const cometChatConversation = await CometChat.getConversation(groupId, type);

    return Conversation.createFromCometChatConversation(cometChatConversation);
  };

  searchGroups = async (searchString, limit = 50) => {
    // Build request
    this.buildUsersRequest(searchString, limit);

    // Fetch users
    const cometChatUsers = await this.usersRequest.fetchNext();

    // Convert users
    const users = cometChatUsers.map((user) => new User(user.uid, user.name, user.avatar));

    return users;
  };
}

class Conversation {
  constructor(groupId, user, lastMessage = null, unreadMessageCount = 0, isGroup, createdAt) {
    this.groupId = groupId;
    this.user = user;
    this.lastMessage = lastMessage;
    this.unreadMessageCount = unreadMessageCount;
    this.isGroup = isGroup;
    this.createdAt = createdAt;
  }

  static createFromCometChatConversation = (cometChatConversation) => {
    const {
      conversationType,
      conversationWith,
      lastMessage,
      unreadMessageCount,
    } = cometChatConversation;
    const { uid, guid, name, avatar, icon, createdAt, lastActiveAt } = conversationWith;

    const isGroup = conversationType === CometChat.RECEIVER_TYPE.GROUP;

    const user = isGroup ? new User(guid, name, icon) : new User(uid, name, avatar);

    const message = lastMessage ? Message.createFromCometChatMessage(lastMessage) : null;

    return new Conversation(
      user.id,
      user,
      message,
      unreadMessageCount || 0,
      isGroup,
      isGroup ? createdAt : lastActiveAt,
    );
  };

  static createFromCometChatUser = (cometchatUser, lastMessage) => {
    const { uid, name, avatar } = cometchatUser;

    return new Conversation(uid, new User(uid, name, avatar), lastMessage, 0, false);
  };

  static createFromCometChatMessage = (cometChatMessage) => {
    const { receiver, sender, receiverType } = cometChatMessage;
    const otherCometchatUser = CometChatManager.myUserId === sender.uid ? receiver : sender;
    const { uid, guid, name, avatar, icon } = otherCometchatUser;

    const isGroup = receiverType === CometChat.RECEIVER_TYPE.GROUP;

    const user = isGroup ? new User(guid, name, icon) : new User(uid, name, avatar);

    return new Conversation(
      user.id,
      user,
      Message.createFromCometChatMessage(cometChatMessage),
      0,
      isGroup,
    );
  };
}

class User {
  constructor(id, name, avatar) {
    this.id = id;
    this.name = name;
    this.avatar = avatar;
  }
}

class Message {
  constructor(id, groupId, text, type, attachment, sentAt, user, isOwned) {
    this.id = id;
    this.groupId = groupId;
    this.text = text;
    this.type = type;
    this.attachment = attachment;
    this.sentAt = sentAt;
    this.user = user;
    this.isOwned = isOwned;
  }

  static createFromCometChatMessage = (cometChatMessage) => {
    const {
      id,
      text,
      type,
      attachment,
      message,
      sentAt,
      sender,
      data,
      receiverId,
    } = cometChatMessage;

    const isOwned = CometChatManager.myUserId === sender.uid;

    if (cometChatMessage.category === 'custom') {
      return new Message(
        id,
        receiverId,
        cometChatMessage.data.text || cometChatMessage.data.customData.message,
        'custom',
        cometChatMessage.data.customData.icon,
        sentAt,
        new User(sender.uid, sender.name, sender.avatar),
        isOwned,
      );
    }

    return new Message(
      id,
      receiverId,
      text || message,
      type,
      attachment ? attachment.fileUrl : data.url,
      sentAt,
      new User(sender.uid, sender.name, sender.avatar),
      isOwned,
    );
  };
}

export default CometChatManager;
