import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  width: 26rem;
  width: 100%;
  border-radius: 12px;
  background: #ffffff;
  border: 0.5px solid #96949444;
  padding: 2.2rem 2rem;
  font-size: 1.4rem;
  font-family: Roboto-l;
  position: absolute;
  z-index: 111;
  ${({ below }) =>
    below
      ? `
  top: Calc(100% + 0.4rem);
  `
      : `
  bottom: Calc(100% + 0.4rem);
  `};
  right: -12.1rem;
  line-height: 2.1rem;
  display: none;
  pointer-events: auto;
`;

export const TooltipTriangleBorder = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${({ below }) => (below ? '0 8px 8px 8px' : '8px 8px 0 8px')};
  border-color: ${({ below }) =>
    below
      ? 'transparent transparent #96949444 transparent'
      : '#96949444 transparent transparent transparent'};
  position: absolute;
  ${({ below }) =>
    below
      ? `
bottom: 100%;
`
      : `
top: 100%;
`};
  left: Calc(50% - 8px);
`;

export const TooltipTriangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${({ below }) => (below ? '0 7px 8px 7px' : '8px 7px 0 7px')};
  border-color: ${({ below }) =>
    below
      ? 'transparent transparent #ffffff transparent'
      : '#ffffff transparent transparent transparent'};
  position: absolute;
  left: Calc(50% - 7px);
  ${({ below }) =>
    below
      ? `
  bottom: Calc(100% - 1px);
  `
      : `
  top: Calc(100% - 1px);
`};
`;
