import * as Yup from 'yup';

// attributes
const attributeName = Yup.string().required('required');
const attributeType = Yup.string('type???');
const attributeValue = Yup.string().when('type', (value, schema) => {
  if (value === 'clock') {
    return schema
      .required('required')
      .matches(/^((0|1|)[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/, 'invalid value for time');
  } else if (value === 'text') {
    return schema.required('required');
  } else if (value === 'nullable') {
    return schema.nullable();
  } else if (value === 'unit') {
    return schema.required('required').oneOf(['Distance', 'Time']);
  }
  return schema.required('required').matches(/^([0-9])*[1-9][0-9]*$/, 'only digits are allowed');
});
const attribute = Yup.object().shape({
  name: attributeName,
  value: attributeValue,
  type: attributeType,
});
const attributes = Yup.array().of(attribute);

// exercise
const id = Yup.string().required('required');
const quantity = Yup.number().positive().required('required');
const unit = Yup.string().required('required');
const weight = Yup.number().min(0, 'invalid');
const exercise = Yup.object().shape({
  exercise: Yup.object().shape({ id }),
  quantity,
  unit,
  weight,
});
const exercises = Yup.array()
  .of(exercise)
  .when('type', (value, schema) => {
    if (value === 'REST' || value === 'RUN' || value === 'CUSTOM') {
      return schema.nullable();
    }
    return schema.min(1, 'add at least one exercise');
  });

// block
const blockName = Yup.string();
const blockType = Yup.string().oneOf([
  'AMRAP',
  'RFT',
  'RNFT',
  'STRENGTH',
  'SUPERSETS',
  'QUALITYSETS',
  'EMOM',
  'REST',
  'CUSTOM',
  'RUN',
]);
const comment = Yup.string().nullable();
const isWarmUp = Yup.boolean();
const videoRequested = Yup.boolean();

const BlockSchema = Yup.object().shape({
  name: blockName,
  type: blockType,
  comment,
  isWarmUp,
  videoRequested,
  attributes,
  exercises,
});

const blocks = Yup.array()
  .required('Add at Least One Block')
  .min(1, 'Add at Least One Block')
  .of(BlockSchema);

// calendar
const name = Yup.string().trim().required('Required').min(2, 'Too Short');
const type = Yup.string().oneOf(['WORKOUT', 'REST'], 'invalid type');
const beforeMessage = Yup.string();
const afterMessage = Yup.string();

export const CalendarSchema = Yup.object().shape({
  name,
  type,
  beforeMessage,
  afterMessage,
  blocks,
});
