import {
  createCalendarMutation,
  deleteCalendarMutation,
  editWorkoutMutation,
} from '@witness/graphql';
import { useMutation } from '@apollo/react-hooks';
import { useState, useEffect, useCallback } from 'react';
import { lengthToDays } from '../services/utils';
import { CalendarType } from '../constants/enums';
import { create_UUID } from '../services/utils';
import { useAlert } from 'react-alert';

const useCoachProgram = ({
  beginningDate,
  programLength,
  calendar,
  coachProgramUid,
  programTemplateUid,
  refetch,
  updateCalendarCache,
  setCalendarDayLoading = () => {},
} = {}) => {
  const [programDays, setProgramDays] = useState([]);
  const [messageDay, setMessageOpen] = useState(null);
  const openMessageModal = useCallback((day) => setMessageOpen(day), []);
  const closeMessageModal = useCallback(() => setMessageOpen(null), []);
  const [copiedWorkout, setCopiedWorkout] = useState(null);
  const [calendarMatrix, setCalendarMatrix] = useState([]);

  const [createCalendar, { loading: createLoading }] = useMutation(createCalendarMutation);
  const [editWorkout, { loading: editLoading }] = useMutation(editWorkoutMutation);
  const alert = useAlert();

  const createNewCalendar = useCallback(
    async (v) => {
      try {
        await createCalendar(v);
        alert.success('Changes Saved !');
      } catch (err) {
        if (err.graphQLErrors.find((x) => x.message === 'date_not_valid')) {
          alert.error('You Cannot Add a Past Day');
        } else {
          alert.error('Changes Cannot Be Saved');
        }
      }
    },
    [createCalendar],
  );
  const editExistingWorkout = useCallback(
    async (v) => {
      try {
        await editWorkout(v);
        alert.success('Changes Saved !');
      } catch (err) {
        if (err.graphQLErrors.find((x) => x.message === 'date_not_valid')) {
          alert.error('You Cannot Change a Past Day');
        } else {
          alert.error('Something Went Wrong');
        }
      }
    },
    [createCalendar],
  );

  const addRestDay = async (programDay, beforeFunc = () => {}) => {
    beforeFunc();

    updateCalendarCache([
      ...(calendar || []),
      {
        customMessage: null,
        icon: null,
        message: null,
        programDay,
        type: CalendarType.REST,
        uid: create_UUID(),
        workout: null,
        __typename: 'Calendar',
      },
    ]);

    createNewCalendar({
      variables: {
        calendar: {
          coachProgram: coachProgramUid,
          programTemplate: programTemplateUid,
          type: CalendarType.REST,
          programDay,
        },
      },
    }).then(refetch);
  };

  const saveWorkoutDay = useCallback(
    async (programDay, values, uid) => {
      const { name, type, beforeMessage, afterMessage, blocks, blocksReadyToSubmit } = values;
      const dataToSubmit = {
        name,
        type,
        beforeMessage,
        afterMessage,
        blocks:
          blocksReadyToSubmit ||
          blocks?.map((block, i) => {
            const { type, videoRequested, isWarmUp, comment, attributes, exercises } = block;
            return {
              type,
              order: i + 1,
              videoRequested,
              isWarmUp,
              comment,
              customAttributes: attributes
                ?.filter((x) => x?.value)
                ?.map((attr) => {
                  let value = attr.value;

                  let textValue = attr.textValue;
                  if (attr.type === 'clock') {
                    const [, m, s] = attr.value.split(':');
                    value = attr?.asNeeded ? 0 : Number(m) * 60 + Number(s);
                  } else if (attr.type === 'text') {
                    textValue = attr?.value;
                    value = -1;
                  } else {
                    value = Number(attr.value);
                  }

                  return {
                    name: attr.name,
                    value,
                    textValue,
                  };
                }),
              exercises: exercises?.map((ex, i) => ({
                exerciseId: ex?.exercise?.id,
                quantity: ex.quantity,
                unit: ex.unit,
                weight: ex.weight,
                order: i + 1,
              })),
            };
          }),
      };

      uid
        ? editExistingWorkout({
            variables: {
              calendar: {
                type: CalendarType.WORKOUT,
                coachProgram: coachProgramUid,
                programTemplate: programTemplateUid,
                programDay: Math.floor(programDay),
              },
              workout: { uid, ...dataToSubmit },
            },
          }).then(refetch)
        : createNewCalendar({
            variables: {
              calendar: {
                type: CalendarType.WORKOUT,
                coachProgram: coachProgramUid,
                programTemplate: programTemplateUid,
                programDay: Math.floor(programDay),
              },
              workout: dataToSubmit,
            },
          }).then(refetch);
    },
    [createNewCalendar, editExistingWorkout, programTemplateUid, coachProgramUid],
  );
  const generateCalendarMatrix = useCallback(() => {
    const currStartDate = new Date(beginningDate || new Date());
    const currEpoch = currStartDate.getTime();
    const startDateWeekDay = currStartDate.getDay();
    const result = [];
    const currprogramLength = programLength || 0;
    const weeksToShow = Math.ceil((currprogramLength + startDateWeekDay) / 7);
    for (const i of new Array(weeksToShow * 7).keys()) {
      const week = Math.floor(i / 7);
      const day = i % 7;
      if (day === 0) {
        result[week] = [];
      }
      if (i < startDateWeekDay || i > currprogramLength + startDateWeekDay - 1) {
        result[week][day] = null;
      } else {
        const currDayDate = new Date(currEpoch + (i - startDateWeekDay) * 86400000);
        const cellToBeAdded = {
          date: `${currDayDate.getDate()}.${
            currDayDate.getMonth() + 1
          }.${currDayDate.getFullYear()}`,
          index: i - startDateWeekDay + 1,
        };
        result[week][day] = cellToBeAdded;
      }
    }

    return result;
  }, [programLength, beginningDate]);

  const placeProgramDays = useCallback(() => {
    // const length = lengthToDays(programLength);
    const programDaysArr = new Array(programLength);
    for (const day of calendar) {
      const index = day?.programDay;
      if (index > -1 && index <= programLength) {
        if (programDaysArr[index]) {
          programDaysArr[index][day?.type] = day;
        } else {
          programDaysArr[index] = { [day?.type]: day };
        }
      }
    }
    setProgramDays(programDaysArr);
  }, [calendar, programLength, lengthToDays]);

  useEffect(() => {
    setCalendarMatrix(generateCalendarMatrix());
  }, [programLength, beginningDate]);

  useEffect(() => {
    if (calendar?.length) {
      placeProgramDays();
    } else {
      setProgramDays([]);
    }
  }, [calendar]);

  const prepareWorkoutToPaste = useCallback((copiedWorkout) => {
    const { name, type, beforeMessage, afterMessage, workoutBlocks } = copiedWorkout;

    const blocks = workoutBlocks?.map((workout) => {
      const {
        uid,
        __typename,
        coachFeedback,
        resultText,
        results,
        customAttributes,
        exercises,
        blockAttachment,
        ...rest
      } = workout;

      const preparedAttributes = customAttributes?.map((attr) => {
        const { uid, __typename, ...rest } = attr;
        return rest;
      });

      const preparedExercises = exercises?.map((attr, i) => {
        const { uid, __typename, exercise, ...rest } = attr;
        return {
          ...rest,
          exerciseId: exercise?.id,
          order: i + 1,
        };
      });

      return {
        ...rest,
        customAttributes: preparedAttributes,
        exercises: preparedExercises,
      };
    });
    return { name, type, beforeMessage, afterMessage, blocks };
  }, []);

  const pasteWorkout = useCallback(
    (index) => {
      const workout = prepareWorkoutToPaste(copiedWorkout?.workout);

      updateCalendarCache([
        ...(calendar || []),
        {
          customMessage: null,
          icon: null,
          message: null,
          programDay: index,
          type: CalendarType.WORKOUT,
          uid: `loading/${create_UUID()}`,
          workout: { ...copiedWorkout?.workout },
          __typename: 'Calendar',
        },
      ]);

      createNewCalendar({
        variables: {
          calendar: {
            type: CalendarType.WORKOUT,
            coachProgram: coachProgramUid,
            programTemplate: programTemplateUid,
            programDay: index,
          },
          workout,
          // workout: { name, type, beforeMessage, afterMessage, blocks: preparedBlocks },
        },
      }).then(refetch);
    },
    [
      copiedWorkout,
      createNewCalendar,
      coachProgramUid,
      programTemplateUid,
      updateCalendarCache,
      refetch,
    ],
  );

  const [deleteCalendar, { loading: deleteLoading }] = useMutation(deleteCalendarMutation);

  const [selectedProgram, setSelectedProgram] = useState(null);
  const handleProgramDelete = useCallback(async () => {
    const { WORKOUT, MESSAGE, REST } = selectedProgram || {};

    const calendarDay = WORKOUT || MESSAGE || REST;

    if (calendarDay) {
      updateCalendarCache(calendar?.filter((item) => item.uid !== calendarDay.uid) || []);

      setSelectedProgram(null);

      try {
        await deleteCalendar({
          variables: {
            id: calendarDay.uid,
          },
        }).then(refetch);
      } catch (err) {
        console.log('could not delete');
      }
    }
  }, [selectedProgram, deleteCalendar, updateCalendarCache, refetch]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  useEffect(() => setCalendarDayLoading(createLoading || editLoading || deleteLoading), [
    createLoading,
    editLoading,
    deleteLoading,
  ]);

  const [copiedWeek, setCopiedWeek] = useState(null);

  const dublicateWeek = useCallback(
    (weekIndex) => {
      if (typeof copiedWeek === 'number') {
        const copiedWeekIndex = copiedWeek;
        const daysDiff = (weekIndex - copiedWeek) * 7;
        let nonProgramDays = 0;
        calendarMatrix[copiedWeekIndex].map((weekDay) => {
          const originIndex = weekDay?.index || nonProgramDays--;
          const { WORKOUT, MESSAGE, REST } = programDays[originIndex + daysDiff] || {};
          console.log(nonProgramDays, originIndex + daysDiff);
          const calendarToDelete = WORKOUT || MESSAGE || REST;
          if (calendarToDelete) {
            console.log('deleting - day', calendarToDelete);
            deleteCalendar({
              variables: {
                id: calendarToDelete?.uid,
              },
            }).then(refetch);
          }
          if (programDays[weekDay?.index]?.WORKOUT?.workout) {
            const workoutToPaste = prepareWorkoutToPaste(
              programDays[weekDay?.index]?.WORKOUT?.workout,
            );
            saveWorkoutDay(weekDay?.index + daysDiff, {
              ...workoutToPaste,
              blocksReadyToSubmit: workoutToPaste?.blocks,
            });
          } else if (programDays[weekDay?.index]?.REST) {
            addRestDay(programDays[weekDay?.index]?.REST?.programDay + daysDiff);
          }
        });
        setCopiedWeek(null);
      } else {
        setCopiedWeek(weekIndex);
      }
    },
    [pasteWorkout, copiedWeek],
  );

  return {
    calendarMatrix,
    programDays,
    addRestDay,
    messageDay,
    openMessageModal,
    closeMessageModal,
    saveWorkoutDay,
    copiedWorkout,
    setCopiedWorkout,
    pasteWorkout,
    selectedProgram,
    setSelectedProgram,
    handleProgramDelete,
    selectedMessage,
    setSelectedMessage,
    dublicateWeek,
    copiedWeek,
  };
};

export default useCoachProgram;
