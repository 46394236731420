import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

const uri = process.env.REACT_APP_API_URL;
const wsUrl = process.env.REACT_APP_SUB_URL;

const uploadLink = createUploadLink({
  uri,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

// const wsLink = new WebSocketLink({
//   uri: wsUrl,
//   options: {
//     reconnect: true,
//     connectionParams: () => {
//       const token = localStorage.getItem('token');
//       return {
//         authorization: token ? `Bearer ${token}` : '',
//       };
//     },
//   },
// });

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
//   },
//   wsLink,
//   uploadLink,
// );

const authLink = setContext((_, { headers, ...rest }) => {
  const token = localStorage.getItem('token');
  const lang = localStorage.getItem('lang') || 'en';
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      language: lang,
    },
    ...rest,
  };
});

const uploadLinkWithAuth = authLink.concat(uploadLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log('TCL: errorLink -> message', message);

      console.log(
        `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      );
    });

  if (networkError) {
    console.log('[Network error]:', networkError);
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.uid,
});

export default new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, uploadLinkWithAuth]),
  connectToDevTools: true,
});
