import React, { useMemo } from 'react';
import { AvatarContainer, AvatarCredentials, AvatarImage } from './components';

const Avatar = ({ src, isOnline, size, name }) => {
  const credentials = useMemo(() => {
    if (typeof name !== 'string') {
      return '';
    }

    const [first, last] = name.split(' ');

    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`.toUpperCase();
  }, [name]);

  return (
    <AvatarContainer size={size} isOnline={isOnline}>
      <AvatarImage src={src}>
        <AvatarCredentials>{src ? '' : credentials}</AvatarCredentials>
      </AvatarImage>
    </AvatarContainer>
  );
};

export default Avatar;
