import styled from 'styled-components';

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RadioIcon = styled.div`
  box-sizing: border-box;
  background-color: white;
  width: ${({ selected }) => (selected ? '22px' : '19px')};
  height: ${({ selected }) => (selected ? '22px' : '19px')};
  border-radius: 50%;
  margin-right: ${({ selected }) => (selected ? '10px' : '13px')};
  margin-bottom: ${({ selected }) => (selected ? '0px' : '3px')};
  border: ${({ selected }) => (selected ? '4px solid #00FF91' : '1px solid rgba(5, 0, 0, 0.2)')};
`;

export const RadioText = styled.span`
  opacity: 0.6;
  color: #373333;
  font-family: Roboto-m;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`;
