import styled from 'styled-components';
import { css } from 'styled-components';

export const StyledButton = styled.button`
  width: 100%;
  min-height: 40px;
  max-height: 100%;
  height: ${({ height }) => (height ? height : '56px')};
  background: ${({ backgroundColor, green, theme }) =>
    backgroundColor ? backgroundColor : green ? theme.color.primary : '#f1f0f0'};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '12px')};
  display: flex;
  border: ${({ outlined, outlineColor, theme }) =>
    outlined ? `1px solid ${outlineColor || theme.color.primary}` : '1px solid transparent'};
  align-items: center;
  justify-content: center;
  outline: none;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Roboto-m')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.6rem')};
  font-weight: ${({ bold }) => (bold ? 600 : 200)};
  color: ${({ color }) => color};
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    opacity: 80%;
    ${({ hoverBackground, hoverOutlineColor }) =>
      hoverBackground || hoverOutlineColor
        ? css`
            background: ${hoverBackground};
            border-color: ${hoverOutlineColor};
            opacity: 1;
          `
        : ''}
  }
  &:active {
    opacity: 100%;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
