import React, { useMemo } from 'react';
import { ParticipantContainer, PartName, Avatar, PartNameWrapper } from './components';

const Participant = ({ item }) => {
  const initials = useMemo(() => {
    const [first, last] = item.fullName?.split(' ');
    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`;
  }, [item]);

  return (
    <ParticipantContainer>
      <Avatar src={item.avatar?.url} size="3.2rem" style={{ marginRight: '1.2rem' }}>
        {initials}
      </Avatar>
      <PartNameWrapper>
        <PartName>{item.fullName}</PartName>
      </PartNameWrapper>
    </ParticipantContainer>
  );
};

export default Participant;
