import React, { useState } from 'react';
import TraineeCard from '../../components/trainee-card/TraineeCard';
import { PseudoTraineeCard } from '../../components/trainee-card/components';
import useProgramRequest from '../../hooks/programRequest';
import { ProgramStatus, ProgramType } from '../../constants/enums';
import ProgramsHeader from '../../components/programs-header/ProgramsHeader';
import Loader from '../../components/loader/Loader';
import EmptyTemplate from '../../components/empty-template/EmptyTemplate';
import { useHistory } from 'react-router-dom';
import IMAGES from '../../constants/images';

const Trainees = () => {
  const {
    programRequests,
    status,
    setStatus,
    statusOpts,
    traineeName,
    setTraineeName,
    programRequestsLoading,
  } = useProgramRequest({
    requestStatus: ProgramStatus.APPROVED,
    programType: ProgramType.ONETOONE,
  });
  const [layout, setLayout] = useState('CARDS');
  const history = useHistory();
  return (
    <>
      <div
        style={{
          width: '100%',
          background: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ProgramsHeader
          statusOpts={statusOpts}
          status={status}
          setStatus={setStatus}
          traineeName={traineeName}
          setTraineeName={setTraineeName}
          heading="Your Trainees"
          layout={layout}
          setLayout={setLayout}
        />
        <div
          style={{
            background: '#f7f6f6',
            padding: '12px 8px',
            margin: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {!programRequests?.getPrograms?.length ? (
            <>
              {(programRequestsLoading && <Loader />) || (
                <EmptyTemplate
                  image={IMAGES.EmptyTrainees}
                  buttonText="Go to Dashboard"
                  onButtonClick={() => history.push('/home')}
                  primaryText={`You don't Have any Trainees ${
                    status?.value
                      ? `with Status '${status?.label}' ${
                          traineeName ? `and Name Consisting '${traineeName}'` : ''
                        }`
                      : traineeName
                      ? `with Name consisting '${traineeName}'`
                      : 'Yet'
                  }
              `}
                  secondarytext={
                    status?.value || traineeName
                      ? ''
                      : 'The List of Trainees wll Appears here when You will approve your first Program request'
                  }
                />
              )}
            </>
          ) : layout === 'CARDS' ? (
            <>
              {programRequests?.getPrograms?.map((program) =>
                program?.status !== 'New' ? (
                  <TraineeCard key={program?.uid} program={program} />
                ) : (
                  false
                ),
              )}
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
            </>
          ) : (
            <table
              style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0 6px',
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Full name
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program Status
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program types
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program ends
                  </th>
                  <th
                    style={{
                      width: '12.4rem',
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {programRequests?.getPrograms?.map((program) =>
                  program?.status !== 'New' ? (
                    <TraineeCard key={program?.uid} program={program} layout="LIST" />
                  ) : (
                    false
                  ),
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Trainees;
