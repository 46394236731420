import React, { useState } from 'react';
import { Field, FieldArray } from 'formik';
import CustomInput from '../input/CustomInput';
import CustomSearch from '../input/CustomSearch';
import CustomButton from '../button/CustomButton';
import CustomTextArea from '../input/CustomTextArea';
import CustomInputClock from '../input/CustomInputClock';
import CustomInputNumber from '../input/CustomInputNumber';
import CustomInputDropdown from '../input/CustomInputDropdown';
import AddExerciseModal from '../add-exercise-modal.jsx/AddExerciseModal';
import { WorkoutBlockType, BlockExerciseUnit } from '../../constants/enums';
import { useExercise } from '../../hooks';
import {
  WorkoutBlockForm,
  WorkoutBlockHeader,
  WorkoutBlockContent,
  AttributeWrapper,
  AttributeBullet,
  AttributeLabel,
  WorkoutBlockFooter,
  WorkoutBlockApplyButton,
  AttrCheckBox,
  ExerciseWrapper,
  ExerciseLabel,
  ExerciseIndex,
  ExerciseContent,
  ExerciseWeight,
  WorkoutBlockHeaderIconsWrapper,
  WorkoutBlockHeaderIcon,
  DeleteExerciseIcon,
  FlexWrapper,
  AsNeeded,
  AsNeededCheck,
  ExerciseOpenButton,
  AttributeUnit,
} from './components';

const EdittingWorkoutBlock = ({
  block,
  index,
  setFieldValue,
  errors,
  touched,
  deleteSelf,
  setTouched,
}) => {
  const {
    exercises,
    exercisesLoading,
    searchText,
    setSearchText,
    createNewExercise,
  } = useExercise();

  const [exerciseAdding, setExerciseAdding] = useState(null);

  return (
    <WorkoutBlockForm>
      <WorkoutBlockHeader>
        {block?.name}

        <WorkoutBlockHeaderIconsWrapper>
          <WorkoutBlockHeaderIcon onClick={deleteSelf} />
        </WorkoutBlockHeaderIconsWrapper>
      </WorkoutBlockHeader>

      <WorkoutBlockContent>
        {block?.attributes?.map((attr, i) => (
          <>
            <AttributeWrapper key={i}>
              {attr?.name === 'unit' ? (
                <AttributeLabel style={{ padding: '1px 0' }}>
                  <AttributeBullet clock={attr?.type === 'clock'} />

                  <Field
                    id={`blocks[${index}].attributes[0].textValue`}
                    name={`blocks[${index}].attributes[0].textValue`}
                  >
                    {({ field }) => (
                      <CustomInputDropdown
                        style={{
                          width: '11.2rem',
                          minHeight: '4rem',
                          margin: '0',
                          height: '4rem',
                        }}
                        borderRadius="8px"
                        options={['Distance', 'Time']}
                        onChange={(v) => {
                          setFieldValue(field.name, v);
                          setFieldValue(
                            `blocks[${index}].attributes[${i}].value`,
                            v === 'Time' ? '00:01:00' : 1500,
                          );
                          setFieldValue(
                            `blocks[${index}].attributes[${i}].type`,

                            v === 'Time' ? 'clock' : 'number',
                          );
                        }}
                        value={field.value}
                        placeholder="-unit-"
                        error={
                          touched?.blocks?.[index] &&
                          !!errors?.blocks?.[index]?.exercises?.[i]?.unit
                        }
                      />
                    )}
                  </Field>
                </AttributeLabel>
              ) : (
                <AttributeLabel small={attr.name === 'rest' || attr.type === 'text'}>
                  <AttributeBullet clock={attr?.type === 'clock'} />
                  {attr?.label}
                </AttributeLabel>
              )}

              <Field
                id={`blocks[${index}].attributes[${i}].value`}
                name={`blocks[${index}].attributes[${i}].value`}
              >
                {({ field }) => (
                  <>
                    {attr?.type === 'clock' ? (
                      <AsNeeded>
                        <CustomInputClock
                          height="4rem"
                          onChange={(v) => setFieldValue(field.name, v)}
                          disabled={attr?.asNeeded}
                          value={attr?.asNeeded ? undefined : field.value}
                          error={
                            touched?.blocks?.[index] &&
                            errors?.blocks?.[index]?.attributes?.[i]?.value
                          }
                        />

                        {typeof attr?.asNeeded === 'boolean' && (
                          <AsNeeded>
                            <AsNeededCheck
                              checked={attr.asNeeded}
                              onClick={() =>
                                setFieldValue(
                                  `blocks[${index}].attributes[${i}].asNeeded`,
                                  !attr.asNeeded,
                                )
                              }
                            />
                            As Needed
                          </AsNeeded>
                        )}
                      </AsNeeded>
                    ) : attr?.name === 'text' ? (
                      <CustomTextArea
                        rowCount={5}
                        style={{
                          padding: '8px',
                        }}
                        placeholder="Type here..."
                        wrapperStyle={{
                          maxWidth: '100%',
                        }}
                        onChange={(v) => setFieldValue(field.name, v)}
                        value={field.value}
                        errorIcon={null}
                        errorBorder
                        error={
                          !!(
                            touched?.blocks?.[index] &&
                            errors?.blocks?.[index]?.attributes?.[i]?.value
                          )
                        }
                      />
                    ) : (
                      <>
                        <CustomInput
                          type={attr?.type === 'text' ? 'text' : 'number'}
                          onChange={(v) => {
                            setFieldValue(field.name, v);
                            if (
                              block?.exercises?.length &&
                              block?.type === WorkoutBlockType.STRENGTH &&
                              attr?.name === 'sets' &&
                              v > 0
                            ) {
                              if (block?.exercises?.length > v) {
                                setFieldValue(
                                  `blocks[${index}].exercises`,
                                  block?.exercises?.slice(0, v),
                                );
                              } else if (block?.exercises?.length < v) {
                                const exercisesToAdd = new Array(v - block?.exercises?.length).fill(
                                  {
                                    ...block?.exercises?.[0],
                                    quantity: 1,
                                    weight: 0,
                                    unit: BlockExerciseUnit.REPS,
                                  },
                                );
                                setFieldValue(`blocks[${index}].exercises`, [
                                  ...block?.exercises,
                                  ...exercisesToAdd,
                                ]);
                              }
                            }
                          }}
                          outlined
                          height="4rem"
                          borderRadius="8px"
                          value={field.value}
                          style={{
                            width: '9.4rem',
                            margin: 0,
                            textAlign: attr?.type === 'text' ? 'left' : 'center',
                            outline: 'none',
                            fontFamily: 'Space-r',
                            flexGrow: attr?.type === 'text' ? '1' : '0',
                          }}
                          borderColor="transparent"
                          focusBorderColor="transparent"
                          backgroundColor="#f1f0f0cc"
                          error={
                            touched?.blocks?.[index] &&
                            !!errors?.blocks?.[index]?.attributes?.[i]?.value
                          }
                          errorIcon={false}
                        />
                      </>
                    )}
                  </>
                )}
              </Field>
              {attr?.name === 'unit' && attr?.textValue === 'Distance' && (
                <AttributeUnit>meters</AttributeUnit>
              )}
              {attr?.name === 'pace' && <AttributeUnit>km/h</AttributeUnit>}
            </AttributeWrapper>
          </>
        ))}

        {block?.type !== WorkoutBlockType.REST && block?.type !== WorkoutBlockType.CUSTOM && (
          <>
            <FieldArray
              name={`blocks[${index}].exercises`}
              render={({ push, remove, replace }) => (
                <>
                  <AddExerciseModal
                    modalOpen={!!exerciseAdding}
                    closeModal={(initialSearch) => {
                      setExerciseAdding(null);
                      setSearchText(initialSearch);
                    }}
                    submitModal={async (v) => {
                      const exercise = await createNewExercise(v);
                      if (block?.type === WorkoutBlockType.STRENGTH) {
                        const setsQuantity = block?.attributes?.find((x) => (x.name = 'sets'))
                          ?.value;
                        const exercisesToAdd = new Array(Number(setsQuantity)).fill({
                          exercise: v,
                          quantity: 1,
                          weight: 0,
                          unit: BlockExerciseUnit.REPS,
                        });
                        setFieldValue(`blocks[${index}].exercises`, exercisesToAdd);
                      } else {
                        push({
                          exercise,
                          quantity: 1,
                          weight: 0,
                          unit: BlockExerciseUnit.REPS,
                        });
                      }
                    }}
                    initialValues={exerciseAdding}
                  />
                  {block?.type === WorkoutBlockType.STRENGTH && !!block?.exercises?.length && (
                    <AttributeWrapper>
                      <FlexWrapper>
                        <AttributeLabel>
                          <AttributeBullet />
                          {block?.exercises?.[0].exercise?.title || false}
                        </AttributeLabel>
                        <DeleteExerciseIcon
                          nonAbsolute
                          onClick={() => setFieldValue(`blocks[${index}].exercises`, [])}
                        />
                      </FlexWrapper>
                    </AttributeWrapper>
                  )}

                  {block?.exercises?.map((ex, i) => (
                    <ExerciseWrapper key={i}>
                      <ExerciseLabel>
                        <ExerciseIndex transparent={block?.type === WorkoutBlockType.STRENGTH}>
                          {i + 1}
                        </ExerciseIndex>
                        <ExerciseOpenButton
                          onClick={() =>
                            setExerciseAdding({
                              ...ex?.exercise,
                              changeCallback: (v) =>
                                setFieldValue(`blocks[${index}].exercises[${i}].exercise`, v),
                            })
                          }
                        />
                        {(block?.type !== WorkoutBlockType.STRENGTH &&
                          (ex?.exercise?.title || 'undefined')) ||
                          `Set ${i + 1}`}
                      </ExerciseLabel>
                      <ExerciseContent>
                        <Field
                          id={`blocks[${index}].exercises[${i}].quantity`}
                          name={`blocks[${index}].exercises[${i}].quantity`}
                        >
                          {({ field }) => (
                            <CustomInputNumber
                              arrows
                              upperlabel="quantity"
                              style={{
                                width: '9.2rem',
                                minHeight: '4rem',
                                margin: '0',
                                height: '4rem',
                                marginRight: '0.8rem',
                              }}
                              borderRadius="8px"
                              onChange={(v) => setFieldValue(field.name, Number(v))}
                              value={field.value}
                              onDecrement={() => setFieldValue(field.name, Number(field.value - 1))}
                              onIncriment={() => setFieldValue(field.name, Number(field.value + 1))}
                              error={
                                touched?.blocks?.[index] &&
                                !!errors?.blocks?.[index]?.exercises?.[i]?.quantity
                              }
                            />
                          )}
                        </Field>
                        <Field
                          id={`blocks[${index}].exercises[${i}].unit`}
                          name={`blocks[${index}].exercises[${i}].unit`}
                        >
                          {({ field }) => (
                            <CustomInputDropdown
                              style={{
                                width: '11.2rem',
                                minHeight: '4rem',
                                margin: '0',
                                height: '4rem',
                              }}
                              borderRadius="8px"
                              options={[
                                BlockExerciseUnit.REPS,
                                BlockExerciseUnit.SECONDS,
                                BlockExerciseUnit.METERS,
                              ]}
                              onChange={(v) => setFieldValue(field.name, v)}
                              value={field.value}
                              placeholder="-unit-"
                              error={
                                touched?.blocks?.[index] &&
                                !!errors?.blocks?.[index]?.exercises?.[i]?.unit
                              }
                            />
                          )}
                        </Field>

                        <Field
                          id={`blocks[${index}].exercises[${i}].weight`}
                          name={`blocks[${index}].exercises[${i}].weight`}
                        >
                          {({ field }) => (
                            <ExerciseWeight>
                              <CustomInputNumber
                                arrows
                                unit="kg"
                                upperlabel="weight"
                                style={{
                                  width: '10rem',
                                  minHeight: '4rem',
                                  margin: '0',
                                  height: '4rem',
                                }}
                                borderRadius="8px"
                                onChange={(v) => setFieldValue(field.name, Number(v))}
                                value={field.value}
                                onDecrement={() =>
                                  setFieldValue(field.name, Number(field.value - 1))
                                }
                                onIncriment={() =>
                                  setFieldValue(field.name, Number(field.value + 1))
                                }
                                error={
                                  touched?.blocks?.[index] &&
                                  !!errors?.blocks?.[index]?.exercises?.[i]?.weight
                                }
                              />
                            </ExerciseWeight>
                          )}
                        </Field>
                        {block?.type !== WorkoutBlockType.STRENGTH && (
                          <DeleteExerciseIcon onClick={() => remove(i)} />
                        )}
                      </ExerciseContent>
                    </ExerciseWrapper>
                  ))}

                  {block?.type !== WorkoutBlockType.RUN && (
                    <ExerciseWrapper>
                      <ExerciseIndex>
                        {block?.type !== WorkoutBlockType.STRENGTH && block?.exercises?.length + 1}
                      </ExerciseIndex>
                      <ExerciseContent>
                        {(block?.type !== WorkoutBlockType.STRENGTH ||
                          !block?.exercises?.length) && (
                          <CustomSearch
                            placeholder="Search Exercise"
                            onChange={(v) => setSearchText(v)}
                            onSelect={(v) => {
                              if (block?.type === WorkoutBlockType.STRENGTH) {
                                const setsQuantity = block?.attributes?.find(
                                  (x) => (x.name = 'sets'),
                                )?.value;
                                const exercisesToAdd = new Array(Number(setsQuantity)).fill({
                                  exercise: v,
                                  quantity: 1,
                                  weight: 0,
                                  unit: BlockExerciseUnit.REPS,
                                });
                                setFieldValue(`blocks[${index}].exercises`, exercisesToAdd);
                              } else {
                                push({
                                  exercise: v,
                                  quantity: 1,
                                  weight: 0,
                                  unit: BlockExerciseUnit.REPS,
                                });
                              }
                            }}
                            createNew={(searchInput) =>
                              setExerciseAdding({ title: searchInput, searchText })
                            }
                            viewOption={(option) => setExerciseAdding({ ...option, searchText })}
                            options={exercises?.getExercises || []}
                            value={searchText}
                            style={{ margin: '0' }}
                            loading={exercisesLoading}
                            error={
                              !block?.exercises?.length &&
                              touched?.blocks?.[index] &&
                              errors?.blocks?.[index]?.exercises
                            }
                          />
                        )}
                      </ExerciseContent>
                    </ExerciseWrapper>
                  )}
                </>
              )}
            ></FieldArray>
            <AttributeWrapper>
              <AttributeLabel>
                <AttributeBullet />
                Comments & Indications
              </AttributeLabel>
              <Field id={`blocks[${index}].comment`} name={`blocks[${index}].comment`}>
                {({ field }) => (
                  <CustomTextArea
                    rowCount={null}
                    style={{
                      height: '68px',
                      padding: '4px',
                      maxHeight: '68px',
                    }}
                    wrapperStyle={{
                      maxWidth: 'min(70%, Calc(100% - 18.6rem))',
                    }}
                    onChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched?.blocks?.[index] && errors?.blocks?.[index]?.comment}
                  />
                )}
              </Field>
            </AttributeWrapper>
          </>
        )}
        <WorkoutBlockFooter>
          <div>
            {block?.type !== 'REST' && (
              <>
                {index === 0 && (
                  <Field id={`blocks[${index}].isWarmUp`} name={`blocks[${index}].isWarmUp`}>
                    {({ field }) => (
                      <AttributeLabel flexGrow>
                        <AttrCheckBox
                          active={field.value}
                          onClick={() => setFieldValue(field.name, !field.value)}
                        />
                        Mark this block as Warmup
                      </AttributeLabel>
                    )}
                  </Field>
                )}
                <Field
                  id={`blocks[${index}].videoRequested`}
                  name={`blocks[${index}].videoRequested`}
                >
                  {({ field }) => (
                    <AttributeLabel flexGrow>
                      <AttrCheckBox
                        active={field.value}
                        onClick={() => setFieldValue(field.name, !field.value)}
                      />
                      Request Video
                    </AttributeLabel>
                  )}
                </Field>
              </>
            )}
          </div>
          <WorkoutBlockApplyButton>
            <CustomButton
              outlined
              outlineColor="#00FF91"
              backgroundColor="white"
              fontSize="1.4rem"
              type="button"
              onClick={async () => {
                await setTouched({ blocks: { [index]: true } });

                const invalid = !!errors?.blocks?.[index];

                if (!invalid) {
                  setFieldValue('editingBlockIndex', null);
                }
              }}
            >
              Apply
            </CustomButton>
          </WorkoutBlockApplyButton>
        </WorkoutBlockFooter>
      </WorkoutBlockContent>
    </WorkoutBlockForm>
  );
};

export default EdittingWorkoutBlock;
