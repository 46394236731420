import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #05000033;
  justify-content: center;
  position: relative;
  margin-bottom: 34px;
  &::after {
    content: '';
    width: 12%;
    padding-bottom: 11%;
    background: #ffffff;
    background-image: url(${ICONS.AlertRed});
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
  }
`;

export const ModalHeaderLines = styled.div`
  width: 50%;
  height: 100%;
  border-left: 1px solid #05000033;
  border-right: 1px solid #05000033;
`;

export const ModalMain = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 36px 16px;
`;

export const ModalPrimaryText = styled.div`
  font-family: Gotham-r;
  width: 40rem;
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #4a4a4a;
  text-align: center;
  padding-bottom: 36px;
`;

export const ModalSecondaryText = styled.div`
  font-size: 1.4rem;
  line-height: 1.9rem;
  text-align: center;
  width: 100%;
`;

export const ButtonsFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

export const ButtonWrapper = styled.div`
  width: 164px;
  height: 40px;
  margin: 0 10px;
`;
