import styled, { css } from 'styled-components';
import ICONS from '../../constants/icons';

// Summary block

export const SummaryBlockContainer = styled.div`
  width: Calc((100% - 40px) / 3);
  height: 120px;
  background: #ffffff;
  padding: 32px 46px 32px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.laptopL`
  padding: 24px 46px 24px 24px;
  height: 100px;
  `}
`;

export const SummaryBlockIcon = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 12px;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-position: center;
`;

export const SummaryBlockMain = styled.div`
  flex-grow: 1;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SummaryBlockTitle = styled.div`
  font-family: Roboto-m;
  line-height: 24px;
  color: #373333;
`;

export const SummaryStats = styled.div`
  display: flex;
  font-family: Roboto-r;
  font-size: 1.2rem;
  color: #05000066;
  line-height: 24px;
  align-items: center;
`;

export const SummaryStatsChange = styled.div`
  padding: 0 18px 0 0;
  font-family: Space-r;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #050000cc;
  background-image: url(${ICONS.ArrowUpGreen});
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-repeat: no-repeat;
  margin-right: 6px;
`;

export const SummaryStatsValue = styled.div`
  font-family: Space-r;
  font-size: 4rem;
  line-height: 6.2rem;
  color: #373333;
`;

// program requests

export const ProgramRequestsContainer = styled.div`
  width: 100%;
  background: #ffffff;
`;

export const ProgramRequestsHeader = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 24px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto-m;
  line-height: 24px;
  color: #373333;
`;

export const ProgramRequestsSeeAll = styled.div`
  font-family: Gotham-r;
  font-size: 1.4rem;
  line-height: 24px;
  color: #05000099;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ProgramRequestsNavBar = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  border-top: 1px solid #0500001f;
  border-bottom: 1px solid #0500001f;
  overflow-x: auto;
`;

export const ProgramRequestsNavItem = styled.div`
  flex-grow: 1;
  /* min-width: 20%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: ${({ active }) => (active ? '#050000' : '#05000099')};
  background: ${({ active }) => (active ? '#ffffff' : '#fafafa')};
  position: relative;
  border-right: 1px solid #0500001f;
  text-align: center;
  text-transform: capitalize;
  &:last-child {
    border-right: none;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 1px;
    background: ${({ theme }) => theme.color.primary};
    top: -2px;
    display: ${({ active }) => (active ? 'block' : 'none')};
  }
  cursor: pointer;
`;

export const ProgramRequestsList = styled.div`
  width: 100%;
  padding: 4px 24px;
`;

// program request row

export const ProgramRequestsRowContainer = styled.div`
  width: 100%;
  height: 44px;
  margin: 18px 0 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 2px solid
    ${({ theme, status }) =>
      status === 'APPROVED'
        ? theme.color.primary
        : status === 'PENDING'
        ? '#FFCC00'
        : theme.color.error};
  padding-right: 14px;
  cursor: pointer;
`;

export const ProgramRequestsRowPrimaryText = styled.span`
  font-family: Roboto-r;
  line-height: 24px;
  color: #050000cc;
  text-transform: capitalize;
`;

export const ProgramRequestsRowSecondaryText = styled.span`
  font-family: ${({ num }) => (num ? 'Space-r' : 'Roboto-r')};
  font-size: 1.2rem;
  line-height: 16px;
  color: #05000066;
`;

export const PlaceholderContainer = styled.div`
  width: 100%;
  height: 44px;
  margin: 18px 0 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 14px;
  padding-top: 90px;
  padding-bottom: 90px;
`;

export const PlaceholderText = styled.span`
  color: #050000;
  font-family: Space-r;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;

export const PlaceholderIcon = styled.img`
  margin-bottom: 24px;
`;

export const WorkoutTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const WorkoutTh = styled.th`
  text-align: left;
  color: #050000;
  font-family: Roboto-m;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.4rem;
  padding: 3.1rem 0 1.8rem 0;
  &:last-child {
    text-align: right;
  }
`;

export const ThText = styled.span`
  opacity: 0.4;
`;

export const ThIcon = styled.div`
  display: inline-block;
  background-image: url(${ICONS.TableSortSvg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1.2rem;
  width: 1rem;
  margin-left: 4px;
  cursor: pointer;
`;

export const WorkoutTd = styled.td`
  border-top: 0.5px solid rgba(5, 0, 0, 0.12);
  text-align: left;
  padding: 1.9rem 0;
`;

export const TdText = styled.span`
  color: #373333;
  font-family: Roboto-r;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.1rem;
`;

const iconByStatus = ({ status }) => {
  switch (status) {
    case 'Done':
      return ICONS.StatusDone;
    case 'Missed':
      return ICONS.StatusMissed;
    default:
      return ICONS.StatusNew;
  }
};

export const StatusIcon = styled.div`
  display: inline-block;
  background-image: url(${iconByStatus});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1.1rem;
`;

export const HC = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

export const AttachmentsCount = styled.span`
  color: #373333;
  font-family: 'Space-r';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  text-align: center;
`;

export const AttachmentIcon = styled.div`
  display: inline-block;
  background-image: url(${ICONS.AttachmentSvg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1.6rem;
  width: 0.7rem;
  margin-left: 4px;
  margin-right: 1.6rem;
`;

export const TabContainer = styled.div`
  background-color: ${({ active }) => (active ? 'white' : 'rgba(0, 255, 145, 0.07)')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${({ active }) => (active ? 'white' : 'rgba(0, 255, 145, 0.035)')};
  }
`;

export const ActiveBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #00ff91;
`;

export const TabName = styled.span`
  opacity: 0.6;
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.8rem;
  margin-top: 1.2rem;
`;

export const TabValue = styled.span`
  color: #050000;
  font-family: 'Space-r';
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 3rem;
  text-align: center;
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  margin-top: 0.8rem;
`;

export const Avatar = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: transparent;
  font-family: 'Gotham-r';
  font-size: 0.8rem;
  letter-spacing: 0;
  line-height: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-shrink: 0;
  margin-right: 1.4rem;
  border-radius: 5px;

  ${({ src }) =>
    !src &&
    css`
      background-color: #f1f0f0;
      color: #373333;
      user-select: none;
    `}
`;

export const EmptyText = styled.span`
  font-family: Space-r;
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 4.8rem auto;
  text-align: center;
`;
