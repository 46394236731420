import * as Yup from 'yup';

const title = Yup.string().trim().required('Required');
const validUntil = Yup.date().min(new Date());
const description = Yup.string().required();
const available = Yup.number().required();
const amount = Yup.number().required();
const discountType = Yup.mixed().required();

export const CouponSchema = Yup.object().shape({
  title,
  validUntil,
  description,
  available,
  amount,
  discountType,
});
