import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import Modal from 'react-modal';
import { positions, Provider as AlertProvider } from 'react-alert';
import apolloClient from './services/apollo';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ChatProvider } from './services/ChatContext';
import CometChatManager from './services/ChatManager';
import AlertSnack from './components/alert-snack/AlertSnack';
import { NotificationProvider } from './services/NotificationContext';
import { OneSignal } from './services/OneSignal';

const root = document.getElementById('root');
Modal.setAppElement(root);

const AlertOptions = {
  timeout: 4000,
  position: positions.BOTTOM_CENTER,
};

const appID = '23039f337feab7f';
const region = 'eu';
CometChatManager.initialize(appID, region);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AlertProvider template={AlertSnack} {...AlertOptions}>
        <ChatProvider>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </ChatProvider>
      </AlertProvider>
    </ApolloProvider>
  </React.StrictMode>,
  root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
