import React from 'react';
import styled, { keyframes } from 'styled-components';
import MinIcon from '../../assets/program/minimize.png';
import ICONS from '../../constants/icons';

export const StatusBar = styled.div`
  border-radius: 4px;
  background-color: #fdfdfd;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.12);
  display: flex;
  align-items: center;
  padding: 1.2rem 2.8rem;
`;

export const StatusTitle = styled.span`
  opacity: 0.6;
  color: #4a4a4a;
  font-family: Roboto-m;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.4rem;
  padding-right: 0.8rem;
`;

export const StatusText = styled.span`
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
`;

export const StatusMessageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const StatusMessage = styled.span`
  opacity: 0.8;
  color: #050000;
  font-family: Roboto-m;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.4rem;
`;

export const IconContainer = styled.div`
  box-sizing: border-box;
  height: 3.65rem;
  width: 3.65rem;
  /* border: 0.5px solid #ffcc00; */
  border-radius: 8px;
  background-color: ${({ isDue, theme }) => (isDue ? theme.color.error : '#ffcc00')};
  margin-right: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.12);
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

export const Sidemenu = styled.div`
  max-width: 340px;
  min-width: ${({ isOpen }) => (isOpen ? 'max(20%, 272px)' : '35px')};
  width: ${({ isOpen }) => (isOpen ? '20%' : '35px')};
  border-radius: 4px 0 0 4px;
  background-color: #f1f0f0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100rem;
`;

export const SideHeader = styled.div`
  min-width: max(20%, 272px);
  max-width: 340px;
  display: flex;
  align-items: center;
  background-color: #f1f0f0;
  padding: 0 2.4rem;
  height: 100%;
`;

const AvatarContainer = styled.div`
  border-radius: 50%;
  position: relative;
  margin-right: 2.4rem;
  width: 6.68rem;
  height: 6.68rem;
  min-width: 6.68rem;
  min-height: 6.68rem;
  border: 0.64rem solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto-b;
  letter-spacing: 0.8px;
  color: ${({ image }) => (image ? 'transparent' : '#cccccc')};
  text-transform: uppercase;
  font-size: 14px;
`;

const AvatarContent = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  position: absolute;
  border: 0.32rem solid ${({ theme }) => theme.color.primary};
`;

export const Avatar = ({ src, children }) => (
  <AvatarContainer>
    {children}
    <AvatarContent src={src} />
  </AvatarContainer>
);

export const TraineeName = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TraineeNameTitle = styled.span`
  color: #4a4a4a;
  font-family: 'Gotham-R';
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  line-height: 2.4rem;
`;
export const TraineeNameSubTitle = styled.span`
  color: #4a4a4a;
  font-family: 'Space-r';
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  line-height: 2.4rem;
`;

export const SideContent = styled.div`
  border-radius: 0 0 0 8px;
  background-color: rgba(255, 255, 255, 0.95);
  border: 4px solid #e6e5e5;
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ isOpen }) => (isOpen ? '100%' : '35px')};
  & > .side-info {
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  }
  max-height: 100%;
`;

export const SideInfo = styled.div`
  margin-top: 5.5rem;
  border-top: 0.05rem solid rgba(5, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InfoHeader = styled.div`
  box-sizing: border-box;
  padding: 0.6rem 0;
  width: 80%;
  border: 0.05rem solid #15df68;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1.6rem;
  left: 10%;
`;

export const InfoHeaderText = styled.span`
  color: #373333;
  font-family: 'Space-r';
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  text-align: center;
`;

export const InfoHeaderEditIcon = styled.span`
  width: 1.2rem;
  height: 1.2rem;
  background-image: url(${ICONS.EditSimpleGrey});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0.8rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

// const CellBorder = '0.5px solid rgba(5, 0, 0, 0.2)';

export const Cell = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  padding: 2.4em 1.6rem 2rem;
  border-color: rgba(5, 0, 0, 0.2);
  border-style: solid;
  border-top-width: ${({ mt }) => (mt ? 0.5 : 0)}px;
  border-right-width: ${({ mr }) => (mr ? 0.5 : 0)}px;
  border-bottom-width: ${({ mb }) => (mb ? 0.5 : 0)}px;
  border-left-width: ${({ ml }) => (ml ? 0.5 : 0)}px;
`;

export const CellTitle = styled.span`
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  text-align: center;
`;

const CellText = styled.span`
  color: #050000;
  font-family: Roboto-l;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2.1rem;
  text-align: center;
  margin-top: 0.8rem;
  padding: 0 1.6rem;
  text-transform: capitalize;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height};
  flex: 1;
  & > #magic-scroll {
    max-width: 23rem;
    min-width: 23rem;
    display: flex;
    justify-content: center;
  }
  & * > .magic-scroll-arrow-left {
    left: -2.4rem;
  }
  & * > .magic-scroll-arrow-right {
    right: -2.4rem;
  }
`;

const arrayfy = (item) => (Array.isArray(item) ? item : [item]);

export const InfoCell = ({ title, text, ...cellProps }) => (
  <Cell {...cellProps}>
    <CellTitle>{title}</CellTitle>
    {arrayfy(text).map((item, index) => (
      <CellText key={index}>{item?.toLowerCase()}</CellText>
    ))}
  </Cell>
);

const MinBtnContainer = styled.div`
  position: absolute;
  width: 34px;
  height: 34px;
  right: -17px;
  top: calc(50% - 17px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-right: 4px solid #e6e5e5;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  cursor: pointer;
  z-index: 1;
`;

const MinBtnIcon = styled.img`
  width: 24px;
  border-radius: 12px;
  transform: scaleX(${({ isOpen }) => (isOpen ? 1 : -1)});
`;

export const MinimizeButton = ({ onClick, isOpen }) => (
  <MinBtnContainer onClick={onClick}>
    <MinBtnIcon isOpen={isOpen} src={MinIcon} alt="" />
  </MinBtnContainer>
);

export const Header = styled.div`
  display: flex;
  height: 94px;
`;

export const Content = styled.div`
  display: flex;
  max-height: 78.9rem;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  border-bottom: 1px solid #e9eaed;
`;

export const Tabs = styled.div`
  display: flex;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 163px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid #e9eaed;
`;

const TabText = styled.span`
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  color: #373333;
  font-family: ${({ active }) => (active ? 'Roboto-m' : 'Roboto-r')};
  font-size: 14px;
  letter-spacing: 0;
  line-height: ${({ active }) => (active ? 24 : 21)}px;
`;

const TabBorder = styled.div`
  height: 4px;
  width: 163px;
  border-radius: 100px 100px 0 0;
  background-color: #00ff91;
  position: absolute;
  bottom: -2px;
`;

export const Tab = ({ text, onClick, active }) => (
  <TabContainer onClick={onClick}>
    <TabText active={active}>{text}</TabText>
    {active && <TabBorder />}
  </TabContainer>
);

export const ButotnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 36px;
`;

export const ButtonText = styled.span`
  margin-left: 59px;
  margin-right: 59px;
`;

export const GroupIdBadge = styled.span`
  width: fit-content;
  font-family: Space-b;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #4a4a4a;
  padding: 0.45rem 0.85rem;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
`;

const OpacityAnimation = keyframes`
from{
  opacity: 0;
}
to {
 opacity: 1;
}
`;

export const ParticipantOfSlider = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 8px;
  margin: 0 0.5rem;
  border: 1px solid #05000033;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #05000080;
  font-size: 1.2rem;
  font-family: Roboto-m;
  animation: ${OpacityAnimation} 100ms linear;
`;

export const ParticipantSliderItem = ({ item, active }) => (
  <ParticipantOfSlider active={active}>
    {item?.fullName
      ?.split(' ')
      .map((x) => x.substring(0, 1))
      .join('')
      ?.substring(0, 2)}
  </ParticipantOfSlider>
);
