import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #05000040;
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.div`
  width: 80px;
  height: 100%;
  border-right: 1px solid #05000040;
  background-image: url(${ICONS.Logo});
  background-size: 38%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  min-width: 80px;
`;

export const HeaderLink = styled.div`
  width: 100px;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid #05000010;
  position: relative;
  &::after {
    content: '';
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.color.primary};
    opacity: 0.5;
    bottom: -2px;
    border-radius: 2px;
  }
  background-image: ${({ icon }) => (icon ? `url(${icon})` : '')};
  background-size: 26%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const HeaderMain = styled.div`
  flex-grow: 1;
  display: flex;
  border-right: 1px solid #05000040;
  background: #fdfdfd;
`;

export const ProfilePic = styled.div`
  width: 44px;
  height: 44px;
  background-image: ${({ img }) => (img ? `url(${img})` : `url(${ICONS.ProfileImage})`)};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
`;

export const SideButtonsRelativeBox = styled.div`
  position: relative;
  width: 400px;
`;
export const SideButtonsAbsoluteBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 102;
`;
