import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 2.4rem;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Space-r')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  font-size: 1.6rem;
  padding: 2rem 2.4rem;
  min-height: 28px;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

export const ModalContent = styled.div`
  width: 100%;
  padding: 0 3.2rem;
`;

export const ArrowIcon = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #d2d1d1;
  background-image: url('');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 3.5rem auto 2rem;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 7.4rem 3.2rem 2.8rem;
`;

export const ButtonWrapper = styled.div`
  height: 4.8rem;
  margin-left: 1.2rem;
  width: 11.2rem;
`;

export const TemplateInputWrapper = styled.div`
  width: 100%;
  padding: 3.4rem 0 0.4rem;
  & * > {
    /* width */
    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
      border-radius: 2px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e2e2e2;
      border-radius: 2px;
    }
  }
  & > * .option_icon {
    display: none;
  }
  & > .css-1joejl3-container {
    margin: 0;
    min-height: 5rem;
  }
  & > * .css-9fbgpq-control {
    min-height: 5rem;
    border: 1px solid #cdcccc;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProgramLengthContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldTitle = styled.div`
  font-family: Roboto-m;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #37333388;
  padding: 0 0.4rem 1.2rem;
  width: fit-content;
`;

export const TemplateLengthItem = styled.div`
  padding: 1.2rem;
  line-height: 1.6rem;
  font-size: 1.4rem;
  min-width: 8rem;
  color: ${({ checked }) => (checked ? '050000' : '#05000080')};
  border: ${({ checked, theme }) =>
    checked ? `1.5px solid ${theme.color.primary}` : '1.5px solid #cdcccc'};
  border-radius: 8px;
  margin-right: 1.2rem;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
