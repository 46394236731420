import React from 'react';
import { WorkoutBlockType } from '../../constants/enums';
import { useUtils } from '../../hooks';
import {
  AttributeBullet,
  WorkoutBlockTable as WorkoutBlockTableContainer,
  WorkoutBlockTableAttributeLabel,
  WorkoutBlockTableAttributeNum,
  WorkoutBlockTableAttributeRow,
  WorkoutBlockTableColumnLeft,
  WorkoutBlockTableColumnRight,
  WorkoutBlockWorkoutIndex,
  WorkoutBlockWorkoutName,
  WorkoutBlockWorkoutRow,
  WorkoutBlockWorkoutValue,
  CustomBlockDescription,
  CustomBlockDescriptionLine,
} from './components';

const WorkoutBlockTable = ({ block }) => {
  const { formatNumber } = useUtils();
  return (
    <WorkoutBlockTableContainer>
      {block.type !== WorkoutBlockType.CUSTOM && block.type !== WorkoutBlockType.RUN && (
        <WorkoutBlockTableColumnLeft>
          {block.type !== WorkoutBlockType.REST && <WorkoutBlockTableAttributeRow />}
          {block?.attributes?.map((attr) => (
            <WorkoutBlockTableAttributeRow key={attr.uid}>
              <AttributeBullet />
              {attr.type === 'clock' ? (
                <>
                  <WorkoutBlockTableAttributeLabel>
                    {`${attr.itemName}: ${attr?.asNeeded ? 'As Needed' : ''}`}
                  </WorkoutBlockTableAttributeLabel>

                  {block.type !== WorkoutBlockType.REST && (
                    <WorkoutBlockTableAttributeNum>
                      {attr?.asNeeded ? '' : attr?.value?.slice(-5)}
                    </WorkoutBlockTableAttributeNum>
                  )}
                </>
              ) : (
                <>
                  <WorkoutBlockTableAttributeNum>{attr.value}</WorkoutBlockTableAttributeNum>
                  <WorkoutBlockTableAttributeLabel>{attr.itemName}</WorkoutBlockTableAttributeLabel>
                </>
              )}
            </WorkoutBlockTableAttributeRow>
          ))}

          {block.type !== WorkoutBlockType.REST && <WorkoutBlockTableAttributeRow />}
        </WorkoutBlockTableColumnLeft>
      )}
      {block.type === WorkoutBlockType.RUN ? (
        <div style={{ width: '100%', padding: '0 4rem' }}>
          <WorkoutBlockTableAttributeRow />
          {block?.attributes?.map((attr) => (
            <>
              {attr?.value && attr?.value !== -1 ? (
                <WorkoutBlockTableAttributeRow>
                  <AttributeBullet />
                  <WorkoutBlockTableAttributeLabel>
                    {(attr.name === 'unit' && `Total ${attr?.textValue}`) || attr.itemName}:
                  </WorkoutBlockTableAttributeLabel>
                  <WorkoutBlockTableAttributeNum style={{ paddingLeft: '0.4rem' }}>
                    {(attr.name === 'unit' &&
                      (attr.type === 'number'
                        ? `${formatNumber(attr?.value)} meters`
                        : `${attr?.value?.slice(-5)}`)) ||
                      `  ${attr?.value} km/h`}
                  </WorkoutBlockTableAttributeNum>
                </WorkoutBlockTableAttributeRow>
              ) : (
                <></>
              )}
            </>
          ))}
          <WorkoutBlockTableAttributeRow />
        </div>
      ) : (
        <WorkoutBlockTableColumnRight>
          {block.type === WorkoutBlockType.REST && (
            <WorkoutBlockWorkoutRow>
              <WorkoutBlockWorkoutValue>
                {/* {block?.attributes?.[0]?.value?.slice(-5)} */}
              </WorkoutBlockWorkoutValue>
            </WorkoutBlockWorkoutRow>
          )}

          {block.type === WorkoutBlockType.CUSTOM &&
            block?.attributes?.map((attr) => (
              <>
                {attr?.name === 'text' && (
                  <CustomBlockDescription>
                    {attr?.value?.split('\n').map((i) => (
                      <CustomBlockDescriptionLine>{i}</CustomBlockDescriptionLine>
                    ))}
                  </CustomBlockDescription>
                )}
              </>
            ))}
          {block?.exercises?.map((ex, i) => (
            <WorkoutBlockWorkoutRow key={ex.uid}>
              <WorkoutBlockWorkoutIndex>
                {block.type === WorkoutBlockType.STRENGTH ? `Set ${i + 1}:` : i + 1}
              </WorkoutBlockWorkoutIndex>

              {block.type !== WorkoutBlockType.STRENGTH && (
                <WorkoutBlockWorkoutName>{`${ex?.exercise?.title}:`}</WorkoutBlockWorkoutName>
              )}
              <WorkoutBlockWorkoutValue>{`${
                ex?.quantity
              } ${ex?.unit.toLowerCase()}`}</WorkoutBlockWorkoutValue>
              {!!ex?.weight && (
                <>
                  <WorkoutBlockWorkoutValue>|</WorkoutBlockWorkoutValue>
                  <WorkoutBlockWorkoutValue>{`${ex?.weight} kg weight`}</WorkoutBlockWorkoutValue>
                </>
              )}
            </WorkoutBlockWorkoutRow>
          ))}
        </WorkoutBlockTableColumnRight>
      )}
    </WorkoutBlockTableContainer>
  );
};

export default WorkoutBlockTable;
