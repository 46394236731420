import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ArrowButtonLeft,
  ArrowButtonRight,
  ScrollContainer,
  ScrollItem,
  ScrollItemsList,
} from './components';

const MagicScroll = ({
  itemsToDisplay = 1,
  items = [],
  ItemComponent,
  firstItem,
  onLeftClick,
  onRightClick,
  keyExtractor = (item) => item && item.toString(),
  activeDate,
  onTabClick,
  noArrows = false,
}) => {
  const [containerWidth, setContainerWidth] = useState(0);

  const scrollToItem = useCallback(() => {
    if (firstItem || firstItem === 0) {
      setTimeout(() => {
        const id = keyExtractor(firstItem);
        const elem = document.getElementById(id);
        if (elem) {
          elem.scrollIntoView({ block: 'nearest', inline: 'start' });
        }
      }, 10);
    }
  }, [firstItem, keyExtractor]);

  useEffect(() => {
    const div = document.getElementById('magic-scroll');

    const ro = new ResizeObserver((entries) => {
      const myDiv = entries.find((entry) => entry.target === div);
      setContainerWidth(myDiv.contentRect.width);
    });

    ro.observe(div);

    return () => ro.unobserve(div);
  }, []);

  const itemStyles = useMemo(() => {
    scrollToItem();
    return {
      width: `${containerWidth / itemsToDisplay}px`,
    };
  }, [itemsToDisplay, containerWidth, scrollToItem]);

  const activeKey = useMemo(() => keyExtractor(activeDate), [keyExtractor, activeDate]);
  const MagicScrollItem = useCallback(
    ({ id, item, active }) => (
      <ScrollItem style={itemStyles} id={id} onClick={() => onTabClick(item)}>
        <ItemComponent item={item} active={id === activeKey} />
      </ScrollItem>
    ),
    [itemStyles, activeKey, onTabClick],
  );

  useEffect(scrollToItem, [scrollToItem]);

  return (
    <ScrollContainer id="magic-scroll">
      {!noArrows && (
        <>
          <ArrowButtonLeft onClick={onLeftClick} className="magic-scroll-arrow-left" />
          <ArrowButtonRight onClick={onRightClick} className="magic-scroll-arrow-right" />
        </>
      )}
      <ScrollItemsList>
        {items.map((item, i) => {
          const key = keyExtractor(item, i);
          return <MagicScrollItem key={key} item={item} id={key} />;
        })}
      </ScrollItemsList>
    </ScrollContainer>
  );
};

export default MagicScroll;
