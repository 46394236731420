import styled from 'styled-components';
import ArrowLeftIcon from '../../assets/dashboard/arrow-white-left.svg';
import ArrowRightIcon from '../../assets/dashboard/arrow-green-right.svg';

export const ScrollContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ScrollItemsList = styled.div`
  height: 100%;
  min-width: 10px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const ScrollItem = styled.div`
  height: 100%;
  display: inline-block;
`;

export const ArrowButton = styled.div`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const ArrowButtonLeft = styled(ArrowButton)`
  left: 2.4rem;
  background-image: url(${ArrowLeftIcon});
  &:hover {
    background-image: url(${ArrowRightIcon});
    transform: translateY(-50%) scaleX(-1);
  }
`;

export const ArrowButtonRight = styled(ArrowButton)`
  right: 2.4rem;
  background-image: url(${ArrowLeftIcon});
  transform: translateY(-50%) scaleX(-1);
  &:hover {
    background-image: url(${ArrowRightIcon});
    transform: translateY(-50%);
  }
`;
