import React, { useMemo } from 'react';
import {
  Avatar,
  ConversationItemColumn,
  ConversationItemContainer,
  ConversationItemLastMessage,
  ConversationItemName,
} from './components';

const ConversationNew = ({ trainee, isActive, onClick }) => {
  const initials = useMemo(() => {
    const [first, last] = trainee?.fullName?.split(' ');
    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`;
  }, [trainee]);

  return (
    <ConversationItemContainer onClick={onClick} isActive={isActive}>
      <ConversationItemColumn>
        <Avatar src={trainee.avatar} size="3.6rem" style={{ margin: '1.2rem' }}>
          {initials}
        </Avatar>
      </ConversationItemColumn>

      <ConversationItemColumn>
        <ConversationItemName>{trainee?.fullName}</ConversationItemName>
        <ConversationItemLastMessage>New conversation</ConversationItemLastMessage>
      </ConversationItemColumn>
    </ConversationItemContainer>
  );
};
export default ConversationNew;
