import React from 'react';
import moment from 'moment';
import {
  PricingTableWrapper,
  PricingTable,
  PricingTableHeadTR,
  PricingTableTR,
  PricingTableTh,
  PricingTableTd,
  PricingTableTdCopy,
  PricingTableTdAction,
  SortSpecification,
} from './components';
import EmptyTemplate from '../empty-template/EmptyTemplate';
import IMAGES from '../../constants/images';

const CouponTable = ({ setEdditingCode, coupons = [], deleteCoupon }) => {
  const copyCodeToClipboard = (id) => {
    const el = document.getElementById(id);
    el.select();
    document.execCommand('copy');
  };
  return (
    <PricingTableWrapper>
      {coupons?.length ? (
        <PricingTable>
          <PricingTableHeadTR>
            <PricingTableTh>
              Coupon Code <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Name <SortSpecification asc />
            </PricingTableTh>
            <PricingTableTh>
              Expiration Date <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Description <SortSpecification desc />
            </PricingTableTh>
            <PricingTableTh>
              Discount Type <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Amount <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Usage <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>Actions</PricingTableTh>
          </PricingTableHeadTR>
          {coupons?.map((code) => (
            <PricingTableTR>
              <PricingTableTd>
                {`#${code?.code?.digits}`}
                <textarea
                  style={{ maxWidth: 0, maxHeight: 0, opacity: 0 }}
                  value={code?.code?.digits}
                  id={`to_copy_${code?.code?.digits}`}
                />
                <PricingTableTdCopy
                  className="copy"
                  onClick={() => copyCodeToClipboard(`to_copy_${code?.code?.digits}`)}
                />
              </PricingTableTd>
              <PricingTableTd>{code?.title}</PricingTableTd>
              <PricingTableTd>{moment(code?.validUntil).format('MM/DD/YYYY')}</PricingTableTd>
              <PricingTableTd width="14rem">{code?.description}</PricingTableTd>
              <PricingTableTd center>{code?.discountType === 'AMOUNT' ? '₪' : '%'}</PricingTableTd>
              <PricingTableTd center> {code?.amount}</PricingTableTd>
              <PricingTableTd center> {`${code?.usage}/${code?.available}`}</PricingTableTd>
              <PricingTableTd>
                <PricingTableTdAction className="edit" onClick={() => setEdditingCode(code)} />
                <PricingTableTdAction className="del" onClick={() => deleteCoupon(code?.uid)} />
              </PricingTableTd>
            </PricingTableTR>
          ))}
        </PricingTable>
      ) : (
        <EmptyTemplate
          image={IMAGES.EmptyTemplates}
          imageSize={'20rem'}
          primaryText="You don't Have any Coupons Yet"
        />
      )}
    </PricingTableWrapper>
  );
};

export default CouponTable;
