import styled, { keyframes, css } from 'styled-components';
import ICONS from '../../constants/icons';

const ProgressAnimation = keyframes`
  0% {
    width: 46px;
    padding-right: 46px;
  }
  100% {
    width: 46px;
  }
`;

const ProgressComplete = keyframes`
  0% {
    width: 46px;
    padding-right: 8px;
  }
  100% {
    width: 46px;
    padding-right: 0px;
  }
`;

const AppearAnimation = keyframes`
  0% {
    width: 0;
    height: 0;
  }
  99% {
    width: 0;
    height: 0;
  }
  100% {
    width: auto;
  }
`;

export const WholeWrapper = styled.div`
  width: 100%;
  padding: 12px 0;
`;

export const UploadedCredWrapper = styled.div`
  width: 100%;
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 8px;
  line-height: 24px;
  font-size: 1.4rem;
  color: #050000cc;
  margin-top: 12px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GreyButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: #f1f0f0;
  background-image: ${({ icon }) => (icon ? `url(${icon})` : `url(${ICONS.File})`)};
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  margin-left: 6px;
`;

export const EditDeleteButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-image: ${({ icon }) => `url(${icon})`};
  &:hover {
    background-image: ${({ hoverIcon }) => `url(${hoverIcon})`};
  }
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 8px;
`;

export const ProcessBox = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #05000033;
  margin-top: 20px;
`;

export const SingleFileWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #292929;
  justify-content: space-between;
  margin: 12px 0;
`;

export const SingleFileIcon = styled.div`
  width: 10px;
  height: 10px;
  background-image: ${({ type }) =>
    type === 'image' ? `url(${ICONS.Image})` : `url(${ICONS.File})`};
  background-position: center;
  background-size: contain;
  opacity: 80%;
  margin-right: 12px;
  background-repeat: no-repeat;
`;

export const FileProgressContainer = styled.div`
  width: ${({ uploaded }) => (uploaded ? '0' : '46px')};
  padding-right: ${({ uploaded }) => (uploaded ? '0' : '8px')};
  height: 2px;
  border-radius: 1px;
  font-size: 1.2rem;
  background: #cccccc;
  overflow: hidden;
  animation-name: ${({ uploaded }) =>
    uploaded
      ? css`
          ${ProgressComplete}
        `
      : css`
          ${ProgressAnimation}
        `};
  animation-duration: ${({ uploaded, size }) => (uploaded ? '0.2s' : `${size / (1024 * 1024)}s`)};
`;

export const FileProgressBar = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1px;
  background: ${({ theme }) => theme.color.primary};
`;

export const FileSize = styled.div`
  width: auto;
  overflow: hidden;
  animation: ${AppearAnimation} 0.3s;
`;

export const SingleFileCancelX = styled.div`
  width: 10px;
  height: 10px;
  background-image: url(${ICONS.X});
  background-position: center;
  background-size: contain;
  margin-left: 12px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const ProcessBoxButtonWrapper = styled.div`
  width: 164px;
  height: 44px;
  position: relative;
`;

export const ProcessBoxInputWrapper = styled.div`
  flex-grow: 1;
  margin-right: 12px;
`;

export const SaveButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
`;

export const DiplomasPlusButton = styled.div`
  width: 48px;
  height: 48px;
  background-image: url(${ICONS.DiplomaPlus});
  background-size: contain;
  background-position: left;
  margin: 12px 0;
  cursor: pointer;
`;
