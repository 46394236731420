import styled from 'styled-components';

export const WholeWrapper = styled.div`
  width: 100%;
  padding: 2rem 10rem;
  padding: 2rem 10rem;
`;

export const ProgressTrack = styled.div`
  width: 100%;
  height: 0.8rem;
  background: #fafafa;
  /* border: 1px solid #f1f0f0; */
  box-shadow: 0 0 1px 1px #f1f0f0 inset;
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    background: #fafafa;
    position: absolute;
    right: -0.6rem;
    border-radius: 50%;
    padding: 0.5rem;
    border: 0.1rem solid #f1f0f0;
    z-index: 10;
  }
`;

export const ProgressBar = styled.div`
  width: ${({ percentage }) => percentage}%;
  height: 100%;
  border-radius: 0.4rem;
  background: #00ff91;
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    background: #ffffff;
    position: absolute;
    right: -0.8rem;
    border-radius: 50%;
    padding: 0.4rem;
    border: 0.4rem solid #00ff8d;
    box-shadow: 0 0 0 0.5rem #d2d1d15d;
    z-index: 11;
  }
  &::before {
    content: '';
    background: #15df68;
    position: absolute;
    left: -0.8rem;
    border-radius: 50%;
    padding: 0.4rem;
    border: 0.4rem solid #00ff8d;
    z-index: 11;
  }
`;

export const ChartLegend = styled.div`
  width: 100%;
  height: 3.6rem;
  & div.end_points {
    font-family: Space-r;
    font-size: 1.8rem;
    color: #373333;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const ChartLegendProgress = styled.div`
  position: absolute;
  width: 10rem;
  top: 0.8rem;
  left: ${({ percentage }) => `Calc(${percentage}% - 5rem)`};
  font-family: Space-b;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #373333;
  text-align: center;
  z-index: 1111;
  & > div {
    width: 100%;
    text-align: center;
    font-family: Roboto-r;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #373333;
  }
`;
