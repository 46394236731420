import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.12);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  max-height: 78.9rem;
  flex-grow: 1;
`;

export const TemplateHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 3.6rem 3rem 1rem 3rem;
  padding-top: 3.6rem;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
`;

export const TemplateInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > span {
    padding: 0 1.2rem 0 0.6rem;
  }
  & > * .option_icon {
    display: none;
  }
  & > .css-1joejl3-container {
    margin: 0;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TemplateLengthItem = styled.div`
  padding: 1.2rem;
  line-height: 1.6rem;
  font-size: 1.4rem;
  color: 1px solid ${({ checked }) => (checked ? '050000' : '#05000080')};
  border: ${({ checked, theme }) =>
    checked ? `2px solid ${theme.color.primary}` : '1px solid #cdcccc'};
  border-radius: 8px;
  margin-right: 1.2rem;
  cursor: pointer;
  min-width: 8.8rem;
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
`;
