import styled from 'styled-components';

export const NotificationsWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 4px;
  }
  overflow-y: auto;
  padding-bottom: 6.4rem;
`;

export const NotificationsSection = styled.div`
  width: 100%;
  border-bottom: 1px solid #05000012;
  &:last-child {
    border-bottom: none;
  }
`;

export const SectionTitle = styled.div`
  padding: 2.8rem 2.8rem 1.4rem 3.2rem;
  font-family: Roboto-m;
  line-height: 2.4rem;
  color: ${({ previous }) => (previous ? '#05000080' : '#050000')};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const MarkAsRead = styled.div`
  font-family: Roboto-r;
  line-height: 2rem;
  font-size: 1.2rem;
  text-decoration: underline;
  text-transform: none;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;
  &:active {
    opacity: 0.6;
  }
`;

export const NotificationRow = styled.div`
  width: 100%;
  padding: 1.8rem 3.2rem;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  &:hover {
    background: #f7f7f7;
  }
  cursor: pointer;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-grow: 1;
`;

export const NotificationImage = styled.div`
  min-width: 3.6rem;
  min-height: 3.6rem;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 8px;
  background: #f1f0f0;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  letter-spacing: 0.8px;
  color: #05000033;
  text-transform: uppercase;
  font-size: 1.2rem;
`;

export const NotificationStatus = styled.div`
  position: absolute;
  right: -5px;
  top: -3px;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 2px solid white;
  background: ${({ status, theme }) =>
    status === 'RED' ? theme.color.error : status === 'GREEN' ? theme.color.primary : '#FFCC00'};
`;

export const NotificationText = styled.div`
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #373333;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const NotificationDate = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #4a4a4acc;
  min-width: 8.8rem;
  text-align: right;
  width: 100%;
  min-width: fit-content;
`;

export const NotificationTextTitle = styled.span`
  color: #050000;
  font-family: Roboto-b;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  min-width: fit-content;
`;

export const NotificationTextSubTitle = styled.span`
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.8rem;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  max-width: 200px;
`;
