import React from 'react';
import { Formik, Form, Field } from 'formik';

import { useRegisterCoach } from '../../hooks/index';
import STRINGS from '../../constants/strings';
import IMAGES from '../../constants/images';
import CustomInput from '../../components/input/CustomInput';
import CustomButtom from '../../components/button/CustomButton';
import CustomInputCheck from '../../components/input/CustomInputCheck';
import CustomInputNumber from '../../components/input/CustomInputNumber';
import CredentialsUpload from '../../components/credentials-upload/CredentialsUpload';
import { SignupProfessionalSchema } from '../../schemas/signup/coachProfile';
import {
  SignUpContainer,
  LeftPhoto,
  FormContainer,
  FormHeading,
  FormSubHeading,
  ProfessionalFormWrapper,
  FieldTip,
  LeftPhotoBackground,
  LeftPhotoBackgroundLine,
  FieldHeading,
  SpecialitiesWrapper,
  YearsWrapper,
  FlexWrapper,
  FavoritesWrapper,
  AboutTextArea,
  AboutButtonWrapper,
  AboutButtonsWrapper,
  Logo,
  ErrorMessage,
  InputErrorIcon,
  PseudoCkeckForFlex,
} from './components';

const CoachSignUpProfessionalInfo = () => {
  const {
    meLoading,
    meError,
    specialities,
    specialitiesLoading,
    specialitiesError,
    getInitialValuesProfessional,
    backToPersonalPage,
    submitProfessionalForm,
  } = useRegisterCoach();

  return (
    <SignUpContainer>
      <LeftPhotoBackground>
        <LeftPhotoBackgroundLine>
          <Logo />
        </LeftPhotoBackgroundLine>
        <LeftPhotoBackgroundLine />
        <LeftPhotoBackgroundLine />
        <LeftPhotoBackgroundLine />
        <LeftPhoto image={IMAGES.FitnessGirlSignupImage} />
      </LeftPhotoBackground>
      <FormContainer>
        <FormHeading>{STRINGS.new_coach_registration_form}</FormHeading>
        <FormSubHeading>{STRINGS.professional_info}</FormSubHeading>
        <ProfessionalFormWrapper>
          {!(meLoading || meError) && (
            <Formik
              initialValues={getInitialValuesProfessional()}
              validationSchema={SignupProfessionalSchema}
              onSubmit={submitProfessionalForm}
            >
              {({ setFieldValue, errors, touched, isSubmitting }) => (
                <Form style={{ width: '100%' }}>
                  <Field id="specialities" name="specialities">
                    {({ field }) => (
                      <>
                        <FieldHeading>{STRINGS.training_specialities}</FieldHeading>
                        <FieldTip>{STRINGS.please_select_all_specialities}</FieldTip>
                        <SpecialitiesWrapper>
                          {!specialitiesLoading &&
                            !specialitiesError &&
                            specialities?.getSpecialities
                              ?.slice(0)
                              ?.reverse()
                              ?.map((spec, i) => {
                                const index = field.value?.indexOf(spec.id);
                                return (
                                  <CustomInputCheck
                                    title={spec.name}
                                    icon={spec?.icon?.url}
                                    checked={index > -1}
                                    onCheck={() => {
                                      if (index > -1) {
                                        const currentValue = [...field.value];
                                        currentValue.splice(index, 1);
                                        setFieldValue(field.name, currentValue);
                                      } else {
                                        setFieldValue(field.name, [...field.value, spec.id]);
                                      }
                                    }}
                                  />
                                );
                              })}
                          <PseudoCkeckForFlex />
                          <PseudoCkeckForFlex />
                          <PseudoCkeckForFlex />
                          <PseudoCkeckForFlex />
                          {touched.specialities && errors.specialities && (
                            <ErrorMessage>
                              <InputErrorIcon />
                              {errors.specialities}
                            </ErrorMessage>
                          )}
                        </SpecialitiesWrapper>
                      </>
                    )}
                  </Field>
                  <Field id="experience" name="experience">
                    {({ field }) => (
                      <>
                        <FieldHeading>{STRINGS.years_of_experience}</FieldHeading>
                        <FieldTip>{STRINGS.how_many_years_coaching}</FieldTip>
                        <YearsWrapper>
                          <CustomInputNumber
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            onIncriment={() => setFieldValue(field.name, Number(field.value) + 1)}
                            onDecrement={() =>
                              setFieldValue(
                                field.name,
                                Number(field.value) - ((field.value >= 1 && 1) || 0),
                              )
                            }
                          />
                        </YearsWrapper>
                      </>
                    )}
                  </Field>
                  <Field id="files" name="files">
                    {({ field }) => (
                      <>
                        <FieldHeading>{STRINGS.diplomas_and_credentials}</FieldHeading>
                        <FieldTip>{STRINGS.provide_all_credentials}</FieldTip>
                        <CredentialsUpload onChange={(creds) => setFieldValue(field.name, creds)} />
                      </>
                    )}
                  </Field>
                  <FlexWrapper>
                    <Field id="playlist" name="playlist">
                      {({ field }) => (
                        <FavoritesWrapper>
                          <FieldHeading>{STRINGS.favorite_playlist}</FieldHeading>
                          <CustomInput
                            placeholder={STRINGS.share_playlist}
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched.playlist && errors.playlist}
                            outlined
                            height="48px"
                          />
                        </FavoritesWrapper>
                      )}
                    </Field>
                    <Field id="meal" name="meal">
                      {({ field }) => (
                        <FavoritesWrapper>
                          <FieldHeading>{STRINGS.favorite_meal}</FieldHeading>
                          <CustomInput
                            placeholder={STRINGS.share_meal}
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched.meal && errors.meal}
                            outlined
                            height="48px"
                          />
                        </FavoritesWrapper>
                      )}
                    </Field>
                  </FlexWrapper>
                  <Field id="about" name="about">
                    {({ field }) => (
                      <>
                        <FieldHeading>{STRINGS.tell_about_yourself}</FieldHeading>
                        <FieldTip>{STRINGS.describe_personal_story}</FieldTip>
                        <AboutTextArea
                          placeholder={STRINGS.type_here}
                          value={field.value}
                          onChange={(e) => setFieldValue(field.name, e.target.value)}
                        />
                        {touched.about && errors.about && (
                          <ErrorMessage>
                            <InputErrorIcon />
                            {errors.about}
                          </ErrorMessage>
                        )}
                      </>
                    )}
                  </Field>
                  <AboutButtonsWrapper>
                    <AboutButtonWrapper>
                      <CustomButtom disabled={isSubmitting} onClick={backToPersonalPage}>
                        {STRINGS.back}
                      </CustomButtom>
                    </AboutButtonWrapper>
                    <AboutButtonWrapper>
                      <CustomButtom green={!isSubmitting} type="submit" disabled={isSubmitting}>
                        {STRINGS.submit_form}
                      </CustomButtom>
                    </AboutButtonWrapper>
                  </AboutButtonsWrapper>
                </Form>
              )}
            </Formik>
          )}
        </ProfessionalFormWrapper>
      </FormContainer>
    </SignUpContainer>
  );
};

export default CoachSignUpProfessionalInfo;
