import React from 'react';
import { useTimer } from 'react-timer-hook';

import STRINGS from '../../constants/strings';
import IMAGES from '../../constants/images';
import CustomButton from '../../components/button/CustomButton';
import {
  SignUpContainer,
  LeftPhoto,
  FormContainer,
  ResultSentence,
  WeWillContact,
  ContactUsText,
  ResultName,
  LeftPhotoBackground,
  LeftPhotoBackgroundLine,
  ContactButtonWrapper,
  Timer,
  Logo,
} from './components';
import { useContactAdmin } from '../../hooks';

const CoachSignUpResult = () => {
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: new Date(sessionStorage.getItem('expirationTime')),
    onExpire: () => console.warn('onExpire called'),
  });
  const { redirectToAdmin } = useContactAdmin();
  return (
    <SignUpContainer>
      <LeftPhotoBackground>
        <LeftPhotoBackgroundLine>
          <Logo />
        </LeftPhotoBackgroundLine>
        <LeftPhotoBackgroundLine />
        <LeftPhotoBackgroundLine />
        <LeftPhotoBackgroundLine />
        <LeftPhoto image={IMAGES.FitnessBoySignupImage} />
      </LeftPhotoBackground>
      <FormContainer style={{ padding: '58px 0' }}>
        <ResultName>{sessionStorage.getItem('name')}</ResultName>
        <ResultSentence>{STRINGS.your_request_success}</ResultSentence>
        <Timer>{`${hours}:${minutes}:${seconds}`}</Timer>
        <WeWillContact>{STRINGS.we_will_contact_in_day}</WeWillContact>
        <ContactUsText>{STRINGS.if_question_contact_us}</ContactUsText>
        <ContactButtonWrapper>
          <CustomButton
            backgroundColor="transparent"
            fontSize="1.4rem"
            outlined
            height="40px"
            onClick={() => redirectToAdmin('Sign Up')}
          >
            {STRINGS.contact_us}
          </CustomButton>
        </ContactButtonWrapper>
      </FormContainer>
    </SignUpContainer>
  );
};

export default CoachSignUpResult;
