import 'react-image-crop/dist/ReactCrop.css';
import { Formik, Field, Form } from 'formik';
import React, { useMemo } from 'react';
import Modal from 'react-modal';

import { TemplateSchema } from '../../schemas/template/template';
import { useCoachPersonalInfo, useTemplate } from '../../hooks';
import SelectStatus from '../programs-header/SelectStatus';
import CustomButton from '../button/CustomButton';
import CustomInput from '../input/CustomInput';
import {
  WholeWrapper,
  ModalHeader,
  ModalFooter,
  ButtonWrapper,
  FieldTitle,
  TemplateInputWrapper,
  FlexWrapper,
  ModalContent,
  ProgramLengthContainer,
  TemplateLengthItem,
} from './components';
import ReactSelectWitness from '../react-select-witness/ReactSelectWitness';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '111',
  },
  content: {
    zIndex: '111111111',
    width: '80%',
    maxWidth: '556px',
    minWidth: '320px',
    height: 'fit-content',
    maxHeight: 'Calc(100% - 40px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
    minHeight: 'fit-content',
    overflow: 'visible',
  },
};

const AddTemplateModal = ({ modalOpen, closeModal }) => {
  const { currentCoach } = useCoachPersonalInfo({ setLoading: null, setOpenModal: null });
  const { createTemplate } = useTemplate();
  const specList = useMemo(() => {
    if (currentCoach?.specialities) {
      const withSpecs = currentCoach?.specialities?.reduce((acc, sp) => {
        if (sp?.name === 'Strength' || sp?.name === 'Running') {
          acc.push({ value: sp?.id, label: sp?.name, trainingTypes: sp?.trainingTypes });
        }
        return acc;
      }, []);
      const specials = [].concat(
        ...currentCoach?.specialities?.map(
          (spec) =>
            (spec?.name !== 'Strength' && spec?.name !== 'Running' && spec?.trainingTypes) || [],
        ),
      );
      return [
        ...withSpecs,
        {
          value: 'non-strength-or-running-spec',
          label: 'Special',
          trainingTypes: specials,
        },
      ];
    }

    return [];
  }, [currentCoach.specialities]);

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>ADD NEW TEMPLATE</ModalHeader>
        <Formik
          initialValues={{
            templateName: '',
            templateLength: 60,
            trainingTypes: [],
            speciality: undefined,
          }}
          validationSchema={TemplateSchema}
          onSubmit={createTemplate}
        >
          {({ setFieldValue, errors, touched, isSubmitting, values, validateForm }) => (
            <Form style={{ width: '100%', paddingTop: '0.6rem' }}>
              <ModalContent>
                <TemplateInputWrapper>
                  <FieldTitle>Template Name</FieldTitle>
                  <Field id="templateName" name="templateName">
                    {({ field }) => (
                      <CustomInput
                        errorIcon={false}
                        error={!!touched[field.name] && errors[field.name]}
                        margin="none"
                        outlined
                        borderRadius="8px"
                        height="4.4rem"
                        placeholder="Type Here"
                        onChange={(v) => setFieldValue(field.name, v)}
                        value={field.value}
                      />
                    )}
                  </Field>
                </TemplateInputWrapper>
                <FlexWrapper>
                  <TemplateInputWrapper style={{ width: 'Calc(50% - 0.8rem)' }}>
                    <FieldTitle>Training Type</FieldTitle>
                    <Field id="speciality" name="speciality">
                      {({ field }) => (
                        <ReactSelectWitness
                          error={touched[field.name] && !!errors[field.name]}
                          options={specList}
                          value={field?.value}
                          placeholder="- Choose training type -"
                          onChange={(v) => {
                            if (v?.value !== field?.value?.value) {
                              setFieldValue('trainingTypes', []);
                            }
                            setFieldValue(field?.name, v);
                          }}
                          backspaceRemovesValue={false}
                        />
                      )}
                    </Field>
                  </TemplateInputWrapper>
                  <TemplateInputWrapper style={{ width: 'Calc(50% - 0.8rem)' }}>
                    <FieldTitle>Training Subtype</FieldTitle>
                    <Field id="trainingTypes" name="trainingTypes">
                      {({ field }) => (
                        <ReactSelectWitness
                          error={touched[field.name] && !!errors[field.name]}
                          options={values?.speciality?.trainingTypes?.map((a) => ({
                            value: a?.id,
                            label: a?.name,
                          }))}
                          placeholder="- Choose training types -"
                          isMulti={values?.speciality?.label === 'Strength'}
                          checkBoxes={values?.speciality?.label === 'Strength'}
                          value={field?.value}
                          onChange={(v) => {
                            if (values?.speciality?.label !== 'Strength') {
                              setFieldValue(field?.name, [v]);
                            } else if (!v || v?.length <= 3) {
                              setFieldValue(field?.name, v);
                            }
                          }}
                          onDeleteOne={(v) => {
                            setFieldValue(
                              field.name,
                              field?.value?.filter((x) => x?.value !== v),
                            );
                          }}
                          backspaceRemovesValue={false}
                        />
                      )}
                    </Field>
                  </TemplateInputWrapper>
                </FlexWrapper>
                <TemplateInputWrapper>
                  <FieldTitle>Program Length</FieldTitle>
                  <Field id="templateLength" name="templateLength">
                    {({ field }) => (
                      <ProgramLengthContainer>
                        <TemplateLengthItem
                          checked={field.value === 30}
                          onClick={() => setFieldValue(field.name, 30)}
                        >
                          1 Month
                        </TemplateLengthItem>
                        <TemplateLengthItem
                          checked={field.value === 60}
                          onClick={() => setFieldValue(field.name, 60)}
                        >
                          2 Month
                        </TemplateLengthItem>
                        <TemplateLengthItem
                          checked={field.value === 90}
                          onClick={() => setFieldValue(field.name, 90)}
                        >
                          3 Month
                        </TemplateLengthItem>
                      </ProgramLengthContainer>
                    )}
                  </Field>
                </TemplateInputWrapper>
              </ModalContent>
              <ModalFooter>
                <ButtonWrapper>
                  <CustomButton fontSize="1.4rem" type="button" onClick={closeModal}>
                    Cancel
                  </CustomButton>
                </ButtonWrapper>
                <ButtonWrapper>
                  <CustomButton fontSize="1.4rem" green type="submit">
                    Create
                  </CustomButton>
                </ButtonWrapper>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </WholeWrapper>
    </Modal>
  );
};

export default AddTemplateModal;
