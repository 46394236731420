import React from 'react';

import STRINGS from '../../constants/strings';
import CustomInput from '../input/CustomInput';
import CustomButton from '../button/CustomButton';
import CustomInputFile from '../input/CustomInputFile';
import {
  FlexWrapper,
  ProcessBox,
  ProcessBoxButtonWrapper,
  ProcessBoxInputWrapper,
  SingleFileWrapper,
  SingleFileIcon,
  SingleFileCancelX,
  SaveButtonContainer,
  FileProgressContainer,
  FileProgressBar,
  FileSize,
} from './components';

const ProcessGroup = ({
  currentGroupName,
  setCurrentGroupName,
  groupNameError,
  currentDiplomas,
  addFiles,
  deleteFile,
  saveCredential,
  prettifyFileSize,
}) => {
  return (
    <ProcessBox>
      <FlexWrapper>
        <ProcessBoxInputWrapper>
          <CustomInput
            margin="0"
            height="44px"
            fill="#fafafa"
            value={currentGroupName}
            error={groupNameError}
            placeholder={STRINGS.type_name_of_spec}
            onChange={(a) => setCurrentGroupName(a)}
          />
        </ProcessBoxInputWrapper>
        <ProcessBoxButtonWrapper>
          <CustomInputFile
            noHover
            multiple="multiple"
            accept="image/*, text/*"
            onChange={addFiles}
          />
          <CustomButton
            outlined
            height="44px"
            fontSize="1.4rem"
            backgroundColor="#ffffff"
            type="button"
            onClick={() => {}}
          >
            {STRINGS.attach_files}
          </CustomButton>
        </ProcessBoxButtonWrapper>
      </FlexWrapper>
      {currentDiplomas.map((record, i) => (
        <SingleFileWrapper>
          <FlexWrapper>
            <SingleFileIcon type={record?.diploma?.type?.split('/')[0]} />
            {record?.diploma?.name}
          </FlexWrapper>
          <FlexWrapper>
            <FileProgressContainer uploaded={record?.uid} size={record?.diploma?.size}>
              <FileProgressBar />
            </FileProgressContainer>
            {record?.uid && <FileSize>{prettifyFileSize(record?.diploma?.size)}</FileSize>}
            <SingleFileCancelX onClick={() => deleteFile(i)} />
          </FlexWrapper>
        </SingleFileWrapper>
      ))}
      {!!currentDiplomas?.length && (
        <SaveButtonContainer>
          <ProcessBoxButtonWrapper>
            <CustomButton
              green
              height="44px"
              fontSize="1.4rem"
              type="button"
              onClick={saveCredential}
              disabled={currentDiplomas.find((x) => !x.uid)}
            >
              {STRINGS.save}
            </CustomButton>
          </ProcessBoxButtonWrapper>
        </SaveButtonContainer>
      )}
    </ProcessBox>
  );
};

export default ProcessGroup;
