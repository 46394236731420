import { useState, useEffect } from 'react';

export const lengthToDays = (lengthEnum) => {
  switch (lengthEnum) {
    case 'oneMonth':
      return 30;
    case 'twoMonth':
      return 60;
    default:
      return 90;
  }
};

export const workoutToMin = (lengthEnum) => {
  switch (lengthEnum) {
    case 'halfHour':
      return '30';
    case 'oneHourOrLess':
      return '45-60';
    default:
      return '60-90';
  }
};

export const create_UUID = () => {
  let dt = Date.now();

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
