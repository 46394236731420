import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getIconsQuery } from '@witness/graphql';
import { useQuery } from '@apollo/react-hooks';
import CustomButton from '../button/CustomButton';
import { Header, HeaderText, IconsBody, Footer } from './components';
import { ModalCloseX } from '../request-modal/components';
import MessageIcon from './MessageIcon';

const modalStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 3334,
  },
  content: {
    // height: '515px',
    width: '536px',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 10px 0 rgba(74,74,74,0.12)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    // borderRadius: '8px',
    border: 'none',
    padding: '0',
  },
};

const IconsModal = ({ isOpen, onRequestClose, apply, appliedIcon }) => {
  const { data } = useQuery(getIconsQuery);

  const [selected, setSelected] = useState('');
  const toggle = useCallback(
    (id) => {
      if (selected === id) {
        setSelected('');
      } else {
        setSelected(id);
      }
    },
    [selected],
  );

  const handleChoose = useCallback(() => {
    apply(selected);
    onRequestClose();
  }, [apply, selected, onRequestClose]);

  useEffect(() => {
    setSelected(isOpen ? appliedIcon : '');
  }, [isOpen, appliedIcon]);

  return (
    <Modal style={modalStyles} isOpen={isOpen} onRequestClose={onRequestClose}>
      <Header hideMb>
        <HeaderText>CHOOSE ICON</HeaderText>
        <ModalCloseX onClick={onRequestClose}>X</ModalCloseX>
      </Header>
      <IconsBody>
        {data?.getIcons?.icons?.map((icon) => (
          <MessageIcon
            key={icon.id}
            selected={icon.id === selected.id}
            onClick={() => toggle(icon)}
            src={icon?.file?.url}
            size={56}
            margin="0.8rem"
          />
        ))}
      </IconsBody>
      <Footer>
        <CustomButton
          style={{
            width: '22.4rem',
            height: '4.8rem',
            alignSelf: 'flex-end',
            boxShadow: '0 4px 10px 0 rgba(74,74,74,0.12)',
            marginLeft: 'auto',
          }}
          backgroundColor="#00ff91"
          fontSize="1.4rem"
          onClick={handleChoose}
        >
          Apply
        </CustomButton>
      </Footer>
    </Modal>
  );
};

export default IconsModal;
