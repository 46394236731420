import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  CalendarDayDate,
  CalendarDayNum,
  CalendarDayTitle,
  DayBody,
  DayContainer,
  DayFooter,
  DayFooterText,
  DayHeader,
  DayMessage,
  DayWorkout,
  DropdownContainer,
  MessageContainer,
  MessageIcon,
  DropdownContainerSmall,
  EmptyActions,
  CardSecondaryContent,
  CardSecondaryContentButton,
  CardSecondaryContentButtonIcon,
} from './components';
import DayDropdown from './DayDropdownV2';
import ICONS from '../../constants/icons';
import HoverActions from './HoverActions';
import { WorkoutStatus } from '../../constants/enums';
import CalendarDragContext from '../../services/CalendarDragContext';
import SecondaryContentTooltip from './SecondaryContentTooltip';

const ProgramCalendarDay = ({
  day,
  program,
  firstRow,
  openModal,
  addRestDay,
  openMessage,
  copied,
  handleCopy,
  pasteWorkout,
  setSelectedProgram,
  setSelectedMessage,
  isToday,
  openMeetingModal,
  openOnlineMeetingModal,
}) => {
  const { WORKOUT, MESSAGE, REST } = program || {};

  const copyWorkout = useCallback(() => {
    if (WORKOUT) {
      handleCopy(WORKOUT);
    }
  }, [WORKOUT, handleCopy]);

  const [statusForColor, statusToText] = useMemo(() => {
    const colorStatus = WORKOUT?.workout?.status?.find((x) => x?.type === 'DONE')
      ? 'DONE'
      : WORKOUT?.workout?.status?.[0]?.type;
    if (WORKOUT?.workout?.status?.length > 1) {
      const doneStatuses = WORKOUT?.workout?.status?.filter((x) => x.type === 'DONE');
      return [colorStatus, `${doneStatuses?.length}/${WORKOUT?.workout?.status?.length} completed`];
    }
    switch (colorStatus) {
      case WorkoutStatus.DONE:
        return [colorStatus, 'Completed'];
      case WorkoutStatus.UNDONE:
        return [colorStatus, 'Not completed'];
      case WorkoutStatus.NEW:
        return [colorStatus, 'Planned'];
      default:
        return [colorStatus, ''];
    }
  }, [WORKOUT]);

  const [isDropOpen, setIsDropOpen] = useState(false);
  const openDrop = useCallback((e) => {
    e.stopPropagation();
    setIsDropOpen(true);
  }, []);
  const closeDrop = useCallback(() => setIsDropOpen(false), []);

  useEffect(() => {
    if (isDropOpen) {
      document.addEventListener('click', closeDrop);
    } else {
      document.removeEventListener('click', closeDrop);
    }

    return () => document.removeEventListener('click', closeDrop);
  }, [closeDrop, isDropOpen]);

  const openMessageEdit = useCallback(() => {
    setSelectedMessage(MESSAGE);
    openMessage({ messageDay: day?.index });
  }, [MESSAGE, day, openMessage, setSelectedMessage]);

  const openMessageCreate = useCallback(() => {
    setSelectedMessage(null);
    openMessage({ messageDay: day?.index });
  }, [day, openMessage, setSelectedMessage]);

  const openWorkoutEdit = useCallback(() => {
    openModal({ programDay: day?.index });
  }, [day, openModal]);

  const openWorkoutCreate = useCallback(() => {
    openModal({ programDay: day?.index });
  }, [day, openModal]);

  const dropdownItems = useMemo(() => {
    const hideTop = Boolean(WORKOUT || REST);
    const hideMid = Boolean(MESSAGE);

    return [
      {
        title: 'Workout',
        hidden: hideTop,
        buttonProps: {
          // style: { backgroundColor: '#00ff91', borderColor: '#00ff91' },
          onClick: openWorkoutCreate,
        },
        icon: ICONS.Dumbell,
        hoverIcon: ICONS.DumbellActive,
      },
      {
        title: 'Rest Day',
        hidden: hideTop,
        buttonProps: {
          onClick: () => addRestDay(day?.index, closeDrop),
        },
        icon: ICONS.RestDayCup,
        hoverIcon: ICONS.RestDayCupActive,
      },
      {
        title: 'Message',
        hidden: hideMid,
        buttonProps: {
          onClick: openMessageCreate,
        },
        icon: ICONS.MessageDay,
        hoverIcon: ICONS.MessageDayActive,
      },
      // {
      //   title: 'Video Call',
      //   hidden: false,
      //   buttonProps: {
      //     onClick: openOnlineMeetingModal,
      //   },
      //   icon: ICONS.VideoCamera,
      //   hoverIcon: ICONS.VideoCameraActive,
      // },
      // {
      //   title: 'Meeting',
      //   hidden: false,
      //   buttonProps: {
      //     onClick: openMeetingModal,
      //   },
      //   icon: ICONS.MeetingLocation,
      // },
      {
        title: 'Copy Workout',
        hidden: !WORKOUT,
        buttonProps: {
          onClick: copyWorkout,
          className: 'copy_paste',
        },
        icon: ICONS.CopyDay,
        iconStyles: { backgroundSize: 'auto 80%' },
        styles: { background: '#F1F0F0', borderColor: '#F1F0F0' },
      },
      {
        title: 'Paste Workout',
        hidden: !copied || WORKOUT || REST,
        buttonProps: {
          onClick: () => pasteWorkout(day?.index),
          className: 'copy_paste',
        },
        icon: ICONS.PasteDay,
      },
    ];
  }, [
    day,
    openWorkoutCreate,
    addRestDay,
    closeDrop,
    MESSAGE,
    REST,
    WORKOUT,
    copied,
    openMessageCreate,
    copyWorkout,
    pasteWorkout,
    openMeetingModal,
    openOnlineMeetingModal,
  ]);

  const { isDragging } = useContext(CalendarDragContext);

  const messageIcon = MESSAGE?.message
    ? MESSAGE?.message.icon?.file?.url
    : MESSAGE?.icon?.file?.url;

  const currentCopied = copied?.uid === WORKOUT?.uid;

  return (
    <DayContainer status={statusForColor}>
      <DayHeader className="day-header">
        <CalendarDayNum isToday={isToday}>{`DAY ${day?.index}`}</CalendarDayNum>
        <CalendarDayDate isToday={isToday}>{day?.date}</CalendarDayDate>
      </DayHeader>

      {program ? (
        <>
          {console.log(WORKOUT)}
          <DayBody>
            <DayWorkout>
              {!WORKOUT && !REST && isDragging && (
                <EmptyActions>
                  <HoverActions droppable index={day?.index} />
                </EmptyActions>
              )}

              {!REST && !WORKOUT ? (
                <DropdownContainerSmall onClick={openDrop}>
                  {/* <DayDropdown
                    isOepn={isDropOpen}
                    onClick={() => setIsDropOpen((s) => !s)}
                    items={dropdownItems}
                    isFirstRow={firstRow}
                    buttonSize="3.2rem"
                  /> */}
                </DropdownContainerSmall>
              ) : (
                <HoverActions
                  showEdit={!!WORKOUT}
                  showDelete
                  onDelete={() => setSelectedProgram(WORKOUT ? { WORKOUT } : { REST })}
                  onEdit={WORKOUT ? openWorkoutEdit : undefined}
                  draggable={!!WORKOUT}
                  droppable={!!REST}
                  index={day?.index}
                  workoutId={WORKOUT?.uid}
                >
                  <CalendarDayTitle>
                    {!!REST && 'Rest Day'}
                    {!!WORKOUT && WORKOUT?.workout?.name}
                  </CalendarDayTitle>
                </HoverActions>
              )}
            </DayWorkout>
            <CardSecondaryContent>
              {!!MESSAGE ? (
                <CardSecondaryContentButton>
                  <CardSecondaryContentButtonIcon icon={ICONS.MessageBtn} />
                  <SecondaryContentTooltip
                    onDeleteClick={() => setSelectedProgram({ MESSAGE })}
                    onEditClick={openMessageEdit}
                    secondaryContent={
                      <>
                        {!!messageIcon && <MessageIcon src={messageIcon} alt="" />}
                        {MESSAGE?.message?.text || MESSAGE?.customMessage}
                      </>
                    }
                  />
                </CardSecondaryContentButton>
              ) : (
                <></>
              )}
              {/* {!!MESSAGE ? (
                <CardSecondaryContentButton>
                  <CardSecondaryContentButtonIcon
                    icon={'https://cdn.onlinewebfonts.com/svg/img_63405.png'}
                  />
                  <CustomTooltip
                    style={{
                      width: '18rem',
                      height: 'fit-content',
                      left: '50%',
                      marginLeft: `-9rem`,
                      // marginBottom: `calc(${buttonSize} / 2 + 8px)`,
                      background: '#FAFAFA',
                      display: 'block',
                      boxShadow: '0px 2px 4px #d0d0c8',
                    }}
                  ></CustomTooltip>
                </CardSecondaryContentButton>
              ) : (
                <></>
              )} */}
              <CardSecondaryContentButton plus>
                {/* <CardSecondaryContentButtonIcon icon={ICONS.PlusSimple} plus /> */}
                <DropdownContainerSmall onClick={openDrop}>
                  <DayDropdown
                    isOepn={isDropOpen}
                    onClick={() => setIsDropOpen((s) => !s)}
                    items={dropdownItems}
                    isFirstRow={firstRow}
                    buttonSize="3.2rem"
                  />
                </DropdownContainerSmall>
              </CardSecondaryContentButton>
            </CardSecondaryContent>
            {/* <DayMessage>
              {!!MESSAGE ? (
                <HoverActions
                  showEdit
                  showDelete
                  onDelete={() => setSelectedProgram({ MESSAGE })}
                  onEdit={openMessageEdit}
                >
                  <MessageContainer>
                    {!!messageIcon && <MessageIcon src={messageIcon} alt="" />}
                    {MESSAGE.message ? MESSAGE.message.text : MESSAGE.customMessage}
                  </MessageContainer>
                </HoverActions>
              ) : (
                <DropdownContainerSmall onClick={openDrop}>
                  <DayDropdown
                    isOepn={isDropOpen}
                    onClick={() => setIsDropOpen((s) => !s)}
                    items={dropdownItems}
                    isFirstRow={firstRow}
                    buttonSize="3.2rem"
                  />
                </DropdownContainerSmall>
              )}
            </DayMessage> */}
          </DayBody>

          <DayFooter>
            <DayFooterText>{statusToText}</DayFooterText>

            {/* {!!WORKOUT && (
              <ButtonsWrapper className="hover-show">
                <DayButton disabled={currentCopied} onClick={copyWorkout}>
                  {currentCopied ? 'Copied' : 'Copy'}{' '}
                  {!currentCopied && <ButtonIcon icon={ICONS.Copy} />}
                </DayButton>
                <DayViewEditButton status={statusForColor} onClick={openWorkoutEdit}>
                  View <ButtonIcon icon={ICONS.Copy} />
                </DayViewEditButton>
              </ButtonsWrapper>
            )} */}
          </DayFooter>
        </>
      ) : (
        <DropdownContainer onClick={openDrop}>
          {isDragging && (
            <EmptyActions>
              <HoverActions droppable index={day?.index} />
            </EmptyActions>
          )}

          <DayDropdown
            isOepn={isDropOpen}
            onClick={() => setIsDropOpen((s) => !s)}
            items={dropdownItems}
            isFirstRow={firstRow}
            buttonSize="4.8rem"
          />
        </DropdownContainer>
      )}
    </DayContainer>
  );
};

export default ProgramCalendarDay;
