import React, { useState, useEffect, useRef, useMemo } from 'react';

import {
  InputWrapper,
  StyledSelect,
  SelectContainer,
  SelectArrow,
  StyledOption,
  OptionContainer,
} from './components';

const CustomInputDropdown = ({
  height,
  borderRadius,
  fontSize,
  value,
  onChange,
  color,
  options,
  disabled,
  optionStyles,
  menuStyles,
  valueStyles,
  optionComponent,
  placeholder,
  ...rest
}) => {
  const [isOpen, setisOpen] = useState(false);
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setisOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const Opt = useMemo(() => {
    return (
      optionComponent ||
      (({ opt }) => (
        <StyledOption style={optionStyles} onClick={() => onChange(opt)}>
          {opt.toLowerCase()}
        </StyledOption>
      ))
    );
  }, [optionComponent, onChange, optionStyles]);

  return (
    <InputWrapper
      height={height}
      outlined
      fontSize={fontSize}
      borderRadius={borderRadius}
      {...rest}
    >
      <SelectContainer
        onClick={() => {
          if (!disabled) {
            setisOpen((curr) => !curr);
          }
        }}
        ref={wrapperRef}
        disabled={disabled}
      >
        <StyledSelect disabled={disabled} color={color} style={valueStyles}>
          {value || placeholder}
        </StyledSelect>

        <SelectArrow disabled={disabled} />
        {isOpen && (
          <OptionContainer style={menuStyles}>
            {options?.map((opt, index) =>
              opt === value ? <></> : <Opt key={index} opt={opt} onClick={() => onChange(opt)} />,
            )}
          </OptionContainer>
        )}
      </SelectContainer>
    </InputWrapper>
  );
};

export default CustomInputDropdown;
