import React from 'react';

import { useUploadCredentials } from '../../hooks';
import ProcessGroup from './ProcessGroup';
import ICONS from '../../constants/icons';
import {
  WholeWrapper,
  UploadedCredWrapper,
  FlexWrapper,
  GreyButton,
  EditDeleteButton,
  DiplomasPlusButton,
} from './components';

const CredentialsUpload = ({ onChange }) => {
  const {
    activeIndex,
    setActiveIndex,
    groupNameError,
    currentGroupName,
    setCurrentGroupName,
    currentDiplomas,
    addFiles,
    deleteFile,
    credentials,
    saveCredential,
    deleteCredential,
    chooseCredentialToEdit,
    prettifyFileSize,
  } = useUploadCredentials(onChange);

  return (
    <WholeWrapper>
      {credentials?.map((cred, i) => {
        return (
          <>
            {activeIndex === i ? (
              <ProcessGroup
                currentGroupName={currentGroupName}
                setCurrentGroupName={setCurrentGroupName}
                groupNameError={groupNameError}
                currentDiplomas={currentDiplomas}
                addFiles={addFiles}
                deleteFile={deleteFile}
                saveCredential={saveCredential}
                prettifyFileSize={prettifyFileSize}
              />
            ) : (
              <UploadedCredWrapper>
                {cred.name}
                <FlexWrapper>
                  {cred.diplomas?.map((dip) =>
                    dip.diploma.type?.split('/')[0] === 'image' ? (
                      <GreyButton icon={ICONS.Image} />
                    ) : (
                      <GreyButton />
                    ),
                  )}
                  <EditDeleteButton
                    icon={ICONS.EditStroke}
                    hoverIcon={ICONS.EditFill}
                    onClick={() => chooseCredentialToEdit(i)}
                  />
                  {i !== 0 && (
                    <EditDeleteButton
                      icon={ICONS.DeleteStroke}
                      hoverIcon={ICONS.DeleteFill}
                      onClick={() => deleteCredential(i)}
                    />
                  )}
                </FlexWrapper>
              </UploadedCredWrapper>
            )}
          </>
        );
      })}
      {credentials.length === activeIndex ? (
        <>
          <ProcessGroup
            currentGroupName={currentGroupName}
            setCurrentGroupName={setCurrentGroupName}
            groupNameError={groupNameError}
            currentDiplomas={currentDiplomas}
            addFiles={addFiles}
            deleteFile={deleteFile}
            saveCredential={saveCredential}
            prettifyFileSize={prettifyFileSize}
          />
        </>
      ) : (
        <DiplomasPlusButton
          onClick={() => {
            saveCredential();
            setActiveIndex(credentials.length);
          }}
        />
      )}
    </WholeWrapper>
  );
};

export default CredentialsUpload;
