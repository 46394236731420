import * as Yup from 'yup';

const fullName = Yup.string().trim().required('Required').min(2, 'Too Short');
const idNumber = Yup.string().trim().required('Required');
const phone = Yup.string()
  .trim()
  .required('Required')
  .matches(/\+?\d+/, 'Invalid');
const email = Yup.string().trim().required('Required').email('Invalid');
const password = Yup.string().trim().required('Required');

const address = Yup.string().trim().required('Required');
const preview = Yup.mixed().required('Required');
const agreedTerms = Yup.bool().oneOf([true], 'Field must be checked');
const specialities = Yup.array()
  .of(Yup.string().required())
  .required('Choose at least 1 speciality');
const experience = Yup.number().required('Required');
const playlist = Yup.string().required('Required');
const meal = Yup.string().required('Required');
const about = Yup.string().required('Required');
const files = Yup.array();
const gender = Yup.string().oneOf(['FEMALE', 'MALE']);
const age = Yup.number().required('Required').min(18, 'invalid');
const height = Yup.number().required('Required').positive('Invalid');
const weight = Yup.number().required('Required').positive('Invalid');

export const SignupPersonalSchema = Yup.object().shape({
  fullName,
  idNumber,
  phone,
  email,
  address,
  preview,
  agreedTerms,
});

export const SignupProfessionalSchema = Yup.object().shape({
  specialities,
  experience,
  playlist,
  meal,
  about,
  files,
});

export const EditPersonalInfoSchema = Yup.object().shape({
  fullName,
  address,
  phone,
  idNumber,
  gender,
  age,
  height,
  weight,
  meal,
  playlist,
});

export const EditAboutMeSchema = Yup.object().shape({
  about,
});

export const SetPaswordEmailStepSchema = Yup.object().shape({
  email,
});

export const SetPaswordPasswordStepSchema = Yup.object().shape({
  email,
  password,
  repassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'No match'),
});

export const SignInSchema = Yup.object().shape({
  email,
  password,
});
