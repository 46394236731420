import React, { useEffect, useState } from 'react';

import { CometChat } from '@cometchat-pro/chat';
import { CallContainer, CallContent } from './components';

const VideoCall = () => {
  const [joined, setJoined] = useState(false);

  useEffect(() => {
    if (joined) {
      let sessionID = '8c5d67b6-26ce-4f0f-879b-267cf4fedc5e';
      let audioOnly = false;
      let deafaultLayout = true;

      let callSettings = new CometChat.CallSettingsBuilder()
        .enableDefaultLayout(deafaultLayout)
        .setSessionID(sessionID)
        .setIsAudioOnlyCall(audioOnly)
        .build();

      CometChat.startCall(
        callSettings,
        document.getElementById('callScreen'),
        new CometChat.OngoingCallListener({
          onCallEnded: (call) => {
            setJoined(false);
            console.log('Call ended:', call);
          },
          onError: (error) => {
            setJoined(false);
            console.log('Error :', error);
          },
        }),
      );
    }
  }, [joined]);

  return (
    <CallContainer>
      {!joined && <button onClick={() => setJoined(true)}>Join call</button>}
      <CallContent id="callScreen" />
    </CallContainer>
  );
};

export default VideoCall;
