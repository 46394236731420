import React from 'react';

import { StyledButton } from './components';

const CustomButton = ({
  height,
  backgroundColor,
  borderRadius,
  border,
  onClick,
  green,
  children,
  type,
  disabled,
  bold,
  fontSize,
  outlined,
  outlineColor,
  color,
  hoverBackground,
  hoverOutlineColor,
  fontFamily,
  ...rest
}) => {
  return (
    <StyledButton
      height={height}
      backgroundColor={backgroundColor}
      hoverBackground={hoverBackground}
      hoverOutlineColor={hoverOutlineColor}
      green={green}
      borderRadius={borderRadius}
      border={border}
      onClick={onClick}
      type={type}
      disabled={disabled}
      bold={bold}
      fontSize={fontSize}
      outlined={outlined}
      outlineColor={outlineColor}
      color={color}
      fontFamily={fontFamily}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
