import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';

import ExplainRejectionModal from './ExplainRejectionModal';
import CustomButton from '../button/CustomButton';
import STRINGS from '../../constants/strings';
import ICONS from '../../constants/icons';
import URLS from '../../constants/urls';
import { useGroup } from '../../hooks';
import { workoutToMin } from '../../services/utils';
import useProgramRequest from '../../hooks/programRequest';

import {
  WholeWrapper,
  ModalHeader,
  ModalRow,
  UpperRowCell,
  MiddleRowCell,
  BottomRowCell,
  ModalFooter,
  TotalPriceBold,
  TotalPriceGreen,
  ModalButtonWrapper,
  ModalButtonsRow,
  RowTitle,
  UpperRowCellLine,
  MiddleRowCellIcon,
  MiddleRowCellKey,
  MiddleRowCellValue,
  ProfilePic,
  ProfileName,
  GenderIcon,
  ParameterRow,
  ParameterCell,
  ModalCloseX,
  ModalGroupSide,
  ModalContentMain,
  ModalContent,
  GroupDetailsHeading,
  GroupImageWrapper,
  GroupImage,
  GroupName,
  GroupParticipants,
  GroupAbout,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 11111111,
  },
  content: {
    width: '74%',
    maxWidth: '1320px',
    minWidth: '980px',
    height: 'Calc(82vh + 30px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '8px',
    border: 'none',
    padding: '0 ',
  },
};

const ProgramRequest = ({ content, closeModal }) => {
  const [explanationModalOpen, setExplanationModalOpen] = useState(false);
  const { changeProgramStatus, changeStatusLoading } = useProgramRequest();
  const weekDays = {
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THUR: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
    SUN: 'Sunday',
  };

  const history = useHistory();
  const handleChat = useCallback(() => {
    history.push(URLS.messages, {
      trainee: content?.trainee,
    });
  }, [history, content?.trainee]);

  const isGroup = useMemo(() => {
    if (content?.type === 'GROUP') return true;
    return false;
  }, [content]);
  const { groupByDigits } = useGroup({ groupDigits: content?.code?.digits });
  console.log(content);
  const [totalPrice, monthlyPrice] = useMemo(() => {
    if (!isGroup) {
      return [
        Math.round((content?.monthlySum * content?.programLength) / 30),
        content?.monthlySum?.toFixed(2),
      ];
    }

    const programStartDate = groupByDigits?.checkProgramCode?.group?.coachProgram?.beginningDate;

    const programGeneralLength = groupByDigits?.checkProgramCode?.group?.programLength;
    const groupMonthlyPrice = groupByDigits?.checkProgramCode?.group?.price;
    const beginningDate = moment(programStartDate);
    const totalMonths = programGeneralLength / 30;
    if (programStartDate && beginningDate.isBefore()) {
      const requestSentDate = moment(content?.approvedDate);
      const endDate = beginningDate.add(programGeneralLength, 'days');
      const daysLeft = endDate.diff(requestSentDate, 'days');
      const ratio = daysLeft / programGeneralLength;
      const total = Math.ceil(groupMonthlyPrice * ratio * totalMonths);
      return [total, (total / Math.ceil(totalMonths)).toFixed(2)];
    } else {
      const total = Math.round(groupMonthlyPrice * totalMonths);
      return [total, (total / Math.ceil(totalMonths)).toFixed(2)];
    }
  }, [groupByDigits, content, isGroup]);

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!content}
      style={customStyles}
      contentLabel="Program Request"
      ariaHideApp={false}
    >
      {console.log(groupByDigits)}
      <WholeWrapper>
        <ExplainRejectionModal
          modalOpen={explanationModalOpen}
          closeModal={() => {
            setExplanationModalOpen(false);
          }}
          submitModal={({ message }) => {
            changeProgramStatus({ uid: content?.uid, status: 'DECLINED', message }, isGroup);

            closeModal();
          }}
        />
        <ModalHeader>
          {content?.status === 'DECLINED'
            ? 'DECLINED PROGRAM REQUEST'
            : content?.status === 'CANCELLED'
            ? 'CANCELLED PROGRAM REQUEST'
            : STRINGS.new_program_request}
          <ModalCloseX onClick={closeModal}>X</ModalCloseX>
        </ModalHeader>
        <ModalContent>
          {isGroup && (
            <ModalGroupSide>
              <GroupDetailsHeading>Group Details</GroupDetailsHeading>
              <GroupImageWrapper>
                <GroupImage groupImage={groupByDigits?.checkProgramCode?.group?.groupImage?.url} />
              </GroupImageWrapper>
              <GroupName>{groupByDigits?.checkProgramCode?.group?.name}</GroupName>
              <GroupParticipants>
                Number of participants:
                <span>{`  ${
                  groupByDigits?.checkProgramCode?.group?.coachProgram?.participants?.length || '0'
                } / ${groupByDigits?.checkProgramCode?.group?.participants || '0'}`}</span>
              </GroupParticipants>
              <GroupAbout>
                {groupByDigits?.checkProgramCode?.group?.description || 'Loading...'}
              </GroupAbout>
            </ModalGroupSide>
          )}
          <ModalContentMain>
            <ModalRow upper grow={isGroup}>
              <UpperRowCell background={ICONS.LeftCellBackground}>
                {!isGroup && <UpperRowCellLine />}
              </UpperRowCell>
              <UpperRowCell mid>
                <ProfilePic image={content?.trainee?.avatar?.url}>
                  {content?.trainee?.fullName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')
                    .substring(0, 3)}
                </ProfilePic>
                <ProfileName>
                  {content?.trainee?.fullName}
                  <GenderIcon gender={content?.trainee?.gender} />
                </ProfileName>
                <ParameterRow>
                  <ParameterCell>
                    {STRINGS.weight}
                    <ParameterCell val>{`${content?.trainee?.weight}`}</ParameterCell>
                  </ParameterCell>
                  <ParameterCell>
                    {STRINGS.height}
                    <ParameterCell val>{content?.trainee?.height}</ParameterCell>
                  </ParameterCell>
                  <ParameterCell>
                    {STRINGS.age}
                    <ParameterCell val>{content?.trainee?.age}</ParameterCell>
                  </ParameterCell>
                </ParameterRow>
              </UpperRowCell>
              <UpperRowCell background={ICONS.RightCellBackground}>
                {!isGroup && <UpperRowCellLine />}
              </UpperRowCell>
            </ModalRow>
            <ModalRow grow>
              <RowTitle>{STRINGS.health_and_fitness}</RowTitle>
              <MiddleRowCell grow={isGroup ? '2' : '1'}>
                <MiddleRowCellIcon icon={ICONS.FitnessLevel} />
                <MiddleRowCellKey>{STRINGS.fitness_level}</MiddleRowCellKey>
                <MiddleRowCellValue>{content?.fitnessLevel}</MiddleRowCellValue>
              </MiddleRowCell>
              {!isGroup && (
                <MiddleRowCell>
                  <MiddleRowCellIcon icon={ICONS.Environment} />
                  <MiddleRowCellKey>{STRINGS.training_environment}</MiddleRowCellKey>
                  <MiddleRowCellValue>{content?.place?.join(', ')}</MiddleRowCellValue>
                </MiddleRowCell>
              )}
              <MiddleRowCell grow="2">
                <MiddleRowCellIcon icon={ICONS.Injures} />
                <MiddleRowCellKey>{STRINGS.injures}</MiddleRowCellKey>
                <MiddleRowCellValue>{`${content?.injury || ''}${
                  (content?.injuryDetails && '. ' + content?.injuryDetails) || ''
                }`}</MiddleRowCellValue>
              </MiddleRowCell>
              {!isGroup && (
                <MiddleRowCell>
                  <MiddleRowCellIcon icon={ICONS.Equipment} />
                  <MiddleRowCellKey>{STRINGS.available_equipment}</MiddleRowCellKey>
                  <MiddleRowCellValue>
                    {content?.equipments?.map((eq) => eq.name).join(', ')}
                    {!!content?.equipments?.length && content?.otherEquipment && ', '}
                    {content?.otherEquipment}
                    {!(content?.equipments?.length || content?.otherEquipment) &&
                      "Trainee didn't mention any available equipment"}
                  </MiddleRowCellValue>
                </MiddleRowCell>
              )}
              <MiddleRowCell grow={isGroup ? '2' : '1'} last>
                <MiddleRowCellIcon icon={ICONS.Health} />
                <MiddleRowCellKey>{STRINGS.health_status}</MiddleRowCellKey>
                <MiddleRowCellValue>
                  {(content?.healthCondition && content?.healthConditionDetails) || 'Healthy'}
                </MiddleRowCellValue>
              </MiddleRowCell>
            </ModalRow>
            {!isGroup && (
              <ModalRow grow>
                <RowTitle>{STRINGS.program_details}</RowTitle>
                <MiddleRowCell>
                  <MiddleRowCellIcon icon={ICONS.ProgramLength} />
                  <MiddleRowCellKey>{STRINGS.program_length}</MiddleRowCellKey>
                  <MiddleRowCellValue>{content?.programLength} days</MiddleRowCellValue>
                </MiddleRowCell>
                <MiddleRowCell>
                  <MiddleRowCellIcon icon={ICONS.Time} />
                  <MiddleRowCellKey>{STRINGS.workout_time} </MiddleRowCellKey>
                  <MiddleRowCellValue>
                    {workoutToMin(content?.workoutLength)} min
                  </MiddleRowCellValue>
                </MiddleRowCell>
                <MiddleRowCell grow="2">
                  <MiddleRowCellIcon icon={ICONS.Type} />
                  <MiddleRowCellKey>{STRINGS.desired_agenda}</MiddleRowCellKey>
                  <MiddleRowCellValue>
                    {content?.daysOfTraining?.map((d) => weekDays[d]).join(', ')}
                  </MiddleRowCellValue>
                </MiddleRowCell>
                <MiddleRowCell>
                  <MiddleRowCellIcon icon={ICONS.Goal} />
                  <MiddleRowCellKey>{STRINGS.training_type}</MiddleRowCellKey>
                  <MiddleRowCellValue>
                    {content?.trainingTypes?.map((ttype) => ttype?.name)?.join(', ') ||
                      content?.trainingType?.name}
                  </MiddleRowCellValue>
                </MiddleRowCell>
                <MiddleRowCell last>
                  <MiddleRowCellIcon icon={ICONS.Focus} />
                  <MiddleRowCellKey>{STRINGS.training_goals}</MiddleRowCellKey>
                  <MiddleRowCellValue>
                    {content?.goals?.map((eq) => eq.name).join(', ')}
                  </MiddleRowCellValue>
                </MiddleRowCell>
              </ModalRow>
            )}
            <ModalRow bottom>
              <RowTitle>{STRINGS.notes}</RowTitle>
              {!isGroup && <BottomRowCell />}
              <BottomRowCell grow="4">
                <div>{content?.note || 'No Additional Notes'}</div>
              </BottomRowCell>
              {!isGroup && <BottomRowCell last />}
            </ModalRow>
          </ModalContentMain>
        </ModalContent>
        {content?.status === 'DECLINED' || content?.status === 'CANCELLED' ? (
          <ModalFooter>
            <TotalPriceBold>
              REQUEST WAS <span className="declined">{content?.status}</span>
            </TotalPriceBold>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <TotalPriceBold>{STRINGS.total_price}</TotalPriceBold>
            <TotalPriceGreen>{`₪${totalPrice}`}</TotalPriceGreen>
            {`(₪ ${monthlyPrice} p/month)`}
            {content?.status === 'PENDING' && (
              <ModalButtonsRow>
                <ModalButtonWrapper>
                  <CustomButton
                    color="#F50A4F"
                    outlined
                    borderRadius="8px"
                    outlineColor="#DEDEDE"
                    backgroundColor="transparent"
                    onClick={() => {
                      setExplanationModalOpen(true);
                    }}
                  >
                    {STRINGS.decline_request}
                  </CustomButton>
                </ModalButtonWrapper>
                <CustomButton
                  onClick={handleChat}
                  borderRadius="8px"
                  style={{ width: 'fit-content', padding: '0 2rem' }}
                >
                  {STRINGS.chat_with + (content?.trainee?.fullName || 'Trainee')}
                </CustomButton>
                <ModalButtonWrapper>
                  <CustomButton
                    green
                    borderRadius="8px"
                    disabled={changeStatusLoading}
                    onClick={async () => {
                      await changeProgramStatus(
                        {
                          uid: content?.uid,
                          status: 'APPROVED',
                        },
                        isGroup && groupByDigits?.checkProgramCode?.group?.uid,
                      );
                      closeModal();
                    }}
                  >
                    {STRINGS.approve_request}
                  </CustomButton>
                </ModalButtonWrapper>
              </ModalButtonsRow>
            )}
          </ModalFooter>
        )}
      </WholeWrapper>
    </Modal>
  );
};

export default ProgramRequest;
