import React from 'react';

import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

import { LayoutContainer, MainArea } from './components';

const Layout = ({ innerComponent }) => {
  return (
    <>
      <Header />
      <LayoutContainer>
        <Sidebar />
        <MainArea>{innerComponent()}</MainArea>
      </LayoutContainer>
    </>
  );
};

export default Layout;
