import { useMemo, useState } from 'react';
import { getProgramsQuery, changeProgramStatusMutation } from '@witness/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { CoachProgramStatus } from '../constants/enums';
import { useDebounce } from '../services/utils';
import { useHistory, Redirect } from 'react-router-dom';
import { useAlert } from 'react-alert';

const useProgramRequest = (props) => {
  const alert = useAlert();
  const history = useHistory();
  const statusOpts = useMemo(
    () => [
      { label: '- Choose -', value: '' },
      { label: 'In progress', value: CoachProgramStatus.ONGOING },
      { label: 'New', value: CoachProgramStatus.APPROVED },
      { label: 'Canceled', value: CoachProgramStatus.DECLINED },
      { label: 'Finished', value: CoachProgramStatus.ENDED },
    ],
    [],
  );

  const [traineeName, setTraineeName] = useState('');
  const delayedName = useDebounce(traineeName, 500);

  const [status, setStatus] = useState(statusOpts[0]);

  const [changeStatus, { loading: changeStatusLoading }] = useMutation(changeProgramStatusMutation);
  const changeProgramStatus = async (values, groupUid) => {
    try {
      const acceptedProgram = await changeStatus({
        variables: {
          record: {
            ...values,
          },
        },
      });
      if (acceptedProgram?.data?.changeProgramStatus?.status === 'APPROVED') {
        const red_url = groupUid
          ? `/program/${groupUid}?group=true`
          : `/program/${acceptedProgram?.data?.changeProgramStatus?.uid}`;
        history.push({
          pathname: red_url,
        });
      }
    } catch (err) {
      if (err.graphQLErrors.find((x) => x.message === 'program_not_relative')) {
        alert.error('Request has already been cancelled');
      } else {
        alert.error('Something Went Wrong');
      }
    }
  };

  const { data: programRequests, loading: programRequestsLoading } = useQuery(getProgramsQuery, {
    variables: {
      record: {
        coachProgramStatus: status.value || undefined,
        status: props?.requestStatus,
        type: props?.programType,
        searchName: delayedName,
      },
    },
  });

  return {
    programRequests,
    changeProgramStatus,
    programRequestsLoading,
    status,
    setStatus,
    statusOpts,
    traineeName,
    setTraineeName,
    changeStatusLoading,
  };
};

export default useProgramRequest;
