import React, { createContext, useMemo, useState } from 'react';

const CalendarDragContext = createContext(null);

export default CalendarDragContext;

export const CalendarDragProvider = ({ children, refetchCalendar }) => {
  const [isDragging, setIsDragging] = useState('');

  const contextValue = useMemo(() => ({ isDragging, setIsDragging, refetchCalendar }), [
    isDragging,
    refetchCalendar,
  ]);

  return (
    <CalendarDragContext.Provider value={contextValue}>{children}</CalendarDragContext.Provider>
  );
};
