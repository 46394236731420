const useContactAdmin = () => {
  const redirectToAdmin = (subject = 'witness', callback = () => {}) => {
    window.open(
      `mailto:developer@witnesstofitness.com?cc=tamar.m@webiz.ge&subject=${subject}`,
      '_blank',
    );
    callback();
  };

  return { redirectToAdmin };
};

export default useContactAdmin;
