import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 7.5%;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Space-r')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  font-size: 1.8rem;
  padding: 4px 28px;
  min-height: 28px;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-grow: 1;
`;

export const ModalContentMain = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalSubHeading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-family: Roboto-r;
  font-size: 1.6rem;
  line-height: 2.4rem;
  justify-content: center;
  color: #848181;
`;

export const ArrowIcon = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #d2d1d1;
  background-image: url(${ICONS.ArrowDownWhite});
  background-position: center;
  background-size: 60%;
  transform: rotate(90deg);
  background-repeat: no-repeat;
  margin: 3.5rem auto 2rem;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const ModalRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #05000033;
  flex-grow: ${({ grow }) => (grow ? '1' : 'unset')};
  height: ${({ upper }) => (upper ? '18.7%' : 'auto')};
  min-height: ${({ bottom }) => (!bottom ? '144px' : '116px')};
  max-height: 33%;
  position: relative;
  float: left;
  &:last-child {
    border-bottom: none;
  }
`;

export const RowTitle = styled.div`
  position: absolute;
  height: 3.2rem;
  z-index: 1111;
  top: -16px;
  left: Calc(50% - 88px);
  background: #ffffff;
  width: 176px;
  border: 0.5px solid #05000033;
  border-radius: 8px;
  font-family: Space-r;
  font-size: 1.4rem;
  line-height: 3.2rem;
  text-align: center;
  background: #fafafa;
`;

export const UpperRowCell = styled.div`
  flex-grow: 1;
  min-width: Calc(100% / 3 - 0.5px);
  border-right: ${({ mid }) => (mid ? '1px solid #05000033' : 'none')};
  border-left: ${({ mid }) => (mid ? '1px solid #05000033' : 'none')};
  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: ${({ mid }) => (mid ? '1.6rem' : '0')};
`;

export const UpperRowCellLine = styled.div`
  width: 1px;
  height: 100%;
  background: #05000033;
  margin: auto;
`;

export const ProfilePic = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  border: 4px solid #f1f0f0;
  background: #d8d8d8;
  border-radius: 50%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  margin: Calc(1.5vh - 4.4px) auto Calc(1.5vh - 6.4px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  letter-spacing: 0.8px;
  color: ${({ image }) => (image ? 'transparent' : '#ffffff')};
  text-transform: uppercase;
  font-size: 1.4rem;
`;

export const ProfileName = styled.div`
  width: 100%;
  font-family: Gotham-r;
  line-height: max(20px, Calc(2vh - 5.8px));
  color: #4a4a4a;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: Calc(1.5vh - 6.4px);
`;

export const GenderIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-image: ${({ gender }) =>
    gender === 'MALE' ? `url(${ICONS.Male})` : `url(${ICONS.Female})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 6px;
`;

export const ParameterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ParameterCell = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: ${({ val }) => (val ? 'Space-b' : 'Roboto-l')};
  color: ${({ val }) => (val ? '#323232' : '#464646')};
  margin: ${({ val }) => (val ? '0 8px' : '0 1.6')};
`;

export const MiddleRowCell = styled.div`
  flex: 1;
  min-width: Calc(100% / 6);
  flex-grow: ${({ grow }) => (grow ? grow : 1)};
  border-right: ${({ last }) => (last ? 'none' : '1px solid #05000033')};
  height: 100%;
  padding: Calc(5vh - 8px) 0 Calc(2vh - 2.2px);
`;

export const BottomRowCell = styled.div`
  flex: 1;
  border-right: ${({ last }) => (last ? 'none' : '1px solid #05000033')};
  flex-grow: ${({ grow }) => (grow ? grow : 1)};
  height: 100%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Roboto-l;
  min-width: Calc(100% / 6);
  padding: ${({ grow }) => (grow === '4' ? '32px 44px 12px' : 0)};
  background: ${({ grow }) => (!grow ? '#fafafa' : 'transparent')};
`;

export const ModalFooter = styled.div`
  text-align: center;
  padding: 1.2% 0 1.5%;
  border-top: 1px solid #05000033;
`;

export const ModalButtonsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: max(40px, 5.12vh);
  margin: 1.1% 0;
`;
export const ModalButtonWrapper = styled.div`
  width: 195px;
  height: 100%;
  margin: 0 24px;
`;

export const TotalPriceGreen = styled.span`
  font-family: Space-b;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #00b972;
  padding: 2px 12px;
`;

export const TotalPriceBold = styled.span`
  font-family: Gotham-r;
  font-size: 1.7rem;
  line-height: 2.4rem;
  letter-spacing: 0.6px;
  color: #373333;
  & > span.declined {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: ${({ theme }) => theme.color.error};
  }
`;

export const MiddleRowCellIcon = styled.div`
  width: max(28px, Calc(4.3vh - 10px));
  height: max(28px, Calc(4.3vh - 10px));
  background-image: ${({ icon }) => `url(${icon})`};
  border: 0.5px solid rgba(0, 185, 114, 0.6);
  border-radius: 6px;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
`;

export const MiddleRowCellKey = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  padding: Calc(1.5vh - 4.4px) min(26px, Calc(4.5vh - 18px)) Calc(0.75vh - 2.2px);
`;

export const MiddleRowCellValue = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  font-family: Roboto-l;
  padding: 0 min(26px, Calc(4.5vh - 18px));
  line-height: max(18px, Calc(2vh - 5.8px));
`;

export const ModalMain = styled.div`
  padding: ${({ padding }) => (padding ? padding : '3.6rem')};
`;
export const ModalTextArea = styled.div`
  max-height: 100%;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  width: Calc(50% - 12px);
  height: 4.8rem;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '1.6rem')};
`;

// group parts

export const ModalGroupSide = styled.div`
  width: 32%;
  background: #f1f0f0;
  height: 100%;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #05000033;
`;

export const GroupDetailsHeading = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  font-family: Space-r;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.1rem;
  padding: 1.6rem;
  text-align: center;
  border-radius: 8px;
`;

export const GroupImageWrapper = styled.div`
  width: 11.2rem;
  height: 11.2rem;
  border-radius: 50%;
  background: #ffffff;
  padding: 0.4rem;
  border: 4px solid ${({ theme }) => theme.color.primary};
  margin: 2.4rem 2.4rem 2rem;
`;

export const GroupImage = styled.div`
  width: 100%;
  height: 100%;
  background: #f1f0f0;
  background-image: url(${({ groupImage }) => groupImage});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
`;

export const GroupName = styled.div`
  font-family: Gotham-r;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #4a4a4a;
  margin-bottom: 2rem;
  letter-spacing: 0.1rem;
  min-height: 2.4rem;
`;

export const GroupParticipants = styled.div`
  font-family: Roboto-r;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #323232;
  margin-bottom: 2.8rem;
  & > span {
    line-height: 2.4rem;
    color: #464646;
    font-family: Space-b;
    font-size: 1.6rem;
    padding: 0 1.6rem;
  }
`;

export const GroupAbout = styled.div`
  font-family: Roboto-l;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #050000;
  padding: 0 2.2rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: center;
`;
