import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';

import AdvancedPriceModal from '../../components/advanced-price-modal/AdvancedPriceModal';
import ProgramCodeModal from '../../components/program-code-modal/ProgramCodeModal';
import CouponTable from '../../components/coupon-table/CouponTable';
import CustomTooltip from '../../components/tooltip/CustomTooltip';
import CustomInput from '../../components/input/CustomInput';
import IMAGES from '../../constants/images';
import { useCoachPersonalInfo, usePricing } from '../../hooks';
import {
  PricingWrapper,
  PricingHeader,
  PricingHeaderItem,
  ProfileCode,
  ProfileCodeValue,
  ProfileIdInfo,
  FlexWrapperSpaceBet,
  AddCouponButton,
  AddCouponButtonPlus,
  PricingListHeading,
  PricingListWrapper,
  ChangesSaved,
  ChangesSavedIcon,
  ProgramsWrapper,
  SingleProgramWrapper,
  ProgramImage,
  ProgramName,
  PriceHeading,
  PriceInputWrapper,
  AdvencedOptions,
  ProgramImageLines,
  ProgramImageFade,
  PseudoSingleProgram,
  LoadingDot,
} from './components';

const CoachProfilePricing = ({ activeTab, setActiveTab }) => {
  const [activeCode, setActiveCode] = useState(null);
  const [activeProgram, setActiveProgram] = useState(null);
  const [loading, setLoading] = useState(false);
  const { programPrices, addProgramPricing, defaultPrices, deleteCoupon } = usePricing({
    setLoading,
  });
  const { currentCoach } = useCoachPersonalInfo({});

  return (
    <PricingWrapper>
      <ProgramCodeModal content={activeCode} closeModal={() => setActiveCode(null)} />
      <PricingHeader>
        <PricingHeaderItem active={!activeTab || activeTab === '0'} onClick={() => setActiveTab(0)}>
          Pricing List
        </PricingHeaderItem>
        <PricingHeaderItem active={activeTab === '1'} onClick={() => setActiveTab(1)}>
          Program Codes
        </PricingHeaderItem>
      </PricingHeader>
      {activeTab === '1' ? (
        <>
          <FlexWrapperSpaceBet>
            <ProfileCode>
              My Profile Code:
              <ProfileCodeValue>
                #
                {(currentCoach?.code?.digits &&
                  `${('000' + Math.floor(currentCoach?.code?.digits / 1000)).slice(-3)}-${(
                    '000' +
                    (currentCoach?.code?.digits % 1000)
                  ).slice(-3)}`) ||
                  '--- ---'}
              </ProfileCodeValue>
              <ProfileIdInfo marginBottom="1.3rem">
                i
                <CustomTooltip below style={{ width: '26rem' }}>
                  This is Your Profile Code. You can send it to Your potential Trainers to help them
                  to find you in the system
                </CustomTooltip>
              </ProfileIdInfo>
            </ProfileCode>
            <AddCouponButton onClick={() => setActiveCode({})}>
              <AddCouponButtonPlus /> Add Coupon
            </AddCouponButton>
          </FlexWrapperSpaceBet>
          <CouponTable
            setEdditingCode={setActiveCode}
            coupons={currentCoach?.coupons}
            deleteCoupon={deleteCoupon}
          />
        </>
      ) : (
        <>
          <PricingListWrapper>
            <AdvancedPriceModal
              content={activeProgram}
              closeModal={() => setActiveProgram(null)}
              submitModal={addProgramPricing}
            />
            <FlexWrapperSpaceBet>
              <PricingListHeading>
                Here you can assign Prices of all your Programs
              </PricingListHeading>
              <ChangesSaved autoSize>
                <ChangesSavedIcon />
                {loading ? (
                  <>
                    <LoadingDot i={2} />
                    <LoadingDot i={1} />
                    <LoadingDot i={0} />
                  </>
                ) : (
                  'Data Saved'
                )}
              </ChangesSaved>
            </FlexWrapperSpaceBet>
          </PricingListWrapper>
          <ProgramsWrapper>
            {programPrices?.getPrices?.map((program) => {
              return (
                <SingleProgramWrapper>
                  <ProgramImage image={IMAGES.TempProgram1}>
                    <ProgramImageFade>
                      <ProgramImageLines />
                    </ProgramImageFade>
                    <ProgramName>{program?.speciality?.name}</ProgramName>
                  </ProgramImage>

                  <PriceHeading>Program Price Per Month</PriceHeading>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      defaultPrice: defaultPrices[program.uid],
                      speciality: program?.speciality?.id,
                    }}
                    // validationSchema={EditAboutMeSchema}
                    onSubmit={addProgramPricing}
                  >
                    {({ setFieldValue, errors, touched, isSubmitting, values, submitForm }) => (
                      <Form style={{ width: '100%' }}>
                        <Field id="defaultPrice" name="defaultPrice">
                          {({ field }) => (
                            <PriceInputWrapper>
                              <CustomInput
                                outlined
                                disabled={defaultPrices[program.uid] === 'Custom'}
                                prefix={defaultPrices[program.uid] === 'Custom' ? '' : '₪ '}
                                height="100%"
                                margin="0"
                                placeholder={
                                  defaultPrices[program.uid] === 'Custom' ? 'Custom' : ' Type Price'
                                }
                                placeholderFontSize="2.4rem"
                                backgroundColor="#ffffff"
                                focusBorderColor="#15DF68"
                                style={{
                                  fontFamily: 'Space-b',
                                  textAlign: 'center',
                                  fontSize: '3.2rem',
                                  padding: '1.6rem',
                                }}
                                onChange={(v) => setFieldValue(field.name, v ? Number(v) : null)}
                                value={field.value}
                                onBlur={submitForm}
                              />
                            </PriceInputWrapper>
                          )}
                        </Field>
                      </Form>
                    )}
                  </Formik>
                  <AdvencedOptions onClick={() => setActiveProgram(program)}>
                    Advanced Options
                  </AdvencedOptions>
                </SingleProgramWrapper>
              );
            })}
            <PseudoSingleProgram />
            <PseudoSingleProgram />
          </ProgramsWrapper>
        </>
      )}
    </PricingWrapper>
  );
};

export default CoachProfilePricing;
