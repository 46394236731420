import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #05000033;
  justify-content: center;
  position: relative;
  margin-bottom: 34px;
  &::after {
    content: '';
    width: 5.8rem;
    height: 5.8rem;
    background: ${({ theme }) => theme.color.primary};
    border-radius: 50%;
    background-image: url(${ICONS.ArrowMainRight});
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -3.9rem;
    border: 1rem solid #ffffff;
    transform: rotate(90deg);
  }
`;

export const ModalHeaderLines = styled.div`
  width: 50%;
  height: 100%;
  border-left: 1px solid #05000033;
  border-right: 1px solid #05000033;
`;

export const ModalMain = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 36px 16px;
`;

export const ModalPrimaryText = styled.div`
  font-family: Gotham-r;
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #4a4a4a;
  text-align: center;
  padding: 1.4rem 0 2.6rem;
`;

export const ModalSecondaryText = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  width: 100%;
  padding: 0 6.2rem 4rem;
  color: #848181;
  & > span {
    font-family: Roboto-m;
    color: #4a4a4a;
  }
`;

export const ModalSecondaryQuestion = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: #050000;
  font-family: Roboto-m;
`;

export const ButtonsFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2.4rem 0 1.6rem;
`;

export const ButtonWrapper = styled.div`
  width: 14rem;
  height: 4rem;
  margin: 0 0.8rem;
`;
