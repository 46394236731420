import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ModalFooter } from '../add-calendar-modal/components';
import CustomButton from '../button/CustomButton';

const modalStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 3335,
  },
  content: {
    // height: '515px',
    width: '536px',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 10px 0 rgba(74,74,74,0.12)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    // borderRadius: '8px',
    border: 'none',
    padding: '0',
  },
};

const MessageText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Space-r;
  font-size: 1.8rem;
  line-height: 2.4rem;
  padding: 1.8rem;
`;

const ConfirmModal = ({
  isOpen,
  closeModal,
  message,
  handleSubmit,
  cancelText = 'Cancel',
  okText = 'Ok',
}) => {
  return (
    <Modal style={modalStyles} onRequestClose={closeModal} isOpen={isOpen}>
      <MessageText>{message}</MessageText>
      <ModalFooter style={{ padding: '1.2rem 0' }}>
        <CustomButton
          style={{ margin: '0 1.2rem', width: '22.4rem', height: '4.8rem' }}
          outlined
          backgroundColor="white"
          fontSize="1.4rem"
          type="submit"
          onClick={closeModal}
        >
          {cancelText}
        </CustomButton>
        <CustomButton
          style={{ margin: '0 1.2rem', width: '22.4rem', height: '4.8rem' }}
          green
          fontSize="1.4rem"
          type="submit"
          onClick={handleSubmit}
        >
          {okText}
        </CustomButton>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
