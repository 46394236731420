import styled from 'styled-components';
import ICONS from '../../constants/icons';
import IMAGES from '../../constants/images';

export const SignUpContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const LeftPhotoBackground = styled.div`
  flex-grow: 1;
  background: #fafafa;
  position: relative;
  display: flex;
  justify-content: space-around;
`;

export const Logo = styled.div`
  flex-grow: 1;
  background-image: url(${ICONS.Logo});
  background-position: top;
  background-size: 64px 122px;
  background-repeat: no-repeat;
`;

export const LeftPhotoBackgroundLine = styled.div`
  flex-grow: 1;
  border-left: 1px solid #05000033;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  &:first-child {
    border: none;
  }
`;

export const LeftPhoto = styled.div`
  position: absolute;
  top: ${({ top }) => (top ? top : '32px')};
  width: 100%;
  height: ${({ height }) => (height ? height : 'Calc(100% - 32px)')};
  background: transparent;
  background-image: ${({ image }) =>
    image ? `url(${image})` : `url(${IMAGES.FitnessGirlSignupImageFirst})`};
  background-size: ${({ size }) => (size ? size : '90% auto')};
  background-position: ${({ position }) => (position ? position : 'center 10px')};
  background-repeat: no-repeat;
`;

export const FormContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 3%;
  padding-top: Calc(7.1vh - 11.6px);
`;

export const FormHeading = styled.div`
  font-size: 3rem;
  opacity: 80%;
  text-align: center;
  letter-spacing: 1.4px;
  font-family: Gotham-r;
`;

export const FormSubHeading = styled.div`
  font-family: Roboto-m;
  width: 80%;
  text-align: center;
  font-size: 1.8rem;
  min-height: 18px;
  line-height: Calc(10.3vh - 16.89px);
  letter-spacing: 0;
  opacity: 70%;
`;

export const ProfileimageLine = styled.div`
  width: 128px;
  height: 128px;
  margin: Calc(3.6vh - 6.07px);
  padding: 8px;
  border-radius: 50%;
  border: ${({ theme, error }) =>
    error ? `1px solid ${theme.color.error}` : `2px solid ${theme.color.primary}`};
  cursor: pointer;
`;

export const Profileimage = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: ${(props) =>
    props.src
      ? `url(${props.src})`
      : `url("https://icon-library.com/images/no-profile-picture-icon-female/no-profile-picture-icon-female-3.jpg")`};
  background-size: cover;
  background-position: center;
`;

export const PersonalFormWrapper = styled.div`
  min-width: 300px;
  max-width: 500px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4;
`;

export const TermsAndConditionsBold = styled.a`
  font-size: 1.4rem;
  min-height: 14px;
  line-height: Calc(1.9vh - 3.11px);
  font-family: Roboto-m;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
export const TermsAndConditions = styled.div`
  font-size: 1.4rem;
  line-height: 16px;
  font-family: Roboto-l;
  color: #373333;
`;

export const ProfessionalFormWrapper = styled.div`
  min-width: 300px;
  max-width: 700px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  margin: Calc(3.9vh - 6.4px) 0;
  display: flex;
  justify-content: space-between;
`;

export const FieldHeading = styled.div`
  font-size: 1.6rem;
  line-height: 44px;
  letter-spacing: 1.25px;
  width: 100%;
  position: relative;
  font-family: Roboto-m;
  &::after {
    font-weight: 800;
    position: absolute;
    left: -12px;
    top: 2px;
    color: ${({ theme }) => theme.color.error};
    content: '*';
    display: ${({ error }) => (error ? 'block' : 'none')};
  }
`;

export const FieldTip = styled.div`
  font-size: 1.4rem;
  line-height: 16px;
  font-family: Roboto-l;
  color: #050000;
  opacity: 64%;
`;

export const SpecialitiesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 22px 0 24px 0;
`;

export const YearsWrapper = styled.div`
  width: 164px;
  margin: 28px 0 24px 0;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const FavoritesWrapper = styled.div`
  /* flex-grow: 1; */
  max-width: 100%;
  min-width: 228px;
  width: Calc(50% - 6px);
`;

export const AboutTextArea = styled.textarea`
  border: 1px solid #05000033;
  font-family: Roboto-l;
  border-radius: 8px;
  margin: 24px 0 0;
  width: 100%;
  height: 88px;
  outline: none;
  padding: 16px;
  font-weight: 600;
  min-width: 100%;
  max-width: 100%;
  min-height: 88px;
`;

export const AboutButtonsWrapper = styled.div`
  width: 100%;
  margin: 28px 0;
  display: flex;
  justify-content: space-between;
`;
export const AboutButtonWrapper = styled.div`
  width: Calc(50% - 6px);
`;
export const TermsCheck = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 8px;
  margin-right: 12px;
  cursor: pointer;
  border: ${({ checked, error, theme }) =>
    checked
      ? '1px solid transparent'
      : error
      ? `1px solid ${theme.color.error}`
      : '1px solid #05000033'};
  background: ${({ checked }) => (checked ? '#00ff91' : 'transparent')};
  background-image: ${({ checked }) => (checked ? `url(${ICONS.TermsCheckMark})` : '')};
  background-size: cover;
  background-position: center;
`;

// result page

export const ResultName = styled.div`
  width: 100%;
  font-size: 3.8rem;
  color: #00ff91;
  text-align: center;
  font-weight: 1000;
  margin-top: 196px;
  letter-spacing: 1.4px;
  opacity: 80%;
`;
export const ResultSentence = styled.div`
  width: 100%;
  font-size: 2.4rem;
  line-height: 44px;
  color: #050000;
  text-align: center;
  font-weight: 800;
  letter-spacing: 1.2px;
  opacity: 80%;
`;

export const WeWillContact = styled.div`
  width: 100%;
  font-size: 2.4rem;
  line-height: 44px;
  color: #050000;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.4px;
  opacity: 80%;
`;

export const ContactUsText = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 20px;
  color: #05000099;
  text-align: center;
  letter-spacing: 0.4px;
  margin-top: 44px;
`;

export const ContactButtonWrapper = styled.div`
  width: 164px;
  margin-top: 24px;
`;
export const Timer = styled.div`
  width: 100%;
  padding: 60px;
  font-family: 'Space-r';
  font-size: 8rem;
  letter-spacing: 8px;
  color: #373333;
  text-align: center;
`;

export const CenteringContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: Calc(7.2vh - 12px) 0 0 0;
`;
export const ProfileImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.error};
  margin-top: ${({ margin }) => (margin ? margin : '8px')};
  font-size: 1.2rem;
  line-height: 16px;
`;

export const InputErrorIcon = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(${ICONS.AlertIcon});
  background-position: center;
  background-size: contain;
  margin-right: 8px;
`;

export const PseudoCkeckForFlex = styled.div`
  width: 164px;
  min-width: Calc(25% - 9px);
  flex-grow: 1;
`;
