import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 7.5%;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Space-r')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  font-size: 1.8rem;
  padding: 4px 28px;
  min-height: 28px;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

export const ArrowIcon = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #d2d1d1;
  background-image: url('');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 3.5rem auto 2rem;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const ModalRow = styled.div`
  width: 100%;
  display: flex;
`;

export const ModalColumn = styled.div`
  width: ${({ left }) => (left ? '43%' : '57%')};
  border-right: ${({ left }) => (left ? '1px solid #05000033' : 'none')};
  border-bottom: 1px solid #05000033;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 3.2rem 3.6rem;
`;
export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FieldWrapper = styled.div`
  width: Calc(50% - 1rem);
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`;

export const FieldTitle = styled.div`
  font-family: Roboto-m;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #37333399;
  margin-bottom: 1.6rem;
  padding: 0 0.4rem;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem 3.2rem;
`;

export const ButtonWrapper = styled.div`
  min-width: 14.4rem;
  height: 4.4rem;
`;

export const CernteredContentFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2.2rem;
`;

//profile image

export const ProfileImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Profileimage = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: ${(props) =>
    props.src
      ? `url(${props.src})`
      : `url("https://icon-library.com/images/no-profile-picture-icon-female/no-profile-picture-icon-female-3.jpg")`};
  background-size: cover;
  background-position: center;
`;

export const ProfileimageLine = styled.div`
  width: 140px;
  height: 140px;
  padding: 8px;
  border-radius: 50%;
  border: ${({ theme, error }) =>
    error ? `1px solid ${theme.color.error}` : `2px solid ${theme.color.primary}`};
  cursor: pointer;
`;

// days

export const DaysContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

export const DayWrapper = styled.div`
  min-width: Calc(25% - 1.2rem);
  margin: 1.2rem 0.6rem 0;
  border-radius: 8px;
  border: 1px solid
    ${({ checked, error, theme }) =>
      error ? theme.color.error : checked ? 'rgba(0,185,114,0.6)' : '#dededecc'};
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 1.3rem 0;
  font-size: 1.4rem;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.color.primary};
    background-image: url(${ICONS.TermsCheckMark});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0 8px 0 8px;
    width: 1.8rem;
    height: 1.8rem;
    top: 0;
    right: 0;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
  }
`;
