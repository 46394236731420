import styled from 'styled-components';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  line-height: 2.4rem;
  font-family: Space-r;
  padding: 2rem 3.6rem;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const ModalMain = styled.div`
  padding: 3.2rem 3.2rem 3.2rem 0;
`;

export const PricingTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0.35rem;
  table-layout: fixed;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #373333;
`;

export const PricingTableTh = styled.th`
  font-family: Roboto-m;
  text-align: center;
  padding: 1.8rem 0;
  line-height: 2.1rem;
  font-weight: inherit;
`;

export const PricingTableLeftColumnTd = styled.td`
  font-family: Roboto-m;
  text-align: left;
  line-height: 2.1rem;
  padding: 1.8rem 0 1.8rem 3.2rem;
  display: flex;
  justify-content: center;
`;

export const PricingTableTd = styled.td`
  background: #fafafa;
  text-align: center;
  padding: 0.7rem 1.1rem;
  &:nth-child(2) {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`;

export const ButtonWrapper = styled.div`
  width: 252px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 3.2rem 0 0.4rem;
  display: flex;
  justify-content: flex-end;
`;
