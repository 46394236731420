import React from 'react';
import Modal from 'react-modal';
import 'react-image-crop/dist/ReactCrop.css';
import STRINGS from '../../constants/strings';
import timezones from 'timezones-list';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  FlexWrapper,
  ButtonWrapper,
  ModalInput,
  CustomRadio,
  CustomRadioText,
  CustomRadioWrapper,
  ModalCloseX,
} from './components';
import CustomInput from '../input/CustomInput';
import CustomInputClock from '../input/CustomInputClock';
import { Formik, Field, Form } from 'formik';
import ReactSelectWitness from '../react-select-witness/ReactSelectWitness';
import { useAddressAutoComplete } from '../../hooks';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: '504px',
    maxWidth: '100%',
    minWidth: '320px',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const MeetingModal = ({ initialValues, closeModal, submitModal }) => {
  const { autoCompleteRef, setHandleAdressChoose } = useAddressAutoComplete();
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={initialValues}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
          {initialValues?.isOnline ? 'LIVE VIDEO MEETING' : 'Personal Meeting'}
          <ModalCloseX onClick={closeModal}>X</ModalCloseX>
        </ModalHeader>
        <ModalMain>
          <Formik onSubmit={console.log} initialValues={{ paid: false, date: '00:12:30' }}>
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '100%' }}>
                <Field id="date" name="date">
                  {({ field }) => (
                    <ModalInput>
                      Time of the meeting
                      <CustomInputClock
                        color="#373333"
                        outlined
                        borderRadius="8px"
                        height="4.4rem"
                        fontSize="1.4rem"
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        error={touched[field.name] && errors[field.name]}
                        hours
                        maxTime="00:23:59"
                      />
                    </ModalInput>
                  )}
                </Field>
                <Field id="timezone" name="timezone">
                  {({ field }) => (
                    <ModalInput>
                      Time Zone
                      <ReactSelectWitness
                        options={timezones?.map((a) => ({ ...a, value: a.tzCode }))}
                        margin="0"
                        width="100%"
                        onChange={(val) => setFieldValue(field.name, val)}
                        placeholder="Select time Zone"
                      />
                    </ModalInput>
                  )}
                </Field>
                {!initialValues?.isOnline && (
                  <Field id="location" name="location">
                    {({ field }) => (
                      <ModalInput>
                        Location
                        {setHandleAdressChoose((val) => setFieldValue(field.name, val))}
                        <CustomInput
                          id="edit-meeting-address"
                          inputRef={autoCompleteRef}
                          color="#373333"
                          outlined
                          borderRadius="8px"
                          height="4.4rem"
                          fontSize="1.4rem"
                          value={field.value}
                          onChange={(val) => setFieldValue(field.name, val)}
                          error={touched[field.name] && errors[field.name]}
                          placeholder="Type here..."
                        />
                      </ModalInput>
                    )}
                  </Field>
                )}
                <Field id="paid" name="paid">
                  {({ field }) => (
                    <ModalInput>
                      Meeting price
                      <CustomRadioWrapper onClick={() => setFieldValue(field?.name, false)}>
                        <CustomRadio checked={!field?.value} />
                        <CustomRadioText>Free</CustomRadioText>
                      </CustomRadioWrapper>
                      <CustomRadioWrapper onClick={() => setFieldValue(field?.name, true)}>
                        <CustomRadio
                          checked={field?.value}
                          onClick={() => setFieldValue(field?.name, true)}
                        />
                        <CustomRadioText>Paid (50 ₪)</CustomRadioText>
                      </CustomRadioWrapper>
                    </ModalInput>
                  )}
                </Field>

                <FlexWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" onClick={closeModal} type="button">
                      Cancel
                    </CustomButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" green type="submit" disabled={isSubmitting}>
                      {STRINGS.save}
                    </CustomButton>
                  </ButtonWrapper>
                </FlexWrapper>
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default MeetingModal;
