import styled from 'styled-components';
import IMAGES from '../../constants/images';

export const CropContainer = styled.div`
  width: 100%;
  align-items: center;
  height: 60vh;
  background: white;
  margin: auto;
  display: flex;
  justify-content: center;
  background: #00000055;
  background-image: url(${IMAGES.CropBackground});
  & .ReactCrop__crop-selection {
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 0 9999em #00000055;
  }
  & .ReactCrop__image {
    max-height: 60vh;
  }
`;

export const ModalHeading = styled.div`
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-family: Roboto-l;
  color: #050000;
  opacity: 64%;
  padding: 24px 0 0;
  text-align: center;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const EditSaveButtonWrapper = styled.div`
  width: 148px;
  margin-left: 12px;
`;

export const AutocropButtonWrapper = styled.div`
  width: 196px;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;
