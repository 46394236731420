import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import Loader from '../components/loader/Loader';
import useRegisterCoach from './registerCoach';

const useRouting = () => {
  const RegistrationRoute = ({ component: Component, ...rest }) => {
    const { me, meLoading } = useRegisterCoach();
    const authed = !!localStorage.getItem('token');
    return (
      <Route
        {...rest}
        render={(props) =>
          meLoading ? (
            <Loader></Loader>
          ) : me?.getCurrentUser?.coach?.status === 'APPROVED' ? (
            <Redirect to={{ pathname: '/home' }} />
          ) : authed ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/signup/coach' }} />
          )
        }
      />
    );
  };

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const { me, meLoading } = useRegisterCoach();
    const authed = !!localStorage.getItem('token');

    return (
      <Route
        {...rest}
        render={(props) =>
          meLoading ? (
            <Loader></Loader>
          ) : !authed || me?.getCurrentUser?.coach?.status !== 'APPROVED' ? (
            <Redirect to={{ pathname: '/signin' }} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  };

  const PublicRoute = ({ component: Component, ...rest }) => {
    const { me } = useRegisterCoach();
    const authed = !!localStorage.getItem('token');
    return (
      <Route
        {...rest}
        render={(props) =>
          !authed || me?.getCurrentUser?.coach?.status !== 'APPROVED' ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/' }} />
          )
        }
      />
    );
  };

  return { PrivateRoute, PublicRoute, RegistrationRoute };
};

export default useRouting;
