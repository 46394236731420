import React from 'react';

import { CheckInputWrapper, CheckInputIcon, CheckInputTitle } from './components';

const CustomInputCheck = ({ checked, icon, checkedIcon, title, little, onCheck }) => {
  return (
    <CheckInputWrapper checked={checked} onClick={onCheck} little={little}>
      <CheckInputIcon
        icon={checked && checkedIcon ? checkedIcon : icon}
        checked={checked}
        little={little}
      />
      <CheckInputTitle checked={checked} multiWord={title?.split('').length > 18}>
        {title}
      </CheckInputTitle>
    </CheckInputWrapper>
  );
};

export default CustomInputCheck;
