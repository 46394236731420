import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import Modal from 'react-modal';

import Loader from '../loader/Loader';
import WorkoutBlock from './WorkoutBlock';
import CustomInput from '../input/CustomInput';
import CustomButton from '../button/CustomButton';
import BLOCKS from '../../constants/workoutBlocks';
import CustomTooltip from '../tooltip/CustomTooltip';
import { CalendarType } from '../../constants/enums';
import CustomTextArea from '../input/CustomTextArea';
import EdittingWorkoutBlock from './EdittingWorkoutBlock';
import { CalendarSchema } from '../../schemas/program/coachProgram';

import { useUtils } from '../../hooks';

import {
  WholeWrapper,
  ModalHeader,
  ModalCloseX,
  ModalColumn,
  ModalHeaderCenterLine,
  LeftSideBlock,
  LeftSideBlockTitle,
  MessageCheck,
  MessageCheckBox,
  ContentWrapper,
  WorkoutBlockDropBox,
  WorkoutBlockDropBoxDashed,
  WorkoutBlockDropBoxPlus,
  RightColumnContainer,
  WorkoutBlocksBox,
  DraggableSign,
  WorkoutBlocksContainer,
  PseudoWorkoutBlock,
  ModalFooter,
  ColumnsWrapper,
  ContentContainer,
  WorkoutBlockItem,
  TooltipWorkoutBlock,
  TooltipBlocksWrapper,
  LeftSideBlockOptional,
  WorkoutBlocksBoxScrollable,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 3333,
  },
  content: {
    width: '74%',
    maxWidth: '1320px',
    minWidth: '1080px',
    height: 'Calc(82vh + 30px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '8px',
    border: 'none',
    padding: '0 ',
    pointerEvents: 'all',
  },
};

const AddCalendarModal = ({ day, closeModal, existingWorkout, submitModal, loading }) => {
  const [draggingBlock, setDraggingBlock] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const dropBoxRef = useRef(null);
  const { getWorkoutBlocksByWorkout } = useUtils();

  const iniatialBlocks = useMemo(() => getWorkoutBlocksByWorkout(existingWorkout?.workout), [
    existingWorkout,
    getWorkoutBlocksByWorkout,
  ]);

  const initialValues = useMemo(
    () => ({
      name: existingWorkout ? existingWorkout.workout?.name : `Day ${day?.programDay}`,
      beforeMessageChecked: !!existingWorkout?.workout?.beforeMessage,
      beforeMessage: existingWorkout ? existingWorkout.workout?.beforeMessage : '',
      afterMessageChecked: !!existingWorkout?.workout?.afterMessage,
      afterMessage: existingWorkout ? existingWorkout.workout?.afterMessage : '',
      type: CalendarType.WORKOUT,
      blocks: iniatialBlocks,
      editingBlockIndex: null,
    }),
    [day, existingWorkout, iniatialBlocks],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropBoxRef.current && !dropBoxRef.current.contains(event.target)) {
      setTooltipOpen(false);
    }
  };

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!day}
      style={customStyles}
      contentLabel="Program Request"
      ariaHideApp={false}
    >
      {loading || existingWorkout?.uid?.startsWith('loading') ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(v) => {
            submitModal(day?.programDay, v, existingWorkout?.workout?.uid);
            closeModal();
          }}
          validationSchema={CalendarSchema}
        >
          {({ setFieldValue, errors, validateForm, touched, values, setTouched }) => (
            <Form style={{ width: '100%', height: '100%' }}>
              <WholeWrapper>
                <ColumnsWrapper>
                  <ModalColumn left>
                    <ModalHeader>{`Day ${day?.programDay}`}</ModalHeader>
                    <LeftSideBlock>
                      <LeftSideBlockTitle>WORKOUT NAME</LeftSideBlockTitle>
                      <Field id="name" name="name">
                        {({ field }) => (
                          <CustomInput
                            outlined
                            height="4.4rem"
                            onChange={(v) => setFieldValue(field.name, v)}
                            value={field.value}
                            borderRadius="8px"
                            error={touched[field.name] && errors[field.name]}
                          />
                        )}
                      </Field>
                    </LeftSideBlock>
                    <LeftSideBlock scrollable>
                      <LeftSideBlockTitle>
                        SCHEDULE MESSAGE<LeftSideBlockOptional>(Optional)</LeftSideBlockOptional>
                      </LeftSideBlockTitle>
                      <Field id="beforeMessageChecked" name="beforeMessageChecked">
                        {({ field }) => (
                          <MessageCheck active={!!field.value}>
                            <MessageCheckBox
                              className="checkbox"
                              onClick={() => setFieldValue(field.name, !field.value)}
                            />
                            Before workout
                          </MessageCheck>
                        )}
                      </Field>
                      <Field id="beforeMessage" name="beforeMessage">
                        {({ field }) => (
                          <>
                            {!!values.beforeMessageChecked && (
                              <CustomTextArea
                                style={{
                                  height: '140px',
                                  fontFamily: 'Roboto-l',
                                  letterSpacing: '0.05rem',
                                }}
                                rows={null}
                                onChange={(v) => setFieldValue(field.name, v)}
                                value={field.value}
                              />
                            )}
                          </>
                        )}
                      </Field>
                      <Field id="afterMessageChecked" name="afterMessageChecked">
                        {({ field }) => (
                          <MessageCheck active={!!field.value}>
                            <MessageCheckBox
                              className="checkbox"
                              onClick={() => setFieldValue(field.name, !field.value)}
                            />
                            After workout
                          </MessageCheck>
                        )}
                      </Field>
                      <Field id="afterMessage" name="afterMessage">
                        {({ field }) => (
                          <>
                            {!!values.afterMessageChecked && (
                              <CustomTextArea
                                style={{
                                  height: '140px',
                                  fontFamily: 'Roboto-l',
                                  letterSpacing: '0.05rem',
                                }}
                                rows={null}
                                onChange={(v) => setFieldValue(field.name, v)}
                                value={field.value}
                              />
                            )}
                          </>
                        )}
                      </Field>
                    </LeftSideBlock>
                  </ModalColumn>
                  <ModalColumn main>
                    <ModalHeader>
                      <div />
                      <ModalHeaderCenterLine />
                      <div />
                    </ModalHeader>
                    <ContentWrapper>
                      <ContentContainer>
                        <FieldArray
                          name="blocks"
                          render={({ push, remove, insert, replace }) => (
                            <>
                              {values?.blocks?.map((block, i) =>
                                i === values?.editingBlockIndex ? (
                                  <EdittingWorkoutBlock
                                    block={block}
                                    key={i}
                                    index={i}
                                    setFieldValue={setFieldValue}
                                    validateForm={validateForm}
                                    errors={errors}
                                    touched={touched}
                                    setTouched={setTouched}
                                    deleteSelf={() => {
                                      remove(i);
                                      setFieldValue('editingBlockIndex', null);
                                    }}
                                  />
                                ) : (
                                  <WorkoutBlock
                                    block={block}
                                    key={i}
                                    deleteSelf={() => {
                                      remove(i);
                                      const beforeEditting = values.editingBlockIndex > i;
                                      if (beforeEditting && values.editingBlockIndex) {
                                        setFieldValue(
                                          'editingBlockIndex',
                                          values.editingBlockIndex - 1,
                                        );
                                      }
                                    }}
                                    dublicateSelf={(v) => {
                                      insert(i + 1, v);
                                    }}
                                    isFirst={i === 0}
                                    isLast={i === values?.blocks?.length - 1}
                                    orderUp={() => {
                                      const clone = block;
                                      if (i > 0) {
                                        const previousOne = values?.blocks?.[i - 1];
                                        replace(i, previousOne);
                                        replace(i - 1, clone);
                                        if (values?.editingBlockIndex === i - 1) {
                                          setFieldValue('editingBlockIndex', i);
                                        }
                                      }
                                    }}
                                    orderDown={() => {
                                      const clone = block;
                                      if (i < values?.blocks?.length - 1) {
                                        const nextOne = values?.blocks?.[i + 1];
                                        replace(i, nextOne);
                                        replace(i + 1, clone);
                                        if (values?.editingBlockIndex === i + 1) {
                                          setFieldValue('editingBlockIndex', i);
                                        }
                                      }
                                    }}
                                    setEditting={async () => {
                                      await setTouched({
                                        blocks: { [values.editingBlockIndex]: true },
                                      });
                                      const invalid = !!errors?.blocks?.[values.editingBlockIndex];
                                      if (!invalid) {
                                        setFieldValue('editingBlockIndex', i);
                                      }
                                    }}
                                  />
                                ),
                              )}
                              <WorkoutBlockDropBox>
                                <WorkoutBlockDropBoxDashed
                                  className="drop-box"
                                  error={!!touched.blocks && typeof errors?.blocks === 'string'}
                                  onDrop={async (e) => {
                                    e.preventDefault();
                                    e.target.style.background = '#ffffff';
                                    await setTouched({
                                      blocks: { [values.editingBlockIndex]: true },
                                    });
                                    const invalid = !!errors?.blocks?.[values.editingBlockIndex];
                                    if (!invalid) {
                                      push({
                                        ...draggingBlock,
                                        exercises: [],
                                        attributes: Object.keys(draggingBlock?.attributes)?.map(
                                          (key) => draggingBlock.attributes[key],
                                        ),
                                      });
                                      setFieldValue(
                                        'editingBlockIndex',
                                        values?.blocks?.length || 0,
                                      );
                                      setDraggingBlock(null);
                                    }
                                  }}
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                    if (draggingBlock) {
                                      e.target.style.borderColor = '#15DF68';
                                      e.target.style.background = 'rgba(0,255,145,0.3)';
                                    }
                                  }}
                                  onDragLeave={(e) => {
                                    e.preventDefault();
                                    e.target.style.borderColor = ' #96949499';
                                    e.target.style.background = '#ffffff';
                                  }}
                                  onMouseOver={(e) => {
                                    if (e.target?.className?.split(' ')?.indexOf('drop-box') > -1) {
                                      e.preventDefault();
                                      e.target.style.borderColor = '#15DF68';
                                    }
                                  }}
                                  onMouseOut={(e) => {
                                    if (e.target?.className?.split(' ')?.indexOf('drop-box') > -1) {
                                      e.preventDefault();
                                      e.target.style.borderColor = ' #96949499';
                                    }
                                  }}
                                  onClick={() => setTooltipOpen((curr) => !curr)}
                                  ref={dropBoxRef}
                                >
                                  <WorkoutBlockDropBoxPlus>
                                    {tooltipOpen && (
                                      <CustomTooltip
                                        below
                                        onMouseOver={() => {}}
                                        style={{
                                          width: '23.6rem',
                                          left: 'Calc(12px - 11.8rem)',
                                          padding: '0.7rem 0.2rem 0.7rem 0',
                                          display: 'block',
                                          height: 'fit-content',
                                        }}
                                      >
                                        <TooltipBlocksWrapper>
                                          {Array.from(Object.keys(BLOCKS))?.map((key, i) => {
                                            const block = BLOCKS[key];
                                            return (
                                              <TooltipWorkoutBlock
                                                key={i}
                                                onClick={async () => {
                                                  await setTouched({
                                                    blocks: { [values.editingBlockIndex]: true },
                                                  });
                                                  const invalid = !!errors?.blocks?.[
                                                    values.editingBlockIndex
                                                  ];
                                                  if (!invalid) {
                                                    push({
                                                      ...block,
                                                      exercises: [],
                                                      attributes: Object.keys(
                                                        block?.attributes,
                                                      )?.map((key) => block.attributes[key]),
                                                    });
                                                    setFieldValue(
                                                      'editingBlockIndex',
                                                      values?.blocks?.length || 0,
                                                    );
                                                  }
                                                }}
                                              >
                                                {block.name}
                                              </TooltipWorkoutBlock>
                                            );
                                          })}
                                        </TooltipBlocksWrapper>
                                      </CustomTooltip>
                                    )}
                                  </WorkoutBlockDropBoxPlus>
                                  {(!!touched.blocks &&
                                    typeof errors?.blocks === 'string' &&
                                    errors?.blocks) ||
                                    'Drag and Drop a Block'}
                                </WorkoutBlockDropBoxDashed>
                              </WorkoutBlockDropBox>
                            </>
                          )}
                        ></FieldArray>
                      </ContentContainer>
                    </ContentWrapper>
                  </ModalColumn>
                  <ModalColumn right>
                    <ModalHeader>
                      <div />
                      <ModalCloseX onClick={closeModal}>X</ModalCloseX>
                    </ModalHeader>
                    <RightColumnContainer>
                      <WorkoutBlocksBoxScrollable>
                        <WorkoutBlocksBox>
                          <DraggableSign /> DRAG A BLOCK
                          <WorkoutBlocksContainer>
                            {Object.keys(BLOCKS)?.map((key, i) => {
                              const block = BLOCKS[key];
                              return (
                                <WorkoutBlockItem
                                  draggable
                                  onDragStart={() => setDraggingBlock(block)}
                                  key={i}
                                  active={
                                    typeof values.editingBlockIndex === 'number' &&
                                    values.blocks[values.editingBlockIndex].type === block.type
                                  }
                                >
                                  {block.name}
                                </WorkoutBlockItem>
                              );
                            })}
                            <PseudoWorkoutBlock />
                          </WorkoutBlocksContainer>
                        </WorkoutBlocksBox>
                      </WorkoutBlocksBoxScrollable>
                    </RightColumnContainer>
                  </ModalColumn>
                </ColumnsWrapper>
                <ModalFooter>
                  <CustomButton
                    style={{ margin: '0 1.2rem', width: '22.4rem', height: '4.8rem' }}
                    fontSize="1.4rem"
                    type="submit"
                    green
                  >
                    Save & Close
                  </CustomButton>
                  {/* <CustomButton
                    style={{ margin: '0 1.2rem', width: '22.4rem', height: '4.8rem' }}
                    green
                    fontSize="1.4rem"
                    type="submit"
                  >
                    Save & Edit Next Day
                  </CustomButton> */}
                </ModalFooter>
              </WholeWrapper>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AddCalendarModal;
