import styled from "styled-components";

export const LayoutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const MainArea = styled.div`
  width: 100%;
  background: #f7f6f6;
  flex-grow: 1;
  padding: 32px;
`;
