import React, { useCallback, useRef } from 'react';
import { AttachmentButtonIcon } from './components';

const AttachmentButton = ({ onChange, accept }) => {
  const inputRef = useRef(null);
  const handleFileOpen = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  return (
    <>
      <input
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={onChange}
        accept={accept}
      />
      <AttachmentButtonIcon onClick={handleFileOpen} />
    </>
  );
};

export default AttachmentButton;
