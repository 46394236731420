import styled from 'styled-components';

export const Header = styled.div`
  height: 72px;
  display: flex;
  padding: 2.4rem 3.2rem;
  border-bottom: ${({ hideMb }) => (hideMb ? '' : '0.5px solid #d2d1d1')};
`;

export const HeaderText = styled.span`
  color: #050000;
  font-family: 'Space-r';
  font-size: 1.6rem;
  letter-spacing: 0.89px;
  line-height: 2.4rem;
  margin-right: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.9rem 3.2rem;
  padding-bottom: 0;
`;

export const AreaHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4.3rem;
`;

export const IconButton = styled.div`
  opacity: 0.6;
  color: #373333;
  font-family: Roboto-r;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.9rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  :hover {
    opacity: 1;
    font-family: Roboto-m;
    color: #00ff91;
  }
`;

export const OptWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0.6rem 1.65rem;
  :hover {
    background-color: rgba(0, 255, 145, 0.1);
    > div > div {
      background-color: #00ff91;
    }
  }
`;

export const OptIcon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: #fafafa;
  margin-right: 1.2rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3.2rem;
`;

export const IconsBody = styled.div`
  height: 392.5px;
  width: 472.5px;
  border: 0.5px solid #d2d1d1;
  border-radius: 8px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  padding: 1.625rem 1.125rem;
`;

export const IconWrapper = styled.div`
  margin: ${({ margin }) => margin};
  height: ${({ size }) => size || 56}px;
  width: ${({ size }) => size || 56}px;
  display: inline-block;
`;

export const IconContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) => (selected ? '#00FF91' : '#fafafa')};
  cursor: pointer;
  :hover {
    background-color: rgba(0, 255, 145, 0.6);
  }
`;

export const Icon = styled.img`
  height: 50%;
  width: 50%;
`;

export const ErrorText = styled.span`
  color: #f50a4f;
  font-family: Roboto-m;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.9rem;
`;
