import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ICONS from '../../constants/icons';
import URLS from '../../constants/urls';
import {
  HeaderContainer,
  Logo,
  HeaderLink,
  HeaderMain,
  ProfilePic,
  SideButtonsRelativeBox,
  SideButtonsAbsoluteBox,
} from './components';
import { useCoachPersonalInfo, useSignin } from '../../hooks';
import NotificationsModal from '../notifications/NotificatiosnModal';
import { useState } from 'react';
import { useMemo } from 'react';
import { NotificationContext } from '../../services/NotificationContext';

const Header = () => {
  const { pathname } = useLocation();
  const { currentCoach } = useCoachPersonalInfo({});
  const { signOut } = useSignin();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { unreadCount } = useContext(NotificationContext);

  return (
    <HeaderContainer>
      {notificationsOpen && (
        <NotificationsModal
          modalOpen={notificationsOpen}
          closeModal={() => setNotificationsOpen(false)}
        />
      )}
      <Link to={URLS.home}>
        <Logo />
      </Link>
      <HeaderMain></HeaderMain>
      <SideButtonsRelativeBox>
        <SideButtonsAbsoluteBox>
          <Link to={URLS.profile}>
            <HeaderLink isActive={!notificationsOpen && pathname === URLS.profile}>
              <ProfilePic img={currentCoach?.profileImage?.url} />
            </HeaderLink>
          </Link>
          <div>
            <HeaderLink
              icon={unreadCount ? ICONS.NotificationsActiveIcon : ICONS.NotificationsIcon}
              isActive={notificationsOpen}
              onClick={() => setNotificationsOpen((curr) => !curr)}
            />
          </div>
          <Link to={URLS.settings}>
            <HeaderLink
              icon={ICONS.SettingsIcon}
              isActive={!notificationsOpen && pathname === URLS.settings}
            />
          </Link>
          <Link to="#" onClick={signOut}>
            <HeaderLink
              icon={ICONS.LogoutIcon}
              isActive={!notificationsOpen && pathname === URLS.logout}
            />
          </Link>
        </SideButtonsAbsoluteBox>
      </SideButtonsRelativeBox>
    </HeaderContainer>
  );
};

export default Header;
