import './graphql.d';

// queries
import meQuery from './queries/me.gql';
import getUsersQuery from './queries/get-users.gql';
import getRolesQuery from './queries/get-roles.gql';
import getSpecialitiesQuery from './queries/get-specialities.gql';
import getSpecialityQuery from './queries/get-speciality.gql';
import getDiplomasQuery from './queries/get-diplomas.gql';
import getAllCoachesQuery from './queries/get-coaches.gql';
import getCoachByIdQuery from './queries/get-coach.gql';
import getExercisesQuery from './queries/get-exercises.gql';
import getExerciseByIdQuery from './queries/get-exercise.gql';
import getProgramsQuery from './queries/get-programs.gql';
import getProgramQuery from './queries/get-program.gql';
import getPricesQuery from './queries/get-prices.gql';
import getGoalsQuery from './queries/get-goals.gql';
import getGoalQuery from './queries/get-goal.gql';
import getCouponsQuery from './queries/get-coupons.gql';
import getCouponQuery from './queries/get-coupon.gql';
import getTrainingTypesQuery from './queries/get-training-types.gql';
import getTrainingTypeQuery from './queries/get-training-type.gql';
import getCalendarMessagesQuery from './queries/get-calendar-messages.gql';
import getCalendarMessageQuery from './queries/get-calendar-message.gql';
import getIconsQuery from './queries/get-icons.gql';
import getCoachGroupsQuery from './queries/get-coach-groups.gql';
import getCoachGroupQuery from './queries/get-coach-group.gql';
import checkProgramCodeQuery from './queries/check-program-code.gql';
import getTraineeByUserQuery from './queries/get-user-trainee.gql';
import getProgramPaymentsQuery from './queries/get-program-payments.gql';
import getNotificationsQuery from './queries/get-notifications.gql';
import getCoachWorkoutsQuery from './queries/get-coach-workouts.gql';
import getProgramTemplatesQuery from './queries/get-program-templates.gql';
import getProgramTemplateQuery from './queries/get-program-template.gql';
import getCoachProgramsQuery from './queries/get-coach-programs.gql';
import getUnreadCountQuery from './queries/get-unread-count.gql';

// mutations
import createCoachPersonalMutation from './mutations/signup/coach-personal.gql';
import createCoachProfessionalMutation from './mutations/signup/coach-professional.gql';
import createOrUpdateAdminCodeMutation from './mutations/coupon/create-or-edit-coupon.gql';
import deleteAdminCodeMutation from './mutations/coupon/delete-coupon.gql';
import editCoachMutation from './mutations/profile/coach-edit.gql';
import uploadDiplomaMutation from './mutations/uploads/upload-diploma.gql';
import editCoachFromAdminMutation from './mutations/coach/edit_coach_from_admin.gql';
import deleteCoachMutation from './mutations/coach/delete_coach.gql';
import createSpecialityMutation from './mutations/speciality/create-speciality.gql';
import editSpecialityMutation from './mutations/speciality/edit-speciality.gql';
import deleteSpecialityMutation from './mutations/speciality/delete-speciality.gql';
import createExerciseMutation from './mutations/exercise/create-exercise.gql';
import createCoachExerciseMutation from './mutations/exercise/create-coach-exercise.gql';
import editExerciseMutation from './mutations/exercise/edit-exercise.gql';
import deleteExerciseMutation from './mutations/exercise/delete-exercise.gql';
import addPricingMutation from './mutations/pricing/add-pricing.gql';
import changeProgramStatusMutation from './mutations/program/change-program-status.gql';
import setPasswordMutation from './mutations/coach/set_password.gql';
import verifyEmailMutation from './mutations/coach/verify_email.gql';
import signInMutation from './mutations/coach/sign_in.gql';
import uploadPortfolioMutation from './mutations/profile/coach-upload-portfolio.gql';
import createCalendarMutation from './mutations/program/create-calendar.gql';
import createGoalMutation from './mutations/goal/create-goal.gql';
import editGoalMutation from './mutations/goal/edit-goal.gql';
import deleteGoalMutation from './mutations/goal/delete-goal.gql';
import createTrainingTypeMutation from './mutations/training-type/create-training-type.gql';
import editTrainingTypeMutation from './mutations/training-type/edit-training-type.gql';
import deleteTrainingTypeMutation from './mutations/training-type/delete-training-type.gql';
import createCalendarMessageMutation from './mutations/calendar-message/create-calendar-message.gql';
import createProgramCalendarMutation from './mutations/calendar/create-program-calendar.gql';
import deleteCalendarMessageMutation from './mutations/calendar-message/delete-calendar-message.gql';
import editCalendarMessageMutation from './mutations/calendar-message/edit-calendar-message.gql';
import deleteCalendarMutation from './mutations/calendar/delete-calendar.gql';
import editCalendarMutation from './mutations/calendar/edit-calendar.gql';
import sendProgramMutation from './mutations/program/send-program.gql';
import editWorkoutMutation from './mutations/workout/edit-workout.gql';
import editCoachProgramMutation from './mutations/program/edit-coach-program.gql';
import createCoachGroupMutation from './mutations/coach-group/create-coach-group.gql';
import updateCalendarDayMutation from './mutations/calendar/update-calendar-day.gql';
import coachCommentMutation from './mutations/feedbacks/coach-comment.gql';
import createCoachProgramTemplateMutation from './mutations/template/create-program-template.gql';
import deleteProgramTemplateMutaton from './mutations/template/delete-template.gql';
import uploadTemplateMutaton from './mutations/template/upload-template.gql';
import notificationMarkAsReadMutation from './mutations/notification/notification-mark-as-read.gql';
import notificationMarkAllAsReadMutation from './mutations/notification/notification-mark-all-as-read.gql';

// subscriptions
import newNotificationSubscription from './subscriptions/new-notification.gql';

// fragments
import { getProgramCalendarFragment } from './read-fragments';

export {
  meQuery,
  getRolesQuery,
  getUsersQuery,
  getSpecialitiesQuery,
  getSpecialityQuery,
  getGoalsQuery,
  getGoalQuery,
  getCouponsQuery,
  getCouponQuery,
  createOrUpdateAdminCodeMutation,
  deleteAdminCodeMutation,
  deleteCoachMutation,
  createGoalMutation,
  editGoalMutation,
  deleteGoalMutation,
  getTrainingTypesQuery,
  getTrainingTypeQuery,
  createTrainingTypeMutation,
  editTrainingTypeMutation,
  deleteTrainingTypeMutation,
  createCoachPersonalMutation,
  createCoachProfessionalMutation,
  editCoachMutation,
  uploadDiplomaMutation,
  getAllCoachesQuery,
  editCoachFromAdminMutation,
  getCoachByIdQuery,
  getDiplomasQuery,
  getExercisesQuery,
  createSpecialityMutation,
  editSpecialityMutation,
  deleteSpecialityMutation,
  createExerciseMutation,
  createCoachExerciseMutation,
  getExerciseByIdQuery,
  deleteExerciseMutation,
  editExerciseMutation,
  getProgramsQuery,
  getProgramQuery,
  getPricesQuery,
  addPricingMutation,
  changeProgramStatusMutation,
  setPasswordMutation,
  verifyEmailMutation,
  signInMutation,
  uploadPortfolioMutation,
  createCalendarMutation,
  getCalendarMessagesQuery,
  getCalendarMessageQuery,
  getIconsQuery,
  createCalendarMessageMutation,
  createProgramCalendarMutation,
  deleteCalendarMessageMutation,
  editCalendarMessageMutation,
  deleteCalendarMutation,
  editCalendarMutation,
  sendProgramMutation,
  editWorkoutMutation,
  editCoachProgramMutation,
  createCoachGroupMutation,
  getCoachGroupsQuery,
  getCoachGroupQuery,
  getTraineeByUserQuery,
  checkProgramCodeQuery,
  updateCalendarDayMutation,
  getProgramCalendarFragment,
  getCoachWorkoutsQuery,
  coachCommentMutation,
  getProgramPaymentsQuery,
  createCoachProgramTemplateMutation,
  getNotificationsQuery,
  newNotificationSubscription,
  getProgramTemplatesQuery,
  getProgramTemplateQuery,
  deleteProgramTemplateMutaton,
  getCoachProgramsQuery,
  uploadTemplateMutaton,
  notificationMarkAsReadMutation,
  notificationMarkAllAsReadMutation,
  getUnreadCountQuery,
};
