import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: '#000000bb',
    zIndex: '111111111111111',
  },
  content: {
    backgroundColor: 'transparent',
    zIndex: '1111111111111111',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '8px',
    border: 'none',
    padding: '0 ',
  },
};

const MediaModal = ({ url, type, onClose }) => {
  return (
    <Modal
      onRequestClose={onClose}
      isOpen={!!url}
      style={customStyles}
      contentLabel="Crop Image"
      ariaHideApp={false}
    >
      {type === 'image' ? (
        <img src={url} style={{ maxWidth: '80vw', maxHeight: '90vh' }} alt="media_modal_image" />
      ) : (
        <video
          controls
          autoPlay
          style={{ maxWidth: '80vw', maxHeight: '90vh', width: '80vh', height: 'auto' }}
        >
          <source src={url} />
        </video>
      )}
    </Modal>
  );
};

export default MediaModal;
