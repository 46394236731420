import BLOCKS from '../constants/workoutBlocks';
import { WorkoutStatus } from '../constants/enums';

const workoutStatuses = {
  [WorkoutStatus.DONE]: 2,
  [WorkoutStatus.NEW]: 1,
  [WorkoutStatus.UNDONE]: 0,
};

const useUtils = () => {
  const sortByOrder = (a, b) => a.order - b.order;

  const sortByWorkoutStatus = (a, b) => workoutStatuses[b?.type] - workoutStatuses[a?.type];

  const getWorkoutBlocksByWorkout = (workout, traineeUid) => {
    const currentBlocks = workout?.workoutBlocks;
    return currentBlocks
      ?.map((block) => {
        const nullableAttributes = BLOCKS[block?.type]?.nullableAttributes && [
          ...BLOCKS[block?.type]?.nullableAttributes,
        ];
        const attributes = block?.customAttributes?.map((attr) => {
          const initialType = BLOCKS[block?.type]?.attributes?.[attr?.name]?.type;
          if (initialType === 'nullable') {
            const index = nullableAttributes.indexOf(attr?.name);
            if (index !== -1) {
              nullableAttributes.splice(index, 1);
            }
          }
          const type =
            attr?.name === 'unit' ? (attr?.textValue === 'Time' ? 'clock' : 'number') : initialType;
          return {
            ...BLOCKS[block?.type]?.attributes?.[attr?.name],
            ...attr,
            asNeeded:
              typeof BLOCKS[block?.type]?.attributes?.[attr?.name]?.asNeeded === 'boolean'
                ? !attr?.value
                : undefined,
            value:
              type === 'clock'
                ? `00:${('0' + Math.floor(attr?.value / 60)).slice(-2)}:${(
                    '0' +
                    (attr?.value % 60)
                  ).slice(-2)}`
                : type === 'text'
                ? attr?.textValue
                : attr?.value,
            type,
          };
        });
        const nullValueAttributes =
          nullableAttributes?.map((x) => BLOCKS[block?.type]?.attributes?.[x]) || [];
        return {
          ...block,
          ...BLOCKS?.[block?.type],
          attributes: [...attributes, ...nullValueAttributes],

          results: block?.results?.filter((x) => x?.trainee?.uid === traineeUid),
        };
      })
      .sort(sortByOrder);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-IN').format(num);
  };

  return { getWorkoutBlocksByWorkout, sortByWorkoutStatus, formatNumber };
};

export default useUtils;
