import React from 'react';
import { Link } from 'react-router-dom';

const Export = () => {
  return (
    <>
      <h1>Export page</h1>
      <Link to="/signup/coach/personal-info">go to signup page</Link>
    </>
  );
};

export default Export;
