import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const Title = styled.div`
  opacity: 0.8;
  color: #ffffff;
  font-family: 'Gotham-r';
  font-size: 2.4rem;
  letter-spacing: 0.8px;
  line-height: 4.5rem;
  text-align: center;
`;

export const SubTitle = styled.div`
  opacity: 0.6;
  color: #ffffff;
  font-family: Roboto-r;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  text-align: center;
  margin-top: 1.2rem;
`;

export const ArrowDown = styled.div`
  background-image: url(${ICONS.ArrowDownIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 6.4rem;
  height: 6.4rem;
  margin-top: 3.2rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  padding: 0 6rem;
  margin-top: ${({ mt }) => mt};
`;

export const CancelLink = styled.span`
  opacity: 0.7;
  color: #ffffff;
  font-family: Roboto-m;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.1rem;
  text-align: center;
`;
