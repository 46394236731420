import React, { useState } from 'react';
import ICONS from '../../constants/icons';
import STRINGS from '../../constants/strings';
import CustomInputCheck from '../../components/input/CustomInputCheck';
import {
  ProfileMainEdgeColumn,
  ProfileMainCenterColumn,
  ProfileImageBlock,
  ProfileImageFrame,
  ProfileImage,
  BorderLabel,
  BorderLabelEditIcon,
  LabeledInfoBlock,
  ProfileName,
  ChangesSaved,
  ChangesSavedIcon,
  PhysicalInfoBox,
  PhysicalInfoItem,
  PhysicalInfoItemLabel,
  PersonalInfoContainer,
  AboutContainer,
  ScrollableContainer,
  ProfessionalInfoContainer,
  ProfessionalInfoItem,
  ProfessionalInfoItemLabel,
  ProfessionalInfoGreenCircle,
  ProfessionalInfoItemValue,
  ExperienceWrapper,
  PortfolioWrapper,
  PortfolioItemWrapper,
  PortfolioScroll,
  ProfileId,
  ProfileIdInfo,
  CredentialItem,
  GreyButton,
  FlexWrapper,
  DownloadButton,
  PseudoSpeciality,
  PseudoPortfolioItem,
  LoadingDot,
  VideoSign,
  CameraIcon,
} from './components';
import { useCoachPersonalInfo } from '../../hooks';
import CustomEditableInfo from '../../components/input/CustomEditableInfo';
import CustomInputNumber from '../../components/input/CustomInputNumber';
import AlertModal from '../../components/alert-modal/AlertModal';
import EditPersonalInfoModal from '../../components/edit-personal-info-modal/EditPersonalInfoModal.jsx';
import EditAboutMeModal from '../../components/edit-personal-info-modal/EditAboutMeModal';
import CustomInputFile from '../../components/input/CustomInputFile';
import CropImageModal from '../../components/crop-image/CropImageModal';
import CustomTooltip from '../../components/tooltip/CustomTooltip';
import MediaModal from '../../components/media-modal/MediaModal';

const CoachProfilePersonal = () => {
  const [openModal, setOpenModal] = useState(null);
  const [openFile, setOpenFile] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [modalImageFile, setModalImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    currentCoach,
    submitEditCoach,
    getInitialValuesAbout,
    getInitialValuesPersonal,
    uploadportfolioItems,
  } = useCoachPersonalInfo({ setLoading, setOpenModal });

  return (
    <>
      <CropImageModal
        modalImageFile={modalImageFile}
        image={modalImage}
        closeModal={() => setModalImage(null)}
        onSubmit={(file, blob) =>
          submitEditCoach({ image: file }, { setSubmitting: () => {}, blob })
        }
      />
      <AlertModal
        title={STRINGS.cannot_change_professional_info}
        content={STRINGS.if_u_want_to_change_professional_info}
        modalOpen={openModal === 'professional_info'}
        closeModal={() => setOpenModal(null)}
      />
      {openModal === 'personal_info' && (
        <EditPersonalInfoModal
          modalOpen={openModal === 'personal_info'}
          closeModal={() => setOpenModal(null)}
          submitModal={submitEditCoach}
          getInitialValues={getInitialValuesPersonal}
        />
      )}
      <EditAboutMeModal
        modalOpen={openModal === 'about_me'}
        closeModal={() => setOpenModal(null)}
        submitModal={submitEditCoach}
        getInitialValues={getInitialValuesAbout}
      />
      <MediaModal url={openFile?.url} onClose={() => setOpenFile(null)} type={openFile?.type} />
      <ProfileMainEdgeColumn>
        <ProfileImageBlock>
          <ProfileImageFrame>
            <ProfileImage image={currentCoach?.profileImage?.url}>
              <CustomInputFile
                accept="image/*"
                onChange={async (e) => {
                  setModalImageFile(e.target.files[0]);
                  var reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onload = (ev) => {
                    setModalImage(ev.target.result);
                  };
                }}
              >
                <CameraIcon />
              </CustomInputFile>
            </ProfileImage>
            <ProfileId>
              #
              {(currentCoach?.code?.digits &&
                `${('000' + Math.floor(currentCoach?.code?.digits / 1000)).slice(-3)}-${(
                  '000' +
                  (currentCoach?.code?.digits % 1000)
                ).slice(-3)}`) ||
                '--- ---'}
              <ProfileIdInfo>
                i
                <CustomTooltip
                  style={{
                    width: '26rem',
                    marginBottom: '1.5rem',
                    left: '-12.1rem',
                  }}
                >
                  This is Your Profile Code. You can send it to Your potential Trainers to help them
                  to find you in the system
                </CustomTooltip>
              </ProfileIdInfo>
            </ProfileId>
          </ProfileImageFrame>
        </ProfileImageBlock>
        <LabeledInfoBlock>
          <BorderLabel>
            Personal Info
            <BorderLabelEditIcon onClick={() => setOpenModal('personal_info')} />
          </BorderLabel>
          <PhysicalInfoBox>
            <PhysicalInfoItem>
              {currentCoach?.gender?.split('')[0].toUpperCase() || '-'}
              <PhysicalInfoItemLabel>Gender</PhysicalInfoItemLabel>
            </PhysicalInfoItem>
            <PhysicalInfoItem>
              {currentCoach?.age || '-'}
              <PhysicalInfoItemLabel>Age</PhysicalInfoItemLabel>
            </PhysicalInfoItem>
            <PhysicalInfoItem>
              {currentCoach?.height || '-'}
              <PhysicalInfoItemLabel>Height (cm)</PhysicalInfoItemLabel>
            </PhysicalInfoItem>
            <PhysicalInfoItem>
              {currentCoach?.weight || '-'}
              <PhysicalInfoItemLabel>Weight (kg)</PhysicalInfoItemLabel>
            </PhysicalInfoItem>
          </PhysicalInfoBox>
          <PersonalInfoContainer>
            <CustomEditableInfo value={currentCoach?.email} icon={ICONS.EnvelopeActiveIcon} />
            <CustomEditableInfo value={currentCoach?.address} icon={ICONS.LocationActiveIcon} />
            <CustomEditableInfo value={currentCoach?.phone} icon={ICONS.PhoneActiveIcon} />
            <CustomEditableInfo value={currentCoach?.idNumber} icon={ICONS.ID} />
            <CustomEditableInfo value={currentCoach?.meal} icon={ICONS.Meal} />
            <CustomEditableInfo value={currentCoach?.playlist} icon={ICONS.Music} />
          </PersonalInfoContainer>
        </LabeledInfoBlock>
      </ProfileMainEdgeColumn>
      <ProfileMainCenterColumn>
        <ProfileName>{currentCoach?.fullName}</ProfileName>
        <LabeledInfoBlock>
          <BorderLabel>
            About Me
            <BorderLabelEditIcon onClick={() => setOpenModal('about_me')} />
          </BorderLabel>
          <AboutContainer>
            <ScrollableContainer>
              {currentCoach?.about?.split('\n').map((i, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      minHeight: '2.4rem',
                      lineHeight: '2.4rem',
                      fontSize: '1.4rem',
                      color: '#050000cc',
                    }}
                  >
                    {i}
                  </div>
                );
              })}
            </ScrollableContainer>
          </AboutContainer>
        </LabeledInfoBlock>
        <LabeledInfoBlock>
          <BorderLabel>
            Professional Info
            <BorderLabelEditIcon onClick={() => setOpenModal('professional_info')} />
          </BorderLabel>
          <ProfessionalInfoContainer>
            <ProfessionalInfoItem>
              <ProfessionalInfoItemLabel>
                <ProfessionalInfoGreenCircle />
                Training Specialities
              </ProfessionalInfoItemLabel>
              <ProfessionalInfoItemValue>
                {currentCoach?.specialities?.map((spec, i) => (
                  <CustomInputCheck
                    key={i}
                    little
                    title={spec.name}
                    icon={spec?.icon?.url}
                    checked
                    onCheck={() => {}}
                  />
                ))}
                <PseudoSpeciality />
                <PseudoSpeciality />
                <PseudoSpeciality />
                <PseudoSpeciality />
                <PseudoSpeciality />
              </ProfessionalInfoItemValue>
            </ProfessionalInfoItem>
            <ProfessionalInfoItem>
              <ProfessionalInfoItemLabel>
                <ProfessionalInfoGreenCircle />
                Years of Experience
              </ProfessionalInfoItemLabel>
              <ProfessionalInfoItemValue>
                <ExperienceWrapper>
                  <CustomInputNumber disabled value={currentCoach?.experience} />
                </ExperienceWrapper>
              </ProfessionalInfoItemValue>
            </ProfessionalInfoItem>
            <ProfessionalInfoItem>
              <ProfessionalInfoItemLabel>
                <ProfessionalInfoGreenCircle />
                Diplomas & Credentials
              </ProfessionalInfoItemLabel>
              <ProfessionalInfoItemValue>
                {currentCoach?.diplomas?.map((diploma, i) => (
                  <CredentialItem key={i}>
                    {diploma.name}
                    <FlexWrapper>
                      {diploma?.files?.map((file) => (
                        <GreyButton
                          key={file.uid}
                          icon={file?.mimetype?.split('/')[0] === 'image' && ICONS.Image}
                        />
                      ))}
                      <a
                        href={diploma?.files?.[0]?.url || ''}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DownloadButton />
                      </a>
                    </FlexWrapper>
                  </CredentialItem>
                ))}
              </ProfessionalInfoItemValue>
            </ProfessionalInfoItem>
          </ProfessionalInfoContainer>
        </LabeledInfoBlock>
      </ProfileMainCenterColumn>
      <ProfileMainEdgeColumn>
        <ChangesSaved>
          <ChangesSavedIcon />
          {loading ? (
            <>
              <LoadingDot i={2} />
              <LoadingDot i={1} />
              <LoadingDot i={0} />
            </>
          ) : (
            'Data Saved'
          )}
        </ChangesSaved>
        <LabeledInfoBlock>
          <BorderLabel>Portfolio</BorderLabel>
          <PortfolioWrapper>
            <PortfolioScroll>
              <PortfolioItemWrapper size="contain" image={ICONS.AddPortfolioItem} addBlock>
                <CustomInputFile
                  noHover
                  inputId="portfolio"
                  multiple="multiple"
                  accept="image/*, video/*"
                  onChange={(e) => uploadportfolioItems(e?.target?.files)}
                />
              </PortfolioItemWrapper>
              {currentCoach?.portfolio
                ?.slice(0)
                .reverse()
                .map((portfolio) => (
                  <React.Fragment key={portfolio.uid}>
                    {portfolio?.files
                      ?.slice(0)
                      .reverse()
                      .map((file) => (
                        <PortfolioItemWrapper
                          key={file.uid}
                          onClick={() =>
                            setOpenFile({ url: file.url, type: file?.mimetype?.split('/')[0] })
                          }
                          blur={portfolio?.uid?.substring(0, 4) === 'temp'}
                          image={file.url}
                        >
                          {file?.mimetype?.split('/')[0] === 'video' && <VideoSign id="scalable" />}
                          <video
                            controls={false}
                            autoPlay
                            style={{ width: '100%', height: '100%', position: 'absolute' }}
                          >
                            <source src={file.url} />
                          </video>
                        </PortfolioItemWrapper>
                      ))}
                  </React.Fragment>
                ))}
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
              <PseudoPortfolioItem />
            </PortfolioScroll>
          </PortfolioWrapper>
        </LabeledInfoBlock>
      </ProfileMainEdgeColumn>
    </>
  );
};

export default CoachProfilePersonal;
