import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Select from 'react-select';

import {
  BorderLabel,
  SettingsWrapper,
  LabeledInfoBlock,
  SettingsInfoBlock,
  SettingsInputLabel,
  LanguageWrapper,
  LanguageLabel,
  TraineesWrapper,
  SettingUnits,
  UnitBlock,
  UnitContainer,
} from './components';
import CustomInputNumber from '../../components/input/CustomInputNumber';
import CustomButton from '../../components/button/CustomButton';
import { MultiValueDeleteX } from '../../components/react-select-witness/components';

const options = [
  { value: 'english', label: 'English' },
  { value: 'hebrew', label: 'Hebrew' },
  { value: 'georgian', label: 'Georgian' },
];
const customStyles = ({ error }) => ({
  control: (provided) => ({
    ...provided,
    width: '38rem',
    height: '4.8rem',
    borderRadius: '8px',
    boxShadow: 'none',
    marginRight: '2.4rem',
    borderColor: error ? '#F50A4F' : '#05000033',
    '&:hover': {
      borderColor: error ? '#F50A4F' : '#05000033',
    },
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    opacity: 0.4,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'rgba(0,255,145,0.4)' : 'white',
    cursor: 'pointer',
    '&:hover': {
      background: state.isSelected ? 'rgba(0,255,145,0.4)' : 'rgba(0,255,145,0.1)',
    },
  }),
});
const CoachProfileSettings = () => {
  return (
    <Formik
      initialValues={{ languages: [], trainees: 3, unitWeight: 'kg', unitLength: 'cm' }}
      enableReinitialize
      onSubmit={console.log}
    >
      {({ setFieldValue, errors, validateForm, touched, values, setTouched }) => (
        <Form style={{ width: '100%', height: '100%' }}>
          <SettingsWrapper>
            <LabeledInfoBlock>
              <BorderLabel style={{ padding: '0 2.4rem' }}>Preferences</BorderLabel>
              <SettingsInfoBlock>
                <SettingsInputLabel>Choose Language</SettingsInputLabel>
                <LanguageWrapper>
                  <Field id="languages" name="languages">
                    {({ field }) => (
                      <>
                        <Select
                          options={options}
                          isMulti
                          styles={customStyles(false)}
                          value={field?.value}
                          onChange={(v) => setFieldValue(field?.name, v)}
                          backspaceRemovesValue={false}
                        />
                        {field?.value?.map((a) => (
                          <LanguageLabel>
                            <MultiValueDeleteX
                              onClick={() =>
                                setFieldValue(
                                  field?.name,
                                  field?.value?.filter((x) => x?.value !== a?.value),
                                )
                              }
                            />
                            {a?.label}
                          </LanguageLabel>
                        ))}
                      </>
                    )}
                  </Field>
                </LanguageWrapper>
                <SettingsInputLabel>Choose maximum amount of trainees:</SettingsInputLabel>
                <Field id="trainees" name="trainees">
                  {({ field }) => (
                    <TraineesWrapper>
                      <CustomInputNumber
                        value={field?.value}
                        onChange={(v) => setFieldValue(field?.name, v)}
                        onDecrement={() =>
                          setFieldValue(field?.name, Math.max(0, field?.value - 1))
                        }
                        onIncriment={() =>
                          setFieldValue(field?.name, Math.min(99, field?.value + 1))
                        }
                      />
                    </TraineesWrapper>
                  )}
                </Field>
              </SettingsInfoBlock>
            </LabeledInfoBlock>
            <LabeledInfoBlock>
              <BorderLabel style={{ padding: '0 24px' }}>Settings</BorderLabel>
              <SettingsInfoBlock>
                <SettingsInputLabel>Choose unit</SettingsInputLabel>
                <SettingUnits>
                  <Field id="unitWeight" name="unitWeight">
                    {({ field }) => (
                      <UnitBlock>
                        <UnitContainer
                          active={field?.value === 'kg'}
                          onClick={() => setFieldValue(field?.name, 'kg')}
                        >
                          kg
                        </UnitContainer>
                        <UnitContainer
                          active={field?.value === 'lbs'}
                          onClick={() => setFieldValue(field?.name, 'lbs')}
                        >
                          lbs
                        </UnitContainer>
                      </UnitBlock>
                    )}
                  </Field>
                  <Field id="unitLength" name="unitLength">
                    {({ field }) => (
                      <UnitBlock>
                        <UnitContainer
                          active={field?.value === 'cm'}
                          onClick={() => setFieldValue(field?.name, 'cm')}
                        >
                          cm
                        </UnitContainer>
                        <UnitContainer
                          active={field?.value === 'inches'}
                          onClick={() => setFieldValue(field?.name, 'inches')}
                        >
                          inches
                        </UnitContainer>
                      </UnitBlock>
                    )}
                  </Field>
                </SettingUnits>
              </SettingsInfoBlock>
            </LabeledInfoBlock>
            <LabeledInfoBlock>
              <SettingsInfoBlock>
                <CustomButton
                  green
                  style={{ padding: '1.6rem 2.8rem', width: 'fit-content' }}
                  type="submit"
                >
                  Save Changes
                </CustomButton>
              </SettingsInfoBlock>
            </LabeledInfoBlock>
          </SettingsWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default CoachProfileSettings;
