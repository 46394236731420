import styled from 'styled-components';
import IMAGES from '../../constants/images';

export const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 160px);
`;
export const DashboardSummaryBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DashboardMainBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const DashboardSummaryItem = styled.div`
  width: Calc((100% - 40px) / 3);
  height: 120px;
  background: #ffffff;
`;

export const DashboardMainItem = styled.div`
  width: ${({ grow }) =>
    grow === 1 ? 'Calc((100% - 40px) / 3)' : 'Calc((100% - 40px) / 3 * 2 + 20px)'};
  background: #ffffff;
`;

export const DashboardBanner = styled.div`
  width: 100%;
  height: 15.8rem;
  background: white;
  background-image: url(${IMAGES.HomeBanner});
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 101%;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(74, 74, 74, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const DashboardBannerContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 6.2rem 3rem 3.8rem;
`;

export const BannerAvatar = styled.div`
  width: 10rem;
  height: 10rem;
  background-image: url(${({ pic }) => pic});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: ${({ theme }) => `4px solid ${theme.color.primary}`};
  margin-right: 3.2rem;
  border-radius: 50%;
`;

export const BannerText = styled.div`
  flex-grow: 1;
`;

export const BannerPrimaryText = styled.div`
  line-height: 4.8rem;
  font-family: Gotham-r;
  font-size: 2rem;
  color: #373333;
  letter-spacing: 0.2rem;
`;

export const BannerSecondaryText = styled.div`
  line-height: 2.4rem;
  font-family: Roboto-m;
  font-size: 1.6rem;
  color: #373333;
  opacity: 0.6;
`;

export const DashboardBannerBackLine = styled.div`
  flex-grow: 1;
  height: 100%;
  border-right: 1px solid #05000012;
  &:last-child {
    border: none;
  }
`;
