import React from 'react';
import { Link } from 'react-router-dom';

const Stats = () => {
  return (
    <>
      <h1>Stats page</h1>
      <Link to="signup/coach">go to Signup Page</Link>
    </>
  );
};

export default Stats;
