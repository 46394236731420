import { useMutation } from '@apollo/react-hooks';
import { coachCommentMutation } from '@witness/graphql';

const useResults = () => {
  const [coachComment] = useMutation(coachCommentMutation);

  const submitFeedback = async (values) => {
    const { feedbacks, workoutStatusUid, traineeUid, workoutComment, workoutUid } = values;
    const feedbacksArr = Object.keys(feedbacks)?.map((workoutBlockUid) => ({
      workoutBlockUid,
      traineeUid,
      coachFeedback: feedbacks?.[workoutBlockUid],
    }));
    await coachComment({
      variables: {
        record: { workoutUid, workoutComment, workoutStatusUid },
        feedbacks: feedbacksArr,
      },
    });
  };

  return { submitFeedback };
};

export default useResults;
