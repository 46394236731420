import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import IMAGES from '../../constants/images';
import ICONS from '../../constants/icons';
import {
  SetPaswordEmailStepSchema,
  SetPaswordPasswordStepSchema,
} from '../../schemas/signup/coachProfile';

import {
  SignInWrapper,
  SignInColumn,
  SignInCell,
  FlexWrapper,
  NonFlexWrapper,
  WelcomeAbsolute,
  ButtonWrapper,
} from './components';
import CustomInput from '../../components/input/CustomInput';
import CustomButton from '../../components/button/CustomButton';
import { useSignin } from '../../hooks';

const SetPassword = () => {
  const [step, setStep] = useState('email');
  const { submitSetPassword, submitVerifyEmail } = useSignin();
  return (
    <SignInWrapper image={IMAGES.PasswordBackground}>
      <SignInColumn minResolution="tablet">
        <SignInCell />
        <SignInCell />
        <SignInCell />
        <SignInCell bg />
        <SignInCell />
        <SignInCell bottom />
      </SignInColumn>
      <SignInColumn main>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <WelcomeAbsolute>Welcome To</WelcomeAbsolute>
        </FlexWrapper>
        <SignInCell collSpan={2}>
          <img style={{ maxWidth: 'min(60vw, 80%)' }} src={IMAGES.LogoImage} alt="Logo" />
        </SignInCell>
        <SignInCell collSpan={2} rowSpan={3} fontSize={step !== 'email' ? null : '1.8rem'}>
          {step === 'email' ? (
            <>
              Enter The Email Address <br />
              Associated With Your Account
            </>
          ) : (
            'Please create a secure password'
          )}
          <Formik
            initialValues={{ email: '', password: '', repassword: '' }}
            validationSchema={
              step === 'email' ? SetPaswordEmailStepSchema : SetPaswordPasswordStepSchema
            }
            onSubmit={
              step === 'email'
                ? (v, { setErrors }) => submitVerifyEmail(v, { setErrors, setStep })
                : (v, { setErrors }) => submitSetPassword(v, { setErrors, setStep })
            }
          >
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '80%', padding: '4rem 0 0', maxWidth: '44rem' }}>
                <>
                  <Field id="email" name="email">
                    {({ field }) => (
                      <>
                        {step === 'email' ? (
                          <CustomInput
                            autocomplete={false}
                            outlined
                            height={'5.6rem'}
                            placeholder="Enter E-mail"
                            icon={ICONS.EnvelopeIcon}
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            backgroundColor="#ffffff"
                            margin="3.2rem 0"
                            error={touched[field.name] && errors[field.name]}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Field>
                </>

                <>
                  <Field id="password" name="password">
                    {({ field }) => (
                      <>
                        {step !== 'email' ? (
                          <CustomInput
                            autocomplete={false}
                            outlined
                            height={'5.6rem'}
                            placeholder="Enter Password"
                            icon={ICONS.PasswordIcon}
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            type="password"
                            backgroundColor="#ffffff"
                            margin="3.2rem 0"
                            error={touched[field.name] && errors[field.name]}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Field>
                  <Field id="repassword" name="repassword">
                    {({ field }) => (
                      <>
                        {step !== 'email' ? (
                          <CustomInput
                            autocomplete={false}
                            outlined
                            height={'5.6rem'}
                            placeholder="Repeat Your Password"
                            icon={ICONS.PasswordIcon}
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            type="password"
                            backgroundColor="#ffffff"
                            error={touched[field.name] && errors[field.name]}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Field>
                </>
                <ButtonWrapper>
                  <CustomButton green>Next</CustomButton>
                </ButtonWrapper>
              </Form>
            )}
          </Formik>
        </SignInCell>
        <FlexWrapper>
          <SignInCell bottom bg />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
      <SignInColumn minResolution="laptopS">
        <FlexWrapper>
          <SignInCell bg />
          <SignInCell />
          <SignInCell />
          <SignInCell />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell />
          <SignInCell collSpan={2} />
          <SignInCell />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <SignInCell collSpan={2} />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <SignInCell rowSpan={2} />
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell bg />
            <SignInCell />
          </NonFlexWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell bottom />
          <SignInCell bottom />
          <SignInCell bottom bg />
          <SignInCell bottom />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
    </SignInWrapper>
  );
};

export default SetPassword;
