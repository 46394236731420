import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { uploadDiplomaMutation } from '@witness/graphql';
import uniqid from 'uniqid';
import STRINGS from '../constants/strings';

const useUploadCredentials = (onChange = () => {}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [groupNameError, setGroupNameError] = useState('');
  const [credentials, setCredentials] = useState([
    { name: STRINGS.coach_credentials, diplomas: [] },
  ]);
  const [currentGroupName, setCurrentGroupName] = useState('');
  const [currentDiplomas, setCurrentFiles] = useState([]);

  const [uploadDiploma] = useMutation(uploadDiplomaMutation, {
    onCompleted(data) {
      const existingTempDiplomaIndex = currentDiplomas.findIndex(
        (x) => x.tempId === data.uploadDiploma.tempId,
      );
      const currentDiplomasClone = [...currentDiplomas];
      if (existingTempDiplomaIndex > -1 && currentDiplomas[existingTempDiplomaIndex]) {
        currentDiplomasClone[existingTempDiplomaIndex].uid = data.uploadDiploma.file.id;
      }
      setCurrentFiles(currentDiplomasClone);
    },
  });

  useEffect(() => {
    if (currentGroupName) {
      setGroupNameError('');
    }
  }, [currentGroupName]);

  useEffect(() => {
    onChange(credentials);
  }, [credentials]);

  const chooseCredentialToEdit = (index) => {
    const success = saveCredential();
    if (!success) {
      return;
    }
    const currCredential = credentials[index];
    setCurrentFiles([...currCredential.diplomas]);
    setCurrentGroupName(currCredential.name);
    setActiveIndex(index);
  };

  const saveCredential = () => {
    if (activeIndex < 0 || (!currentDiplomas.length && !currentGroupName)) {
      return 1;
    }
    if (!currentGroupName) {
      setGroupNameError(STRINGS.required);
      return 0;
    }
    const credentialsClone = [...credentials];
    credentialsClone[activeIndex] = {
      name: currentGroupName,
      diplomas: currentDiplomas,
    };
    setCredentials(credentialsClone);
    setActiveIndex(-1);
    setCurrentFiles([]);
    setCurrentGroupName('');
    setGroupNameError('');
    return 1;
  };

  const deleteCredential = (index) => {
    if (index === 0) {
      return;
    }
    const credentialsClone = [...credentials];
    credentialsClone.splice(index, 1);
    if (activeIndex === index) {
      setActiveIndex(-1);
      setCurrentFiles([]);
      setCurrentGroupName('');
    }
    setCredentials(credentialsClone);
  };

  const deleteFile = (index) => {
    const currentDiplomasClone = [...currentDiplomas];
    currentDiplomasClone.splice(index, 1);
    setCurrentFiles(currentDiplomasClone);
  };

  const addFiles = async (e) => {
    e.persist();
    for (const diploma of Array.from(e.target.files)) {
      const tempId = uniqid('temp-');
      setCurrentFiles((curr) => [...curr, { diploma, tempId }]);
      uploadDiploma({
        variables: {
          file: {
            diploma,
            tempId,
          },
        },
      });
    }
  };

  const prettifyFileSize = (s) => {
    const bytesInKB = 1024;
    const bytesInMB = 1024 * 1024;
    if (s > bytesInMB) {
      return (s / bytesInMB).toFixed(2) + ' Mb';
    } else if (s > bytesInKB) {
      return (s / bytesInKB).toFixed(2) + ' Kb';
    } else {
      return s + ' b';
    }
  };

  return {
    activeIndex,
    setActiveIndex,
    groupNameError,
    currentGroupName,
    setCurrentGroupName,
    currentDiplomas,
    addFiles,
    deleteFile,
    credentials,
    saveCredential,
    deleteCredential,
    chooseCredentialToEdit,
    prettifyFileSize,
  };
};

export default useUploadCredentials;
