import React from 'react';

import { WorkoutBlockType } from '../../constants/enums';

import {
  WorkoutBlockForm,
  WorkoutBlockHeader,
  WorkoutBlockContent,
  WorkoutBlockAdditionalAttr,
  WorkoutBlockAdditionalAttrWrapper,
  WorkoutBlockHeaderIconsWrapper,
  WorkoutBlockHeaderIcon,
  WorkoutBlockOrderArrow,
  WorkoutBlockComment,
} from './components';
import WorkoutBlockTable from './WorkoutBlockTable';

const WorkoutBlock = ({
  block,
  setEditting,
  deleteSelf,
  orderDown,
  orderUp,
  isFirst,
  isLast,
  immutable,
  dublicateSelf,
}) => {
  return (
    <WorkoutBlockForm>
      <WorkoutBlockHeader>
        <WorkoutBlockHeaderIconsWrapper>
          {!immutable && (
            <>
              <WorkoutBlockOrderArrow onClick={orderUp} nonClickable={isFirst} />
              <WorkoutBlockOrderArrow onClick={orderDown} down nonClickable={isLast} />
            </>
          )}
          {`${
            block?.type === WorkoutBlockType.CUSTOM
              ? block?.attributes?.find((x) => x.name === 'block_title')?.value || block?.name
              : block?.name
          } ${
            (block?.type === WorkoutBlockType.STRENGTH &&
              !!block?.exercises?.length &&
              `- ${block?.exercises?.[0].exercise?.title}`) ||
            ''
          }`}
        </WorkoutBlockHeaderIconsWrapper>

        {!immutable && (
          <WorkoutBlockHeaderIconsWrapper>
            <WorkoutBlockHeaderIcon onClick={() => dublicateSelf(block)} dublicate />
            <WorkoutBlockHeaderIcon onClick={setEditting} edit />
            <WorkoutBlockHeaderIcon onClick={deleteSelf} />
          </WorkoutBlockHeaderIconsWrapper>
        )}
      </WorkoutBlockHeader>
      <WorkoutBlockContent style={{ paddingTop: '3.2rem' }}>
        <WorkoutBlockTable block={block} />
        {block?.comment && <WorkoutBlockComment>{block?.comment}</WorkoutBlockComment>}
        {(block?.videoRequested || block?.isWarmUp) && (
          <WorkoutBlockAdditionalAttrWrapper>
            <div>
              {block?.videoRequested && (
                <WorkoutBlockAdditionalAttr>Video Requested</WorkoutBlockAdditionalAttr>
              )}
              {block?.isWarmUp && <WorkoutBlockAdditionalAttr>Warmup</WorkoutBlockAdditionalAttr>}
            </div>
          </WorkoutBlockAdditionalAttrWrapper>
        )}
      </WorkoutBlockContent>
    </WorkoutBlockForm>
  );
};

export default WorkoutBlock;
