import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ICONS from '../../constants/icons';
import URLS from '../../constants/urls';
import ChatContext from '../../services/ChatContext';
import { SidebarLink, SidebarContainer, ChatUnreadIndicator, LinkHoverTooltip } from './components';

const Sidebar = () => {
  let { pathname } = useLocation();
  const { totalUnreadCount } = useContext(ChatContext);
  return (
    <SidebarContainer>
      <Link to={URLS.home}>
        <SidebarLink src={ICONS.HomeIcon} isActive={pathname === URLS.home || pathname === '/'}>
          <LinkHoverTooltip>Home</LinkHoverTooltip>
        </SidebarLink>
      </Link>

      {/* <Link to={URLS.export}>
        <SidebarLink src={ICONS.ExportIcon} isActive={pathname === URLS.export} />
      </Link>
      <Link to={URLS.stats}>
        <SidebarLink src={ICONS.StatsIcon} isActive={pathname === URLS.stats} />
      </Link>
      <Link to={URLS.media}>
        <SidebarLink src={ICONS.MediaIcon} isActive={pathname === URLS.media} />
      </Link>
      <Link to={URLS.calendar}>
        <SidebarLink src={ICONS.CalendarIcon} isActive={pathname === URLS.calendar} />
      </Link> */}

      <Link to={URLS.trainees}>
        <SidebarLink src={ICONS.TraineeIcon} isActive={pathname === URLS.trainees}>
          <LinkHoverTooltip>Trainees</LinkHoverTooltip>
        </SidebarLink>
      </Link>
      <Link to={URLS.groups}>
        <SidebarLink src={ICONS.UsersIcon} isActive={pathname === URLS.groups}>
          <LinkHoverTooltip>Groups</LinkHoverTooltip>
        </SidebarLink>
      </Link>
      <Link to={URLS.templates}>
        <SidebarLink src={ICONS.ExportIcon} isActive={pathname === URLS.templates}>
          <LinkHoverTooltip>Templates</LinkHoverTooltip>
        </SidebarLink>
      </Link>
      <Link to={URLS.messages} style={{ position: 'relative' }}>
        <SidebarLink src={ICONS.ChatIcon} isActive={pathname === URLS.messages}>
          <LinkHoverTooltip>Chat</LinkHoverTooltip>
        </SidebarLink>
        {totalUnreadCount > 0 && <ChatUnreadIndicator />}
      </Link>
      {/* <Link to={URLS.settings}>
        <SidebarLink src={ICONS.SettingsIconSide} isActive={pathname === URLS.settings} />
      </Link> */}
    </SidebarContainer>
  );
};

export default Sidebar;
