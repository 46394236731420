import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { Formik, Field, Form } from 'formik';
import Modal from 'react-modal';

import CustomTextArea from '../input/CustomTextArea';
import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  FlexWrapper,
  ButtonWrapper,
  ModalTextArea,
  ModalSubHeading,
  ArrowIcon,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111111111',
  },
  content: {
    zIndex: '11111111111',
    width: '32%',
    maxWidth: '556px',
    minWidth: '320px',
    height: 'fit-content',
    maxHeight: 'Calc(100% - 40px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const ExplainRejectionModal = ({ modalOpen, closeModal, submitModal }) => {
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalMain padding={'3.2rem 3.2rem 1.6rem'}>
          <ModalHeader fontFamily="Space-b" centered noborder>
            DESCRIBE WHY THE REQUEST WAS REJECTED
          </ModalHeader>
        </ModalMain>
        <ModalSubHeading>Customer will get your message</ModalSubHeading>
        <ArrowIcon />
        <ModalMain padding={'1rem 3.2rem 3.2rem'}>
          <Formik initialValues={{ message: null }} onSubmit={submitModal}>
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '100%' }}>
                <Field id="message" name="message">
                  {({ field }) => (
                    <ModalTextArea>
                      <CustomTextArea
                        placeholder="Type Here"
                        rowCount={7}
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        error={errors[field.name]}
                      />
                    </ModalTextArea>
                  )}
                </Field>
                <FlexWrapper>
                  <ButtonWrapper marginTop="2.4rem">
                    <CustomButton fontSize="1.4rem" onClick={closeModal} type="button">
                      Cancel
                    </CustomButton>
                  </ButtonWrapper>
                  <ButtonWrapper marginTop="2.4rem">
                    <CustomButton
                      fontSize="1.4rem"
                      outlineColor="#f50a4f"
                      backgroundColor="transparent"
                      outlined
                      type="submit"
                    >
                      Send
                    </CustomButton>
                  </ButtonWrapper>
                </FlexWrapper>
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default ExplainRejectionModal;
