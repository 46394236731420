import React from 'react';
import styled from 'styled-components';
import ICONS from '../../constants/icons';

const Container = styled.div`
  box-sizing: border-box;
  height: 3.2rem;
  width: 3.2rem;
  border: ${({ checked }) => (checked ? '0.65px solid #00FF91' : '0.5px solid rgba(5, 0, 0, 0.4)')};
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Text = styled.span`
  color: #000000;
  font-family: ${({ checked }) => (checked ? 'Roboto-m' : 'Roboto-l')};
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.6rem;
  text-align: center;
`;

const IconWrapper = styled.div`
  background-color: #00ff91;
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${ICONS.TermsCheckMark});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Checkbox = ({ text, checked, ...rest }) => {
  return (
    <Container checked={checked} {...rest}>
      <Text checked={checked}>{text}</Text>

      {checked && <IconWrapper />}
    </Container>
  );
};

export default Checkbox;
