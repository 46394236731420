import React from 'react';

import { CMultiCheckItem, CMultiCheckItemMark, FlexWrapper } from './components';

const CustomCheckSingle = ({ options = [], itemStyles, value, onChange, ...rest }) => {
  return (
    <FlexWrapper {...rest}>
      {options.map((item) => {
        return (
          <CMultiCheckItem
            key={item.key}
            onClick={() => onChange(item)}
            style={{
              width: 'fit-content',
              marginLeft: '1.2rem',
              ...itemStyles,
            }}
          >
            <CMultiCheckItemMark
              active={item?.key === value?.key}
              style={{ marginRight: '0.6rem' }}
            />
            {item.label}
          </CMultiCheckItem>
        );
      })}
    </FlexWrapper>
  );
};

export default CustomCheckSingle;
