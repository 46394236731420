import styled from 'styled-components';

export const SidebarContainer = styled.div`
  min-width: 80px;
  min-height: Calc(100vh - 80px);
  border-right: 1px solid #05000040;
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SidebarLink = styled.div`
  width: 42px;
  height: 42px;
  margin-bottom: 40px;
  border-radius: 4px;
  position: relative;
  &:last-child {
    border-bottom: none;
  }
  background-image: ${({ src }) => (src ? `url(${src})` : '')};
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? props.theme.color.primary : 'transparent')};
  opacity: ${(props) => (props.isActive ? '1' : '0.6')};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
    & span {
      display: block;
    }
  }
`;

export const LinkHoverTooltip = styled.span`
  position: absolute;
  padding: 0.4rem 1.2rem;
  font-family: Roboto-r;
  color: #05000077;
  font-size: 1.4rem;
  background: #ededed;
  border-radius: 0.6rem;
  z-index: 1000;
  left: Calc(100% + 0.4rem);
  display: none;
`;

export const ChatUnreadIndicator = styled.span`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.error};
  position: absolute;
  top: 1rem;
  right: 1rem;
`;
