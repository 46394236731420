import React from 'react';
import './terms-style.css';

const TermsOfService = () => {
  return (
    <>
      <div id="page_1">
        <div id="id1_1">
          <p class="p0 ft0">Terms and Conditions</p>
          <p class="p1 ft2">
            These terms and conditions ("<span class="ft1">Agreement</span>") sets forth the general
            terms and conditions of your use of the "Witness" mobile application or website ("
            <span class="ft1">Application</span>" or "<span class="ft1">Service</span>") and any of
            its related products and services (collectively, "<span class="ft1">Services</span>" or
            “<span class="ft1">Platform</span>”). This Agreement is legally binding between you ("
            <span class="ft1">User</span>", "<span class="ft1">you</span>" or "
            <span class="ft1">your</span>") and Lifestyle A Team Ltd ("
            <span class="ft1">Lifestyle A Team Ltd</span>", "<span class="ft1">we</span>", "
            <span class="ft1">us</span>" or "<span class="ft1">our</span>"). By accessing and using
            the Application and Services, you acknowledge that you have read, understood, and agree
            to be bound by the terms of this Agreement.
          </p>
          <p class="p2 ft2">
            If you are entering into this Agreement on behalf of a business or other legal entity,
            you represent that you have the authority to bind such entity to this Agreement, in
            which case the terms "User", "you" or "your" shall refer to such entity. If you do not
            have such authority, or if you do not agree with the terms of this Agreement, you must
            not accept this Agreement and may not access and use the Application and Services.
          </p>
          <p class="p3 ft3">
            You acknowledge that this Agreement is a contract between you and Lifestyle A Team Ltd,
            even though it is electronic and is not physically signed by you, and it governs your
            use of the Application and Services.
          </p>
          <p class="p4 ft0">General Description</p>
          <p class="p5 ft3">
            <span class="ft4">1.</span>
            <span class="ft5">
              Witness is Platform bringing together trainers, organizations and other professionals
              from the wellness industry (The: “
            </span>
            <span class="ft6">Trainers</span>” “<span class="ft6">Coaches</span>”) and trainees (or
            training groups) where they can interact, buy and sell services, set goals, set training
            plans over time and get feedback on performance.
          </p>
          <p class="p6 ft8">
            <span class="ft4">2.</span>
            <span class="ft7">
              Trainers using the platform can build personal training plans for their trainees
              according to their profile and goals. The Platform provides{' '}
            </span>
            <nobr>built-in</nobr> tools of a high and professional standard for building training
            programs, monitoring performance and stay in touch with the trainees.
          </p>
          <p class="p7 ft10">
            <span class="ft4">3.</span>
            <span class="ft9">
              Trainees may request and obtain professional services and/or buy training programs
              from the Trainers according to the prices and tariffs shown on such Trainer’s Profile.
            </span>
          </p>
          <p class="p8 ft10">
            <span class="ft4">4.</span>
            <span class="ft9">
              Lifestyle A Team Ltd itself has no responsibility or liability for any services
              provided by the Trainers to the Trainees within the Witness Service.
            </span>
          </p>
          <p class="p9 ft12">
            <span class="ft4">5.</span>
            <span class="ft11">
              Lifestyle A Team Ltd is a meeting place between Trainees and eligible Trainers and the
              Witness Service is not an employment or matching agency. Except as specifically stated
              herein, the Witness Service does not engage as a referral, matching or placement
              service and does not provide, refer, place, offer or seek to obtain employment or
              engagements for any of its Users.
            </span>
          </p>
        </div>
      </div>
      <div id="page_2">
        <div id="id2_1">
          <p class="p11 ft0">Using our Services</p>
          <p class="p12 ft4">
            <span class="ft4">6.</span>
            <span class="ft14">
              Registering our Services is free for both Trainers and Trainees.
            </span>
          </p>
          <p class="p13 ft4">
            <span class="ft4">7.</span>
            <span class="ft14">
              Trainers that will want to sell their services using the Platform shall be charged
              with a monthly fee
            </span>
          </p>
          <p class="p14 ft10">
            (the “<span class="ft15">Fee</span>”) + monthly commission that is a percentage out of
            their monthly revenues at the platform (the “<span class="ft15">Commission</span>”) as
            described in the <span class="ft16">Payment Processing and Fees</span> section below.
          </p>
          <p class="p15 ft3">
            <span class="ft4">8.</span>
            <span class="ft5">Trainees can purchase periodic training plans (the “</span>
            <span class="ft6">Periodic Plans</span>”) and/or other services from Trainers through
            the Platform. Purchasing is possible using a valid payment method. The price of the
            services and/or the Periodic Plans is determined by the Trainers and is visible on the
            purchase page.
          </p>
          <p class="p16 ft18">
            <span class="ft4">9.</span>
            <span class="ft17">Trainers</span>
          </p>
          <p class="p17 ft10">
            <span class="ft4">9.1.</span>
            <span class="ft19">
              Trainers may be eligible to access and use the Platform only after submitting their
              details, as requested by us, and subject to our approval.
            </span>
          </p>
          <p class="p18 ft8">
            <span class="ft4">9.2.</span>
            <span class="ft20">
              Once approved, Trainer will get access to a customized dashboard where he can update
              his personal and professional info, set pricing to his services, manage his billing,
              create communities and much more.
            </span>
          </p>
          <p class="p19 ft3">
            <span class="ft4">9.3.</span>
            <span class="ft21">
              Witness shall have sole and absolute discretion regarding the eligibility of any
              Trainer to access, use and offer any Services through the Witness Service, and does
              not guarantee that any Trainer will be presented on the Witness Service for any
              minimum period of time or at all.
            </span>
          </p>
          <p class="p20 ft3">
            <span class="ft4">9.4.</span>
            <span class="ft21">
              We reserves the right to remove any Trainer from the Service, limit the scope and/or
              type of services a Trainer may offer through the Platform and to require a Trainer to
              remove User Content included in its Profile, at our discretion. We shall not be
              obligated to provide any explanations regarding our decisions in this respect.
            </span>
          </p>
          <p class="p21 ft4">
            <span class="ft4">9.5.</span>
            <span class="ft22">
              By using the Platform, you confirm, undertake, acknowledge, and warrant that:
            </span>
          </p>
          <p class="p22 ft4">
            <span class="ft23">•</span>
            <span class="ft24">
              You have a valid professional diploma from a certified institution.
            </span>
          </p>
          <p class="p23 ft4">
            <span class="ft23">•</span>
            <span class="ft24">You have a valid professional liability insurance.</span>
          </p>
          <p class="p24 ft26">
            <span class="ft23">•</span>
            <span class="ft25">
              All the information provided by you to us and in your Profile is true and correct. You
              are solely responsible for editing and updating such information on your Profile. You
              shall keep any User Content in your Profile{' '}
            </span>
            <nobr>up-to-date</nobr> with no broken links or misinformation. You are responsible for
            removing from your Profile any User Content you are not authorized to use anymore. Any
            information you provide must be true and up to date.
          </p>
          <p class="p25 ft26">
            <span class="ft23">•</span>
            <span class="ft25">
              You have the full right and authority to present and publish the User Content
              presented in your Profile (including, without limitation, links and{' '}
            </span>
            <nobr>third-party</nobr> websites). You must not submit, upload, post, display or share
            any User Content that you do not have the right to copy, use, link to or share for the
            purpose of your use of the Platform. You shall be solely liable for any damages
            resulting from any infringement of copyrights,
          </p>
        </div>
      </div>
      <div id="page_3">
        <div id="id3_1">
          <p class="p26 ft10">
            trademarks or other intellectual property rights, or any other harm resulting from your
            uploading, posting or publishing User Content to the Platform.
          </p>
          <p class="p27 ft3">
            <span class="ft23">•</span>
            <span class="ft27">
              You shall abide by fair business practices and ethics in any dealings with any
              Trainee. You should act fairly and appropriately with your clients.
            </span>
          </p>
          <p class="p28 ft2">
            <span class="ft23">•</span>
            <span class="ft28">
              We are not an employment service and does not serve as an employer of any Trainer. As
              such, Trainers are solely responsible for any tax, withholding or reporting
              obligations. You understand and agree that if we are found to be liable for any tax,
              withholding or reporting obligation in connection with any services provided or
              received by you, you will immediately reimburse and indemnify us for all costs,
              expenses and liabilities (including any interest and penalties) relating to the same.
            </span>
          </p>
          <p class="p29 ft2">
            <span class="ft23">•</span>
            <span class="ft29">
              Any information communicated to you about a Trainee via the Platform shall be conveyed
              in conformity with the information provided to us by such Trainee. We are not
              responsible for the truthfulness and validity of information provided by any Trainee.
              We are not responsible for information provided by third parties.
            </span>
          </p>
          <p class="p30 ft4">
            <span class="ft23">•</span>
            <span class="ft24">
              Each Trainer may have only one Profile presented on the Platform.
            </span>
          </p>
          <p class="p31 ft3">
            <span class="ft23">•</span>
            <span class="ft27">
              We reserve the right to remove any Trainer from the Platform or limit the services a
              Trainer may offer through the Platform without notice, for any reason.
            </span>
          </p>
          <p class="p13 ft18">
            <span class="ft4">10.</span>
            <span class="ft30">Trainees</span>
          </p>
          <p class="p32 ft4">As a Trainee using our Platform, you agree and acknowledge that:</p>
          <p class="p33 ft3">
            <span class="ft4">10.1.</span>
            <span class="ft31">
              The Content, including training and nutrition programs, provided through the Service,
              is for entertainment purposes only. We recommend that you consult a physician before
              beginning any exercise program or diet. We are not a licensed medical care provider.
              You should understand that participating in any exercise program or diet can result in
              physical injury, and you agree to do so at your own risk. The findings and opinions of
              authors and Trainers expressed herein are those of the author and do not necessarily
              state or reflect those of Witness or Lifestyle A team Ltd.
            </span>
          </p>
          <p class="p34 ft33">
            <span class="ft4">10.2.</span>
            <span class="ft32">If you have knowledge of any </span>
            <nobr>pre-existing</nobr> medical conditions, we advise you to seek medical advice from
            a doctor urgently before using the Services. This applies in particular if you have
            knowledge of one or more of the following medical complaints/conditions/procedures:
          </p>
          <p class="p35 ft4">
            <span class="ft23">•</span>
            <span class="ft24">cardiovascular disease,</span>
          </p>
          <p class="p23 ft4">
            <span class="ft23">•</span>
            <span class="ft24">lung or respiratory disease (including asthma),</span>
          </p>
          <p class="p36 ft4">
            <span class="ft23">•</span>
            <span class="ft24">spinal and/or joint problems,</span>
          </p>
          <p class="p36 ft4">
            <span class="ft23">•</span>
            <span class="ft24">neuromuscular disease,</span>
          </p>
          <p class="p23 ft4">
            <span class="ft23">•</span>
            <span class="ft24">surgical procedures,</span>
          </p>
          <p class="p36 ft4">
            <span class="ft23">•</span>
            <span class="ft24">any other health issues</span>
          </p>
        </div>
      </div>
      <div id="page_4">
        <p class="p37 ft8">
          <span class="ft4">10.3.</span>
          <span class="ft34">
            In case of the Services related to nutrition, you are responsible for verifying that the
            foods and nutrients recommended as part of the programs or guide do not contain any
            ingredients or contents to which you are allergic or which may cause food intolerance.
          </span>
        </p>
        <p class="p38 ft10">
          <span class="ft4">10.4.</span>
          <span class="ft35">
            In addition, our female Trainees should note that pregnant women and{' '}
          </span>
          <nobr>breast-feeding</nobr> mothers should seek medical advice from a doctor before using
          the Services
        </p>
        <p class="p39 ft3">
          <span class="ft4">10.5.</span>
          <span class="ft31">
            We are not the employer of any Trainer, nor do we supervise, direct or control the
            Trainer’s work or services, in any manner. We do not provide any training or equipment
            to the Trainers, and has no control over the quality, timing, legality, or any other
            aspect whatsoever of the services provided by the Trainer.
          </span>
        </p>
        <p class="p40 ft10">
          <span class="ft4">10.6.</span>
          <span class="ft35">
            Without derogating from the above- in case of Trainer failed to provide a service that
            you paid for, we will refund you- in accordance with our refund policy.
          </span>
        </p>
        <p class="p41 ft10">
          <span class="ft4">10.7.</span>
          <span class="ft35">
            Each Trainer provides you services as an independent contractor and is not an employee,
            joint venturer, partner, agent, or franchisee of us for any purpose whatsoever.
          </span>
        </p>
        <p class="p42 ft3">
          <span class="ft4">10.8.</span>
          <span class="ft31">
            Any listing of a Trainer on the Platform shall be according to the information and
            Profile provided by such Trainer. We are not responsible for the truthfulness and
            validity of information presented by any Trainer. We do not have any obligation to
            independently verify any information provided to it by any Trainer, their experience or
            training, and shall not be responsible or liable for any representation or undertaking
            made by any Trainer on the Platform.
          </span>
        </p>
        <p class="p43 ft10">
          <span class="ft4">10.9.</span>
          <span class="ft35">
            You are responsible for providing to us and such Trainers you contact via the Platform,
            accurate, current and complete information in connection with your request for services.
          </span>
        </p>
        <p class="p44 ft3">
          <span class="ft4">10.10.</span>
          <span class="ft36">
            In no event shall any reference by the Platform to any Trainer be construed as an
            approval or endorsement by us of such Trainers or of any services provided by them.
            Likewise, a link to any Trainer’s website does not imply that we endorse or accept any
            responsibility for the content or use of such website. As with the purchase of a product
            or service through any medium or in any environment, you are responsible for exercising
            appropriate judgment and caution.
          </span>
        </p>
        <p class="p45 ft3">
          <span class="ft4">10.11.</span>
          <span class="ft36">
            You agree to receive from time to time messages and promotional materials from us by e-
            mail or any other contact form you may provide us with (including your phone number for
            calls or text messages), including, without limitation, messages featuring Profiles of
            various Trainers. If you do not wish to receive such promotional materials or notices,
            please notify us at any time.
          </span>
        </p>
        <p class="p46 ft3">
          <span class="ft4">11.</span>
          <span class="ft37">
            Other than providing the Platform and the payment processing services we are not a party
            to any interaction between the Trainers and the Trainees. Trainees contract for services
            directly with the Trainers. We do not take responsibility, in any way or manner, for the
            quality of the services provided to any Trainee by any Trainer.
          </span>
        </p>
      </div>
      <div id="page_5">
        <div id="id5_1">
          <p class="p10 ft0">Payment Processing and Fees</p>
          <p class="p12 ft4">
            <span class="ft4">12.</span>
            <span class="ft38">The Platform supports payment between Trainers and Trainees.</span>
          </p>
          <p class="p48 ft10">
            <span class="ft4">13.</span>
            <span class="ft39">Monthly Fee </span>- Trainers’ Fee shall be as indicated in the
            Platform and will be charged each month, on a recuring basis, from the payment method,
            provided by the Trainer.
          </p>
          <p class="p9 ft33">
            <span class="ft4">14.</span>
            <span class="ft40">Commission </span>- our Commission shall be calculated as a
            percentage out of the Trainer’s monthly revenues at the platform. The exact percentage
            shall be agreed between the trainers and us in a separate agreement or will be indicated
            in the Trainer’s Dashboard, according to our discretion.
          </p>
          <p class="p49 ft12">
            <span class="ft4">15.</span>
            <span class="ft41">
              Trainers are responsible for paying any direct or indirect taxes, including any GST,
              VAT or otherwise, which may apply to them depending on residency, location or the
              billing information they provide in their account settings. Trainers represent and
              warrant that they comply, and will comply at all times, with their obligations under
              income tax provisions in their jurisdiction.
            </span>
          </p>
          <p class="p6 ft43">
            <span class="ft4">16.</span>
            <span class="ft42">Appointment as Limited Payment Collection Agent:</span> Trainers
            hereby appoints Lifestyle A team Ltd as Trainer’s limited authorized payment collection
            agent solely for the purpose of accepting payments (via its Payment Services Provider)
            from Trainee, and remitting those payments to Trainer. Trainer agrees that payment from
            Trainee to Lifestyle A team Ltd shall be considered the same as a made directly to
            Trainer. Trainee’s payment obligation to Trainer will be satisfied upon receipt of
            payment by Lifestyle A team Ltd (by its Payment Services Provider), and Lifestyle A team
            Ltd (via its Payment Services Provider) is responsible for remitting the funds to the
            Trainee in the manner described in these Terms. In the event that Lifestyle A team Ltd
            (via Payment Services Provider) does not remit any such amounts to Trainer, the Trainer
            will have recourse only against Lifestyle A team Ltd and not the Trainee directly.
            Trainer agrees that Lifestyle A team Ltd may describe or otherwise reflect the terms
            contained herein in any terms of service, receipts, disclosures, or notices including,
            but not limited to, receipts provided to Trainees that Lifestyle A team Ltd may deem
            necessary or prudent.
          </p>
          <p class="p50 ft3">
            <span class="ft4">17.</span>
            <span class="ft37">
              We partner with Payment Services Providers for purposes of collecting payments from
              Trainees, transferring such payments from Trainees to Trainers, and holding funds in
              connection with account’s Balances. All payments services in connection with the
              withdrawal of funds on the Platform are performed by OUR Payment Services Providers
              and not by us.
            </span>
          </p>
          <p class="p51 ft3">
            <span class="ft4">18.</span>
            <span class="ft37">
              Transfer of funds to Trainers shall be made on a monthly basis up to the 9
            </span>
            <span class="ft44">th </span>day of each month, with regard to the funds collected on
            the previous month. Funds will be transferred to the bank account which its details were
            provided by the Trainer.
          </p>
          <p class="p51 ft3">
            <span class="ft4">19.</span>
            <span class="ft37">
              Trainees that would like to purchase services from the Trainers may not offer Trainers
              to pay, or make payment using any method other than through the Platform. In case you
              have been asked to use an alternative payment method by one of our Trainers, please
              report it immediately to customer support.
            </span>
          </p>
        </div>
      </div>
      <div id="page_6">
        <p class="p52 ft10">
          <span class="ft4">20.</span>
          <span class="ft45">
            Payments in our Platform can be made using a valid Credit/Debit Card (excluding Diners
            and American Express).
          </span>
        </p>
        <p class="p53 ft3">
          <span class="ft4">21.</span>
          <span class="ft37">
            To protect against fraud, unauthorized transactions (such as money laundering), claims,
            or other liabilities, payment information in connection with withdrawals is collected by
            our Payment Services Providers. Payment Services Providers may also collect such other
            information as necessary for the purpose of processing payments. We are not exposed to
            the payment information provided to Payment Services Providers, and this information is
            subject to the privacy policy applicable to the Payment Service Provider. Please see our
            Privacy Policy for more information.
          </span>
        </p>
        <p class="p54 ft3">
          <span class="ft4">22.</span>
          <span class="ft37">
            By using any payment method and/or providing payment details for making purchases on the
            Platform, you represent and warrant that: (a) you are legally authorized to provide such
            information; (b) you are legally authorized or have permission to make payments using
            the payment method(s); (c) if you are an employee or agent of a company or person that
            owns the payment method, you are authorized by that company or person to use the payment
            method to make payments on our Platform; and (d) such actions do not violate any
            applicable law
          </span>
        </p>
        <p class="p55 ft2">
          <span class="ft4">23.</span>
          <span class="ft46">
            We reserve the right to change services and services’ pricing at any time. We also
            reserve the right to refuse any order you place with us. We may, in our sole discretion,
            limit or cancel quantities purchased per person, per household or per order. These
            restrictions may include orders placed by or under the same Trainee account, the same
            credit/debit card, and/or orders that use the same billing and/or shipping address. In
            the event that we make a change to or cancel an order, we may attempt to notify you by
            contacting the{' '}
          </span>
          <nobr>e-mail</nobr> and/or billing address/phone number provided at the time the order was
          made.
        </p>
        <p class="p56 ft26">
          <span class="ft4">24.</span>
          <span class="ft47">
            You agree and acknowledge that all payments must be processed through the Platform.
            Without derogating from our rights, under this Agreement, any payment that will be made
            between Trainers and Trainees, not through the Platform shall entitle us with additional
            penalty fee equal to 50% of the rate of such service, which will be charged from both
            the Trainer and the Trainee.
          </span>
        </p>
        <p class="p57 ft0">Cancellation Policy</p>
        <p class="p58 ft48">Trainers</p>
        <p class="p59 ft3">
          <span class="ft4">25.</span>
          <span class="ft37">
            Trainers may cancel their membership with 30 days prior written notice to our customer
            support at support@witnesstofitness.com, provided that the Trainers has delivered in
            full all of the services that were purchased from them.
          </span>
        </p>
      </div>
      <div id="page_7">
        <div id="id7_1">
          <p class="p10 ft48">Trainees</p>
          <p class="p61 ft50">
            <span class="ft4">26.</span>
            <span class="ft49">
              Trainees may cancel their Periodic Plans within 48 hours from purchasing, provided
              that they didn’t yet received the first Training Plan. In such case Trainees shall be
              entitled to a full refund.
            </span>
          </p>
          <p class="p62 ft12">
            <span class="ft4">27.</span>
            <span class="ft41">
              Cancellation not according to section 26 is subject to 14 days prior notice (the “
            </span>
            <span class="ft51">Prior Notice</span>”) and shall entitle the Trainee with a relative
            refund against the services/products he/she didn’t yet receive. It shall be clarified
            that during the Prior Notice period, all charges shall continue to apply.
          </p>
          <p class="p63 ft0">Accounts and membership</p>
          <p class="p64 ft2">
            <span class="ft4">28.</span>
            <span class="ft46">
              Trainers must be at least 18 years of age and Trainees must be at least 16 years of
              age and have their legal guardian approval to use the Application and Services. By
              using the Application and Services and by agreeing to this Agreement you warrant and
              represent that you meet these conditions.
            </span>
          </p>
          <p class="p46 ft2">
            <span class="ft4">29.</span>
            <span class="ft46">
              If you create an account in the Application, you are responsible for maintaining the
              security of your account and you are fully responsible for all activities that occur
              under the account and any other actions taken in connection with it. We may, but have
              no obligation to, monitor and review new accounts before you may sign in and start
              using the Services.
            </span>
          </p>
          <p class="p50 ft3">
            <span class="ft4">30.</span>
            <span class="ft37">
              Providing false contact information of any kind may result in the termination of your
              account. You must immediately notify us of any unauthorized uses of your account or
              any other breaches of security.
            </span>
          </p>
          <p class="p8 ft10">
            <span class="ft4">31.</span>
            <span class="ft45">
              We will not be liable for any acts or omissions by you, including any damages of any
              kind incurred as a result of such acts or omissions.
            </span>
          </p>
          <p class="p15 ft2">
            <span class="ft4">32.</span>
            <span class="ft46">
              We may suspend, disable, or delete your account (or any part thereof) if we determine
              that you have violated any provision of this Agreement or that your conduct or content
              would tend to damage our reputation and goodwill. If we delete your account for the
              foregoing reasons, you may not{' '}
            </span>
            <nobr>re-register</nobr> for our Services. We may block your email address and Internet
            protocol address to prevent further registration.
          </p>
          <p class="p57 ft0">User content</p>
          <p class="p65 ft2">
            <span class="ft4">33.</span>
            <span class="ft46">
              We do not own any data, information or material (collectively, " User Content") that
              you submit in the Application in the course of using the Service. You shall have sole
              responsibility for the accuracy, quality, integrity, legality, reliability,
              appropriateness, and intellectual property ownership or right to use of all submitted
              Content.
            </span>
          </p>
          <p class="p49 ft53">
            <span class="ft4">34.</span>
            <span class="ft52">
              We may, but have no obligation to, monitor and review the Content in the Application
              submitted or created using our Services by you. You grant us permission to access,
              copy, distribute, store,
            </span>
          </p>
        </div>
      </div>
      <div id="page_8">
        <div id="id8_1">
          <p class="p66 ft54">
            transmit, reformat, display and perform the Content of your user account solely as
            required for the purpose of providing the Services to you.
          </p>
          <p class="p6 ft26">
            <span class="ft4">35.</span>
            <span class="ft47">
              Without limiting any of those representations or warranties, we have the right, though
              not the obligation, to, in our own sole discretion, refuse or remove any Content that,
              in our reasonable opinion, violates any of our policies or is in any way harmful or
              objectionable. You also grant us the license to use, reproduce, adapt, modify, publish
              or distribute the Content created by you or stored in your user account for
              commercial, marketing or any similar purpose.
            </span>
          </p>
          <p class="p67 ft0">Accuracy of information</p>
          <p class="p68 ft26">
            <span class="ft4">36.</span>
            <span class="ft47">
              Occasionally there may be information in the Application that contains typographical
              errors, inaccuracies or omissions that may relate to product descriptions, pricing,
              availability, promotions and offers. We reserve the right to correct any errors,
              inaccuracies or omissions, and to change or update information or cancel orders if any
              information in the Application or Services is inaccurate at any time without prior
              notice (including after you have submitted your order).
            </span>
          </p>
          <p class="p69 ft2">
            <span class="ft4">37.</span>
            <span class="ft46">
              We undertake no obligation to update, amend or clarify information in the Application
              including, without limitation, pricing information, except as required by law. No
              specified update or refresh date applied in the Application should be taken to
              indicate that all information in the Application or Services has been modified or
              updated.
            </span>
          </p>
          <p class="p63 ft0">Third party services</p>
          <p class="p70 ft2">
            <span class="ft4">38.</span>
            <span class="ft46">
              If you decide to enable, access or use third party services, be advised that your
              access and use of such other services are governed solely by the terms and conditions
              of such other services, and we do not endorse, are not responsible or liable for, and
              make no representations as to any aspect of such other services, including, without
              limitation, their content or the manner in which they handle data (including your
              data) or any interaction between you and the provider of such other services.
            </span>
          </p>
          <p class="p71 ft26">
            <span class="ft4">39.</span>
            <span class="ft47">
              You irrevocably waive any claim against Lifestyle A Team Ltd with respect to such
              other services. Lifestyle A Team Ltd is not liable for any damage or loss caused or
              alleged to be caused by or in connection with your enablement, access or use of any
              such other services, or your reliance on the privacy practices, data security
              processes or other policies of such other services. You may be required to register
              for or log into such other services on their respective platforms.
            </span>
          </p>
          <p class="p72 ft10">
            <span class="ft4">40.</span>
            <span class="ft45">
              By enabling any other services, you are expressly permitting Lifestyle A Team Ltd to
              disclose your data as necessary to facilitate the use or enablement of such other
              service.
            </span>
          </p>
        </div>
      </div>
      <div id="page_9">
        <div id="id9_1">
          <p class="p10 ft0">Backups</p>
          <p class="p73 ft2">
            <span class="ft4">41.</span>
            <span class="ft46">
              We perform regular backups of the Content, however, these backups are for our own
              administrative purposes only and are in no way guaranteed. You are responsible for
              maintaining your own backups of your data. We do not provide any sort of compensation
              for lost or incomplete data in the event that backups do not function properly. We
              will do our best to ensure complete and accurate backups but assume no responsibility
              for this duty.
            </span>
          </p>
          <p class="p57 ft0">Advertisements</p>
          <p class="p70 ft2">
            <span class="ft4">42.</span>
            <span class="ft46">
              During your use of the Application and Services, you may enter into correspondence
              with or participate in promotions of advertisers or sponsors showing their goods or
              services through the Application and Services. Any such activity, and any terms,
              conditions, warranties or representations associated with such activity, is solely
              between you and the applicable third party. We shall have no liability, obligation or
              responsibility for any such correspondence, purchase or promotion between you and any
              such third party.
            </span>
          </p>
          <p class="p4 ft0">Links to other resources</p>
          <p class="p74 ft12">
            <span class="ft4">43.</span>
            <span class="ft41">
              Although the Application and Services may link to other resources (such as websites,
              applications, etc.), we are not, directly or indirectly, implying any approval,
              association, sponsorship, endorsement, or affiliation with any linked resource, unless
              specifically stated herein.
            </span>
          </p>
          <p class="p75 ft26">
            <span class="ft4">44.</span>
            <span class="ft47">
              Some of the links in the Application may be "affiliate links". This means if you click
              on the link and purchase an item, Lifestyle A Team Ltd will receive an affiliate
              commission. We are not responsible for examining or evaluating, and we do not warrant
              the offerings of, any businesses or individuals or the content of their resources. We
              do not assume any responsibility or liability for the actions, products, services, and
              content of any other third parties. You should carefully review the legal statements
              and other conditions of use of any resource which you access through a link in the
              Application and Services. Your linking to any other{' '}
            </span>
            <nobr>off-site</nobr> resources is at your own risk.
          </p>
          <p class="p76 ft0">Prohibited uses</p>
          <p class="p77 ft26">
            <span class="ft4">45.</span>
            <span class="ft47">
              In addition to other terms as set forth in the Agreement, you are prohibited from
              using the Application and Services or Content: (a) for any unlawful purpose; (b) to
              solicit others to perform or participate in any unlawful acts; (c) to violate any
              international, federal, provincial or state regulations, rules, laws, or local
              ordinances; (d) to infringe upon or violate our intellectual property rights or the
              intellectual property rights of others; (e) to harass, abuse, insult, harm, defame,
              slander, disparage, intimidate, or discriminate based on gender, sexual orientation,
              religion, ethnicity, race, age, national origin, or disability; (f) to submit false or
              misleading information; (g)
            </span>
          </p>
        </div>
      </div>
      <div id="page_10">
        <div id="id10_1">
          <p class="p78 ft12">
            to upload or transmit viruses or any other type of malicious code that will or may be
            used in any way that will affect the functionality or operation of the Application and
            Services, third party products and services, or the Internet; (h) to spam, phish, pharm,
            pretext, spider, crawl, or scrape;
          </p>
          <p class="p79 ft2">
            <span class="ft55">(i)</span>
            <span class="ft56">
              for any obscene or immoral purpose; or (j) to interfere with or circumvent the
              security features of the Application and Services, third party products and services,
              or the Internet. We reserve the right to terminate your use of the Application and
              Services for violating any of the prohibited uses.
            </span>
          </p>
          <p class="p63 ft0">Intellectual property rights</p>
          <p class="p77 ft26">
            <span class="ft4">46.</span>
            <span class="ft47">
              "Intellectual Property Rights" means all present and future rights conferred by
              statute, common law or equity in or in relation to any copyright and related rights,
              trademarks, designs, patents, inventions, goodwill and the right to sue for passing
              off, rights to inventions, rights to use, and all other intellectual property rights,
              in each case whether registered or unregistered and including all applications and
              rights to apply for and be granted, rights to claim priority from, such rights and all
              similar or equivalent rights or forms of protection and any other results of
              intellectual activity which subsist or will subsist now or in the future in any part
              of the world.
            </span>
          </p>
          <p class="p69 ft2">
            <span class="ft4">47.</span>
            <span class="ft46">
              This Agreement does not transfer to you any intellectual property owned by Lifestyle A
              Team Ltd or third parties, and all rights, titles, and interests in and to such
              property will remain (as between the parties) solely with Lifestyle A Team Ltd. All
              trademarks, service marks, graphics and logos used in connection with the Application
              and Services, are trademarks or registered trademarks of Lifestyle A Team Ltd or its
              licensors. Other trademarks, service marks, graphics and logos used in connection with
              the Application and Services may be the trademarks of other third parties. Your use of
              the Application and Services grants you no right or license to reproduce or otherwise
              use any of Lifestyle A Team Ltd or third party trademarks.
            </span>
          </p>
          <p class="p80 ft0">Disclaimer of warranty</p>
          <p class="p77 ft58">
            <span class="ft4">48.</span>
            <span class="ft57">
              You agree that such Service is provided on an "as is" and "as available" basis and
              that your use of the Application and Services is solely at your own risk. We expressly
              disclaim all warranties of any kind, whether express or implied, including but not
              limited to the implied warranties of merchantability, fitness for a particular purpose
              and{' '}
            </span>
            <nobr>non-infringement.</nobr> We make no warranty that the Services will meet your
            requirements, or that the Service will be uninterrupted, timely, secure, or{' '}
            <nobr>error-free;</nobr> nor do we make any warranty as to the results that may be
            obtained from the use of the Service or as to the accuracy or reliability of any
            information obtained through the Service or that defects in the Service will be
            corrected. You understand and agree that any material and/or data downloaded or
            otherwise obtained through the use of Service is done at your own discretion
          </p>
        </div>
      </div>
      <div id="page_11">
        <div id="id11_1">
          <p class="p78 ft26">
            and risk and that you will be solely responsible for any damage or loss of data that
            results from the download of such material and/or data. We make no warranty regarding
            any goods or services purchased or obtained through the Service or any transactions
            entered into through the Service unless stated otherwise. No advice or information,
            whether oral or written, obtained by you from us or through the Service shall create any
            warranty not expressly made herein.
          </p>
          <p class="p81 ft0">Limitation of liability</p>
          <p class="p82 ft60">
            <span class="ft4">49.</span>
            <span class="ft59">
              To the fullest extent permitted by applicable law, in no event will Lifestyle A Team
              Ltd, its affiliates, directors, officers, employees, agents, suppliers or licensors be
              liable to any person for any indirect, incidental, special, punitive, cover or
              consequential damages (including, without limitation, damages for lost profits,
              revenue, sales, goodwill, use of content, impact on business, business interruption,
              loss of anticipated savings, loss of business opportunity) however caused, under any
              theory of liability, including, without limitation, contract, tort, warranty, breach
              of statutory duty, negligence or otherwise, even if the liable party has been advised
              as to the possibility of such damages or could have foreseen such damages. To the
              maximum extent permitted by applicable law, the aggregate liability of Lifestyle A
              Team Ltd and its affiliates, officers, employees, agents, suppliers and licensors
              relating to the services will be limited to an amount greater of one dollar or any
              amounts actually paid by you to Lifestyle A Team Ltd for the prior one month period
              prior to the first event or occurrence giving rise to such liability. The limitations
              and exclusions also apply if this remedy does not fully compensate you for any losses
              or fails of its essential purpose.
            </span>
          </p>
          <p class="p83 ft0">Indemnification</p>
          <p class="p70 ft2">
            <span class="ft4">50.</span>
            <span class="ft46">
              You agree to indemnify and hold Lifestyle A Team Ltd and its affiliates, directors,
              officers, employees, agents, suppliers and licensors harmless from and against any
              liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred
              in connection with or arising from any third party allegations, claims, actions,
              disputes, or demands asserted against any of them as a result of or relating to your
              Content, your use of the Application and Services or any willful misconduct on your
              part.
            </span>
          </p>
          <p class="p4 ft0">Severability</p>
          <p class="p77 ft26">
            <span class="ft4">51.</span>
            <span class="ft47">
              All rights and restrictions contained in this Agreement may be exercised and shall be
              applicable and binding only to the extent that they do not violate any applicable laws
              and are intended to be limited to the extent necessary so that they will not render
              this Agreement illegal, invalid or unenforceable. If any provision or portion of any
              provision of this Agreement shall be held to be
            </span>
          </p>
        </div>
      </div>
      <div id="page_12">
        <div id="id12_1">
          <p class="p84 ft2">
            illegal, invalid or unenforceable by a court of competent jurisdiction, it is the
            intention of the parties that the remaining provisions or portions thereof shall
            constitute their agreement with respect to the subject matter hereof, and all such
            remaining provisions or portions thereof shall remain in full force and effect.
          </p>
          <p class="p85 ft0">Dispute resolution</p>
          <p class="p82 ft60">
            <span class="ft4">52.</span>
            <span class="ft59">
              The formation, interpretation, and performance of this Agreement and any disputes
              arising out of it shall be governed by the substantive and procedural laws of Israel
              without regard to its rules on conflicts or choice of law and, to the extent
              applicable, the laws of Israel. The exclusive jurisdiction and venue for actions
              related to the subject matter hereof shall be the courts located in Tel Aviv Israel,
              and you hereby submit to the personal jurisdiction of such courts. You hereby waive
              any right to a jury trial in any proceeding arising out of or related to this
              Agreement. The United Nations Convention on Contracts for the International Sale of
              Goods does not apply to this Agreement.
            </span>
          </p>
          <p class="p57 ft0">Assignment</p>
          <p class="p65 ft2">
            <span class="ft4">53.</span>
            <span class="ft46">You may not assign, resell, </span>
            <nobr>sub-license</nobr> or otherwise transfer or delegate any of your rights or
            obligations hereunder, in whole or in part, without our prior written consent, which
            consent shall be at our own sole discretion and without obligation; any such assignment
            or transfer shall be null and void.
          </p>
          <p class="p86 ft54">
            <span class="ft4">54.</span>
            <span class="ft61">
              We are free to assign any of its rights or obligations hereunder, in whole or in part,
              to any third party as part of the sale of all or substantially all of its assets or
              stock or as part of a merger.
            </span>
          </p>
          <p class="p85 ft0">Changes and amendments</p>
          <p class="p64 ft2">
            <span class="ft4">55.</span>
            <span class="ft46">
              We reserve the right to modify this Agreement or its terms relating to the Application
              and Services at any time, effective upon posting of an updated version of this
              Agreement in the Application. When we do, we will revise the updated date at the
              bottom of this page. We may also provide notice to you in other ways in our
              discretion, such as through contact information you have provided and/or by posting a
              notice in the Application.
            </span>
          </p>
          <p class="p72 ft10">
            <span class="ft4">56.</span>
            <span class="ft62">
              Continued use of the Application and Services after any such changes shall constitute
              your consent to such changes.
            </span>
          </p>
          <p class="p87 ft0">Acceptance of these terms</p>
          <p class="p74 ft50">
            <span class="ft4">57.</span>
            <span class="ft49">
              You acknowledge that you have read this Agreement and agree to all its terms and
              conditions. By accessing and using the Application and Services you agree to be bound
              by this Agreement. If you
            </span>
          </p>
        </div>
      </div>
      <div id="page_13">
        <div id="id13_1">
          <p class="p66 ft54">
            do not agree to abide by the terms of this Agreement, you are not authorized to access
            or use the Application and Services.
          </p>
          <p class="p85 ft0">Contacting us</p>
          <p class="p88 ft54">
            <span class="ft4">58.</span>
            <span class="ft61">
              If you would like to contact us to understand more about this Agreement or wish to
              contact us concerning any matter relating to it, you may send an email to
              support@witnesstofitness.com
            </span>
          </p>
          <p class="p89 ft4">This document was last updated on Thursday, 05 November 2020</p>
        </div>
      </div>
      <div id="page_1">
        <div id="id1_1">
          <p class="p0 ft0">Terms and Conditions</p>
          <p class="p1 ft2">
            These terms and conditions ("<span class="ft1">Agreement</span>") sets forth the general
            terms and conditions of your use of the "Witness" mobile application or website ("
            <span class="ft1">Application</span>" or "<span class="ft1">Service</span>") and any of
            its related products and services (collectively, "<span class="ft1">Services</span>" or
            “<span class="ft1">Platform</span>”). This Agreement is legally binding between you ("
            <span class="ft1">User</span>", "<span class="ft1">you</span>" or "
            <span class="ft1">your</span>") and Lifestyle A Team Ltd ("
            <span class="ft1">Lifestyle A Team Ltd</span>", "<span class="ft1">we</span>", "
            <span class="ft1">us</span>" or "<span class="ft1">our</span>"). By accessing and using
            the Application and Services, you acknowledge that you have read, understood, and agree
            to be bound by the terms of this Agreement.
          </p>
          <p class="p2 ft2">
            If you are entering into this Agreement on behalf of a business or other legal entity,
            you represent that you have the authority to bind such entity to this Agreement, in
            which case the terms "User", "you" or "your" shall refer to such entity. If you do not
            have such authority, or if you do not agree with the terms of this Agreement, you must
            not accept this Agreement and may not access and use the Application and Services.
          </p>
          <p class="p3 ft3">
            You acknowledge that this Agreement is a contract between you and Lifestyle A Team Ltd,
            even though it is electronic and is not physically signed by you, and it governs your
            use of the Application and Services.
          </p>
          <p class="p4 ft0">General Description</p>
          <p class="p5 ft3">
            <span class="ft4">1.</span>
            <span class="ft5">
              Witness is Platform bringing together trainers, organizations and other professionals
              from the wellness industry (The: “
            </span>
            <span class="ft6">Trainers</span>” “<span class="ft6">Coaches</span>”) and trainees (or
            training groups) where they can interact, buy and sell services, set goals, set training
            plans over time and get feedback on performance.
          </p>
          <p class="p6 ft8">
            <span class="ft4">2.</span>
            <span class="ft7">
              Trainers using the platform can build personal training plans for their trainees
              according to their profile and goals. The Platform provides{' '}
            </span>
            <nobr>built-in</nobr> tools of a high and professional standard for building training
            programs, monitoring performance and stay in touch with the trainees.
          </p>
          <p class="p7 ft10">
            <span class="ft4">3.</span>
            <span class="ft9">
              Trainees may request and obtain professional services and/or buy training programs
              from the Trainers according to the prices and tariffs shown on such Trainer’s Profile.
            </span>
          </p>
          <p class="p8 ft10">
            <span class="ft4">4.</span>
            <span class="ft9">
              Lifestyle A Team Ltd itself has no responsibility or liability for any services
              provided by the Trainers to the Trainees within the Witness Service.
            </span>
          </p>
          <p class="p9 ft12">
            <span class="ft4">5.</span>
            <span class="ft11">
              Lifestyle A Team Ltd is a meeting place between Trainees and eligible Trainers and the
              Witness Service is not an employment or matching agency. Except as specifically stated
              herein, the Witness Service does not engage as a referral, matching or placement
              service and does not provide, refer, place, offer or seek to obtain employment or
              engagements for any of its Users.
            </span>
          </p>
        </div>
      </div>
      <div id="page_2">
        <div id="id2_1">
          <p class="p11 ft0">Using our Services</p>
          <p class="p12 ft4">
            <span class="ft4">6.</span>
            <span class="ft14">
              Registering our Services is free for both Trainers and Trainees.
            </span>
          </p>
          <p class="p13 ft4">
            <span class="ft4">7.</span>
            <span class="ft14">
              Trainers that will want to sell their services using the Platform shall be charged
              with a monthly fee
            </span>
          </p>
          <p class="p14 ft10">
            (the “<span class="ft15">Fee</span>”) + monthly commission that is a percentage out of
            their monthly revenues at the platform (the “<span class="ft15">Commission</span>”) as
            described in the <span class="ft16">Payment Processing and Fees</span> section below.
          </p>
          <p class="p15 ft3">
            <span class="ft4">8.</span>
            <span class="ft5">Trainees can purchase periodic training plans (the “</span>
            <span class="ft6">Periodic Plans</span>”) and/or other services from Trainers through
            the Platform. Purchasing is possible using a valid payment method. The price of the
            services and/or the Periodic Plans is determined by the Trainers and is visible on the
            purchase page.
          </p>
          <p class="p16 ft18">
            <span class="ft4">9.</span>
            <span class="ft17">Trainers</span>
          </p>
          <p class="p17 ft10">
            <span class="ft4">9.1.</span>
            <span class="ft19">
              Trainers may be eligible to access and use the Platform only after submitting their
              details, as requested by us, and subject to our approval.
            </span>
          </p>
          <p class="p18 ft8">
            <span class="ft4">9.2.</span>
            <span class="ft20">
              Once approved, Trainer will get access to a customized dashboard where he can update
              his personal and professional info, set pricing to his services, manage his billing,
              create communities and much more.
            </span>
          </p>
          <p class="p19 ft3">
            <span class="ft4">9.3.</span>
            <span class="ft21">
              Witness shall have sole and absolute discretion regarding the eligibility of any
              Trainer to access, use and offer any Services through the Witness Service, and does
              not guarantee that any Trainer will be presented on the Witness Service for any
              minimum period of time or at all.
            </span>
          </p>
          <p class="p20 ft3">
            <span class="ft4">9.4.</span>
            <span class="ft21">
              We reserves the right to remove any Trainer from the Service, limit the scope and/or
              type of services a Trainer may offer through the Platform and to require a Trainer to
              remove User Content included in its Profile, at our discretion. We shall not be
              obligated to provide any explanations regarding our decisions in this respect.
            </span>
          </p>
          <p class="p21 ft4">
            <span class="ft4">9.5.</span>
            <span class="ft22">
              By using the Platform, you confirm, undertake, acknowledge, and warrant that:
            </span>
          </p>
          <p class="p22 ft4">
            <span class="ft23">•</span>
            <span class="ft24">
              You have a valid professional diploma from a certified institution.
            </span>
          </p>
          <p class="p23 ft4">
            <span class="ft23">•</span>
            <span class="ft24">You have a valid professional liability insurance.</span>
          </p>
          <p class="p24 ft26">
            <span class="ft23">•</span>
            <span class="ft25">
              All the information provided by you to us and in your Profile is true and correct. You
              are solely responsible for editing and updating such information on your Profile. You
              shall keep any User Content in your Profile{' '}
            </span>
            <nobr>up-to-date</nobr> with no broken links or misinformation. You are responsible for
            removing from your Profile any User Content you are not authorized to use anymore. Any
            information you provide must be true and up to date.
          </p>
          <p class="p25 ft26">
            <span class="ft23">•</span>
            <span class="ft25">
              You have the full right and authority to present and publish the User Content
              presented in your Profile (including, without limitation, links and{' '}
            </span>
            <nobr>third-party</nobr> websites). You must not submit, upload, post, display or share
            any User Content that you do not have the right to copy, use, link to or share for the
            purpose of your use of the Platform. You shall be solely liable for any damages
            resulting from any infringement of copyrights,
          </p>
        </div>
      </div>
      <div id="page_3">
        <div id="id3_1">
          <p class="p26 ft10">
            trademarks or other intellectual property rights, or any other harm resulting from your
            uploading, posting or publishing User Content to the Platform.
          </p>
          <p class="p27 ft3">
            <span class="ft23">•</span>
            <span class="ft27">
              You shall abide by fair business practices and ethics in any dealings with any
              Trainee. You should act fairly and appropriately with your clients.
            </span>
          </p>
          <p class="p28 ft2">
            <span class="ft23">•</span>
            <span class="ft28">
              We are not an employment service and does not serve as an employer of any Trainer. As
              such, Trainers are solely responsible for any tax, withholding or reporting
              obligations. You understand and agree that if we are found to be liable for any tax,
              withholding or reporting obligation in connection with any services provided or
              received by you, you will immediately reimburse and indemnify us for all costs,
              expenses and liabilities (including any interest and penalties) relating to the same.
            </span>
          </p>
          <p class="p29 ft2">
            <span class="ft23">•</span>
            <span class="ft29">
              Any information communicated to you about a Trainee via the Platform shall be conveyed
              in conformity with the information provided to us by such Trainee. We are not
              responsible for the truthfulness and validity of information provided by any Trainee.
              We are not responsible for information provided by third parties.
            </span>
          </p>
          <p class="p30 ft4">
            <span class="ft23">•</span>
            <span class="ft24">
              Each Trainer may have only one Profile presented on the Platform.
            </span>
          </p>
          <p class="p31 ft3">
            <span class="ft23">•</span>
            <span class="ft27">
              We reserve the right to remove any Trainer from the Platform or limit the services a
              Trainer may offer through the Platform without notice, for any reason.
            </span>
          </p>
          <p class="p13 ft18">
            <span class="ft4">10.</span>
            <span class="ft30">Trainees</span>
          </p>
          <p class="p32 ft4">As a Trainee using our Platform, you agree and acknowledge that:</p>
          <p class="p33 ft3">
            <span class="ft4">10.1.</span>
            <span class="ft31">
              The Content, including training and nutrition programs, provided through the Service,
              is for entertainment purposes only. We recommend that you consult a physician before
              beginning any exercise program or diet. We are not a licensed medical care provider.
              You should understand that participating in any exercise program or diet can result in
              physical injury, and you agree to do so at your own risk. The findings and opinions of
              authors and Trainers expressed herein are those of the author and do not necessarily
              state or reflect those of Witness or Lifestyle A team Ltd.
            </span>
          </p>
          <p class="p34 ft33">
            <span class="ft4">10.2.</span>
            <span class="ft32">If you have knowledge of any </span>
            <nobr>pre-existing</nobr> medical conditions, we advise you to seek medical advice from
            a doctor urgently before using the Services. This applies in particular if you have
            knowledge of one or more of the following medical complaints/conditions/procedures:
          </p>
          <p class="p35 ft4">
            <span class="ft23">•</span>
            <span class="ft24">cardiovascular disease,</span>
          </p>
          <p class="p23 ft4">
            <span class="ft23">•</span>
            <span class="ft24">lung or respiratory disease (including asthma),</span>
          </p>
          <p class="p36 ft4">
            <span class="ft23">•</span>
            <span class="ft24">spinal and/or joint problems,</span>
          </p>
          <p class="p36 ft4">
            <span class="ft23">•</span>
            <span class="ft24">neuromuscular disease,</span>
          </p>
          <p class="p23 ft4">
            <span class="ft23">•</span>
            <span class="ft24">surgical procedures,</span>
          </p>
          <p class="p36 ft4">
            <span class="ft23">•</span>
            <span class="ft24">any other health issues</span>
          </p>
        </div>
      </div>
      <div id="page_4">
        <p class="p37 ft8">
          <span class="ft4">10.3.</span>
          <span class="ft34">
            In case of the Services related to nutrition, you are responsible for verifying that the
            foods and nutrients recommended as part of the programs or guide do not contain any
            ingredients or contents to which you are allergic or which may cause food intolerance.
          </span>
        </p>
        <p class="p38 ft10">
          <span class="ft4">10.4.</span>
          <span class="ft35">
            In addition, our female Trainees should note that pregnant women and{' '}
          </span>
          <nobr>breast-feeding</nobr> mothers should seek medical advice from a doctor before using
          the Services
        </p>
        <p class="p39 ft3">
          <span class="ft4">10.5.</span>
          <span class="ft31">
            We are not the employer of any Trainer, nor do we supervise, direct or control the
            Trainer’s work or services, in any manner. We do not provide any training or equipment
            to the Trainers, and has no control over the quality, timing, legality, or any other
            aspect whatsoever of the services provided by the Trainer.
          </span>
        </p>
        <p class="p40 ft10">
          <span class="ft4">10.6.</span>
          <span class="ft35">
            Without derogating from the above- in case of Trainer failed to provide a service that
            you paid for, we will refund you- in accordance with our refund policy.
          </span>
        </p>
        <p class="p41 ft10">
          <span class="ft4">10.7.</span>
          <span class="ft35">
            Each Trainer provides you services as an independent contractor and is not an employee,
            joint venturer, partner, agent, or franchisee of us for any purpose whatsoever.
          </span>
        </p>
        <p class="p42 ft3">
          <span class="ft4">10.8.</span>
          <span class="ft31">
            Any listing of a Trainer on the Platform shall be according to the information and
            Profile provided by such Trainer. We are not responsible for the truthfulness and
            validity of information presented by any Trainer. We do not have any obligation to
            independently verify any information provided to it by any Trainer, their experience or
            training, and shall not be responsible or liable for any representation or undertaking
            made by any Trainer on the Platform.
          </span>
        </p>
        <p class="p43 ft10">
          <span class="ft4">10.9.</span>
          <span class="ft35">
            You are responsible for providing to us and such Trainers you contact via the Platform,
            accurate, current and complete information in connection with your request for services.
          </span>
        </p>
        <p class="p44 ft3">
          <span class="ft4">10.10.</span>
          <span class="ft36">
            In no event shall any reference by the Platform to any Trainer be construed as an
            approval or endorsement by us of such Trainers or of any services provided by them.
            Likewise, a link to any Trainer’s website does not imply that we endorse or accept any
            responsibility for the content or use of such website. As with the purchase of a product
            or service through any medium or in any environment, you are responsible for exercising
            appropriate judgment and caution.
          </span>
        </p>
        <p class="p45 ft3">
          <span class="ft4">10.11.</span>
          <span class="ft36">
            You agree to receive from time to time messages and promotional materials from us by e-
            mail or any other contact form you may provide us with (including your phone number for
            calls or text messages), including, without limitation, messages featuring Profiles of
            various Trainers. If you do not wish to receive such promotional materials or notices,
            please notify us at any time.
          </span>
        </p>
        <p class="p46 ft3">
          <span class="ft4">11.</span>
          <span class="ft37">
            Other than providing the Platform and the payment processing services we are not a party
            to any interaction between the Trainers and the Trainees. Trainees contract for services
            directly with the Trainers. We do not take responsibility, in any way or manner, for the
            quality of the services provided to any Trainee by any Trainer.
          </span>
        </p>
      </div>
      <div id="page_5">
        <div id="id5_1">
          <p class="p10 ft0">Payment Processing and Fees</p>
          <p class="p12 ft4">
            <span class="ft4">12.</span>
            <span class="ft38">The Platform supports payment between Trainers and Trainees.</span>
          </p>
          <p class="p48 ft10">
            <span class="ft4">13.</span>
            <span class="ft39">Monthly Fee </span>- Trainers’ Fee shall be as indicated in the
            Platform and will be charged each month, on a recuring basis, from the payment method,
            provided by the Trainer.
          </p>
          <p class="p9 ft33">
            <span class="ft4">14.</span>
            <span class="ft40">Commission </span>- our Commission shall be calculated as a
            percentage out of the Trainer’s monthly revenues at the platform. The exact percentage
            shall be agreed between the trainers and us in a separate agreement or will be indicated
            in the Trainer’s Dashboard, according to our discretion.
          </p>
          <p class="p49 ft12">
            <span class="ft4">15.</span>
            <span class="ft41">
              Trainers are responsible for paying any direct or indirect taxes, including any GST,
              VAT or otherwise, which may apply to them depending on residency, location or the
              billing information they provide in their account settings. Trainers represent and
              warrant that they comply, and will comply at all times, with their obligations under
              income tax provisions in their jurisdiction.
            </span>
          </p>
          <p class="p6 ft43">
            <span class="ft4">16.</span>
            <span class="ft42">Appointment as Limited Payment Collection Agent:</span> Trainers
            hereby appoints Lifestyle A team Ltd as Trainer’s limited authorized payment collection
            agent solely for the purpose of accepting payments (via its Payment Services Provider)
            from Trainee, and remitting those payments to Trainer. Trainer agrees that payment from
            Trainee to Lifestyle A team Ltd shall be considered the same as a made directly to
            Trainer. Trainee’s payment obligation to Trainer will be satisfied upon receipt of
            payment by Lifestyle A team Ltd (by its Payment Services Provider), and Lifestyle A team
            Ltd (via its Payment Services Provider) is responsible for remitting the funds to the
            Trainee in the manner described in these Terms. In the event that Lifestyle A team Ltd
            (via Payment Services Provider) does not remit any such amounts to Trainer, the Trainer
            will have recourse only against Lifestyle A team Ltd and not the Trainee directly.
            Trainer agrees that Lifestyle A team Ltd may describe or otherwise reflect the terms
            contained herein in any terms of service, receipts, disclosures, or notices including,
            but not limited to, receipts provided to Trainees that Lifestyle A team Ltd may deem
            necessary or prudent.
          </p>
          <p class="p50 ft3">
            <span class="ft4">17.</span>
            <span class="ft37">
              We partner with Payment Services Providers for purposes of collecting payments from
              Trainees, transferring such payments from Trainees to Trainers, and holding funds in
              connection with account’s Balances. All payments services in connection with the
              withdrawal of funds on the Platform are performed by OUR Payment Services Providers
              and not by us.
            </span>
          </p>
          <p class="p51 ft3">
            <span class="ft4">18.</span>
            <span class="ft37">
              Transfer of funds to Trainers shall be made on a monthly basis up to the 9
            </span>
            <span class="ft44">th </span>day of each month, with regard to the funds collected on
            the previous month. Funds will be transferred to the bank account which its details were
            provided by the Trainer.
          </p>
          <p class="p51 ft3">
            <span class="ft4">19.</span>
            <span class="ft37">
              Trainees that would like to purchase services from the Trainers may not offer Trainers
              to pay, or make payment using any method other than through the Platform. In case you
              have been asked to use an alternative payment method by one of our Trainers, please
              report it immediately to customer support.
            </span>
          </p>
        </div>
      </div>
      <div id="page_6">
        <p class="p52 ft10">
          <span class="ft4">20.</span>
          <span class="ft45">
            Payments in our Platform can be made using a valid Credit/Debit Card (excluding Diners
            and American Express).
          </span>
        </p>
        <p class="p53 ft3">
          <span class="ft4">21.</span>
          <span class="ft37">
            To protect against fraud, unauthorized transactions (such as money laundering), claims,
            or other liabilities, payment information in connection with withdrawals is collected by
            our Payment Services Providers. Payment Services Providers may also collect such other
            information as necessary for the purpose of processing payments. We are not exposed to
            the payment information provided to Payment Services Providers, and this information is
            subject to the privacy policy applicable to the Payment Service Provider. Please see our
            Privacy Policy for more information.
          </span>
        </p>
        <p class="p54 ft3">
          <span class="ft4">22.</span>
          <span class="ft37">
            By using any payment method and/or providing payment details for making purchases on the
            Platform, you represent and warrant that: (a) you are legally authorized to provide such
            information; (b) you are legally authorized or have permission to make payments using
            the payment method(s); (c) if you are an employee or agent of a company or person that
            owns the payment method, you are authorized by that company or person to use the payment
            method to make payments on our Platform; and (d) such actions do not violate any
            applicable law
          </span>
        </p>
        <p class="p55 ft2">
          <span class="ft4">23.</span>
          <span class="ft46">
            We reserve the right to change services and services’ pricing at any time. We also
            reserve the right to refuse any order you place with us. We may, in our sole discretion,
            limit or cancel quantities purchased per person, per household or per order. These
            restrictions may include orders placed by or under the same Trainee account, the same
            credit/debit card, and/or orders that use the same billing and/or shipping address. In
            the event that we make a change to or cancel an order, we may attempt to notify you by
            contacting the{' '}
          </span>
          <nobr>e-mail</nobr> and/or billing address/phone number provided at the time the order was
          made.
        </p>
        <p class="p56 ft26">
          <span class="ft4">24.</span>
          <span class="ft47">
            You agree and acknowledge that all payments must be processed through the Platform.
            Without derogating from our rights, under this Agreement, any payment that will be made
            between Trainers and Trainees, not through the Platform shall entitle us with additional
            penalty fee equal to 50% of the rate of such service, which will be charged from both
            the Trainer and the Trainee.
          </span>
        </p>
        <p class="p57 ft0">Cancellation Policy</p>
        <p class="p58 ft48">Trainers</p>
        <p class="p59 ft3">
          <span class="ft4">25.</span>
          <span class="ft37">
            Trainers may cancel their membership with 30 days prior written notice to our customer
            support at support@witnesstofitness.com, provided that the Trainers has delivered in
            full all of the services that were purchased from them.
          </span>
        </p>
      </div>
      <div id="page_7">
        <div id="id7_1">
          <p class="p10 ft48">Trainees</p>
          <p class="p61 ft50">
            <span class="ft4">26.</span>
            <span class="ft49">
              Trainees may cancel their Periodic Plans within 48 hours from purchasing, provided
              that they didn’t yet received the first Training Plan. In such case Trainees shall be
              entitled to a full refund.
            </span>
          </p>
          <p class="p62 ft12">
            <span class="ft4">27.</span>
            <span class="ft41">
              Cancellation not according to section 26 is subject to 14 days prior notice (the “
            </span>
            <span class="ft51">Prior Notice</span>”) and shall entitle the Trainee with a relative
            refund against the services/products he/she didn’t yet receive. It shall be clarified
            that during the Prior Notice period, all charges shall continue to apply.
          </p>
          <p class="p63 ft0">Accounts and membership</p>
          <p class="p64 ft2">
            <span class="ft4">28.</span>
            <span class="ft46">
              Trainers must be at least 18 years of age and Trainees must be at least 16 years of
              age and have their legal guardian approval to use the Application and Services. By
              using the Application and Services and by agreeing to this Agreement you warrant and
              represent that you meet these conditions.
            </span>
          </p>
          <p class="p46 ft2">
            <span class="ft4">29.</span>
            <span class="ft46">
              If you create an account in the Application, you are responsible for maintaining the
              security of your account and you are fully responsible for all activities that occur
              under the account and any other actions taken in connection with it. We may, but have
              no obligation to, monitor and review new accounts before you may sign in and start
              using the Services.
            </span>
          </p>
          <p class="p50 ft3">
            <span class="ft4">30.</span>
            <span class="ft37">
              Providing false contact information of any kind may result in the termination of your
              account. You must immediately notify us of any unauthorized uses of your account or
              any other breaches of security.
            </span>
          </p>
          <p class="p8 ft10">
            <span class="ft4">31.</span>
            <span class="ft45">
              We will not be liable for any acts or omissions by you, including any damages of any
              kind incurred as a result of such acts or omissions.
            </span>
          </p>
          <p class="p15 ft2">
            <span class="ft4">32.</span>
            <span class="ft46">
              We may suspend, disable, or delete your account (or any part thereof) if we determine
              that you have violated any provision of this Agreement or that your conduct or content
              would tend to damage our reputation and goodwill. If we delete your account for the
              foregoing reasons, you may not{' '}
            </span>
            <nobr>re-register</nobr> for our Services. We may block your email address and Internet
            protocol address to prevent further registration.
          </p>
          <p class="p57 ft0">User content</p>
          <p class="p65 ft2">
            <span class="ft4">33.</span>
            <span class="ft46">
              We do not own any data, information or material (collectively, " User Content") that
              you submit in the Application in the course of using the Service. You shall have sole
              responsibility for the accuracy, quality, integrity, legality, reliability,
              appropriateness, and intellectual property ownership or right to use of all submitted
              Content.
            </span>
          </p>
          <p class="p49 ft53">
            <span class="ft4">34.</span>
            <span class="ft52">
              We may, but have no obligation to, monitor and review the Content in the Application
              submitted or created using our Services by you. You grant us permission to access,
              copy, distribute, store,
            </span>
          </p>
        </div>
      </div>
      <div id="page_8">
        <div id="id8_1">
          <p class="p66 ft54">
            transmit, reformat, display and perform the Content of your user account solely as
            required for the purpose of providing the Services to you.
          </p>
          <p class="p6 ft26">
            <span class="ft4">35.</span>
            <span class="ft47">
              Without limiting any of those representations or warranties, we have the right, though
              not the obligation, to, in our own sole discretion, refuse or remove any Content that,
              in our reasonable opinion, violates any of our policies or is in any way harmful or
              objectionable. You also grant us the license to use, reproduce, adapt, modify, publish
              or distribute the Content created by you or stored in your user account for
              commercial, marketing or any similar purpose.
            </span>
          </p>
          <p class="p67 ft0">Accuracy of information</p>
          <p class="p68 ft26">
            <span class="ft4">36.</span>
            <span class="ft47">
              Occasionally there may be information in the Application that contains typographical
              errors, inaccuracies or omissions that may relate to product descriptions, pricing,
              availability, promotions and offers. We reserve the right to correct any errors,
              inaccuracies or omissions, and to change or update information or cancel orders if any
              information in the Application or Services is inaccurate at any time without prior
              notice (including after you have submitted your order).
            </span>
          </p>
          <p class="p69 ft2">
            <span class="ft4">37.</span>
            <span class="ft46">
              We undertake no obligation to update, amend or clarify information in the Application
              including, without limitation, pricing information, except as required by law. No
              specified update or refresh date applied in the Application should be taken to
              indicate that all information in the Application or Services has been modified or
              updated.
            </span>
          </p>
          <p class="p63 ft0">Third party services</p>
          <p class="p70 ft2">
            <span class="ft4">38.</span>
            <span class="ft46">
              If you decide to enable, access or use third party services, be advised that your
              access and use of such other services are governed solely by the terms and conditions
              of such other services, and we do not endorse, are not responsible or liable for, and
              make no representations as to any aspect of such other services, including, without
              limitation, their content or the manner in which they handle data (including your
              data) or any interaction between you and the provider of such other services.
            </span>
          </p>
          <p class="p71 ft26">
            <span class="ft4">39.</span>
            <span class="ft47">
              You irrevocably waive any claim against Lifestyle A Team Ltd with respect to such
              other services. Lifestyle A Team Ltd is not liable for any damage or loss caused or
              alleged to be caused by or in connection with your enablement, access or use of any
              such other services, or your reliance on the privacy practices, data security
              processes or other policies of such other services. You may be required to register
              for or log into such other services on their respective platforms.
            </span>
          </p>
          <p class="p72 ft10">
            <span class="ft4">40.</span>
            <span class="ft45">
              By enabling any other services, you are expressly permitting Lifestyle A Team Ltd to
              disclose your data as necessary to facilitate the use or enablement of such other
              service.
            </span>
          </p>
        </div>
      </div>
      <div id="page_9">
        <div id="id9_1">
          <p class="p10 ft0">Backups</p>
          <p class="p73 ft2">
            <span class="ft4">41.</span>
            <span class="ft46">
              We perform regular backups of the Content, however, these backups are for our own
              administrative purposes only and are in no way guaranteed. You are responsible for
              maintaining your own backups of your data. We do not provide any sort of compensation
              for lost or incomplete data in the event that backups do not function properly. We
              will do our best to ensure complete and accurate backups but assume no responsibility
              for this duty.
            </span>
          </p>
          <p class="p57 ft0">Advertisements</p>
          <p class="p70 ft2">
            <span class="ft4">42.</span>
            <span class="ft46">
              During your use of the Application and Services, you may enter into correspondence
              with or participate in promotions of advertisers or sponsors showing their goods or
              services through the Application and Services. Any such activity, and any terms,
              conditions, warranties or representations associated with such activity, is solely
              between you and the applicable third party. We shall have no liability, obligation or
              responsibility for any such correspondence, purchase or promotion between you and any
              such third party.
            </span>
          </p>
          <p class="p4 ft0">Links to other resources</p>
          <p class="p74 ft12">
            <span class="ft4">43.</span>
            <span class="ft41">
              Although the Application and Services may link to other resources (such as websites,
              applications, etc.), we are not, directly or indirectly, implying any approval,
              association, sponsorship, endorsement, or affiliation with any linked resource, unless
              specifically stated herein.
            </span>
          </p>
          <p class="p75 ft26">
            <span class="ft4">44.</span>
            <span class="ft47">
              Some of the links in the Application may be "affiliate links". This means if you click
              on the link and purchase an item, Lifestyle A Team Ltd will receive an affiliate
              commission. We are not responsible for examining or evaluating, and we do not warrant
              the offerings of, any businesses or individuals or the content of their resources. We
              do not assume any responsibility or liability for the actions, products, services, and
              content of any other third parties. You should carefully review the legal statements
              and other conditions of use of any resource which you access through a link in the
              Application and Services. Your linking to any other{' '}
            </span>
            <nobr>off-site</nobr> resources is at your own risk.
          </p>
          <p class="p76 ft0">Prohibited uses</p>
          <p class="p77 ft26">
            <span class="ft4">45.</span>
            <span class="ft47">
              In addition to other terms as set forth in the Agreement, you are prohibited from
              using the Application and Services or Content: (a) for any unlawful purpose; (b) to
              solicit others to perform or participate in any unlawful acts; (c) to violate any
              international, federal, provincial or state regulations, rules, laws, or local
              ordinances; (d) to infringe upon or violate our intellectual property rights or the
              intellectual property rights of others; (e) to harass, abuse, insult, harm, defame,
              slander, disparage, intimidate, or discriminate based on gender, sexual orientation,
              religion, ethnicity, race, age, national origin, or disability; (f) to submit false or
              misleading information; (g)
            </span>
          </p>
        </div>
      </div>
      <div id="page_10">
        <div id="id10_1">
          <p class="p78 ft12">
            to upload or transmit viruses or any other type of malicious code that will or may be
            used in any way that will affect the functionality or operation of the Application and
            Services, third party products and services, or the Internet; (h) to spam, phish, pharm,
            pretext, spider, crawl, or scrape;
          </p>
          <p class="p79 ft2">
            <span class="ft55">(i)</span>
            <span class="ft56">
              for any obscene or immoral purpose; or (j) to interfere with or circumvent the
              security features of the Application and Services, third party products and services,
              or the Internet. We reserve the right to terminate your use of the Application and
              Services for violating any of the prohibited uses.
            </span>
          </p>
          <p class="p63 ft0">Intellectual property rights</p>
          <p class="p77 ft26">
            <span class="ft4">46.</span>
            <span class="ft47">
              "Intellectual Property Rights" means all present and future rights conferred by
              statute, common law or equity in or in relation to any copyright and related rights,
              trademarks, designs, patents, inventions, goodwill and the right to sue for passing
              off, rights to inventions, rights to use, and all other intellectual property rights,
              in each case whether registered or unregistered and including all applications and
              rights to apply for and be granted, rights to claim priority from, such rights and all
              similar or equivalent rights or forms of protection and any other results of
              intellectual activity which subsist or will subsist now or in the future in any part
              of the world.
            </span>
          </p>
          <p class="p69 ft2">
            <span class="ft4">47.</span>
            <span class="ft46">
              This Agreement does not transfer to you any intellectual property owned by Lifestyle A
              Team Ltd or third parties, and all rights, titles, and interests in and to such
              property will remain (as between the parties) solely with Lifestyle A Team Ltd. All
              trademarks, service marks, graphics and logos used in connection with the Application
              and Services, are trademarks or registered trademarks of Lifestyle A Team Ltd or its
              licensors. Other trademarks, service marks, graphics and logos used in connection with
              the Application and Services may be the trademarks of other third parties. Your use of
              the Application and Services grants you no right or license to reproduce or otherwise
              use any of Lifestyle A Team Ltd or third party trademarks.
            </span>
          </p>
          <p class="p80 ft0">Disclaimer of warranty</p>
          <p class="p77 ft58">
            <span class="ft4">48.</span>
            <span class="ft57">
              You agree that such Service is provided on an "as is" and "as available" basis and
              that your use of the Application and Services is solely at your own risk. We expressly
              disclaim all warranties of any kind, whether express or implied, including but not
              limited to the implied warranties of merchantability, fitness for a particular purpose
              and{' '}
            </span>
            <nobr>non-infringement.</nobr> We make no warranty that the Services will meet your
            requirements, or that the Service will be uninterrupted, timely, secure, or{' '}
            <nobr>error-free;</nobr> nor do we make any warranty as to the results that may be
            obtained from the use of the Service or as to the accuracy or reliability of any
            information obtained through the Service or that defects in the Service will be
            corrected. You understand and agree that any material and/or data downloaded or
            otherwise obtained through the use of Service is done at your own discretion
          </p>
        </div>
      </div>
      <div id="page_11">
        <div id="id11_1">
          <p class="p78 ft26">
            and risk and that you will be solely responsible for any damage or loss of data that
            results from the download of such material and/or data. We make no warranty regarding
            any goods or services purchased or obtained through the Service or any transactions
            entered into through the Service unless stated otherwise. No advice or information,
            whether oral or written, obtained by you from us or through the Service shall create any
            warranty not expressly made herein.
          </p>
          <p class="p81 ft0">Limitation of liability</p>
          <p class="p82 ft60">
            <span class="ft4">49.</span>
            <span class="ft59">
              To the fullest extent permitted by applicable law, in no event will Lifestyle A Team
              Ltd, its affiliates, directors, officers, employees, agents, suppliers or licensors be
              liable to any person for any indirect, incidental, special, punitive, cover or
              consequential damages (including, without limitation, damages for lost profits,
              revenue, sales, goodwill, use of content, impact on business, business interruption,
              loss of anticipated savings, loss of business opportunity) however caused, under any
              theory of liability, including, without limitation, contract, tort, warranty, breach
              of statutory duty, negligence or otherwise, even if the liable party has been advised
              as to the possibility of such damages or could have foreseen such damages. To the
              maximum extent permitted by applicable law, the aggregate liability of Lifestyle A
              Team Ltd and its affiliates, officers, employees, agents, suppliers and licensors
              relating to the services will be limited to an amount greater of one dollar or any
              amounts actually paid by you to Lifestyle A Team Ltd for the prior one month period
              prior to the first event or occurrence giving rise to such liability. The limitations
              and exclusions also apply if this remedy does not fully compensate you for any losses
              or fails of its essential purpose.
            </span>
          </p>
          <p class="p83 ft0">Indemnification</p>
          <p class="p70 ft2">
            <span class="ft4">50.</span>
            <span class="ft46">
              You agree to indemnify and hold Lifestyle A Team Ltd and its affiliates, directors,
              officers, employees, agents, suppliers and licensors harmless from and against any
              liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred
              in connection with or arising from any third party allegations, claims, actions,
              disputes, or demands asserted against any of them as a result of or relating to your
              Content, your use of the Application and Services or any willful misconduct on your
              part.
            </span>
          </p>
          <p class="p4 ft0">Severability</p>
          <p class="p77 ft26">
            <span class="ft4">51.</span>
            <span class="ft47">
              All rights and restrictions contained in this Agreement may be exercised and shall be
              applicable and binding only to the extent that they do not violate any applicable laws
              and are intended to be limited to the extent necessary so that they will not render
              this Agreement illegal, invalid or unenforceable. If any provision or portion of any
              provision of this Agreement shall be held to be
            </span>
          </p>
        </div>
      </div>
      <div id="page_12">
        <div id="id12_1">
          <p class="p84 ft2">
            illegal, invalid or unenforceable by a court of competent jurisdiction, it is the
            intention of the parties that the remaining provisions or portions thereof shall
            constitute their agreement with respect to the subject matter hereof, and all such
            remaining provisions or portions thereof shall remain in full force and effect.
          </p>
          <p class="p85 ft0">Dispute resolution</p>
          <p class="p82 ft60">
            <span class="ft4">52.</span>
            <span class="ft59">
              The formation, interpretation, and performance of this Agreement and any disputes
              arising out of it shall be governed by the substantive and procedural laws of Israel
              without regard to its rules on conflicts or choice of law and, to the extent
              applicable, the laws of Israel. The exclusive jurisdiction and venue for actions
              related to the subject matter hereof shall be the courts located in Tel Aviv Israel,
              and you hereby submit to the personal jurisdiction of such courts. You hereby waive
              any right to a jury trial in any proceeding arising out of or related to this
              Agreement. The United Nations Convention on Contracts for the International Sale of
              Goods does not apply to this Agreement.
            </span>
          </p>
          <p class="p57 ft0">Assignment</p>
          <p class="p65 ft2">
            <span class="ft4">53.</span>
            <span class="ft46">You may not assign, resell, </span>
            <nobr>sub-license</nobr> or otherwise transfer or delegate any of your rights or
            obligations hereunder, in whole or in part, without our prior written consent, which
            consent shall be at our own sole discretion and without obligation; any such assignment
            or transfer shall be null and void.
          </p>
          <p class="p86 ft54">
            <span class="ft4">54.</span>
            <span class="ft61">
              We are free to assign any of its rights or obligations hereunder, in whole or in part,
              to any third party as part of the sale of all or substantially all of its assets or
              stock or as part of a merger.
            </span>
          </p>
          <p class="p85 ft0">Changes and amendments</p>
          <p class="p64 ft2">
            <span class="ft4">55.</span>
            <span class="ft46">
              We reserve the right to modify this Agreement or its terms relating to the Application
              and Services at any time, effective upon posting of an updated version of this
              Agreement in the Application. When we do, we will revise the updated date at the
              bottom of this page. We may also provide notice to you in other ways in our
              discretion, such as through contact information you have provided and/or by posting a
              notice in the Application.
            </span>
          </p>
          <p class="p72 ft10">
            <span class="ft4">56.</span>
            <span class="ft62">
              Continued use of the Application and Services after any such changes shall constitute
              your consent to such changes.
            </span>
          </p>
          <p class="p87 ft0">Acceptance of these terms</p>
          <p class="p74 ft50">
            <span class="ft4">57.</span>
            <span class="ft49">
              You acknowledge that you have read this Agreement and agree to all its terms and
              conditions. By accessing and using the Application and Services you agree to be bound
              by this Agreement. If you
            </span>
          </p>
        </div>
      </div>
      <div id="page_13">
        <div id="id13_1">
          <p class="p66 ft54">
            do not agree to abide by the terms of this Agreement, you are not authorized to access
            or use the Application and Services.
          </p>
          <p class="p85 ft0">Contacting us</p>
          <p class="p88 ft54">
            <span class="ft4">58.</span>
            <span class="ft61">
              If you would like to contact us to understand more about this Agreement or wish to
              contact us concerning any matter relating to it, you may send an email to
              support@witnesstofitness.com
            </span>
          </p>
          <p class="p89 ft4">This document was last updated on Thursday, 05 November 2020</p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
