import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  getNotificationsQuery,
  notificationMarkAsReadMutation,
  notificationMarkAllAsReadMutation,
  meQuery,
  getUnreadCountQuery,
} from '@witness/graphql';
import { OneSignal } from './OneSignal';

export const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
  const { data: meData, loading: meLoading } = useQuery(meQuery);
  const { data: unreadCount, refetch: refetchUnreadCount } = useQuery(getUnreadCountQuery);
  const [notifications, setNotifications] = useState();
  const { refetch } = useQuery(getNotificationsQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (val) => {
      setNotifications(val?.getNotifications);
      refetchUnreadCount();
    },
  });
  const safeFetch = useCallback(async () => {
    try {
      await refetch();
    } catch (err) {
      console.log('notification refetch:', err);
    }
  }, [refetch]);

  useEffect(() => {
    if (!meLoading) {
      if (meData?.getCurrentUser?.user) {
        OneSignal.push(() => {
          OneSignal.showSlidedownPrompt();

          OneSignal.on('popoverAllowClick', () => {
            OneSignal.registerForPushNotifications();
          });
        });

        OneSignal.push(() => {
          OneSignal.setDefaultNotificationUrl(window.location.origin);
          OneSignal.sendTag('user_uid', meData.getCurrentUser.user.uid);
          OneSignal.setSubscription(true);
        });

        OneSignal.push(() => {
          OneSignal.on('notificationDisplay', (event) => {
            // new notification received
            setNotifications((curr) => [event?.data, ...curr]);
            console.log('notif event', event);
            console.warn('OneSignal notification displayed:', event);
          });
        });

        OneSignal.push(() => {
          OneSignal.addListenerForNotificationOpened((data) => {
            // user clicked on notification
            console.log('Received NotificationOpened:');
            console.log(data);
          });
        });

        window.addEventListener('focus', safeFetch);
      } else {
        OneSignal.push(() => {
          OneSignal.setSubscription(false);
          OneSignal.getTags((tags) => {
            OneSignal.deleteTags(Object.keys(tags));
          });
        });

        window.removeEventListener('focus', safeFetch);
      }
    }

    return () => {
      window.removeEventListener('focus', safeFetch);
    };
  }, [meData, meLoading, safeFetch]);

  const [notificationMarkAsRead] = useMutation(notificationMarkAsReadMutation);
  const markAsRead = useCallback(
    async (notification) => {
      await notificationMarkAsRead({
        variables: { uid: notification?.uid },
        notificationMarkAsRead: {
          __typename: 'Mutation',
          updateComment: {
            ...notification,
            __typename: 'OneSignalMessageData ',
            isRead: true,
          },
        },
      });
      safeFetch();
    },
    [notificationMarkAsRead, safeFetch],
  );

  const [notificationMarkAllAsRead] = useMutation(notificationMarkAllAsReadMutation);
  const markAllAsRead = useCallback(async () => {
    await notificationMarkAllAsRead();
    safeFetch();
  }, [notificationMarkAllAsRead, safeFetch]);

  const contextValue = useMemo(
    () => ({ notifications, markAsRead, markAllAsRead, unreadCount: unreadCount?.getUnreadCount }),
    [notifications, markAsRead, markAllAsRead, unreadCount],
  );
  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  );
};
