import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import moment from 'moment';

import CalendarResultsModal from '../calendar-results-modal/CalendarResultsModal';
import WorkoutMessageModal from '../workout-message-modal/WorkoutMessageModal';
import ChooseTemplateModal from '../choose-template-modal/ChooseTemplateModal';
import { CalendarDragProvider } from '../../services/CalendarDragContext';
import AddCalendarModal from '../add-calendar-modal/AddCalendarModal';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import ProgramCalendarDay from './ProgramCalendarDayV3';
import { CalendarType } from '../../constants/enums';
import CustomInput from '../input/CustomInput';
import { useCoachProgram } from '../../hooks';
import Loader from '../loader/Loader';
import {
  CalendarContainer,
  CalendarHeading,
  CalendarBeginning,
  CalendarBeginningDateWrapper,
  CalendarUploadTemplate,
  CalendarUploadIcon,
  CalendarMain,
  CalendarTable,
  CalendarThead,
  CalendarTh,
  CalendarTd,
  CalendarHeadingTable,
  RelativeBox,
  CalendarHeadingTableWrapper,
  WholeWrapper,
  CalendarTableWrapper,
  // CopyWeekButton,
} from './components';
import MeetingModal from '../meeting-modal/MeetingModal';

const ProgramCalendar = ({
  noHeader,
  calendar,
  programLength = 60,
  coachProgramUid,
  programTemplateUid,
  beginningDate,
  refetch,
  loading,
  handleCoachProgramUpdate,
  updateCalendarCache,
  setCalendarDayLoading,
  urlProgramDay,
  urlTraineeUid,
  isOngoing,
} = {}) => {
  const {
    calendarMatrix,
    programDays,
    addRestDay,
    messageDay,
    openMessageModal,
    closeMessageModal,
    copiedWorkout,
    setCopiedWorkout,
    pasteWorkout,
    selectedProgram,
    setSelectedProgram,
    handleProgramDelete,
    selectedMessage,
    setSelectedMessage,
    saveWorkoutDay,
    // dublicateWeek,
    // copiedWeek,
  } = useCoachProgram({
    beginningDate,
    programLength,
    coachProgramUid,
    programTemplateUid,
    calendar,
    refetch,
    updateCalendarCache,
    setCalendarDayLoading,
  });
  const [edittingDay, setEdditingDay] = useState(null);
  const [openResults, setOpenResults] = useState(null);
  const [templateOpenModal, setTemplateOpenModal] = useState(false);
  const [meetingModalContent, setMeetingModalContent] = useState(null);
  const alert = useAlert();
  const todayRef = useRef(null);

  useEffect(() => {
    if (todayRef?.current) {
      setTimeout(() => {
        if (todayRef?.current) {
          todayRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 1000);
    }
  }, [calendarMatrix, todayRef?.current]);

  useEffect(() => {
    setOpenResults(urlProgramDay);
  }, [urlProgramDay]);

  const selectedWorkout = useMemo(() => {
    return calendar?.find(
      (day) => day.type === CalendarType.WORKOUT && day.programDay === edittingDay?.programDay,
    );
  }, [calendar, edittingDay]);

  const closeWorkoutModal = useCallback(() => {
    setEdditingDay(null);
  }, []);
  const closeResultsModal = useCallback(() => {
    setOpenResults(null);
  }, []);
  const sureMessage = useMemo(() => {
    const { WORKOUT, REST } = selectedProgram || {};

    if (WORKOUT) return 'Are you sure to delete this workout?';
    if (REST) return 'Are you sure to delete this rest day?';

    return 'Are you sure to delete this message?';
  }, [selectedProgram]);

  const dateInputValue = useMemo(() => {
    return moment(beginningDate || new Date()).format('YYYY-MM-DD');
  }, [beginningDate]);

  return (
    <WholeWrapper>
      <AddCalendarModal
        day={edittingDay}
        closeModal={closeWorkoutModal}
        existingWorkout={selectedWorkout}
        submitModal={saveWorkoutDay}
        loading={loading}
      />
      {meetingModalContent && (
        <MeetingModal
          initialValues={meetingModalContent}
          closeModal={() => setMeetingModalContent(null)}
          submitModal={console.log}
        />
      )}
      {openResults && (
        <CalendarResultsModal
          day={programDays[openResults]?.WORKOUT}
          urlTraineeUid={urlTraineeUid}
          closeModal={closeResultsModal}
          refetchProgram={refetch}
          loading={loading}
          alert={alert}
        />
      )}
      {templateOpenModal && (
        <ChooseTemplateModal
          programUid={coachProgramUid}
          modalOpen={templateOpenModal}
          closeModal={() => setTemplateOpenModal(false)}
          alert={alert}
        />
      )}
      <WorkoutMessageModal
        day={messageDay}
        closeModal={closeMessageModal}
        existingMessage={selectedMessage}
        coachProgram={coachProgramUid}
        programTemplate={programTemplateUid}
        refetchProgram={refetch}
      />
      <ConfirmModal
        isOpen={!!selectedProgram}
        closeModal={() => setSelectedProgram(null)}
        message={sureMessage}
        handleSubmit={handleProgramDelete}
      />
      {!!calendarMatrix?.[0]?.[6] ? (
        <CalendarContainer>
          {!noHeader && (
            <CalendarHeading>
              <CalendarBeginning>
                Beginning Date
                <CalendarBeginningDateWrapper>
                  <CustomInput
                    type="date"
                    outlined
                    height="4.4rem"
                    borderColor="rgba(74,74,74,0.12)"
                    margin="0 0 0 3.6rem"
                    onChange={handleCoachProgramUpdate}
                    value={dateInputValue}
                  />
                </CalendarBeginningDateWrapper>
              </CalendarBeginning>
              {!isOngoing && (
                <CalendarUploadTemplate onClick={() => setTemplateOpenModal((curr) => !curr)}>
                  Upload a program template
                  <CalendarUploadIcon />
                </CalendarUploadTemplate>
              )}
            </CalendarHeading>
          )}
          <CalendarMain>
            <CalendarHeadingTableWrapper>
              <CalendarHeadingTable>
                <CalendarThead>
                  <tr>
                    <CalendarTh>Sunday</CalendarTh>
                    <CalendarTh>Monday</CalendarTh>
                    <CalendarTh>Tuesday</CalendarTh>
                    <CalendarTh>Wednesday</CalendarTh>
                    <CalendarTh>Thursday</CalendarTh>
                    <CalendarTh>Friday</CalendarTh>
                    <CalendarTh>Saturday</CalendarTh>
                  </tr>
                </CalendarThead>
              </CalendarHeadingTable>
            </CalendarHeadingTableWrapper>
            <RelativeBox>
              <CalendarTableWrapper>
                <CalendarDragProvider refetchCalendar={refetch}>
                  <CalendarTable>
                    <tbody>
                      {calendarMatrix.map((week, i) => (
                        <tr key={i}>
                          {week?.map((day, j) => (
                            <CalendarTd
                              key={j}
                              ref={
                                moment(day?.date, 'DD-MM-YYYY').isSame(new Date(), 'day')
                                  ? todayRef
                                  : undefined
                              }
                            >
                              {/* {j === 0 &&
                                i !== copiedWeek &&
                                (copiedWeek === null ||
                                  moment(day?.date, 'DD-MM-YYYY').isAfter(new Date())) && (
                                  <CopyWeekButton
                                    paste={typeof copiedWeek === 'number'}
                                    className="copy_week_btn"
                                    onClick={() => dublicateWeek(i)}
                                  />
                                )} */}
                              {day ? (
                                <ProgramCalendarDay
                                  day={day}
                                  isToday={moment(day?.date, 'DD-MM-YYYY').isSame(
                                    new Date(),
                                    'day',
                                  )}
                                  firstRow={i === 0}
                                  program={programDays[day.index]}
                                  openModal={
                                    (programDays[day.index]?.WORKOUT?.workout?.status?.filter(
                                      (x) => x?.type !== 'NEW',
                                    )?.length &&
                                      (() => setOpenResults(day.index))) ||
                                    setEdditingDay
                                  }
                                  addRestDay={addRestDay}
                                  openMessage={openMessageModal}
                                  copied={copiedWorkout}
                                  handleCopy={setCopiedWorkout}
                                  pasteWorkout={pasteWorkout}
                                  setSelectedProgram={setSelectedProgram}
                                  setSelectedMessage={setSelectedMessage}
                                  openMeetingModal={() => setMeetingModalContent(day)}
                                  openOnlineMeetingModal={() =>
                                    setMeetingModalContent({ ...day, isOnline: true })
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </CalendarTd>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </CalendarTable>
                </CalendarDragProvider>
              </CalendarTableWrapper>
            </RelativeBox>
          </CalendarMain>
        </CalendarContainer>
      ) : (
        <Loader />
      )}
    </WholeWrapper>
  );
};

export default ProgramCalendar;
