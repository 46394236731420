import {
  getPricesQuery,
  addPricingMutation,
  createOrUpdateAdminCodeMutation,
  deleteAdminCodeMutation,
  meQuery,
} from '@witness/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useState, useEffect } from 'react';

const usePricing = ({ setLoading } = {}) => {
  const { data: programPrices, refetch } = useQuery(getPricesQuery);
  const [defaultPrices, setDefaultPrices] = useState({});

  useEffect(() => {
    const result = {};
    programPrices &&
      programPrices.getPrices &&
      programPrices.getPrices.map((program) => {
        const defaultPrice =
          program.p11 === program.p12 &&
          program.p11 === program.p13 &&
          program.p11 === program.p21 &&
          program.p11 === program.p22 &&
          program.p11 === program.p23 &&
          program.p11 === program.p31 &&
          program.p11 === program.p32 &&
          program.p11 === program.p33
            ? program.p11
            : 'Custom';
        result[program?.uid] = defaultPrice;
        return 1;
      });
    setDefaultPrices(result);
  }, [programPrices]);

  const [addPricing] = useMutation(addPricingMutation);

  const addProgramPricing = async (values) => {
    setLoading(true);
    await addPricing({
      variables: {
        record: {
          ...values,
        },
      },
    });
    refetch();
    setLoading(false);
  };

  const [createOrUpdateAdminCode] = useMutation(createOrUpdateAdminCodeMutation, {
    refetchQueries: [{ query: meQuery }],
  });
  const createAdminCode = (values) => {
    const { uid, title, description, discountType, amount, validUntil, available } = values;
    createOrUpdateAdminCode({
      variables: {
        record: {
          uid,
          title,
          description,
          validUntil,
          discountType: discountType?.value,
          amount: Number(amount),
          available: Number(available),
        },
      },
    });
  };

  const [deleteAdminCode] = useMutation(deleteAdminCodeMutation, {
    refetchQueries: [{ query: meQuery }],
  });
  const deleteCoupon = (uid) => {
    deleteAdminCode({
      variables: {
        record: {
          uid,
        },
      },
    });
  };

  return { programPrices, addProgramPricing, defaultPrices, createAdminCode, deleteCoupon };
};

export default usePricing;
