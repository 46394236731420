import styled from 'styled-components';
import ICONS from '../../constants/icons';
import IMAGES from '../../constants/images';

export const SignInWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: black;
  display: flex;

  justify-content: space-between;
  overflow-x: hidden;
  color: #ffffff;
  background-image: url(${({ image }) => image});
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  ${({ theme }) => theme.laptopS`
     flex-direction: row-reverse;
     background-size: auto 100%;
      `};
  ${({ theme }) => theme.tablet`
    background-image:none;
      `};
`;

export const SignInColumn = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${IMAGES.FitnessGirlSignupImageFirst});
  background-image: url(${({ image }) => image});
  background-position: center bottom;
  background-size: auto 92%;
  background-repeat: no-repeat;
  max-width: ${({ minResolution }) => (minResolution === 'tablet' ? '25%' : '100%')};
  ${({ theme, minResolution }) =>
    minResolution === 'laptopS' &&
    theme.laptopS`
      display: none;
      `};
  ${({ theme, minResolution }) =>
    minResolution === 'tablet' &&
    theme.tablet`
      display: none;
      `};
`;
export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const WelcomeAbsolute = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  font-family: Gotham-r;
  font-size: 2.4rem;
  line-height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60%;
  color: #ffffffcc;
`;

export const NonFlexWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 1.4rem 0 2rem;
  height: 5.6rem;
`;

export const DoNotHaveAcount = styled.div`
  width: 100%;
  text-align: center;
  line-height: 2.1rem;
  font-family: Roboto-m;
  color: #ffffffbb;
  padding: 1.2rem;
`;

export const SignUpLink = styled.span`
  color: ${({ theme }) => theme.color.primary};
  padding-left: 0.8rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const SignInCell = styled.div`
  min-width: ${({ collSpan }) => `Calc(${collSpan || 1} * (100vw / 8))`};
  height: ${({ rowSpan }) => `Calc(${rowSpan || 1} * (100vh / 6))`};
  min-height: ${({ rowSpan }) => `Calc(${rowSpan || 1} * 116px)`};
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  border-bottom: ${({ bottom }) => (bottom ? 'none' : '1px solid rgba(255,255,255,0.6)')};
  background: ${({ bg }) => (bg ? 'rgba(255,255,255,0.2)' : 'transparent')};
  color: #ffffff;
  font-family: Roboto-m;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '2rem')};
  line-height: 2.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
`;

export const ErrorAuth = styled.div`
  width: 100%;
  height: 1.6rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.color.error};
  text-align: left;
  display: flex;
  padding: 0 0.8rem;
`;

export const AuthErrorIcon = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(${ICONS.AlertIcon});
  background-position: center;
  background-size: contain;
  margin: ${({ margin }) => (margin ? margin : '0 8px 0 0')};
  background-repeat: no-repeat;
`;

export const ForgotPasswordLinkWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  margin: 0 0 1.6rem;
  height: 1.2rem;
`;

export const ForgotPasswordLink = styled.span`
  opacity: 0.7;
  color: #ffffff;
  font-family: Roboto-m;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
`;
