import React, { useState } from 'react';

import {
  SignInWrapper,
  SignInColumn,
  SignInCell,
  FlexWrapper,
  NonFlexWrapper,
} from '../sign-in/components';
import images from '../../constants/images';
import { Title, SubTitle, ArrowDown, InputWrapper, CancelLink } from './components';
import CustomInput from '../../components/input/CustomInput';
import ICONS from '../../constants/icons';
import { Link } from 'react-router-dom';
import URLS from '../../constants/urls';
import CustomButton from '../../components/button/CustomButton';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  return (
    <SignInWrapper image={images.SigninBackground}>
      <SignInColumn>
        <FlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bg />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell bg />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
        </FlexWrapper>
      </SignInColumn>
      <SignInColumn>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
        </FlexWrapper>
        <SignInCell collSpan={2}>
          <img style={{ width: '80%' }} src={images.LogoImage} alt="Logo" />
        </SignInCell>
        <SignInCell collSpan={2} rowSpan={3}>
          <Title>Forgot Password?</Title>
          <SubTitle>Enter the email address associated with your account.</SubTitle>
          <ArrowDown />
          <InputWrapper mt="3.2rem">
            <CustomInput
              autocomplete={false}
              outlined
              height={'5.6rem'}
              placeholder="Enter Email Address"
              icon={ICONS.EnvelopeIcon}
              value={email}
              onChange={setEmail}
              backgroundColor="#ffffff"
            />
          </InputWrapper>
          <InputWrapper mt="1.8rem">
            <CustomButton green>Log In</CustomButton>
          </InputWrapper>
          <InputWrapper mt="2.4rem">
            <Link to={URLS.sign_in}>
              <CancelLink>Cancel</CancelLink>
            </Link>
          </InputWrapper>
        </SignInCell>
        <FlexWrapper>
          <SignInCell bottom />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
      <SignInColumn>
        <FlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bg />
            <SignInCell bottom />
          </NonFlexWrapper>
        </FlexWrapper>
      </SignInColumn>
    </SignInWrapper>
  );
};

export default ForgotPassword;
