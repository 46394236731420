import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
  getCalendarMessagesQuery,
  createCalendarMutation,
  editCalendarMutation,
} from '@witness/graphql';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CustomInputDropdown from '../input/CustomInputDropdown';
import CustomTextArea from '../input/CustomTextArea';
import RadioButton from '../radio/RadioButton';
import {
  Header,
  HeaderText,
  Body,
  AreaHeaderRow,
  IconButton,
  OptWrapper,
  Footer,
  ErrorText,
} from './components';
import CustomButton from '../button/CustomButton';
import IconsModal from './IconsModal';
import { useCallback } from 'react';
import MessageIcon from './MessageIcon';
import { CalendarType } from '../../constants/enums';

const modalStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 3333,
  },
  content: {
    // height: '515px',
    width: '536px',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 10px 0 rgba(74,74,74,0.12)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    // borderRadius: '8px',
    border: 'none',
    padding: '0',
  },
};

const menuStyles = {
  maxHeight: '180px',
  boxShadow: '0 4px 10px 0 rgba(74,74,74,0.12)',
  paddingTop: '1.8rem',
  paddingBottom: '1.8rem',
};

const valueStyles = {
  color: '#373333',
  fontFamily: 'Roboto-r',
  fontSize: '1.6rem',
  letterSpacing: 0,
};

const optionStyles = {
  ...valueStyles,
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '21px',
};

const errorMessages = ['Please select a message', "You haven't specified a custom message text"];

const MessageOption = ({ opt, onClick }) => (
  <OptWrapper onClick={onClick}>
    <MessageIcon margin={'0 1rem 0 0'} src={opt?.icon?.file?.url} size={36} />
    <span>{opt.text}</span>
  </OptWrapper>
);

const MessageValue = ({ opt }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <MessageIcon margin={'0 1rem 0 0'} src={opt?.icon?.file?.url} size={36} />
    <span>{opt.text}</span>
  </div>
);

const WorkoutMessageModal = ({
  day,
  closeModal,
  existingMessage,
  program,
  refetchProgram,
  coachProgram,
  programTemplate,
}) => {
  const { data } = useQuery(getCalendarMessagesQuery);

  const [messageType, setMessageType] = useState(existingMessage?.customMessage ? 1 : 0);
  const [message, setMessage] = useState(existingMessage || {});
  const [text, setText] = useState(existingMessage?.customMessage || '');

  const [icon, setIcon] = useState(existingMessage?.icon || '');
  const [isIconsOpen, setIsIconsOpen] = useState(false);
  const toggleIconsModal = useCallback(() => {
    if (messageType === 1) {
      setIsIconsOpen((s) => !s);
    }
  }, [messageType]);

  useEffect(() => {
    if (messageType === 0) {
      setText('');
      setIcon('');
    } else if (messageType === 1) {
      setMessage({});
    }
  }, [messageType]);

  const [error, setError] = useState('');

  const checkErrors = useCallback(() => {
    if (messageType === 0 && !message.id) {
      setError(errorMessages[0]);
      return true;
    } else if (messageType === 1 && !text) {
      setError(errorMessages[1]);
      return true;
    } else {
      setError('');
      return false;
    }
  }, [message.id, messageType, text]);

  const [handleMessageCreate, { loading }] = useMutation(createCalendarMutation);
  const [handleMessageUpdate, { loading: editLoading }] = useMutation(editCalendarMutation);

  const handleSubmit = useCallback(async () => {
    if (!checkErrors()) {
      const messageDetails =
        messageType === 0
          ? {
              calendarMessage: message.id,
            }
          : {
              customMessage: text,
              icon: icon?.id,
            };

      console.log({
        ...messageDetails,
        type: CalendarType.MESSAGE,
        coachProgram,
        programTemplate,
        programDay: day.messageDay,
      });

      if (existingMessage) {
        await handleMessageUpdate({
          variables: {
            record: {
              uid: existingMessage.uid,
              ...messageDetails,
            },
          },
        });
      } else {
        await handleMessageCreate({
          variables: {
            calendar: {
              ...messageDetails,
              type: CalendarType.MESSAGE,
              coachProgram,
              programTemplate,
              programDay: day.messageDay,
            },
          },
        });
      }

      refetchProgram();

      closeModal();
    }
  }, [
    existingMessage,
    handleMessageCreate,
    handleMessageUpdate,
    icon,
    message.id,
    messageType,
    program,
    refetchProgram,
    text,
    day,
    closeModal,
    checkErrors,
  ]);

  useEffect(() => {
    if (error) {
      checkErrors();
    }
  }, [error, checkErrors]);

  // reset to defaults
  useEffect(() => {
    setMessageType(existingMessage?.customMessage ? 1 : 0);
    setMessage(existingMessage || {});
    setText(existingMessage?.customMessage || '');
    setIcon(existingMessage?.icon || '');
    setIsIconsOpen(false);
    setError('');
  }, [existingMessage]);

  return (
    <Modal style={modalStyles} onRequestClose={closeModal} isOpen={!!day}>
      <Header>
        <HeaderText>CREATE MESSAGE</HeaderText>
      </Header>
      <Body>
        <RadioButton
          onClick={() => setMessageType(0)}
          selected={messageType === 0}
          text="Choose Message"
        />

        <div style={{ marginTop: '1.6rem' }}>
          <CustomInputDropdown
            color="#373333"
            outlined
            borderRadius="8px"
            height="4.4rem"
            fontSize="1.4rem"
            options={data?.getCalendarMessages?.messages || []}
            optionComponent={MessageOption}
            value={message.text ? <MessageValue opt={message} /> : ''}
            onChange={(val) => setMessage(val)}
            disabled={messageType !== 0}
            optionStyles={optionStyles}
            menuStyles={menuStyles}
            valueStyles={valueStyles}
            placeholder="- message text -"
          />
        </div>

        <AreaHeaderRow>
          <RadioButton
            onClick={() => setMessageType(1)}
            selected={messageType === 1}
            text="Create Custom Message"
          />

          {!icon?.id && (
            <IconButton onClick={toggleIconsModal} disabled={messageType !== 1}>
              Choose Icon
            </IconButton>
          )}

          {icon?.id && <MessageIcon src={icon?.file?.url} onClick={toggleIconsModal} size={36} />}

          <IconsModal
            isOpen={isIconsOpen}
            onRequestClose={toggleIconsModal}
            apply={setIcon}
            appliedIcon={icon}
          />
        </AreaHeaderRow>

        <div style={{ marginTop: '1.6rem' }}>
          <CustomTextArea
            placeholder="Type Here"
            rowCount={7}
            value={text}
            onChange={(val) => setText(val)}
            // error={errors[field.name]}
            disabled={messageType !== 1}
          />
        </div>

        <div style={{ marginTop: '1.6rem', height: '1.9rem' }}>
          {!!error && <ErrorText>{error}</ErrorText>}
        </div>
      </Body>

      <Footer>
        <CustomButton
          style={{ marginRight: '1.2rem', width: '22.4rem', height: '4.8rem' }}
          backgroundColor="#F1F0F0"
          fontSize="1.4rem"
          onClick={closeModal}
        >
          Cancel
        </CustomButton>
        <CustomButton
          style={{ marginLeft: '1.2rem', width: '22.4rem', height: '4.8rem' }}
          backgroundColor="#00ff91"
          fontSize="1.4rem"
          onClick={handleSubmit}
          disabled={loading || editLoading}
        >
          Save
        </CustomButton>
      </Footer>
    </Modal>
  );
};

export default WorkoutMessageModal;
