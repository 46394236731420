import React from 'react';
import { PseudoTraineeCard } from '../../components/trainee-card/components';
import GroupCard from './GroupCard';
import AddGroupModal from '../../components/add-group-modal.jsx/AddGroupModal';
import { useState } from 'react';
import { useGroup } from '../../hooks';
import { ProgramStatus } from '../../constants/enums';
import useProgramRequest from '../../hooks/programRequest';
import ProgramsHeader from '../../components/programs-header/ProgramsHeader';
import CustomButton from '../../components/button/CustomButton';
import Loader from '../../components/loader/Loader';
import EmptyTemplate from '../../components/empty-template/EmptyTemplate';
import IMAGES from '../../constants/images';

const Groups = () => {
  const [addingNewGroup, setAddingNewGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState('LIST');
  const {
    groups,
    searchName,
    setSearchName,
    coachProgramStatus,
    setCoachProgramStatus,
    groupsLoading,
  } = useGroup();
  const { statusOpts } = useProgramRequest({
    requestStatus: ProgramStatus.APPROVED,
  });

  return (
    <>
      <div
        style={{
          width: '100%',
          background: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AddGroupModal
          modalOpen={addingNewGroup}
          submitModal={console.log}
          closeModal={() => setAddingNewGroup(false)}
          setLoading={setLoading}
          loading={loading}
        />
        <ProgramsHeader
          layout={layout}
          setLayout={setLayout}
          statusOpts={statusOpts}
          status={coachProgramStatus}
          setStatus={setCoachProgramStatus}
          traineeName={searchName}
          setTraineeName={setSearchName}
          heading="Your Groups"
          additionalButton={
            <CustomButton
              height="4.6rem"
              green
              onClick={() => setAddingNewGroup(true)}
              style={{ padding: '1rem 3rem', marginLeft: '1.6rem' }}
            >
              Create New Group
            </CustomButton>
          }
        />
        <div
          style={{
            background: '#f7f6f6',
            padding: '12px 8px',
            margin: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {!groups?.getGroups?.length ? (
            <>
              {(groupsLoading && <Loader />) || (
                <EmptyTemplate
                  image={IMAGES.EmptyGroups}
                  buttonText="Create Group"
                  onButtonClick={() => setAddingNewGroup(true)}
                  primaryText={`You don't Have any Groups ${
                    coachProgramStatus?.value
                      ? `with Status '${coachProgramStatus?.label}' ${
                          searchName ? `and Name consisting '${searchName}'` : ''
                        }`
                      : searchName
                      ? `with Name consisting '${searchName}'`
                      : 'Yet'
                  }
                  `}
                  secondarytext={
                    coachProgramStatus?.value || searchName
                      ? ''
                      : 'Create groups, Unite trainees together to get common results You can create groups with different number of the participants'
                  }
                />
              )}
            </>
          ) : layout === 'CARDS' ? (
            <>
              {!!groups?.getGroups?.length &&
                groups.getGroups.map((group) => (
                  <GroupCard
                    key={group?.uid}
                    group={{ ...group, beginningDate: group?.coachProgram?.beginningDate }}
                  />
                ))}
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
            </>
          ) : (
            <table
              style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0 6px',
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Full name
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program Status
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program types
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                      textAlign: 'center',
                    }}
                  >
                    Participants max number
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program ends
                  </th>
                  <th
                    style={{
                      width: '12.4rem',
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {!!groups?.getGroups?.length &&
                  groups.getGroups.map((group) => (
                    <GroupCard
                      layout="LIST"
                      key={group?.uid}
                      group={{ ...group, beginningDate: group?.coachProgram?.beginningDate }}
                    />
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Groups;
