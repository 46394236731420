import React from 'react';
import { LoaderContainer, LoaderSign, LoaderText } from './components';

const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderSign />
      <LoaderText>Loading, Please, Wait</LoaderText>
    </LoaderContainer>
  );
};

export default Loader;
