import { useMutation, useQuery } from '@apollo/react-hooks';
import { useState } from 'react';
import moment from 'moment';
import {
  createCoachGroupMutation,
  getCoachGroupsQuery,
  getTrainingTypesQuery,
  checkProgramCodeQuery,
} from '@witness/graphql';
import { useHistory } from 'react-router-dom';

const useGroup = ({ groupDigits } = {}) => {
  const [searchName, setSearchName] = useState(null);
  const [coachProgramStatus, setCoachProgramStatus] = useState({
    label: '- Choose -',
    value: null,
  });

  const history = useHistory();

  const { data: groups, loading: groupsLoading, refetch } = useQuery(getCoachGroupsQuery, {
    variables: {
      record: {
        searchName,
        coachProgramStatus: coachProgramStatus?.value,
      },
    },
  });
  const { data: groupByDigits } = useQuery(checkProgramCodeQuery, {
    variables: {
      record: {
        digits: groupDigits,
      },
    },
    skip: !groupDigits,
  });

  const [createCoachGroup] = useMutation(createCoachGroupMutation);
  const { data: trainingTypes } = useQuery(getTrainingTypesQuery);

  const createGroup = async (values, setErrors, setLaoding, closeModal) => {
    setLaoding(true);
    const {
      uid,
      name,
      groupImage,
      price,
      participants,
      programLength,
      trainingTypes,
      description,
      daysOfTraining,
      startDate,
      endDate,
    } = values;
    let calculatedLength = programLength;
    const uidSpreader = { uid };
    if (!programLength) {
      calculatedLength = moment(endDate).diff(moment(startDate), 'days');
    }
    try {
      const addedGroup = await createCoachGroup({
        variables: {
          record: {
            ...uidSpreader,
            name,
            groupImage,
            price: Number(price),
            participants: Number(participants),
            programLength: Number(calculatedLength),
            trainingTypes: trainingTypes?.map((x) => x.value) || [],
            description,
            daysOfTraining,
          },
        },
      });
      setLaoding(false);
      refetch();
      if (addedGroup?.data?.createOrUpdateGroup?.uid) {
        closeModal();
        history.push(`/program/${addedGroup?.data?.createOrUpdateGroup?.uid}?group=true`);
      }
    } catch (err) {
      if (err.graphQLErrors.find((x) => x.message === 'group_name_already_exists')) {
        setErrors({ name: 'Name is already used' });
      }
    }
  };

  return {
    createGroup,
    trainingTypes,
    groups,
    searchName,
    setSearchName,
    coachProgramStatus,
    setCoachProgramStatus,
    groupsLoading,
    groupByDigits,
  };
};

export default useGroup;
