import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import DashboardProgramRequests from '../../components/dashboard/DashboardProgramRequests';
import DashboardWorkoutList from '../../components/dashboard/DashboardWorkoutList';
import ProgramRequest from '../../components/request-modal/ProgramRequest';
import CustomButton from '../../components/button/CustomButton';
import { useCoachPersonalInfo, useProgram } from '../../hooks';
import {
  DashboardSummaryBox,
  Dashboard,
  DashboardMainBox,
  DashboardMainItem,
  DashboardBanner,
  DashboardBannerBackLine,
  DashboardBannerContent,
  BannerAvatar,
  BannerText,
  BannerPrimaryText,
  BannerSecondaryText,
} from './components';

const Home = () => {
  const [requestModal, setRequestModal] = useState(null);
  const { currentCoach } = useCoachPersonalInfo(() => {});
  const history = useHistory();

  const urlRequestId = useMemo(
    () => history?.location?.search?.split('request_id=')?.[1]?.split('&')?.[0],
    [history?.location?.search],
  );

  const { program } = useProgram({ programId: urlRequestId });
  useEffect(() => {
    if (program?.status === 'APPROVED') {
      if (program?.type === 'GROUP' && program?.coachProgram?.group?.uid) {
        history.push({ pathname: `/program/${program?.coachProgram?.group?.uid}?group=true` });
      } else {
        history.push({ pathname: `/program/${program?.uid}` });
      }
    } else {
      console.log(program);
      setRequestModal(program);
    }
  }, [program]);

  return (
    <Dashboard>
      {!!requestModal && (
        <ProgramRequest
          content={requestModal}
          closeModal={() => {
            setRequestModal(null);
            history.push(history?.location?.pathname);
          }}
        />
      )}
      <DashboardSummaryBox>
        <DashboardBanner>
          <DashboardBannerContent>
            <BannerAvatar pic={currentCoach?.profileImage?.url} />
            <BannerText>
              <BannerPrimaryText>{`Welcome, coach ${
                currentCoach?.fullName?.split(' ')?.[0]
              }!`}</BannerPrimaryText>
              <BannerSecondaryText>
                Please, feel free to edit Your Personal and Professional Details any time
              </BannerSecondaryText>
            </BannerText>
            <Link to="/profile">
              <CustomButton
                style={{ padding: '0 3.2rem', width: 'fit-content' }}
                height="4.8rem"
                borderRadius="8px"
                green
              >
                Edit Profile
              </CustomButton>
            </Link>
          </DashboardBannerContent>
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
        </DashboardBanner>
        {/* <DashboardSummaryBlock
          title="Program Requests"
          valueChange="+11"
          timeChange="In last 36 hours"
          total="15"
          icon={ICONS.ProgramRequests}
        />
        <DashboardSummaryBlock />
        <DashboardSummaryBlock /> */}
      </DashboardSummaryBox>
      <DashboardMainBox>
        <DashboardMainItem grow={1}>
          <DashboardProgramRequests openRequest={setRequestModal} />
        </DashboardMainItem>
        <DashboardMainItem grow={2}>
          <DashboardWorkoutList />
        </DashboardMainItem>
      </DashboardMainBox>
    </Dashboard>
  );
};
export default Home;
