import React from 'react';
import { IconContainer, IconWrapper, Icon } from './components';

const MessageIcon = ({ selected, onClick, src, size, margin }) => (
  <IconWrapper margin={margin} size={size}>
    <IconContainer selected={selected} onClick={onClick}>
      <Icon src={src} alt="" />
    </IconContainer>
  </IconWrapper>
);

export default MessageIcon;
