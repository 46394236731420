const BLOCKS = {
  AMRAP: {
    name: 'AMRAP',
    type: 'AMRAP',
    fullName: 'As Many Rounds As Possible',
    attributes: {
      totalTime: {
        label: 'Total Time (mm:ss)',
        value: '00:01:00',
        name: 'totalTime',
        type: 'clock',
        itemName: 'Total',
      },
    },
  },
  RFT: {
    name: 'Work for Time',
    type: 'RFT',
    attributes: {
      rounds: {
        label: 'Number of Rounds',
        value: 3,
        name: 'rounds',
        itemName: 'rounds',
      },
    },
  },
  RNFT: {
    name: 'Work not for Time',
    type: 'RNFT',
    attributes: {
      rounds: {
        label: 'Number of Rounds',
        value: 3,
        name: 'rounds',
        itemName: 'rounds',
      },
    },
  },
  STRENGTH: {
    name: 'Strength Sets',
    type: 'STRENGTH',
    attributes: {
      sets: {
        label: 'Number of Sets',
        value: 3,
        name: 'sets',
        itemName: 'Sets',
      },
      interval: {
        label: 'Interval Time (mm:ss)',
        value: '00:01:00',
        name: 'interval',
        asNeeded: false,
        type: 'clock',
        itemName: 'Rest Time',
      },
    },
  },
  SUPERSETS: {
    name: 'Super Sets',
    type: 'SUPERSETS',
    attributes: {
      sets: {
        label: 'Number of Sets',
        value: 3,
        name: 'sets',
        itemName: 'Sets',
      },
      interval: {
        label: 'Interval Time (mm:ss)',
        value: '00:01:00',
        name: 'interval',
        asNeeded: false,
        type: 'clock',
        itemName: 'Rest Time',
      },
    },
  },
  // QUALITYSETS: {
  //   name: 'Quality Sets',
  //   type: 'QUALITYSETS',
  //   attributes: {
  //     sets: {
  //       label: 'Number of Sets',
  //       value: 3,
  //       name: 'sets',
  //       itemName: 'Sets',
  //     },
  //   },
  // },
  EMOM: {
    name: 'EMOM',
    type: 'EMOM',
    attributes: {
      roundTime: {
        label: 'Round Time (mm:ss)',
        value: '00:01:00',
        name: 'roundTime',
        type: 'clock',
        itemName: 'Total',
      },
      rounds: {
        label: 'Number of Rounds',
        value: 3,
        name: 'rounds',
        itemName: 'rounds',
      },
    },
  },
  RUN: {
    name: 'Run',
    type: 'RUN',
    nullableAttributes: ['pace'],
    attributes: {
      unit: {
        label: 'Unit',
        value: '00:01:00',
        textValue: 'Time',
        name: 'unit',
        type: 'clock',
        itemName: 'Unit',
      },
      pace: {
        label: 'Pace (optional)',
        value: null,
        name: 'pace',
        type: 'nullable',
        itemName: 'Pace',
      },
    },
  },
  REST: {
    name: 'Rest',
    type: 'REST',
    attributes: {
      rest: {
        label: 'Rest Time (mm:ss)',
        value: '00:01:00',
        name: 'rest',
        type: 'clock',
        itemName: 'Rest',
      },
    },
  },
  CUSTOM: {
    name: 'Custom',
    type: 'CUSTOM',
    attributes: {
      block_title: {
        label: 'Block Title',
        value: 'Custom',
        textValue: 'Custom',
        name: 'block_title',
        type: 'text',
        itemName: 'title',
      },
      text: {
        label: 'Description',
        value: '',
        textValue: '',
        name: 'text',
        type: 'text',
        itemName: 'Text',
      },
    },
  },
};

export default BLOCKS;
