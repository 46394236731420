import { useMutation, useQuery } from '@apollo/react-hooks';
import { meQuery, editCoachMutation, uploadPortfolioMutation } from '@witness/graphql';

const useCoachPersonalInfo = ({ setLoading = () => {}, setOpenModal = () => {} }) => {
  const { data: me, loading: meLoading, error: meError, refetch: meRefetch } = useQuery(meQuery);

  const getInitialValuesPersonal = () => {
    return {
      fullName: me?.getCurrentUser?.coach?.fullName || '',
      address: me?.getCurrentUser?.coach?.address || '',
      phone: me?.getCurrentUser?.coach?.phone || '',
      idNumber: me?.getCurrentUser?.coach?.idNumber || '',
      gender: me?.getCurrentUser?.coach?.gender || '',
      age: me?.getCurrentUser?.coach?.age || null,
      height: me?.getCurrentUser?.coach?.height || null,
      weight: me?.getCurrentUser?.coach?.weight || null,
      meal: me?.getCurrentUser?.coach?.meal || '',
      playlist: me?.getCurrentUser?.coach?.playlist || '',
    };
  };

  const getInitialValuesAbout = () => {
    return {
      about: me?.getCurrentUser?.coach?.about || ``,
    };
  };

  const [editCoach] = useMutation(editCoachMutation);

  const submitEditCoach = async (values, { setSubmitting, setErrors, blob }) => {
    setOpenModal(null);
    setLoading(true);
    console.log(blob);
    try {
      await editCoach({
        variables: {
          record: {
            ...values,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          editCoach: blob
            ? {
                ...me?.getCurrentUser?.coach,
                __typename: 'Coach',
                profileImage: {
                  __typename: 'File',
                  uid: 'temp',
                  id: 'temp',
                  url: blob,
                  filename: 'profile.img',
                  mimetype: '',
                  compressedUrl: blob,
                  encoding: '',
                },
              }
            : {
                ...me?.getCurrentUser?.coach,
                __typename: 'Coach',
                ...values,
              },
        },
      });
      setSubmitting(false);
      await meRefetch();
      setLoading(false);
    } catch (err) {
      if (err.graphQLErrors?.find((x) => x.message === 'coach_already_exists')) {
        setErrors({ email: 'Email already exists' });
      }
    }
  };

  const [uploadPortfolio] = useMutation(uploadPortfolioMutation);

  const uploadportfolioItems = async (values) => {
    setLoading(true);
    new Promise((resolve, reject) => {
      const alreadyReadFiles = [];
      Array.from(values).map((file) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          alreadyReadFiles.push({
            url: reader.result,
            __typename: 'File',
            id: 'tempid',
            mimetype: 'text/',
            encoding: '7bit',
            filename: 'filename-temp',
          });
          if (alreadyReadFiles.length >= Array.from(values).length) {
            resolve({
              uid: 'temp-0b72-41e9-8d91-4326ba973cc6',
              __typename: 'Portfolio',
              files: alreadyReadFiles,
            });
          }
        };
        if (file) {
          reader.readAsDataURL(file);
        } else {
        }
        return 1;
      });
    }).then(async (optimisticAddedFiles) => {
      await uploadPortfolio({
        variables: {
          record: {
            portfolio: values,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          uploadPortfolio: optimisticAddedFiles,
        },
        update: (proxy, { data: { uploadPortfolio } }) => {
          const data = proxy.readQuery({ query: meQuery });
          proxy.writeQuery({
            query: meQuery,
            data: {
              ...data,
              getCurrentUser: {
                ...data.getCurrentUser,
                coach: {
                  uid: me?.getCurrentUser?.coach?.id,
                  ...data.getCurrentUser.coach,
                  __typename: 'Coach',
                  portfolio: [...me?.getCurrentUser?.coach?.portfolio, uploadPortfolio],
                },
              },
            },
          });
        },
      });
      setLoading(false);
    });
  };

  return {
    getInitialValuesPersonal,
    getInitialValuesAbout,
    currentCoach: !meLoading &&
      !meError && { ...me?.getCurrentUser?.coach, email: me?.getCurrentUser?.user?.email },
    submitEditCoach,
    uploadportfolioItems,
  };
};

export default useCoachPersonalInfo;
