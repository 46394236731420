import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { getCoachWorkoutsQuery } from '@witness/graphql';
import { useAlert } from 'react-alert';

import CustomButton from '../button/CustomButton';
import MagicScroll from '../magic-scroll/MagicScroll';
import {
  ProgramRequestsContainer,
  ProgramRequestsHeader,
  ProgramRequestsList,
  ProgramRequestsNavBar,
  ProgramRequestsSeeAll,
  WorkoutTable,
  WorkoutTh,
  ThText,
  WorkoutTd,
  TdText,
  StatusIcon,
  HC,
  AttachmentsCount,
  AttachmentIcon,
  TabContainer,
  TabName,
  TabValue,
  ActiveBorder,
  Avatar,
} from './components';
import Loader from '../../components/loader/Loader';
import EmptyTemplate from '../empty-template/EmptyTemplate';
import ICONS from '../../constants/icons';
import CalendarResultsModal from '../calendar-results-modal/CalendarResultsModal';

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const statusNames = {
  NEW: 'Planned',
  DONE: 'Done',
  UNDONE: 'Missed',
};

const Item = ({ item, active }) => (
  <TabContainer active={active}>
    {active && <ActiveBorder />}
    <TabName>{weekDays[item.getDay()]}</TabName>
    <TabValue>{item.getDate()}</TabValue>
  </TabContainer>
);

const getWeek = (middleDate) => {
  const modifiers = [-3, -2, -1, 0, 1, 2, 3];

  return modifiers.map((mod) => {
    const date = new Date(middleDate);
    date.setDate(date.getDate() + mod);
    return date;
  });
};

const keyExtractor = (item) => moment(item).format('YYYY/MM/DD');

const countWorkoutAttachments = (workout) => {
  return workout?.workoutBlocks?.reduce((blockAcc, block) => {
    const x = block.results?.reduce((resutlAcc, result) => {
      const y = result.blockAttachment?.files?.length;

      return resutlAcc + (y || 0);
    }, 0);

    return blockAcc + (x || 0);
  }, 0);
};

const DashboardWorkoutList = () => {
  const [items, setItems] = useState(() => {
    const date = new Date();
    const thisWeek = getWeek(date);

    date.setDate(date.getDate() - 7);
    const lastWeek = getWeek(date);

    date.setDate(date.getDate() + 14);
    const nextWeek = getWeek(date);
    return [...lastWeek, ...thisWeek, ...nextWeek];
  });

  const [firstItem, setFirstItem] = useState(items[7]);

  const nextDay = useCallback(() => {
    const key = keyExtractor(firstItem);
    const currentIndex = items.findIndex((item) => keyExtractor(item) === key);
    setFirstItem(items[currentIndex + 1]);

    if (currentIndex === items.length - 7) {
      const lastDate = items[items.length - 1];
      const newWeekMid = new Date(lastDate);
      newWeekMid.setDate(newWeekMid.getDate() + 4);
      const newWeek = getWeek(newWeekMid);

      setItems([...items, ...newWeek]);
    }
  }, [items, firstItem]);

  const prevDay = useCallback(() => {
    const key = keyExtractor(firstItem);
    const currentIndex = items.findIndex((item) => keyExtractor(item) === key);
    setFirstItem(items[currentIndex - 1]);

    if (currentIndex === 1) {
      const firstDate = items[0];
      const newWeekMid = new Date(firstDate);
      newWeekMid.setDate(newWeekMid.getDate() - 4);
      const newWeek = getWeek(newWeekMid);

      setItems([...newWeek, ...items]);
    }
  }, [items, firstItem]);

  const handleTabClick = useCallback(
    (item) => {
      const key = keyExtractor(item);
      const newIndex = items.findIndex((i) => keyExtractor(i) === key) - 3;
      setFirstItem(items[newIndex]);

      if (newIndex < 4) {
        const firstDate = items[0];
        const newWeekMid = new Date(firstDate);
        newWeekMid.setDate(newWeekMid.getDate() - 4);
        const newWeek = getWeek(newWeekMid);

        setItems([...newWeek, ...items]);
      }

      if (newIndex > items.length - 8) {
        const lastDate = items[items.length - 1];
        const newWeekMid = new Date(lastDate);
        newWeekMid.setDate(newWeekMid.getDate() + 4);
        const newWeek = getWeek(newWeekMid);

        setItems([...items, ...newWeek]);
      }
    },
    [items],
  );

  const activeDate = useMemo(() => {
    const active = new Date(firstItem);
    active.setDate(active.getDate() + 3);
    return moment(active).format('YYYY/MM/DD');
  }, [firstItem]);

  const { data, loading, refetch } = useQuery(getCoachWorkoutsQuery, {
    variables: {
      record: {
        date: activeDate,
      },
    },
    fetchPolicy: 'network-only',
  });

  const days = useMemo(() => {
    if (Array.isArray(data?.getCoachWorkouts)) {
      return data.getCoachWorkouts.reduce((acc, item) => {
        acc[item?.calendar?.[0]?.uid] = item?.calendar?.[0];
        return acc;
      }, {});
    }

    return {};
  }, [data]);

  const dateIndicator = useMemo(() => moment(activeDate).format('MMMM YYYY'), [activeDate]);

  const [resultModalDay, setResultModalDay] = useState({ day: '', name: '' });

  const alert = useAlert();

  return (
    <ProgramRequestsContainer>
      {resultModalDay.day && (
        <CalendarResultsModal
          day={days[resultModalDay.day]}
          name={resultModalDay.name}
          closeModal={() => setResultModalDay({ day: '', name: '' })}
          alert={alert}
          refetchProgram={refetch}
        />
      )}
      <ProgramRequestsHeader>
        TRAINEE'S WORKOUTS
        <ProgramRequestsSeeAll>{dateIndicator}</ProgramRequestsSeeAll>
      </ProgramRequestsHeader>
      <ProgramRequestsNavBar>
        <MagicScroll
          itemsToDisplay={7}
          items={items}
          ItemComponent={Item}
          firstItem={firstItem}
          onLeftClick={prevDay}
          onRightClick={nextDay}
          keyExtractor={keyExtractor}
          activeDate={activeDate}
          onTabClick={handleTabClick}
        />
      </ProgramRequestsNavBar>

      <ProgramRequestsList>
        {loading && (
          <HC justify="center" style={{ maxHeight: '40vh' }}>
            <Loader />
          </HC>
        )}
        {!loading && Array.isArray(data?.getCoachWorkouts) && data.getCoachWorkouts.length > 0 && (
          <WorkoutTable>
            <thead>
              <tr>
                <WorkoutTh>
                  <ThText>Status</ThText>
                  {/* <ThIcon /> */}
                </WorkoutTh>
                <WorkoutTh>
                  <ThText>Full Name</ThText>
                  {/* <ThIcon /> */}
                </WorkoutTh>
                <WorkoutTh>
                  <ThText>Workout</ThText>
                  {/* <ThIcon /> */}
                </WorkoutTh>
                <WorkoutTh>
                  <ThText>Attached Files</ThText>
                  {/* <ThIcon /> */}
                </WorkoutTh>
              </tr>
            </thead>
            <tbody>
              {data.getCoachWorkouts.map((item) => {
                const name = item.group ? item.group.name : item.trainee.fullName;

                const [first, last] = name?.split(' ') || ['', ''];
                const initials = `${first ? first[0] : ''}${last ? last[0] : first[0]}`;

                const avatarUrl = item.group
                  ? item.group.groupImage?.compressedUrl || item.group.groupImage?.url
                  : item.trainee.avatar?.compressedUrl || item.trainee.avatar?.url;

                const workout = item?.calendar?.[0]?.workout;

                const status = statusNames[workout?.status?.[0]?.type];

                const workoutName = workout?.name;

                const count = countWorkoutAttachments(workout);

                return (
                  <tr key={item.uid}>
                    <WorkoutTd>
                      <HC>
                        <StatusIcon status={status} />
                        <TdText>{status || statusNames.NEW}</TdText>
                      </HC>
                    </WorkoutTd>
                    <WorkoutTd>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={avatarUrl} size="1.8rem">
                          {initials}
                        </Avatar>
                        <TdText>{name}</TdText>
                      </div>
                    </WorkoutTd>
                    <WorkoutTd>
                      <TdText>{workoutName}</TdText>
                    </WorkoutTd>
                    <WorkoutTd>
                      <HC justify="flex-end">
                        <AttachmentsCount>
                          {count ? '+' + count : <div style={{ marginRight: '2.6rem' }}>-</div>}
                        </AttachmentsCount>

                        {!!count && <AttachmentIcon />}

                        <CustomButton
                          style={{ width: '7.1rem', height: '3.2rem' }}
                          outlined
                          backgroundColor="white"
                          fontSize="1.4rem"
                          type="submit"
                          outlineColor="rgba(150,148,148,0.33)"
                          hoverOutlineColor="#00FF91"
                          borderRadius="6px"
                          fontFamily="Roboto-r"
                          onClick={() =>
                            setResultModalDay({
                              day: item?.calendar?.[0]?.uid,
                              name,
                            })
                          }
                        >
                          View
                        </CustomButton>
                      </HC>
                    </WorkoutTd>
                  </tr>
                );
              })}
            </tbody>
          </WorkoutTable>
        )}

        {!loading && (!Array.isArray(data?.getCoachWorkouts) || !data?.getCoachWorkouts.length) && (
          <EmptyTemplate
            primaryText="There are no assigned workouts for this day"
            secondarytext="All your trainees & group workouts will appear in this section."
            image={ICONS.EmptyWorkoutSvg}
            imageSize="10.7rem"
            hideImageGrid
          />
        )}
      </ProgramRequestsList>
    </ProgramRequestsContainer>
  );
};

export default DashboardWorkoutList;
