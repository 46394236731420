import { css } from 'styled-components';

const screenSizes = {
  mobile: 480,
  tablet: 768,
  laptopS: 1024,
  laptopM: 1366,
  laptopL: 1560,
  desktopS: 1680,
  desktopM: 1920,
};

const media = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${screenSizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Theme = {
  color: {
    primary: '#00ff91',
    error: '#F50A4F',
  },
  ...media,
};

export default Theme;
