import React, { useMemo } from 'react';

import { WorkoutBlockType } from '../../constants/enums';
import { useUtils } from '../../hooks';
import {
  BlockResultsTable,
  BlockResultsTH,
  BlockResultsRow,
  BlockResultsTD,
  HoverIndicator,
  NoResults,
} from './components';

const ResultTable = ({ block }) => {
  const { formatNumber } = useUtils();
  const [setOrRoundAttr, blockRows] = useMemo(
    () => [
      block?.type === WorkoutBlockType.RFT
        ? { name: 'total times', value: 1 }
        : block?.type === WorkoutBlockType.AMRAP
        ? { name: 'Total Number of Roundss', value: 1 }
        : block?.type === WorkoutBlockType.RUN
        ? (block?.customAttributes?.find((x) => x.name === 'unit')?.textValue === 'Distance' && {
            name: 'Times',
            value: 1,
          }) || { name: '' }
        : block?.customAttributes?.find((x) => x.name === 'sets' || x.name === 'rounds') || {
            name: 'times',
            value: 1,
          },
      block?.type === WorkoutBlockType.STRENGTH
        ? [block?.exercises?.[0]]
        : block?.type === WorkoutBlockType?.RUN
        ? (block?.customAttributes?.find((x) => x.name === 'unit')?.textValue === 'Distance' && [
            {
              exercise: {
                title: `${formatNumber(
                  block?.customAttributes?.find((x) => x.name === 'unit')?.value,
                )} meters`,
              },
              value: 1,
            },
          ]) || [{ name: '' }]
        : block?.exercises,
    ],
    [block, formatNumber],
  );

  const arrayofHeadings = useMemo(() => new Array(setOrRoundAttr.value).fill(1), [setOrRoundAttr]);
  return (
    <>
      {!block?.results?.[0]?.value?.length ? (
        <NoResults>No Results Yet</NoResults>
      ) : (
        <BlockResultsTable
          style={block?.type === WorkoutBlockType.AMRAP ? { width: 'fit-content' } : {}}
        >
          <thead>
            <BlockResultsTH></BlockResultsTH>
            {block?.type !== WorkoutBlockType.AMRAP && <BlockResultsTH>Name</BlockResultsTH>}
            {arrayofHeadings?.map((heading, j) => (
              <BlockResultsTH>{`${setOrRoundAttr?.name?.substr(
                0,
                setOrRoundAttr?.name?.length - 1,
              )} ${
                ((setOrRoundAttr?.name === 'rounds' || setOrRoundAttr?.name === 'sets') && j + 1) ||
                ''
              }`}</BlockResultsTH>
            ))}
          </thead>
          <tbody>
            {block?.type === WorkoutBlockType.AMRAP ? (
              <BlockResultsRow>
                <BlockResultsTD>
                  <HoverIndicator className="hover_indicator" />
                </BlockResultsTD>
                <BlockResultsTD style={{ textAlign: 'center' }}>
                  {`${block?.results?.[0]?.value?.[0]?.[0]}` || '-'}
                </BlockResultsTD>
              </BlockResultsRow>
            ) : block?.type === WorkoutBlockType.RFT ? (
              block?.results?.[0]?.value?.map((round, row) => (
                <BlockResultsRow>
                  <BlockResultsTD>
                    <HoverIndicator className="hover_indicator" />
                  </BlockResultsTD>
                  <BlockResultsTD>
                    {`${block?.customAttributes?.find((x) => x.name === 'rounds')?.value} Rounds`}
                  </BlockResultsTD>
                  <BlockResultsTD>
                    {`${('0' + Math.floor(round?.[0] / 60)).slice(-2)}:${(
                      '0' +
                      (round?.[0] % 60)
                    ).slice(-2)}` || '-'}
                  </BlockResultsTD>
                </BlockResultsRow>
              ))
            ) : (
              blockRows?.map((exercise, row) => (
                <BlockResultsRow>
                  <BlockResultsTD>
                    <HoverIndicator className="hover_indicator" />
                    {block?.type !== WorkoutBlockType?.RUN && row + 1}
                  </BlockResultsTD>
                  <BlockResultsTD>{exercise?.exercise?.title}</BlockResultsTD>
                  {arrayofHeadings?.map((a, col) => (
                    <BlockResultsTD>
                      {block?.type === WorkoutBlockType.STRENGTH
                        ? block?.results?.[0]?.value?.[row]?.[col]
                        : block?.type === WorkoutBlockType?.RUN
                        ? `${(
                            '0' + Math.floor(block?.results?.[0]?.value?.[col]?.[row] / 60)
                          ).slice(-2)}:${(
                            '0' +
                            (block?.results?.[0]?.value?.[col]?.[row] % 60)
                          ).slice(-2)}`
                        : block?.results?.[0]?.value?.[col]?.[row] || '-'}
                    </BlockResultsTD>
                  ))}
                  {/* <BlockResultsTD>
                <FlexTDContent>
                View <HistoryViewEye className="hoverable_view_icon" />
                </FlexTDContent>
            </BlockResultsTD> */}
                </BlockResultsRow>
              ))
            )}
          </tbody>
        </BlockResultsTable>
      )}
    </>
  );
};

export default ResultTable;
