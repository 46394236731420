import React from 'react';
import { useHistory } from 'react-router-dom';
import IMAGES from '../../constants/images';

import CustomButton from '../button/CustomButton';
import {
  TemplateCardContainer,
  CardHeader,
  ProfilePic,
  ParticipantCardBody,
  CardName,
  ParticipantCardFooter,
  ParticipantHealthCell,
  ParticipantHealthCellValue,
  CardFooter,
  FooterLine,
  ButtonWrapper,
  HoverIndicator,
  TraineeRowProfilePic,
  TraineeTD,
  FlexTDContent,
  TraineeRow,
} from './components';

const templateLengthMonth = {
  '30': '1 month',
  '60': '2 month',
  '90': '3 month',
};

const TemplateCard = ({ template, withoutFooterButton, selected, onSelect, layout }) => {
  const history = useHistory();
  return (
    <>
      {layout === 'LIST' ? (
        <TraineeRow>
          <TraineeTD>
            <FlexTDContent>
              <HoverIndicator className="hover_indicator" />
              <TraineeRowProfilePic profilePic={IMAGES.TempProgram1} style={{ opacity: '0.7' }}>
                {template?.name.substring(0, 1)}
              </TraineeRowProfilePic>
              {template?.name}
            </FlexTDContent>
          </TraineeTD>
          <TraineeTD> {template?.trainingTypes?.map((a) => a?.name)?.join(', ')}</TraineeTD>
          <TraineeTD>
            {templateLengthMonth[template?.programLength] || `${template?.programLength} days`}
          </TraineeTD>
          <TraineeTD>08-10-20</TraineeTD>
          <TraineeTD>
            <div style={{ width: '12.4rem' }}>
              <CustomButton
                className="hoverable_view_button"
                outlined
                borderRadius="8px"
                hoverBackground="#00ff91"
                backgroundColor="#ffffff"
                height="3.2rem"
                fontSize="1.4rem"
                onClick={() => history.push(`template/${template?.uid}`)}
              >
                View Template
              </CustomButton>
            </div>
          </TraineeTD>
        </TraineeRow>
      ) : (
        <TemplateCardContainer
          onClick={onSelect}
          style={{
            width: 'Calc(100% / 6 - 2.4rem)',
            height: 'fit-content',
            borderColor: selected ? '#15DF68' : 'rgba(5, 0, 0, 0.12)',
          }}
          withoutFooterButton={withoutFooterButton}
        >
          <CardHeader
            style={{
              height: '11.2rem',
              boxShadow: `0 0 0 2px ${selected ? '#15DF68' : 'transparent'}`,
            }}
            backgroundUrl={IMAGES.TempProgram1Opacity}
          >
            <ProfilePic
              profilePic={template?.image}
              width="7.2rem"
              style={{
                background: '#d8d8d8',
                boxShadow: `0 0 0 2px ${selected ? '#15DF68' : '#d8d8d8'}`,
              }}
            >
              {!template?.image && template?.name?.substring(0, 1)}
            </ProfilePic>
          </CardHeader>
          <ParticipantCardBody style={{ height: '8.4rem', padding: '3.4rem 1.6rem 0' }}>
            <CardName
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                margin: 0,
              }}
            >
              {template?.name}
            </CardName>
          </ParticipantCardBody>
          <ParticipantCardFooter>
            <ParticipantHealthCell style={{ height: '8rem' }}>
              Training Types
              <ParticipantHealthCellValue>
                {template?.trainingTypes?.map((a) => a?.name)?.join(', ')}
              </ParticipantHealthCellValue>
            </ParticipantHealthCell>
            <ParticipantHealthCell style={{ height: '8rem' }}>
              Program Length
              <ParticipantHealthCellValue>
                {templateLengthMonth[template?.programLength] || `${template?.programLength} days`}
              </ParticipantHealthCellValue>
            </ParticipantHealthCell>
          </ParticipantCardFooter>
          <CardFooter style={withoutFooterButton ? { height: '1.2rem' } : {}}>
            {!withoutFooterButton && (
              <ButtonWrapper>
                <CustomButton
                  outlined
                  borderRadius="8px"
                  hoverBackground="#00ff91"
                  backgroundColor="#ffffff"
                  onClick={() => history.push(`template/${template?.uid}`)}
                >
                  View Template
                </CustomButton>
              </ButtonWrapper>
            )}
            <FooterLine />
            <FooterLine />
            <FooterLine />
          </CardFooter>
        </TemplateCardContainer>
      )}
    </>
  );
};

export default TemplateCard;
