import React from 'react';
import Modal from 'react-modal';
import STRINGS from '../../constants/strings';
import moment from 'moment';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  FlexWrapper,
  ButtonWrapper,
  ModalColumn,
  ModalInput,
  CloseX,
} from './components';
import CustomInput from '../input/CustomInput';
import CustomInputNumber from '../input/CustomInputNumber';
import { Formik, Field, Form } from 'formik';
import usePricing from '../../hooks/pricing';
import ReactSelectWitness from '../react-select-witness/ReactSelectWitness';
import CustomTextArea from '../input/CustomTextArea';
import { CouponSchema } from '../../schemas/coupon/coupon';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: 'Calc(100% - 40px)',
    maxWidth: '520px',
    minWidth: '320px',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const ProgramCodeModal = ({ content, closeModal }) => {
  const { createAdminCode } = usePricing();
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!content}
      style={customStyles}
      contentLabel="EditPersonalInfo"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
          {content?.uid ? 'MODIFY PROGRAM CODE' : 'NEW PROGRAM CODE'}{' '}
          <CloseX onClick={closeModal}>X</CloseX>
        </ModalHeader>
        <ModalMain>
          <Formik
            initialValues={{
              // specifications: content?.specifications || [0],
              ...content,
              title: content?.title || '',
              amount: content?.amount || 1,
              description: content?.description || '',
              available: content?.available || 10,
              validUntil: content?.validUntil
                ? moment(content?.validUntil).format('YYYY-MM-DD')
                : moment().add(1, 'days').format('YYYY-MM-DD'),
              discountType:
                content?.discountType === 'AMOUNT'
                  ? { value: 'AMOUNT', label: '₪' }
                  : { value: 'PERCENTAGE', label: '%' },
            }}
            onSubmit={(...args) => {
              createAdminCode(...args);
              closeModal();
            }}
            validationSchema={CouponSchema}
          >
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '100%' }}>
                <FlexWrapper>
                  <ModalColumn>
                    {console.log(errors)}
                    <Field id="title" name="title">
                      {({ field }) => (
                        <ModalInput>
                          Name
                          <CustomInput
                            color="#373333"
                            outlined
                            type="text"
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && !!errors[field.name]}
                            placeholder="Program Code Name"
                            errorIcon={false}
                          />
                        </ModalInput>
                      )}
                    </Field>
                    {/* <Field id="valid" name="valid">
                      {({ field }) => (
                        <ModalInput>
                          Valid From
                          <CustomInput
                            disabled
                            color="#373333"
                            outlined
                            type="date"
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && !!errors[field.name]}
                            style={{ opacity: 0.6 }}
                          />
                        </ModalInput>
                      )}
                    </Field> */}
                    <FlexWrapper>
                      <Field id="discountType" name="discountType">
                        {({ field }) => (
                          <ModalInput small>
                            Discount Type
                            <ReactSelectWitness
                              options={[
                                { value: 'PERCENTAGE', label: '%' },
                                { value: 'AMOUNT', label: '₪' },
                              ]}
                              style={{ maxHeight: '3.8rem', margin: '1rem 0 2rem' }}
                              value={field.value}
                              onChange={(val) => setFieldValue(field.name, val)}
                              error={touched[field.name] && !!errors[field.name]}
                            />
                          </ModalInput>
                        )}
                      </Field>
                      <Field id="amount" name="amount">
                        {({ field }) => (
                          <ModalInput small>
                            Amount
                            <CustomInputNumber
                              arrows
                              color="#373333"
                              outlined
                              borderRadius="8px"
                              style={{ minHeight: '4.4rem' }}
                              fontSize="1.4rem"
                              type="number"
                              onDecrement={() => setFieldValue(field.name, field.value - 1)}
                              onIncriment={() => setFieldValue(field.name, field.value + 1)}
                              value={field.value}
                              onChange={(val) => setFieldValue(field.name, val)}
                              error={touched[field.name] && !!errors[field.name]}
                            />
                          </ModalInput>
                        )}
                      </Field>
                    </FlexWrapper>
                    <Field id="available" name="available">
                      {({ field }) => (
                        <ModalInput>
                          Usage Limit
                          <CustomInput
                            color="#373333"
                            outlined
                            type="number"
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && !!errors[field.name]}
                            errorIcon={false}
                          />
                        </ModalInput>
                      )}
                    </Field>
                  </ModalColumn>
                  <ModalColumn>
                    <Field id="validUntil" name="validUntil">
                      {({ field }) => (
                        <ModalInput>
                          Expiration Date
                          <CustomInput
                            color="#373333"
                            outlined
                            type="date"
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && !!errors[field.name]}
                            placeholder={'dd-mm-yyyy'}
                            errorIcon={false}
                          />
                        </ModalInput>
                      )}
                    </Field>
                    {/* <Field id="specifications" name="specifications">
                      {({ field }) => (
                        <ModalInput height="17.5rem">
                          Valid For
                          <CustomCheckMulti
                            disabled={content?.id || true}
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && !!errors[field.name]}
                            margin="10px 0 0"
                          />
                        </ModalInput>
                      )}
                    </Field> */}
                    <Field id="description" name="description">
                      {({ field }) => (
                        <ModalInput height="17.5rem">
                          Description
                          <CustomTextArea
                            style={{
                              height: '12.4rem',
                              maxHeight: '12.4rem',
                              minHeight: '5rem',
                              padding: '1rem',
                            }}
                            wrapperStyle={{ margin: '1rem 0 2rem' }}
                            value={field.value}
                            error={touched[field.name] && !!errors[field.name]}
                            onChange={(val) => setFieldValue(field.name, val)}
                            errorBorder
                          />
                        </ModalInput>
                      )}
                    </Field>
                  </ModalColumn>
                </FlexWrapper>
                <FlexWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" onClick={closeModal} type="button">
                      {content?.id ? 'Delete Program Code' : STRINGS.close}
                    </CustomButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" green type="submit" disabled={isSubmitting}>
                      {content?.id ? 'Save Changes' : 'Create Program Code'}
                    </CustomButton>
                  </ButtonWrapper>
                </FlexWrapper>
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default ProgramCodeModal;
