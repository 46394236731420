import React from 'react';
import { Formik, Field, Form } from 'formik';
import Modal from 'react-modal';

import CustomButton from '../button/CustomButton';
import CustomInput from '../input/CustomInput';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  ButtonWrapper,
  ButtonContainer,
  PricingTable,
  PricingTableTh,
  PricingTableLeftColumnTd,
  PricingTableTd,
  ModalCloseX,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: '54%',
    maxWidth: '976px',
    minWidth: '320px',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const AdvancedPriceModal = ({ content, closeModal, submitModal }) => {
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!content}
      style={customStyles}
      contentLabel="advancedPrices"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
          CREATE CUSTOM PRICE FOR {content?.speciality?.name}
          <ModalCloseX onClick={closeModal}>X</ModalCloseX>
        </ModalHeader>
        <ModalMain>
          <Formik
            initialValues={{
              speciality: content?.speciality?.id,
              p11: content?.p11,
              p12: content?.p12,
              p13: content?.p13,
              p21: content?.p21,
              p22: content?.p22,
              p23: content?.p23,
              p31: content?.p31,
              p32: content?.p32,
              p33: content?.p33,
            }}
            // validationSchema={EditAboutMeSchema}
            onSubmit={(vals) => {
              closeModal();
              submitModal(vals);
            }}
          >
            {({ setFieldValue, errors, touched, isSubmitting, values, submitForm }) => (
              <Form style={{ width: '100%' }}>
                <PricingTable>
                  <tr>
                    <PricingTableTh></PricingTableTh>
                    <PricingTableTh>Price Per Month</PricingTableTh>
                    <PricingTableTh>Price Per Month for 2 month</PricingTableTh>
                    <PricingTableTh>Price Per Month for 3 month</PricingTableTh>
                  </tr>
                  {[
                    { name: '1-3 workouts p/week', prices: ['p11', 'p12', 'p13'] },
                    { name: '4-5 workouts p/week', prices: ['p21', 'p22', 'p23'] },
                    { name: '6-7 workouts p/week', prices: ['p31', 'p32', 'p33'] },
                  ].map((frequency) => (
                    <>
                      <tr>
                        <PricingTableLeftColumnTd>{frequency.name}</PricingTableLeftColumnTd>
                        {frequency?.prices?.map((price) => (
                          <Field id={price} name={price}>
                            {({ field }) => (
                              <PricingTableTd>
                                <CustomInput
                                  prefix="₪ "
                                  placeholder="type price"
                                  outlined
                                  height="4.2rem"
                                  margin="0"
                                  borderColor="transparent"
                                  backgroundColor="transparent"
                                  focusBackgroundColor="#ffffff"
                                  focusBorderColor="#15DF68"
                                  borderRadius="4px"
                                  style={{
                                    fontFamily: 'Space-r',
                                    textAlign: 'center',
                                    fontSize: '1.8rem',
                                    padding: '1.05rem',
                                    borderWidth: '0.2rem',
                                  }}
                                  value={field.value}
                                  onChange={(v) => setFieldValue(field.name, v ? Number(v) : null)}
                                />
                              </PricingTableTd>
                            )}
                          </Field>
                        ))}
                      </tr>
                    </>
                  ))}
                </PricingTable>
                <ButtonContainer>
                  <ButtonWrapper>
                    <CustomButton green height="4.8rem" fontSize="1.4rem">
                      Save Prices
                    </CustomButton>
                  </ButtonWrapper>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default AdvancedPriceModal;
