import React from 'react';
import Modal from 'react-modal';
import 'react-image-crop/dist/ReactCrop.css';
import STRINGS from '../../constants/strings';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  FlexWrapper,
  ButtonWrapper,
  ModalColumn,
  ModalInput,
} from './components';
import CustomInput from '../input/CustomInput';
import CustomInputNumber from '../input/CustomInputNumber';
import CustomInputDropdown from '../input/CustomInputDropdown';
import { Formik, Field, Form } from 'formik';
import { EditPersonalInfoSchema } from '../../schemas/signup/coachProfile';
import { useAddressAutoComplete } from '../../hooks';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: 'Calc(100% - 40px)',
    maxWidth: '684px',
    minWidth: '320px',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const EditPersonalInfoModal = ({ modalOpen, closeModal, getInitialValues, submitModal }) => {
  const { autoCompleteRef, setHandleAdressChoose } = useAddressAutoComplete();
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>{STRINGS.edit_personal_info}</ModalHeader>
        <ModalMain>
          <Formik
            initialValues={getInitialValues()}
            validationSchema={EditPersonalInfoSchema}
            onSubmit={submitModal}
          >
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '100%' }}>
                <FlexWrapper>
                  <ModalColumn>
                    <Field id="fullName" name="fullName">
                      {({ field }) => (
                        <ModalInput>
                          {STRINGS.name}
                          <CustomInput
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && errors[field.name]}
                          />
                        </ModalInput>
                      )}
                    </Field>
                    <Field id="address" name="address">
                      {({ field }) => (
                        <ModalInput style={{ position: 'relative' }}>
                          {STRINGS.address}
                          {setHandleAdressChoose((val) => setFieldValue(field.name, val))}
                          <CustomInput
                            id="edit-personal-adress"
                            inputRef={autoCompleteRef}
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && errors[field.name]}
                          />
                        </ModalInput>
                      )}
                    </Field>
                    <Field id="phone" name="phone">
                      {({ field }) => (
                        <ModalInput>
                          {STRINGS.phone_number}
                          <CustomInput
                            type="tel"
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && errors[field.name]}
                          />
                        </ModalInput>
                      )}
                    </Field>
                    <Field id="idNumber" name="idNumber">
                      {({ field }) => (
                        <ModalInput>
                          {STRINGS.id}
                          <CustomInput
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && errors[field.name]}
                          />
                        </ModalInput>
                      )}
                    </Field>
                  </ModalColumn>
                  <ModalColumn>
                    <FlexWrapper>
                      <Field id="gender" name="gender">
                        {({ field }) => (
                          <ModalInput small>
                            {STRINGS.gender}
                            <CustomInputDropdown
                              color="#373333"
                              outlined
                              borderRadius="8px"
                              height="4.4rem"
                              fontSize="1.4rem"
                              options={['MALE', 'FEMALE']}
                              value={field.value}
                              onChange={(val) => setFieldValue(field.name, val)}
                            />
                          </ModalInput>
                        )}
                      </Field>
                      <Field id="age" name="age">
                        {({ field }) => (
                          <ModalInput small>
                            {STRINGS.age}
                            <CustomInputNumber
                              arrows
                              color="#373333"
                              outlined
                              borderRadius="8px"
                              height="4.4rem"
                              fontSize="1.4rem"
                              type="number"
                              onDecrement={() => setFieldValue(field.name, Number(field.value - 1))}
                              onIncriment={() => setFieldValue(field.name, Number(field.value + 1))}
                              value={field.value}
                              onChange={(val) => setFieldValue(field.name, Number(val))}
                              error={touched[field.name] && errors[field.name]}
                            />
                          </ModalInput>
                        )}
                      </Field>
                    </FlexWrapper>
                    <FlexWrapper>
                      <Field id="height" name="height">
                        {({ field }) => (
                          <ModalInput small>
                            {STRINGS.height}
                            <CustomInputNumber
                              arrows
                              color="#373333"
                              outlined
                              borderRadius="8px"
                              height="4.4rem"
                              fontSize="1.4rem"
                              type="number"
                              onDecrement={() => setFieldValue(field.name, Number(field.value - 1))}
                              onIncriment={() => setFieldValue(field.name, Number(field.value + 1))}
                              value={field.value}
                              onChange={(val) => setFieldValue(field.name, Number(val))}
                              error={touched[field.name] && errors[field.name]}
                            />
                          </ModalInput>
                        )}
                      </Field>
                      <Field id="weight" name="weight">
                        {({ field }) => (
                          <ModalInput small>
                            {STRINGS.weight}
                            <CustomInputNumber
                              arrows
                              color="#373333"
                              outlined
                              borderRadius="8px"
                              height="4.4rem"
                              fontSize="1.4rem"
                              type="number"
                              onDecrement={() => setFieldValue(field.name, Number(field.value) - 1)}
                              onIncriment={() => setFieldValue(field.name, Number(field.value) + 1)}
                              value={field.value}
                              onChange={(val) => setFieldValue(field.name, Number(val))}
                              error={touched[field.name] && errors[field.name]}
                            />
                          </ModalInput>
                        )}
                      </Field>
                    </FlexWrapper>
                    <Field id="meal" name="meal">
                      {({ field }) => (
                        <ModalInput>
                          {STRINGS.favorite_meal_camel}
                          <CustomInput
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && errors[field.name]}
                          />
                        </ModalInput>
                      )}
                    </Field>
                    <Field id="playlist" name="playlist">
                      {({ field }) => (
                        <ModalInput>
                          {STRINGS.favorite_playlist_camel}
                          <CustomInput
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            height="4.4rem"
                            fontSize="1.4rem"
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && errors[field.name]}
                          />
                        </ModalInput>
                      )}
                    </Field>
                  </ModalColumn>
                </FlexWrapper>
                <FlexWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" onClick={closeModal} type="button">
                      {STRINGS.close}
                    </CustomButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" green type="submit">
                      {STRINGS.save}
                    </CustomButton>
                  </ButtonWrapper>
                </FlexWrapper>
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default EditPersonalInfoModal;
