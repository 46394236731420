import 'react-image-crop/dist/ReactCrop.css';
import { Formik, Field, Form } from 'formik';
import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';

import ICONS from '../../constants/icons';

import CustomInput from '../input/CustomInput';
import CustomButton from '../button/CustomButton';
import CustomTextArea from '../input/CustomTextArea';
import CustomInputFile from '../input/CustomInputFile';
import CropImageModal from '../crop-image/CropImageModal';
import CustomCheckSingle from '../input/CustomCheckSingle';
import { useCoachPersonalInfo, useGroup } from '../../hooks';
import { GroupSchema } from '../../schemas/group/groupSchema';
import ReactSelectWitness from '../react-select-witness/ReactSelectWitness';
import {
  WholeWrapper,
  ModalHeader,
  ModalRow,
  ModalFooter,
  ButtonWrapper,
  ModalColumn,
  FieldTitle,
  FlexWrapper,
  Profileimage,
  ProfileimageLine,
  ProfileImageContainer,
  CernteredContentFlex,
  DaysContainer,
  DayWrapper,
  FieldWrapper,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '111',
  },
  content: {
    zIndex: '111111111',
    width: '80%',
    maxWidth: '972px',
    minWidth: '320px',
    height: 'fit-content',
    maxHeight: 'Calc(100% - 40px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};
const weekDays = ['MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT', 'SUN'];

const AddGroupModal = ({ modalOpen, closeModal, initialValues, setLoading = () => {} }) => {
  const [modalImage, setModalImage] = useState(null);
  const [modalImageFile, setModalImageFile] = useState(null);
  const { createGroup } = useGroup();
  const { currentCoach } = useCoachPersonalInfo({ setLoading: null, setOpenModal: null });

  const specList = useMemo(() => {
    if (currentCoach?.specialities) {
      const withSpecs = currentCoach?.specialities?.reduce((acc, sp) => {
        if (sp?.name === 'Strength' || sp?.name === 'Running') {
          acc.push({ value: sp?.id, label: sp?.name, trainingTypes: sp?.trainingTypes });
        }
        return acc;
      }, []);
      const specials = [].concat(
        ...currentCoach?.specialities?.map(
          (spec) =>
            (spec?.name !== 'Strength' && spec?.name !== 'Running' && spec?.trainingTypes) || [],
        ),
      );
      return [
        ...withSpecs,
        {
          value: 'non-strength-or-running-spec',
          label: 'Special',
          trainingTypes: specials,
        },
      ];
    }

    return [];
  }, [currentCoach.specialities]);

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader style={{ padding: '2rem 3.6rem', fontSize: '1.6rem' }}>
          CREATE NEW GROUP
        </ModalHeader>
        <Formik
          initialValues={
            initialValues
              ? {
                  ...initialValues,
                  preview: initialValues?.groupImage?.url || undefined,
                  trainingTypes: initialValues?.trainingTypes?.map((a) => ({
                    value: a?.id,
                    label: a?.name,
                  })),
                  groupImage: undefined,
                  speciality: specList?.find(
                    (x) =>
                      !!x?.trainingTypes?.find(
                        (y) => y?.id === initialValues?.trainingTypes?.[0]?.id,
                      ),
                  ),
                }
              : {
                  programLength: 180,
                  daysOfTraining: [],
                  groupImage: undefined,
                  preview: undefined,
                  name: undefined,
                  price: undefined,
                  participants: undefined,
                  trainingTypes: undefined,
                  speciality: undefined,
                  description: undefined,
                  startDate: undefined,
                  endDate: undefined,
                }
          }
          onSubmit={(values, { setErrors }) => {
            createGroup(values, setErrors, setLoading, closeModal);
          }}
          validationSchema={GroupSchema}
        >
          {({ setFieldValue, errors, touched, isSubmitting, values, validateForm }) => (
            <Form style={{ width: '100%' }}>
              <ModalRow>
                <ModalColumn left>
                  <Field id="groupImage" name="groupImage">
                    {({ field }) => (
                      <CernteredContentFlex>
                        <ProfileImageContainer>
                          <CropImageModal
                            modalImageFile={modalImageFile}
                            image={modalImage}
                            closeModal={() => setModalImage(null)}
                            onSubmit={(file, blob) => {
                              setFieldValue(field.name, file);
                              setFieldValue('preview', blob);
                            }}
                          />
                          <ProfileimageLine error={touched.preview && errors.preview}>
                            <Profileimage
                              src={values.preview || ICONS.AvatarDefaultImage}
                            ></Profileimage>
                          </ProfileimageLine>
                        </ProfileImageContainer>
                        <div
                          style={{ width: '18.5rem', margin: '3.8rem auto', position: 'relative' }}
                        >
                          <CustomButton outlined backgroundColor="transparent" type="button">
                            Upload Group Image
                            <CustomInputFile
                              accept="image/*"
                              noHover
                              onChange={async (e) => {
                                setModalImageFile(e.target.files[0]);
                                var reader = new FileReader();
                                reader.readAsDataURL(e.target.files[0]);
                                reader.onload = (ev) => {
                                  setModalImage(ev.target.result);
                                };
                              }}
                            />
                          </CustomButton>
                        </div>
                      </CernteredContentFlex>
                    )}
                  </Field>
                </ModalColumn>
                <ModalColumn>
                  <FieldTitle>Group name</FieldTitle>
                  <Field id="name" name="name">
                    {({ field }) => (
                      <CustomInput
                        errorIcon={false}
                        placeholder="Type Name"
                        outlined
                        height="4rem"
                        borderRadius="8px"
                        margin="0 0 3.6rem"
                        value={field.value}
                        onChange={(val) => {
                          setFieldValue(field.name, val);
                        }}
                        error={touched[field.name] && errors[field.name]}
                      />
                    )}
                  </Field>
                  <FlexWrapper>
                    <FieldWrapper>
                      <FieldTitle>Price per participant per month</FieldTitle>
                      <Field id="price" name="price">
                        {({ field }) => (
                          <CustomInput
                            errorIcon={false}
                            placeholder="Price"
                            outlined
                            height="4rem"
                            type="number"
                            borderRadius="8px"
                            margin="0 0 2.4rem"
                            value={field.value}
                            onChange={(val) => {
                              setFieldValue(field.name, val);
                            }}
                            error={touched[field.name] && !!errors[field.name]}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                    <FieldWrapper>
                      <FieldTitle>Max number of participants</FieldTitle>
                      <Field id="participants" name="participants">
                        {({ field }) => (
                          <CustomInput
                            errorIcon={false}
                            placeholder="Number of participants"
                            type="number"
                            outlined
                            height="4rem"
                            borderRadius="8px"
                            margin="0"
                            value={field.value}
                            onChange={(val) => {
                              setFieldValue(field.name, val);
                            }}
                            error={touched[field.name] && !!errors[field.name]}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </FlexWrapper>
                  <FlexWrapper>
                    <FieldWrapper>
                      <FieldTitle>Training Type</FieldTitle>
                      <Field id="speciality" name="speciality">
                        {({ field }) => (
                          <ReactSelectWitness
                            error={touched[field.name] && !!errors[field.name]}
                            options={specList}
                            value={field?.value}
                            placeholder="- Choose training type -"
                            onChange={(v) => {
                              if (v?.value !== field?.value?.value) {
                                setFieldValue('trainingTypes', []);
                              }
                              setFieldValue(field?.name, v);
                            }}
                            backspaceRemovesValue={false}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                    <FieldWrapper disabled={!values?.speciality}>
                      <FieldTitle>Training Subtypes</FieldTitle>
                      <Field id="trainingTypes" name="trainingTypes">
                        {({ field }) => (
                          <ReactSelectWitness
                            checkBoxes
                            error={touched[field.name] && !!errors[field.name]}
                            options={values?.speciality?.trainingTypes?.map((a) => ({
                              value: a?.id,
                              label: a?.name,
                            }))}
                            placeholder="- Choose training types -"
                            isMulti={values?.speciality?.label === 'Strength'}
                            value={field?.value}
                            onChange={(v) => {
                              if (values?.speciality?.label !== 'Strength') {
                                setFieldValue(field?.name, [v]);
                              } else if (!v || v?.length <= 3) {
                                setFieldValue(field?.name, v);
                              }
                            }}
                            onDeleteOne={(v) => {
                              setFieldValue(
                                field.name,
                                field?.value?.filter((x) => x?.value !== v),
                              );
                            }}
                            backspaceRemovesValue={false}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                    {/* <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        flexGrow: 1,
                      }}
                    >
                      <FieldTitle>Training Type</FieldTitle>
                      <Field id="trainingType" name="trainingType">
                        {({ field }) => (
                          <CustomCheckMulti
                            disabled={false}
                            color="#373333"
                            outlined
                            borderRadius="8px"
                            fontSize="1.4rem"
                            style={{ margin: 0 }}
                            value={field.value}
                            onChange={(val) => setFieldValue(field.name, val)}
                            error={touched[field.name] && !!errors[field.name]}
                            margin="10px 0 0"
                            options={ttList}
                          />
                        )}
                      </Field>
                    </div> */}
                  </FlexWrapper>
                </ModalColumn>
              </ModalRow>
              <ModalRow>
                <ModalColumn left>
                  <FieldTitle>Program Length</FieldTitle>
                  <FlexWrapper style={values.programLength ? { opacity: '50%' } : {}}>
                    <Field id="startDate" name="startDate">
                      {({ field }) => (
                        <CustomInput
                          errorIcon={false}
                          placeholder="-Start Date-"
                          type="date"
                          outlined
                          height="4.4rem"
                          borderColor="rgba(74,74,74,0.12)"
                          margin="0 1.2rem 2.4rem 0"
                          onChange={async (v) => {
                            await setFieldValue(field.name, v);
                            await setFieldValue('programLength', null);
                            validateForm();
                          }}
                          value={field.value}
                          borderRadius="8px"
                          error={touched[field.name] && !!errors[field.name]}
                        />
                      )}
                    </Field>
                    <Field id="endDate" name="endDate">
                      {({ field }) => (
                        <CustomInput
                          errorIcon={false}
                          type="date"
                          outlined
                          height="4.4rem"
                          borderColor="rgba(74,74,74,0.12)"
                          margin="0 0 2.4rem"
                          onChange={async (v) => {
                            await setFieldValue(field.name, v);
                            await setFieldValue('programLength', null);
                            validateForm();
                          }}
                          value={field.value}
                          borderRadius="8px"
                          error={touched[field.name] && !!errors[field.name]}
                        />
                      )}
                    </Field>
                  </FlexWrapper>
                  <FlexWrapper style={{ marginBottom: '3.2rem' }}>
                    <FieldTitle style={{ margin: 0 }}>Or choose:</FieldTitle>
                    <Field id="programLength" name="programLength">
                      {({ field }) => (
                        <CustomCheckSingle
                          itemStyles={{ marginBottom: '0.2rem' }}
                          value={{ key: field.value }}
                          onChange={async (v) => {
                            await setFieldValue(field.name, v?.key);
                            await setFieldValue('startDate', null);
                            await setFieldValue('endDate', null);
                            validateForm();
                          }}
                          options={[
                            { key: 180, label: '6 Months' },
                            { key: 360, label: '1 Year' },
                            { key: 730, label: '2 Years' },
                          ]}
                        />
                      )}
                    </Field>
                  </FlexWrapper>
                  <FieldTitle style={{ marginBottom: '0.4rem' }}>Training Days</FieldTitle>
                  <DaysContainer>
                    <Field id="daysOfTraining" name="daysOfTraining">
                      {({ field }) => (
                        <>
                          {weekDays.map((day) => {
                            return (
                              <DayWrapper
                                key={day}
                                checked={field.value?.indexOf(day) > -1}
                                error={touched[field.name] && !!errors[field.name]}
                                onClick={() => {
                                  const currentValue = [...field.value];
                                  const index = currentValue.indexOf(day);
                                  if (index > -1) {
                                    currentValue.splice(index, 1);
                                  } else {
                                    currentValue.push(day);
                                  }
                                  setFieldValue(`daysOfTraining`, currentValue);
                                }}
                              >
                                {day}
                              </DayWrapper>
                            );
                          })}
                        </>
                      )}
                    </Field>
                  </DaysContainer>
                </ModalColumn>
                <ModalColumn>
                  <FieldTitle>Group Description</FieldTitle>
                  <Field id="description" name="description">
                    {({ field }) => (
                      <CustomTextArea
                        style={{
                          fontFamily: 'Roboto-l',
                          letterSpacing: '0.05rem',
                          maxHeight: '100%',
                        }}
                        wrapperStyle={{
                          height: '26.55rem',
                          borderColor:
                            touched[field.name] && !!errors[field.name] ? '#F50A4F' : '#d2d1d1',
                        }}
                        rows={null}
                        onChange={(v) => setFieldValue(field.name, v)}
                        value={field.value}
                      />
                    )}
                  </Field>
                </ModalColumn>
              </ModalRow>
              <ModalFooter>
                <ButtonWrapper>
                  <CustomButton fontSize="1.4rem" green type="submit">
                    Save Changes
                  </CustomButton>
                </ButtonWrapper>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </WholeWrapper>
    </Modal>
  );
};

export default AddGroupModal;
