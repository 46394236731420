import styled, { keyframes } from 'styled-components';
import ICONS from '../../constants/icons';

const Spin = keyframes`
  0% {
    transform: rotate(0)
  }
  12.5% {
    transform: rotate(45deg)
  }
  25% {
    transform: rotate(90deg)
  }
  37.5% {
    transform: rotate(135deg)
  }
  50% {
    transform: rotate(180deg)
  }
  62.5% {
    transform: rotate(225deg)
  }
  75% {
    transform: rotate(270deg)
  }
  87.5% {
    transform: rotate(315deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  height: 100vh;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10% 0;
`;

export const LoaderSign = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  background-image: url(${ICONS.Loader});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 4.8rem;
  animation: ${Spin} 800ms infinite;
  animation-timing-function: steps(1);
`;

export const LoaderText = styled.div`
  font-family: Space-r;
  font-size: 2rem;
  line-height: 3rem;
  color: #050000;
`;
