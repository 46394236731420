import FitnessGirlSignupImageFirst from '../assets/signup/fitnessGirlSignup.png';
import FitnessGirlSignupImage from '../assets/signup/FitnessGirlSignup2.png';
import FitnessBoySignupImage from '../assets/signup/fitnessBoySignup.png';
import CropBackground from '../assets/signup/cropBackground.png';
import SigninBackground from '../assets/signin/background.png';
import PasswordBackground from '../assets/set-password/background.png';
import LogoImage from '../assets/common/logo-white.png';

import TempProgram1 from '../assets/temp/temp-program-1.jpg';
import TempProgram1Opacity from '../assets/temp/temp-program-1-opacity.png';
import TempProgram2 from '../assets/temp/temp-program-2.png';
import TempProgram3 from '../assets/temp/temp-program-3.jpg';

import EmptyTrainees from '../assets/empty-state/empty-trainees.png';
import EmptyGroups from '../assets/empty-state/empty-groups.png';
import EmptyTemplates from '../assets/empty-state/empty-templates.png';

import HomeBanner from '../assets/dashboard/homeBanner.png';

const images = {
  FitnessGirlSignupImageFirst,
  FitnessGirlSignupImage,
  FitnessBoySignupImage,
  CropBackground,
  TempProgram1,
  TempProgram2,
  TempProgram3,
  SigninBackground,
  LogoImage,
  PasswordBackground,
  EmptyTrainees,
  EmptyGroups,
  EmptyTemplates,
  HomeBanner,
  TempProgram1Opacity,
};

export default images;
