import React, { useState } from 'react';

import {
  ProgramRequestsContainer,
  ProgramRequestsHeader,
  ProgramRequestsSeeAll,
  ProgramRequestsNavBar,
  ProgramRequestsNavItem,
  ProgramRequestsList,
} from './components';
import useProgramRequest from '../../hooks/programRequest';
import ProgramList from './ProgramList';
import { ProgramStatus } from '../../constants/enums';

const statuses = [
  { label: 'All', value: null },
  { label: 'New', value: ProgramStatus.PENDING },
  { label: 'Approved', value: ProgramStatus.APPROVED },
  { label: 'Declined', value: ProgramStatus.DECLINED },
  { label: 'Cancelled', value: ProgramStatus.CANCELLED },
];

const DashboardProgramRequests = ({ openRequest }) => {
  const [active, setActive] = useState('PENDING');
  const { programRequests, programRequestsLoading } = useProgramRequest({ requestStatus: active });

  return (
    <ProgramRequestsContainer>
      <ProgramRequestsHeader>
        PROGRAM REQUESTS
        {/* <ProgramRequestsSeeAll>See All</ProgramRequestsSeeAll> */}
      </ProgramRequestsHeader>
      <ProgramRequestsNavBar>
        {statuses.map((status) => (
          <ProgramRequestsNavItem
            key={status.value}
            active={active === status.value}
            onClick={() => setActive(status.value)}
          >
            {status.label}
          </ProgramRequestsNavItem>
        ))}
      </ProgramRequestsNavBar>
      <ProgramRequestsList>
        <ProgramList
          items={programRequests?.getPrograms}
          openRequest={openRequest}
          status={active}
          isLoading={programRequestsLoading}
        />
      </ProgramRequestsList>
    </ProgramRequestsContainer>
  );
};

export default DashboardProgramRequests;
