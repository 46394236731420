import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const CardContainer = styled.div`
  min-width: 220px;
  width: max(220px, Calc(90% / 6));
  max-width: 320px;
  flex-grow: 1;
  height: 315px;
  border-radius: 8px;
  border: 1px solid rgba(5, 0, 0, 0.12);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 1.2rem;
  overflow: hidden;
`;
export const TemplateCardContainer = styled.div`
  min-width: 22rem;
  width: max(22rem, Calc(90% / 6));
  flex-grow: 1;
  /* height: 315px; */
  border-radius: 8px;
  border: 1px solid rgba(5, 0, 0, 0.12);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 1.2rem;
  overflow: hidden;
  cursor: ${({ withoutFooterButton }) => (withoutFooterButton ? 'pointer' : 'inherit')};
`;

export const PseudoTraineeCard = styled.div`
  min-width: 220px;
  max-width: 320px;
  width: max(220px, Calc(90% / 6));
  flex-grow: 1;
  margin: 12px;
`;

export const PseudoTemplateCard = styled.div`
  min-width: max(22rem, Calc(100% / 6 - 2.4rem));
  width: Calc(100% / 6 - 2.4rem);
  flex-grow: 1;
  margin: 1.2rem;
`;

export const PseudoParticipantCard = styled.div`
  min-width: 24rem;
  width: max(26rem, Calc(100% / 6 - 2.4rem));
  flex-grow: 1;
  margin: 12px;
`;

export const CardHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-bottom: 1px solid #05000016;
  position: relative;
  justify-content: center;
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const CardHeaderIconCell = styled.div`
  flex: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusCircle = styled.div`
  width: 14px;
  height: 14px;
  background: ${({ status, theme }) =>
    status === 'ONGOING'
      ? theme.color.primary
      : status === 'APPROVED'
      ? '#ffcc00'
      : status === 'DECLINED'
      ? theme.color.error
      : 'transparent'};
  border: 3px solid
    ${({ status, theme }) => (status === 'FINISHED' ? theme.color.primary : 'transparent')};
  border-radius: 50%;
`;

export const MessengerIcon = styled.div`
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  &.hoverable_message_button:hover {
    background: rgba(245, 10, 79, 0.8);
  }
`;

export const MessengerIconImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ active }) => (active ? ICONS.MessageActive : ICONS.Message)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-size: 60% auto;
`;

export const HeaderProfileCell = styled.div`
  flex: 1;
  flex-grow: 2;
  display: flex;
  justify-content: center;
  border-right: 1px solid #05000016;
  border-left: 1px solid #05000016;
  position: relative;
`;

export const ProfilePic = styled.div`
  width: ${({ width }) => width || '8.8rem'};
  height: ${({ width }) => width || '8.8rem'};
  position: absolute;
  bottom: Calc(${({ width }) => width || '8.8rem'} / -2);
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 0 0 2px #d8d8d8;
  background-color: #d8d8d8;
  background-image: url(${({ profilePic }) => profilePic}) !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  letter-spacing: 0.8px;
  color: ${({ image }) => (image ? 'transparent' : '#cccccc')};
  text-transform: uppercase;
  font-size: 2rem;
  color: white;
`;

export const CardBody = styled.div`
  flex-grow: 1;
  padding: 60px 2.1rem 2.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ParticipantCardBody = styled.div`
  flex-grow: 1;
  padding: 60px 1.4rem 2.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardName = styled.div`
  width: 100%;
  font-family: Gotham-r;
  color: #373333;
  text-align: center;
  margin: auto;
  margin-bottom: 24px;
`;

export const CardLevel = styled.div`
  width: 100%;
  font-size: 1.2rem;
  font-family: Roboto-r;
  color: #373333;
  text-align: center;
  margin: auto;
  margin-bottom: 24px;
`;

export const CardInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const CardInfoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardInfoText = styled.div`
  font-family: ${({ val }) => (val ? 'Roboto-r' : 'Roboto-l')};
  min-width: ${({ val }) => (val ? '4rem' : 'fit-content')};
  max-width: ${({ val }) => (val ? '50%' : 'fit-content')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
  line-height: 18px;
  color: #373333;
`;

export const CardFooter = styled.div`
  width: 100%;
  height: 60px;
  border-top: 1px solid #05000016;
  display: flex;
  justify-content: space-evenly;
  position: relative;
`;

export const FooterLine = styled.div`
  width: 1px;
  height: 100%;
  background: #05000016;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  width: 86%;
  height: Calc(100% - 20px);
  top: 10px;
  left: 7%;
  border-radius: 8px;
  &:hover {
    box-shadow: -1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const ParticipantCardFooter = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid #05000016;
`;

export const ParticipantHealthCell = styled.div`
  width: 50%;
  height: 9.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.2rem;
  border-left: 0.5px solid #05000010;
  &:first-child {
    border-left: none;
    border-right: 0.5px solid #05000010;
  }
  color: #37333399;
  font-family: Roboto-l;
  font-size: 1.2rem;
`;

export const ParticipantHealthCellValue = styled.div`
  color: #373333;
  height: 3.6rem;
  font-family: Roboto-r;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const ParticipantPhysicsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const ParticipantPhysic = styled.div`
  font-family: Space-b;
  font-size: 1.8rem;
  color: #373333cc;
  padding: 0 1rem 0 2.4rem;
  background-image: url(${({ physIcon }) => physIcon});
  background-repeat: no-repeat;
  background-position: left;
  background-size: 2rem auto;
`;

// list item styling

export const FlexTDContent = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'flex-start')};
  position: relative;
`;

export const HoverIndicator = styled.div`
  position: absolute;
  height: 116%;
  width: 3px;
  left: -3.2rem;
  border-radius: 0 10rem 10rem 0;
  top: -6%;
  display: block;
  background: ${({ theme }) => theme.color.primary};
  display: none;
`;

export const TraineeRow = styled.tr`
  width: 100%;
  background: white;
  margin: 0.6rem;
  border-radius: 8px;
  position: relative;
  &:hover {
    box-shadow: 0px 0px 4px 2px #00000012;
  }
  &:hover * .hover_indicator {
    display: block;
  }
  &:hover * .hoverable_message_button {
    border-color: rgba(245, 10, 79, 0.8) !important;
    transform: scale(1.05);
  }
  &:hover * .hoverable_view_button {
    border-color: ${({ theme }) => theme.color.primary} !important;
    transform: scale(1.05);
  }
  &:hover > * {
    color: #050000;
  }
`;

export const TraineeTD = styled.td`
  padding: 1.4rem 0 1.4rem 3.2rem;
  text-align: left;
  color: #373333e6;
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    padding-right: 3.2rem;
  }
`;

export const TraineeRowProfilePic = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 0.8rem;
  background-color: #d8d8d8;
  background-image: url(${({ profilePic }) => profilePic});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  letter-spacing: 0.8px;
  color: ${({ image }) => (image ? 'transparent' : '#cccccc')};
  text-transform: uppercase;
  font-size: 1.5rem;
  color: white;
  margin-right: 2rem;
`;
