import React, { useContext } from 'react';
import moment from 'moment';

import {
  CardContainer,
  CardHeader,
  CardHeaderIconCell,
  StatusCircle,
  HeaderProfileCell,
  ProfilePic,
  MessengerIcon,
  MessengerIconImg,
  CardBody,
  CardName,
  CardFooter,
  FooterLine,
  ButtonWrapper,
  CardInfo,
  CardInfoText,
  CardInfoRow,
  TraineeRow,
  TraineeTD,
  TraineeRowProfilePic,
  FlexTDContent,
  HoverIndicator,
} from './components';
import CustomButton from '../button/CustomButton';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { CoachProgramStatusUI } from '../../constants/enums';
import ChatContext from '../../services/ChatContext';

const TraineeCard = ({ isGroup, program, layout = 'list' }) => {
  const history = useHistory();

  const endDate = useMemo(() => {
    if (!program) return 'N/A';

    return moment(program.coachProgram.beginningDate)
      .add(program.programLength, 'days')
      .format('DD/MM/YYYY');
  }, [program]);

  const { conversations, setCurrentConversation } = useContext(ChatContext);

  const { conversation, hasUnread } = useMemo(() => {
    const groupId = isGroup ? program.uid : program.trainee?.user?.uid;
    const conversation = conversations[groupId];

    return {
      conversation,
      hasUnread: !!(conversation && conversation.unreadMessageCount
        ? conversation.unreadMessageCount
        : 0),
    };
  }, [conversations, isGroup, program]);
  return (
    <>
      {layout === 'LIST' ? (
        <TraineeRow>
          <TraineeTD>
            <FlexTDContent>
              <HoverIndicator className="hover_indicator" />
              <TraineeRowProfilePic profilePic={program?.trainee?.avatar?.url || ''}>
                {!program?.trainee?.avatar?.url &&
                  program?.trainee?.fullName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')}
              </TraineeRowProfilePic>
              {program?.trainee?.fullName}
            </FlexTDContent>
          </TraineeTD>
          <TraineeTD>
            <FlexTDContent>
              <StatusCircle
                status={program?.coachProgram?.status}
                style={{ marginRight: '0.8rem', width: '1.2rem', height: '1.2rem' }}
              />
              {CoachProgramStatusUI[program?.coachProgram?.status]}
            </FlexTDContent>
          </TraineeTD>
          <TraineeTD>
            {program?.trainingTypes?.map((a) => a?.name)?.join(', ') || program?.trainingType?.name}
          </TraineeTD>
          {isGroup && (
            <TraineeTD style={{ textAlign: 'center', padding: 0 }}>{`${
              program?.coachProgram?.participants?.length || '0'
            }/${program?.participants}`}</TraineeTD>
          )}
          <TraineeTD>{endDate}</TraineeTD>
          <TraineeTD>
            <FlexTDContent flexEnd>
              <MessengerIcon
                className="hoverable_message_button"
                style={{
                  marginRight: '1.6rem',
                  border: '1px solid #F1F0F0',
                  borderRadius: '8px',
                  width: '3.2rem',
                  height: '3.2rem',
                }}
                onClick={() => {
                  setCurrentConversation(conversation);
                  history.push(
                    `/program/${program?.uid}${(isGroup && '?group=true&tab=chat') || '?tab=chat'}`,
                  );
                }}
              >
                <MessengerIconImg active={hasUnread} />
              </MessengerIcon>
              <div style={{ width: '12.4rem' }}>
                <CustomButton
                  className="hoverable_view_button"
                  outlined
                  borderRadius="8px"
                  hoverBackground="#00ff91"
                  outlineColor="#F1F0F0"
                  backgroundColor="#ffffff"
                  height="3.2rem"
                  fontSize="1.4rem"
                  onClick={() =>
                    history.push(`/program/${program?.uid}${(isGroup && '?group=true') || ''}`)
                  }
                >
                  View Program
                </CustomButton>
              </div>
            </FlexTDContent>
          </TraineeTD>
        </TraineeRow>
      ) : (
        <CardContainer>
          <CardHeader>
            <CardHeaderIconCell>
              <StatusCircle status={program?.coachProgram?.status} />
            </CardHeaderIconCell>
            <HeaderProfileCell>
              <ProfilePic profilePic={program?.trainee?.avatar?.url || ''}>
                {!program?.trainee?.avatar?.url &&
                  program?.trainee?.fullName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')}
              </ProfilePic>
            </HeaderProfileCell>
            <CardHeaderIconCell>
              <MessengerIcon
                onClick={() => {
                  setCurrentConversation(conversation);
                  history.push(
                    `/program/${program?.uid}${(isGroup && '?group=true&tab=chat') || '?tab=chat'}`,
                  );
                }}
              >
                <MessengerIconImg active={hasUnread} />
              </MessengerIcon>
            </CardHeaderIconCell>
          </CardHeader>
          <CardBody>
            <CardName>{program?.trainee?.fullName}</CardName>
            <CardInfo>
              <CardInfoRow>
                <CardInfoText>Training Types</CardInfoText>
                <CardInfoText val>
                  {program?.trainingTypes?.map((a) => a?.name)?.join(', ') ||
                    program?.trainingType?.name}
                </CardInfoText>
              </CardInfoRow>
              <CardInfoRow>
                <CardInfoText>Program ends </CardInfoText>
                <CardInfoText val>{endDate}</CardInfoText>
              </CardInfoRow>
            </CardInfo>
          </CardBody>
          <CardFooter>
            <ButtonWrapper>
              <CustomButton
                outlined
                borderRadius="8px"
                hoverBackground="#00ff91"
                backgroundColor="#ffffff"
                onClick={() =>
                  history.push(`/program/${program?.uid}${(isGroup && '?group=true') || ''}`)
                }
              >
                View Program
              </CustomButton>
            </ButtonWrapper>
            <FooterLine />
            <FooterLine />
            <FooterLine />
          </CardFooter>
        </CardContainer>
      )}
    </>
  );
};

export default TraineeCard;
