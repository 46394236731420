export const OneSignal = window.OneSignal || [];

// if (OneSignal.installServiceWorker) {
//   OneSignal.installServiceWorker();
// } else if (navigator.serviceWorker) {
//   navigator.serviceWorker.register(
//     `/OneSignalSDKWorker.js?appId=${process.env.REACT_APP_SIGNAL_KEY}`,
//   );
// }

// OneSignal.init({
//   appId: process.env.REACT_APP_SIGNAL_KEY,
//   allowLocalhostAsSecureOrigin: true,
//   autoResubscribe: true,
// });
