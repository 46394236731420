import styled from 'styled-components';

export const CallContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const CallContent = styled.div`
  width: 100%;
  height: 100%;
`;
