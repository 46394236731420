import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  min-height: Calc(100vh - 64px);
  background: #fafafa;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  min-height: 6rem;
  display: flex;
  align-items: center;
  line-height: 2.4rem;
  font-family: Space-r;
  border-bottom: 1px solid #0500002a;
  font-size: 2.2rem;
  padding: 0 2.8rem;
  justify-content: space-between;
`;

export const ModalContent = styled.div`
  width: 100%;
  padding: 0 3.2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ParticipantContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  ${({ group }) =>
    group
      ? `
  background: #ffffff;
  padding: 0 1.6rem 1.6rem;
  border-radius: 8px;
  box-shadow: 0 0 1px 1px #0500000a;
  `
      : ''}
`;

export const WorkoutName = styled.div`
  width: 33%;
  border-right: 1px solid #0500001a;
  border-left: 1px solid #0500001a;
  padding: 3rem;
  font-family: Roboto-m;
  font-size: 2.2rem;
  line-height: 2.4rem;
  color: #050000cc;
  text-align: center;
  align-self: center;
  & > span {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-family: Gotham-r;
    padding-right: 2.2rem;
  }
`;

export const EntireMessageHeading = styled.div`
  width: Calc(33% + 2rem);
  border-right: 1px solid #0500001a;
  border-left: 1px solid #0500001a;
  padding: 3.2rem 1rem 2.6rem;
  font-family: Space-r;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #050000cc;
  text-align: center;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.2rem;
`;

export const ModalHeaderCenterLine = styled.div`
  width: 1px;
  height: 100%;
  background: #0500001a;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

// workout block result box

export const WorkoutBlockResult = styled.div`
  width: 100%;
  background: #f1f0f0;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  display: flex;
  margin-bottom: 1.6rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BlockExercisesSide = styled.div`
  flex-grow: 1;
`;

export const BlockExercisesHeader = styled.div`
  height: 6.4rem;
  display: flex;
  align-items: center;
  font-family: Roboto-m;
  padding: 0 2.4rem;
  border-bottom: 1px solid #cccccccc;
`;

export const BlockExercisesRest = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  font-family: Roboto-m;
  padding: 0 2.4rem;
`;

export const BlockExercisesHeaderLabel = styled.div`
  background: #ffffff;
  padding: 0.6rem 1.2rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #050000cc;
  margin: 0 1.6rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
`;

export const HeaderLabelCheckmark = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(${ICONS.CheckMark});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 0.8rem;
`;

export const BlockResults = styled.div`
  width: 100%;
  padding: 1.2rem 2.4rem;
  flex-grow: 1;
`;

export const BlockResultsTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.6rem;
  text-align: left;
`;

export const BlockResultsTH = styled.th`
  padding: 1.2rem 3.1rem;
  font-family: Roboto-m;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #4a4a4acc;
  text-transform: capitalize;
  &:first-child {
    max-width: fit-content;
    width: 3rem;
    padding: 2rem;
  }
  /* &:last-child {
    width: 4rem;
    max-width: fit-content;
  } */
  &:nth-child(2) {
    padding-left: 2.3rem;
  }
`;

export const BlockResultsRow = styled.tr`
  width: 100%;
  background: white;
  margin: 0.6rem;
  border-radius: 4px;
  position: relative;
  &:hover {
    box-shadow: 0px 0px 3px 1px #00000012;
  }
  &:hover * .hover_indicator {
    display: block;
  }
  &:hover * .hoverable_view_icon {
    background-image: url(${ICONS.EnvelopeActiveIcon});
  }
  &:hover > * {
    font-family: Roboto-r;
  }
`;

export const BlockResultsTD = styled.td`
  padding: 1.8rem 2rem 1.8rem 3.2rem;
  text-align: left;
  font-family: Roboto-l;
  font-size: 1.4rem;
  line-height: 2rem;
  position: relative;
  &:nth-child(2) {
    border-right: 2px solid #f1f0f0;
    border-left: 2px solid #f1f0f0;
    padding: 1.8rem 2rem;
  }
  &:first-child {
    padding: 0 1rem;
    border-radius: 4px 0 0 4px;
    text-align: center;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
    /* padding-right: 3.2rem; */
  }
`;

export const HoverIndicator = styled.div`
  position: absolute;
  height: ${({ height }) => height || '76%'};
  width: ${({ width }) => width || '2px'};
  left: 0;
  border-radius: 0 10rem 10rem 0;
  top: ${({ height }) => (height ? `Calc((100% - ${height})/2)` : '12%')};
  background: ${({ theme }) => theme.color.primary};
  display: none;
`;

export const HistoryViewEye = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.4rem;
  background-image: url(${ICONS.EnvelopeIcon});
  background-size: 90% auto;
  background-position: center;
  background-repeat: no-repeat;
`;

export const FlexTDContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
`;

export const BlockFeedbackSide = styled.div`
  width: Calc(33.5% + 0.5px);
  padding: 0.8rem 0.8rem 0.8rem 0;
`;

export const BlockFeedback = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  border: 0.5px solid rgba(5, 0, 0, 0.1);
`;

export const BlockFeedbackContent = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 2.4rem 2.4rem 1.8rem;
  display: flex;
  flex-direction: column;
  position: relative;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 11px 12px 0;
    border-color: transparent rgba(5, 0, 0, 0.1) transparent transparent;
    position: absolute;
    right: 100%;
    top: Calc(50% - 12px);
  }
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 10px 12px 0;
    border-color: transparent #ffffff transparent transparent;
    position: absolute;
    right: 100%;
    top: Calc(50% - 12px);
  }
`;

export const FeedbackHeading = styled.div`
  font-size: 1.4rem;
  color: #4a4a4a;
  line-height: 2.1rem;
  font-family: Roboto-m;
  margin-bottom: 1.2rem;
`;

export const FeedbackTextAreaWrapper = styled.div`
  flex-grow: 1;
`;

export const BlockFeedbackHeader = styled.div`
  height: 5.5rem;
  display: flex;
  align-items: center;
  font-family: Roboto-r;
  padding: 0 2.4rem;
  font-size: 1.4rem;
  color: #4a4a4a;
  border-bottom: 1px solid #0500001a;
`;

export const BLockAttachment = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  background: #f1f0f0;
  background-image: url(${({ att }) => att});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 0.8rem;
  cursor: pointer;
  border-radius: 4px;
`;

export const ResultVideoButton = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  background-image: url(${ICONS.VideoSignGrey});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.6rem;
`;

export const EntireMessageWrapper = styled.div`
  width: 100%;
  padding: 1.2rem;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

export const BlocksWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
`;

// group results

export const EntireGroupResultsWrapper = styled.div`
  width: 100%;
  padding: 0 1.6rem 1.6rem;
`;

export const EntireGroupResults = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 0 1px 1px rgba (0, 0, 0, 0.04);
`;

export const EntireGroupHeader = styled.div`
  width: 100%;
  height: 4.8rem;
  position: relative;
  border-bottom: 1px solid #0500002a;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
`;

export const EntireGroupHeading = styled.div`
  padding: 1.2rem 1.6rem;
  line-height: 2rem;
  position: absolute;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  color: #4a4a4a;
  bottom: -2.2rem;
  background: #ffffff;
`;

export const EntireGroupContent = styled.div`
  padding: 0.8rem 1.6rem;
`;

export const GroupWorkoutsWrapper = styled.div`
  padding: 3.2rem;
`;

export const GroupParticipantsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: Roboto-m;
  position: relative;
  padding-right: 1.6rem;
  align-items: center;
  & > div {
    font-family: Roboto-m;
  }
  &::after {
    content: '';
    position: absolute;
    width: 1.4rem;
    height: 2.8rem;
    right: -1rem;
    background-image: url(${ICONS.Logo});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

export const GroupParticipantsContent = styled.div`
  padding: 4rem 3.2rem;
`;

export const GroupParticipantRow = styled.div`
  padding: 0.8rem 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f1f0f0;
  border-radius: 8px;
  margin-bottom: 1.2rem;
  cursor: pointer;
  position: relative;
  &:hover {
    border-color: ${({ theme }) => theme.color.primary};
  }
  &:hover > .hover_indicator {
    display: block;
  }
`;

export const GroupParticipantImage = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 8px;
  background: #cececeaa;
  margin-right: 1.2rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto-b;
  font-size: 1.4rem;
  text-transform: uppercase;
`;

export const GroupParticipantName = styled.div`
  flex-grow: 1;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #373333;
`;

export const GroupParticipantResultIndicator = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IndicatorDone = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  background-image: url(${ICONS.Logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const IndicatorGeneral = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: ${({ status, theme }) => (status === 'UNDONE' ? theme.color.error : '#FFCC00')};
`;

export const IndividualTraineeHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IndividualTraineeHeaderMain = styled.div`
  width: Calc(33% + 2rem);
  padding: 2rem;
  border-right: 1px solid #0500001a;
  border-left: 1px solid #0500001a;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: Calc(33% + 2rem);
  & > span {
    font-family: Gotham-r;
    font-size: 1.8rem;
    color: #4a4a4a;
    padding: 0 1.2rem;
  }
`;

export const IndividualTraineePic = styled.div`
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 0 2px 2px #00ff91;
  background: #cececeaa;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto-b;
  font-size: 1.4rem;
  text-transform: uppercase;
`;

export const IndividualTraineeHeaderSide = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const IndividualTraineeBackIcon = styled.div`
  width: 2.4rem;
  height: 2rem;
  background-image: url(${ICONS.ArrowLeft});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.2rem;
`;

export const IndividualTraineeBack = styled.div`
  font-size: 1.4rem;
  padding: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const NoResults = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Space-r;
  color: #373333cc;
  padding: 2.2rem;
`;

export const WorkoutBlockComment = styled.div`
  padding-left: 3.6rem;
  min-height: 2.5rem;
  font-family: Roboto-l;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background-image: url(${ICONS.Comment});
  background-size: 2.5rem auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-top: 2rem;
  margin-left: 0.8rem;
`;

export const ToggleDetailsLink = styled.span`
  margin-left: 1.2rem;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #050000cc;
  text-decoration: underline;
`;
