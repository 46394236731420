import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const PricingTableWrapper = styled.div`
  padding: 0 3.2rem;
  width: 100%;
`;
export const PricingTable = styled.table`
  width: 100%;
  padding: 0.4rem 3.2rem;
  background: #fafafa;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0 4px;
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

export const PricingTableHeadTR = styled.tr`
  width: 100%;
  font-family: Roboto-r;
  color: #373333;
`;
export const PricingTableTR = styled.tr`
  width: 100%;
  border-radius: 8px;
  border: 1px solid red;
  color: #373333;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 4px 0.4px rgba(0, 0, 0, 0.05);
    color: #050000;
    & .copy {
      background-image: url(${ICONS.CopyActive});
      &:active {
        background-image: url(${ICONS.Copy});
      }
    }
    & .edit {
      background-image: url(${ICONS.EditStroke});
    }
    & .del {
      background-image: url(${ICONS.DeleteStroke});
    }
  }
`;

export const PricingTableTh = styled.th`
  padding: 2rem 1.6rem;
  color: #4a4a4aaa;
  font-family: Roboto-l;
  text-align: left;
`;

export const PricingTableTd = styled.td`
  padding: 2rem 1.6rem;
  min-width: ${({ width }) => (width ? width : 'fit-content')};
  background: #ffffff;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  color: inherit;
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`;

export const PricingTableTdCopy = styled.span`
  padding: 1.6rem;
  background-image: url(${ICONS.Copy});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
`;

export const PricingTableTdAction = styled.span`
  padding: 1.6rem;

  &.edit {
    background-image: url(${ICONS.EditGrey});
    &:hover {
      background-image: url(${ICONS.EditFill});
    }
    &:active {
      background-image: url(${ICONS.EditStroke});
    }
  }
  &.del {
    background-image: url(${ICONS.DeleteGrey});
    &:hover {
      background-image: url(${ICONS.DeleteFill});
    }
    &:active {
      background-image: url(${ICONS.DeleteStroke});
    }
  }
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-right: 1rem;
  &:last-child {
    margin: 0;
  }
`;

export const SortSpecification = styled.span`
  padding: 0.5rem;
  background-image: ${({ asc, desc }) =>
    asc ? `url(${ICONS.SortAsc})` : desc ? `url(${ICONS.SortDesc})` : `url(${ICONS.SortNone})`};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin: 0 0 0 1rem;
  cursor: pointer;
`;
