import React from 'react';
import {
  FiltersForm,
  FiltersFormTitlte,
  FiltersTitle,
  LayoutOption,
  TraineesFilters,
} from './components';
import SelectStatus from './SelectStatus';
import CustomInput from '../../components/input/CustomInput';
import AnIcon from '../../assets/input/search-icon.png';

const iconStyles = {
  borderRight: '1px solid #050000',
  height: '100%',
  width: '4.8rem',
  margin: 0,
  backgroundSize: '48% auto',
  opacity: 0.2,
};

const ProgramsHeader = ({
  statusOpts,
  status,
  setStatus,
  traineeName,
  setTraineeName,
  heading,
  additionalButton,
  layout,
  setLayout,
  statusFilter = true,
  nameFilter = true,
}) => {
  return (
    <>
      <TraineesFilters>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FiltersTitle>{heading}</FiltersTitle>
          {layout && (
            <>
              <LayoutOption checked={layout === 'CARDS'} onClick={() => setLayout('CARDS')} grid />
              <LayoutOption checked={layout === 'LIST'} onClick={() => setLayout('LIST')} />
            </>
          )}
        </div>
        <FiltersForm>
          {statusFilter && (
            <>
              <FiltersFormTitlte>Filter by status</FiltersFormTitlte>
              <SelectStatus options={statusOpts} value={status} onChange={setStatus} />
            </>
          )}
          {nameFilter && (
            <CustomInput
              outlined
              height="4.4rem"
              borderRadius="8px"
              margin="0 0 0 1.55rem"
              placeholder="Search by name"
              icon={AnIcon}
              iconStyles={iconStyles}
              value={traineeName}
              onChange={setTraineeName}
            />
          )}
          {additionalButton}
        </FiltersForm>
      </TraineesFilters>
    </>
  );
};

export default ProgramsHeader;
