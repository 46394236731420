import styled, { css, keyframes } from 'styled-components';
import ICONS from '../../constants/icons';

export const ValuesWrapper = styled.div`
  width: 100%;
  position: relative;
  & * > .react-select__menu-list {
    background: red;
  }
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0.1rem 0.4rem 0.4rem;
`;

export const ValueLabel = styled.div`
  padding: 0.5rem 1rem 0.5rem 0.8rem;
  border-radius: 4px;
  background: rgba(0, 255, 145, 0.3);
  margin: 0.6rem 0.4rem 0;
  opacity: 0.8;
  font-family: Roboto-r;
  font-size: 1rem;
  color: #373333;
  line-height: 1.2rem;
  width: fit-content;
  position: relative;
`;

export const MultiValueDeleteX = styled.div`
  cursor: pointer;
  position: absolute;
  content: '';
  right: -0.6rem;
  top: -0.6rem;
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(${ICONS.PlusIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(45deg);
  border-radius: 50%;
  &:hover {
    transform: scale(1.25) rotate(45deg);
  }
`;

export const OptionsHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.1rem auto 1.2rem;
  text-align: center;
  color: #323232;
  opacity: 0.6;
  font-family: Roboto-r;
  font-size: 1.3rem;
  line-height: 1.3rem;
  ${({ warn }) =>
    warn
      ? `
  color: #F50A4F;
  opacity: 1;
  `
      : ''}
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 2.8rem;
  cursor: pointer;
`;

export const CheckBox = styled.div`
  cursor: pointer;
  width: 2.6rem;
  height: 2.5rem;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.color.primary : '#05000033')};
  background: ${({ theme, checked }) => (checked ? theme.color.primary : '#ffffff')};
  background-image: ${({ checked }) => (checked ? `url(${ICONS.TermsCheckMark})` : 'url()')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 86% auto;
  border-radius: 8px;
  margin-right: 1.6rem;
`;
