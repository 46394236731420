import React, { useMemo } from 'react';
import moment from 'moment';
import { CometChat } from '@cometchat-pro/chat';
import {
  MessageContainer,
  MessageContent,
  Avatar,
  MessageText,
  MessageSentAt,
  MessageVideo,
  MessageImage,
} from './components';

const Message = ({ message, showAvatar, isLast }) => {
  const initials = useMemo(() => {
    const [first, last] = message?.user?.name?.split(' ');
    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`;
  }, [message]);

  const time = useMemo(() => {
    const updatedAt = message.sentAt * 1000;
    return moment(updatedAt).fromNow();
  }, [message]);

  const isUnknown = useMemo(() => {
    return !Object.values(CometChat.MESSAGE_TYPE).includes(message.type);
  }, [message.type]);

  if (isUnknown) {
    return null;
  }

  return (
    <MessageContainer>
      <MessageContent rtl={message.isOwned}>
        {!message.isOwned && showAvatar && (
          <Avatar
            src={message.user.avatar}
            size="3.6rem"
            style={{ position: 'absolute', lineHeight: '1.8rem', fontSize: '1.2rem' }}
          >
            {initials}
          </Avatar>
        )}
        {message.type === CometChat.MESSAGE_TYPE.TEXT && (
          <MessageText
            hasAvatar={message.isOwned ? false : showAvatar}
            isOwned={message.isOwned}
            isLast={isLast}
          >
            {message.text}
            <MessageSentAt>{time}</MessageSentAt>
          </MessageText>
        )}
        {message.type === CometChat.MESSAGE_TYPE.IMAGE && (
          <MessageImage src={message.attachment} alt="" />
        )}
        {message.type === CometChat.MESSAGE_TYPE.VIDEO && (
          <MessageVideo controls>
            <source src={message.attachment} type="video/mp4" />
            Your browser does not support the video tag.
          </MessageVideo>
        )}
        {message.type === CometChat.MESSAGE_TYPE.CUSTOM && (
          <MessageText
            hasAvatar={message.isOwned ? false : showAvatar}
            isOwned={message.isOwned}
            isLast={isLast}
            flex
          >
            <img src={message.attachment} alt="" style={{ marginRight: '1.2rem' }} />
            <span>{message.text}</span>
            <MessageSentAt>{time}</MessageSentAt>
          </MessageText>
        )}
      </MessageContent>
    </MessageContainer>
  );
};

export default Message;
